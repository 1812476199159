import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  AnoType,
  MesType,
  VSegurosFI,
  VentasCRMFIType,
  bodegaType,
  marcaType,
  zonaType,
} from "../../types";
import { BarChart } from "@mui/x-charts";
import { blue, green, grey, orange, red } from "@mui/material/colors";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { useTheme } from "@mui/material/styles";

export const FIResumenPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    getMarcas();
    getZonas();
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);

      setUsername(account.username);
      console.log(account);
    } else {
      setName("");
    }
  }, [account]);

  const listadoMarcas: marcaType[] = [];
  const listadoZona: zonaType[] = [];
  const listaAnos: AnoType[] = [
    { id: 2023, ano: 2023 },
    { id: 2024, ano: 2024 },
    { id: 2025, ano: 2025 },
  ];

  const columns: GridColDef[] = [
    { field: "zona", headerName: "Zona", editable: false, width: 200 },
    { field: "agencia", headerName: "Agencia", editable: false, width: 200 },
    {
      field: "vhs",
      headerName: "# Vehículos",
      headerAlign: "center",
      align: "center",
      editable: false,
      width: 100,
    },
    {
      field: "porcen",
      headerName: "45%",
      headerAlign: "center",
      align: "center",
      editable: false,
      width: 100,
    },
    {
      field: "seg",
      headerName: "# Seguros",
      headerAlign: "center",
      align: "center",
      editable: false,
      width: 100,
    },
    {
      field: "psto",
      headerName: "% Presupuesto",
      editable: false,
      width: 100,
      headerAlign: "right",
      align: "right",
      valueGetter: ({ row }) => {
        return String(row.psto) + "%";
      },
    },
    {
      field: "estado1",
      headerName: "",
      editable: false,
      width: 50,
      renderCell: (params) => {
        return (
          <div>
            {params.row.psto < 30 ? (
              <RadioButtonCheckedIcon sx={{ color: red[500] }} />
            ) : params.row.psto >= 30 && params.row.psto < 60 ? (
              <RadioButtonCheckedIcon sx={{ color: orange[500] }} />
            ) : params.row.psto >= 60 ? (
              <RadioButtonCheckedIcon sx={{ color: green[500] }} />
            ) : (
              <RadioButtonCheckedIcon sx={{ color: grey[500] }} />
            )}
          </div>
        );
      },
    },

    {
      field: "colo",
      headerName: "% Colocación",
      editable: false,
      width: 100,
      headerAlign: "right",
      align: "right",
      valueGetter: ({ row }) => {
        return String(row.colo) + "%";
      },
    },
    {
      field: "estado2",
      headerName: "",
      editable: false,
      width: 50,
      renderCell: (params) => {
        return (
          <div>
            {params.row.colo < 30 ? (
              <RadioButtonCheckedIcon sx={{ color: red[500] }} />
            ) : params.row.colo >= 30 && params.row.colo < 60 ? (
              <RadioButtonCheckedIcon sx={{ color: orange[500] }} />
            ) : params.row.colo >= 60 ? (
              <RadioButtonCheckedIcon sx={{ color: green[500] }} />
            ) : (
              <RadioButtonCheckedIcon sx={{ color: grey[500] }} />
            )}
          </div>
        );
      },
    },
  ];

  const [dataMarca, setDataMarca] = useState<marcaType[]>(listadoMarcas);
  const [dataZona, setDataZona] = useState<zonaType[]>(listadoZona);
  const [dataAno, setDataAno] = useState<AnoType[]>(listaAnos);
  const [marca, setMarca] = React.useState(1);
  const [ano, setAno] = React.useState(2023);
  const [zona, setZona] = React.useState(1);
  const [mes, setMes] = React.useState(12);
  const [agencia, setAgencia] = React.useState(0);
  const [mostrar, setMostrar] = React.useState<boolean>(false);

  const handleChangeMarca = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMarca(Number(event.target.value));
    setMostrar(false);
  };
  const handleChangeAno = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAno(Number(event.target.value));
    setMostrar(false);
  };
  const handleChangeZona = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZona(Number(event.target.value));
    setMostrar(false);
  };

  async function getZonas() {
    try {
      await axios
        .get<zonaType[]>("https://apisatelite.azurewebsites.net/dimzona")
        .then((response) => {
          setDataZona(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataZona, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getMarcas() {
    try {
      await axios
        .get<marcaType[]>("https://apisatelite.azurewebsites.net/dimmarca")
        .then((response) => {
          setDataMarca(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const [listaDatos, setListaDatos] = useState<VSegurosFI[]>([]);

  async function getListado(anio: number, marca: string, id_zona:number) {
    try {
      await axios
        .get<VSegurosFI[]>(
          "https://apisatelite.azurewebsites.net/vsegurosfi/consultar/" +
            String(anio) +
            "/" +
            marca +
            "/" +
            String(id_zona)
        )
        .then((response) => {
          setListaDatos(response.data);
          setMostrar(true);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }
  interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Seguros F&I - Resumen
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "12%",
                }}
                style={{ display: "inline-block" }}
              >
                Año:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={ano}
                onChange={handleChangeAno}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "2%", width: "15%" }}
              >
                {dataAno.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.ano}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "4%",
                }}
                style={{ display: "inline-block" }}
              >
                Marca:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={marca}
                onChange={handleChangeMarca}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "2%", width: "15%" }}
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {dataMarca.map((option) => (
                  <MenuItem key={option.marId} value={option.marId}>
                    {option.marNombre}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "4%",
                }}
                style={{ display: "inline-block" }}
              >
                Zona:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={zona}
                onChange={handleChangeZona}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "2%", width: "15%" }}
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {dataZona.map((option) => (
                  <MenuItem key={option.zonId} value={option.zonId}>
                    {option.zonNombre}
                  </MenuItem>
                ))}
              </TextField>

              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 1,
                  width: "12%",
                  marginLeft: "44%",
                }}
                onClick={function (event) {
                  getListado(
                    ano,
                    marca === 1
                      ? "CHEVROLET"
                      : marca === 2
                      ? "GAC"
                      : "VOLKSWAGEN", zona
                  );
                  setMostrar(true);
                }}
              >
                Consultar
              </Button>
            </Paper>
            {mostrar ? (
              <Paper
                sx={{
                  padding: "1.2em",
                  borderRadius: "0.5em",
                  backgroundColor: "rgb(255,255,255,0.95)",
                  mt: 1,
                  mb: 4,
                }}
              >
                <Grid flexDirection="row">
                  <Box sx={{ bgcolor: "background.paper" }}>
                    <AppBar position="static">
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                      >
                        <Tab label="Enero" {...a11yProps(0)} />
                        <Tab label="Febrero" {...a11yProps(1)} />
                        <Tab label="Marzo" {...a11yProps(2)} />
                        <Tab label="Abril" {...a11yProps(3)} />
                        <Tab label="Mayo" {...a11yProps(4)} />
                        <Tab label="Junio" {...a11yProps(5)} />
                        <Tab label="Julio" {...a11yProps(6)} />
                        <Tab label="Agosto" {...a11yProps(7)} />
                        <Tab label="Septiembre" {...a11yProps(8)} />
                        <Tab label="Octubre" {...a11yProps(9)} />
                        <Tab label="Noviembre" {...a11yProps(10)} />
                        <Tab label="Diciembre" {...a11yProps(11)} />
                      </Tabs>
                    </AppBar>

                    <TabPanel value={value} index={0} dir={theme.direction}>
                      {/* Enero */}
                      <DataGrid
                        getRowId={(row) => row.id}
                        //sx={{ height: 600 }}
                        editMode="row"
                        rows={listaDatos.filter((obj) => {
                          return obj.mes === 1;
                        })}
                        columns={columns}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 20 } },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        slotProps={{
                          pagination: {
                            labelRowsPerPage: "Registros por página:",
                          },
                        }}
                        pagination
                        localeText={{
                          noRowsLabel: "No se ha encontrado datos.",
                          noResultsOverlayLabel:
                            "No se ha encontrado ningún resultado",
                          toolbarColumns: "Columnas",
                          toolbarColumnsLabel: "Seleccionar columnas",
                          toolbarFilters: "Filtros",
                          toolbarFiltersLabel: "Ver filtros",
                          toolbarFiltersTooltipHide: "Quitar filtros",
                          toolbarFiltersTooltipShow: "Ver filtros",
                        }}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                      {/* Febrero */}
                      <DataGrid
                        getRowId={(row) => row.id}
                        //sx={{ height: 600 }}
                        editMode="row"
                        rows={listaDatos.filter((obj) => {
                          return obj.mes === 2;
                        })}
                        columns={columns}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 20 } },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        slotProps={{
                          pagination: {
                            labelRowsPerPage: "Registros por página:",
                          },
                        }}
                        localeText={{
                          noRowsLabel: "No se ha encontrado datos.",
                          noResultsOverlayLabel:
                            "No se ha encontrado ningún resultado",
                          toolbarColumns: "Columnas",
                          toolbarColumnsLabel: "Seleccionar columnas",
                          toolbarFilters: "Filtros",
                          toolbarFiltersLabel: "Ver filtros",
                          toolbarFiltersTooltipHide: "Quitar filtros",
                          toolbarFiltersTooltipShow: "Ver filtros",
                        }}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={2} dir={theme.direction}>
                      {/* Marzo */}
                      <DataGrid
                        getRowId={(row) => row.id}
                        //sx={{ height: 600 }}
                        editMode="row"
                        rows={listaDatos.filter((obj) => {
                          return obj.mes === 3;
                        })}
                        columns={columns}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 20 } },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        slotProps={{
                          pagination: {
                            labelRowsPerPage: "Registros por página:",
                          },
                        }}
                        localeText={{
                          noRowsLabel: "No se ha encontrado datos.",
                          noResultsOverlayLabel:
                            "No se ha encontrado ningún resultado",
                          toolbarColumns: "Columnas",
                          toolbarColumnsLabel: "Seleccionar columnas",
                          toolbarFilters: "Filtros",
                          toolbarFiltersLabel: "Ver filtros",
                          toolbarFiltersTooltipHide: "Quitar filtros",
                          toolbarFiltersTooltipShow: "Ver filtros",
                        }}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={3} dir={theme.direction}>
                      {/* Abril */}
                      <DataGrid
                        getRowId={(row) => row.id}
                        //sx={{ height: 600 }}
                        editMode="row"
                        rows={listaDatos.filter((obj) => {
                          return obj.mes === 4;
                        })}
                        columns={columns}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 20 } },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        slotProps={{
                          pagination: {
                            labelRowsPerPage: "Registros por página:",
                          },
                        }}
                        localeText={{
                          noRowsLabel: "No se ha encontrado datos.",
                          noResultsOverlayLabel:
                            "No se ha encontrado ningún resultado",
                          toolbarColumns: "Columnas",
                          toolbarColumnsLabel: "Seleccionar columnas",
                          toolbarFilters: "Filtros",
                          toolbarFiltersLabel: "Ver filtros",
                          toolbarFiltersTooltipHide: "Quitar filtros",
                          toolbarFiltersTooltipShow: "Ver filtros",
                        }}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={4} dir={theme.direction}>
                      {/* Mayo */}
                      <DataGrid
                        getRowId={(row) => row.id}
                        //sx={{ height: 600 }}
                        editMode="row"
                        rows={listaDatos.filter((obj) => {
                          return obj.mes === 5;
                        })}
                        columns={columns}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 20 } },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        slotProps={{
                          pagination: {
                            labelRowsPerPage: "Registros por página:",
                          },
                        }}
                        localeText={{
                          noRowsLabel: "No se ha encontrado datos.",
                          noResultsOverlayLabel:
                            "No se ha encontrado ningún resultado",
                          toolbarColumns: "Columnas",
                          toolbarColumnsLabel: "Seleccionar columnas",
                          toolbarFilters: "Filtros",
                          toolbarFiltersLabel: "Ver filtros",
                          toolbarFiltersTooltipHide: "Quitar filtros",
                          toolbarFiltersTooltipShow: "Ver filtros",
                        }}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={5} dir={theme.direction}>
                      {/* Junio */}
                      <DataGrid
                        getRowId={(row) => row.id}
                        //sx={{ height: 600 }}
                        editMode="row"
                        rows={listaDatos.filter((obj) => {
                          return obj.mes === 6;
                        })}
                        columns={columns}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 20 } },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        slotProps={{
                          pagination: {
                            labelRowsPerPage: "Registros por página:",
                          },
                        }}
                        localeText={{
                          noRowsLabel: "No se ha encontrado datos.",
                          noResultsOverlayLabel:
                            "No se ha encontrado ningún resultado",
                          toolbarColumns: "Columnas",
                          toolbarColumnsLabel: "Seleccionar columnas",
                          toolbarFilters: "Filtros",
                          toolbarFiltersLabel: "Ver filtros",
                          toolbarFiltersTooltipHide: "Quitar filtros",
                          toolbarFiltersTooltipShow: "Ver filtros",
                        }}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={6} dir={theme.direction}>
                      {/* Julio */}
                      <DataGrid
                        getRowId={(row) => row.id}
                        //sx={{ height: 600 }}
                        editMode="row"
                        rows={listaDatos.filter((obj) => {
                          return obj.mes === 7;
                        })}
                        columns={columns}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 20 } },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        slotProps={{
                          pagination: {
                            labelRowsPerPage: "Registros por página:",
                          },
                        }}
                        localeText={{
                          noRowsLabel: "No se ha encontrado datos.",
                          noResultsOverlayLabel:
                            "No se ha encontrado ningún resultado",
                          toolbarColumns: "Columnas",
                          toolbarColumnsLabel: "Seleccionar columnas",
                          toolbarFilters: "Filtros",
                          toolbarFiltersLabel: "Ver filtros",
                          toolbarFiltersTooltipHide: "Quitar filtros",
                          toolbarFiltersTooltipShow: "Ver filtros",
                        }}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={7} dir={theme.direction}>
                      {/* Agosto */}
                      <DataGrid
                        getRowId={(row) => row.id}
                        //sx={{ height: 600 }}
                        editMode="row"
                        rows={listaDatos.filter((obj) => {
                          return obj.mes === 8;
                        })}
                        columns={columns}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 20 } },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        slotProps={{
                          pagination: {
                            labelRowsPerPage: "Registros por página:",
                          },
                        }}
                        localeText={{
                          noRowsLabel: "No se ha encontrado datos.",
                          noResultsOverlayLabel:
                            "No se ha encontrado ningún resultado",
                          toolbarColumns: "Columnas",
                          toolbarColumnsLabel: "Seleccionar columnas",
                          toolbarFilters: "Filtros",
                          toolbarFiltersLabel: "Ver filtros",
                          toolbarFiltersTooltipHide: "Quitar filtros",
                          toolbarFiltersTooltipShow: "Ver filtros",
                        }}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={8} dir={theme.direction}>
                      {/* Septiembre */}
                      <DataGrid
                        getRowId={(row) => row.id}
                        //sx={{ height: 600 }}
                        editMode="row"
                        rows={listaDatos.filter((obj) => {
                          return obj.mes === 9;
                        })}
                        columns={columns}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 20 } },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        slotProps={{
                          pagination: {
                            labelRowsPerPage: "Registros por página:",
                          },
                        }}
                        localeText={{
                          noRowsLabel: "No se ha encontrado datos.",
                          noResultsOverlayLabel:
                            "No se ha encontrado ningún resultado",
                          toolbarColumns: "Columnas",
                          toolbarColumnsLabel: "Seleccionar columnas",
                          toolbarFilters: "Filtros",
                          toolbarFiltersLabel: "Ver filtros",
                          toolbarFiltersTooltipHide: "Quitar filtros",
                          toolbarFiltersTooltipShow: "Ver filtros",
                        }}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={9} dir={theme.direction}>
                      {/* Octubre */}
                      <DataGrid
                        getRowId={(row) => row.id}
                        //sx={{ height: 600 }}
                        editMode="row"
                        rows={listaDatos.filter((obj) => {
                          return obj.mes === 10;
                        })}
                        columns={columns}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 20 } },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        slotProps={{
                          pagination: {
                            labelRowsPerPage: "Registros por página:",
                          },
                        }}
                        localeText={{
                          noRowsLabel: "No se ha encontrado datos.",
                          noResultsOverlayLabel:
                            "No se ha encontrado ningún resultado",
                          toolbarColumns: "Columnas",
                          toolbarColumnsLabel: "Seleccionar columnas",
                          toolbarFilters: "Filtros",
                          toolbarFiltersLabel: "Ver filtros",
                          toolbarFiltersTooltipHide: "Quitar filtros",
                          toolbarFiltersTooltipShow: "Ver filtros",
                        }}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={10} dir={theme.direction}>
                      {/* Noviembre */}
                      <DataGrid
                        getRowId={(row) => row.id}
                        //sx={{ height: 800 }}
                        editMode="row"
                        rows={listaDatos.filter((obj) => {
                          return obj.mes === 11;
                        })}
                        columns={columns}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 20 } },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        slotProps={{
                          pagination: {
                            labelRowsPerPage: "Registros por página:",
                          },
                        }}
                        localeText={{
                          noRowsLabel: "No se ha encontrado datos.",
                          noResultsOverlayLabel:
                            "No se ha encontrado ningún resultado",
                          toolbarColumns: "Columnas",
                          toolbarColumnsLabel: "Seleccionar columnas",
                          toolbarFilters: "Filtros",
                          toolbarFiltersLabel: "Ver filtros",
                          toolbarFiltersTooltipHide: "Quitar filtros",
                          toolbarFiltersTooltipShow: "Ver filtros",
                        }}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={11} dir={theme.direction}>
                      {/* Diciembre */}
                      <DataGrid
                        getRowId={(row) => row.id}
                        // //sx={{ height: 600 }}
                        editMode="row"
                        rows={listaDatos.filter((obj) => {
                          return obj.mes === 12;
                        })}
                        columns={columns}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 20 } },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        slotProps={{
                          pagination: {
                            labelRowsPerPage: "Registros por página:",
                          },
                        }}
                        localeText={{
                          noRowsLabel: "No se ha encontrado datos.",
                          noResultsOverlayLabel:
                            "No se ha encontrado ningún resultado",
                          toolbarColumns: "Columnas",
                          toolbarColumnsLabel: "Seleccionar columnas",
                          toolbarFilters: "Filtros",
                          toolbarFiltersLabel: "Ver filtros",
                          toolbarFiltersTooltipHide: "Quitar filtros",
                          toolbarFiltersTooltipShow: "Ver filtros",
                        }}
                      />
                    </TabPanel>
                  </Box>
                </Grid>
              </Paper>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
