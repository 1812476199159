import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardActions, CardContent, CardMedia, Container, Grid, Paper, Typography } from "@mui/material";
import fondo from "../assets/fon_3_marcas.png"
import imgmesa from "../assets/mesa.png"
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import imgdevops from "../assets/devops.png"
import imgelerning from "../assets/elearning.png"
import landingpage from "../assets/landingpage.png"
import { styled } from "@mui/system";


export const HomeDemoPage: React.FC<{}> = () => {

    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const userAgent = navigator.userAgent;
    const mobileKeywords = [
        "Mobile",
        "Android",
        "iPhone",
        "iPad",
        "Windows Phone",
    ];
    const checkDeviceType = () => {
        setIsMobile(
            mobileKeywords.some((keyword) => userAgent.includes(keyword))
        );
    };


    useEffect(() => {
        checkDeviceType();
        if (account && account.name) {
            console.log("este son los atributos de msal >>>>>>", account);
            setName(account.name);
            setUsername(account.username);
            console.log(account);

        } else {
            setName("");
        }
    }, [account]);

    const { instance } = useMsal();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleLogin = (loginType: string) => {
        setAnchorEl(null);
    }

    const isAuthenticated = useIsAuthenticated();
    const imageURL = "https://cdn.pixabay.com/photo/2023/05/20/20/39/european-roller-8007340__340.jpg";
    const Background = styled("div")({
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundImage: `url(${fondo})`,
        //backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        opacity: "50%"
        //marginLeft: -900
    });

    type tarjetaType = {
        titulo: string;
        descripcion: string;
        imagen: string;
        link: string;
        boton: string;
        otraVentana: boolean;
    };


    const dataTarjetas: tarjetaType[] = [
        {
            titulo: "Clientes",
            descripcion: "Crea y consulta el listado de clientes",
            imagen: landingpage,
            link: `/demo`,
            boton: "Abrir",
            otraVentana: false,
        },
        {
            titulo: "Visitas",
            descripcion: "Registra una visita a tu cliente",
            imagen: imgmesa,
            link: "/pedidodemo",
            boton: "Abrir",
            otraVentana: false,
        },
        {
            titulo: "Productos",
            descripcion: "Consulta, agrega y/o modifica los productos",
            imagen: imgdevops,
            link: "/demo",
            boton: "Abrir",
            otraVentana: false,
        },
        {
            titulo: "Usuarios",
            descripcion:
                "Consulta, agrega y/o modifica los usuarios",
            imagen: imgelerning,
            link: "/demo",
            boton: "Abrir",
            otraVentana: false,
        },
    ];
    return (
        <>
            {/* <Background /> */}
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{ minHeight: "90vh" }}
                >
                    <Grid item>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.7)",
                                mt: 1,
                            }}
                        >


                            {isMobile ? <> <Grid alignItems={"center"}
                                justifyContent={"center"} sx={{ marginLeft: 1 }}>
                                {dataTarjetas.map((row) => (
                                    <Card
                                        sx={{
                                            width: 240,
                                            display: "inline-block",
                                            // marginRight: 4,
                                            mt: 2,
                                            mb: 2,
                                        }}
                                    >
                                        <CardMedia
                                            sx={{ height: 140 }}
                                            image={row.imagen}
                                            title=""
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="div">
                                                <strong>{row.titulo}</strong>
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                sx={{ height: 60 }}
                                            >
                                                {row.descripcion}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button href={row.link} size="small">
                                                {row.boton}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                ))}
                            </Grid></> : <> <Grid sx={{ marginLeft: 1 }}>
                                {dataTarjetas.map((row) => (
                                    <Card
                                        sx={{
                                            width: 240,
                                            display: "inline-block",
                                            marginRight: 4,
                                            mt: 2,
                                            mb: 2,
                                        }}
                                    >
                                        <CardMedia
                                            sx={{ height: 140 }}
                                            image={row.imagen}
                                            title=""
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="div">
                                                <strong>{row.titulo}</strong>
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                sx={{ height: 60 }}
                                            >
                                                {row.descripcion}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            {row.otraVentana ? <>
                                                <Button target="_blank"
                                                    component="a" href={row.link} size="small">
                                                    {row.boton}
                                                </Button></> : <><Button href={row.link} size="small">
                                                    {row.boton}
                                                </Button></>}

                                        </CardActions>
                                    </Card>
                                ))}
                            </Grid></>}

                            <Typography
                                variant="h6"
                                textAlign={"center"}
                                sx={{ mt: 3, mb: 1 }}
                            >

                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

        </>
    );
};
