import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { ThemeConfig } from "../../config/theme.config";
import { resolve } from "path";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridRowId,
  GridRowModesModel,
  GridRowModes,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import {
  randomCreatedDate,
  randomTraderName,
  randomUpdatedDate,
} from "@mui/x-data-grid-generator";
import SaveIcon from "@mui/icons-material/Save";
import { useParams } from "react-router-dom";

export const PoliticaComercialPage: React.FC<{}> = () => {
  useEffect(() => {
    getZonas();
    getMarcas();    
    setZona(Number(params.zona));
    setMarca(Number(params.marca));
    setMes(1);

    {
      Number(params.id) > 0
        ? consultarPorCanal(
            Number(params.marca),
            Number(params.zona),
            Number(params.semana),
            Number(params.mes)
          )
        : console.log("Useeffect, es nueva");
    }
   getPoliticaComercial(Number(params.id));
    console.log("Estos son los parametros", params);
  }, []);

    async function postGuardarDatos(parametroGuardar: postPoliticaComercialType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/politicacomercial",
          parametroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: ", response.data.id);
          setOpenDialog(true);
        }); //setArticleId(response.data.id));
      // handleClickOpen();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  function guardarDatos(
    pocId: number,
    idMarca: number,
    idZona: number,
    pocAnio: number,
    pocMes: number,
    pocSemana: number,

    pocObjetivoMarca: number,

    pocUnidadesRetail: number,
    pocUnidadesPremium: number,
    pocUnidadesFlotas: number,
    pocUnidadesChevy: number,

    pocMontoRetail: number,
    pocMontoPremium: number,
    pocMontoFlotas: number,
    pocMontoChevy: number,

    pocMargenRetail: number,
    pocMargenPremium: number,
    pocMargenFlotas: number,
    pocMargenChevy: number
  ) {
    console.log("registro a guardar >> ");
    const nuevaRegistro: postPoliticaComercialType = {
      pocId: pocId,
      idMarca: idMarca,
      idZona: idZona,
      pocAnio: pocAnio,
      pocMes: pocMes,
      pocSemana: pocSemana,

      pocObjetivoMarca: pocObjetivoMarca,

      pocUnidadesRetail: pocUnidadesRetail,
      pocUnidadesPremium: pocUnidadesPremium,
      pocUnidadesFlotas: pocUnidadesFlotas,
      pocUnidadesChevy: pocUnidadesChevy,

      pocMontoRetail: pocMontoRetail,
      pocMontoPremium: pocMontoPremium,
      pocMontoFlotas: pocMontoFlotas,
      pocMontoChevy: pocMontoChevy,

      pocMargenRetail: pocMargenRetail,
      pocMargenPremium: pocMargenPremium,
      pocMargenFlotas: pocMargenFlotas,
      pocMargenChevy: pocMargenChevy,
      totalMargen: 0,
      totalMonto: 0,
      totalUnidades: 0
    };
    console.log("datos a guardar >> ", JSON.stringify(nuevaRegistro, null, 4));
    postGuardarDatos(nuevaRegistro);
  }


    function consultarPorCanal(
    marca: number,
    zona: number,
    semana: number,
    mes: number,
  ) {
     consultarVentas(marca,zona,semana, mes)
    
    //  getVentasPorSemana(
    //   String("REATIL"),
    //   Number(mes),
    //   Number(marca),
    //   Number(zona),
    //   Number(semana)
    // );
    //  getVentasPorSemana(
    //   String("PREMIUM"),
    //   Number(mes),
    //   Number(marca),
    //   Number(zona),
    //   Number(semana)
    // );
    //  getVentasPorSemana(
    //   String("FLOTAS"),
    //   Number(mes),
    //   Number(marca),
    //   Number(zona),
    //   Number(semana)
    // );
    //  getVentasPorSemana(
    //   String("CHEVY"),
    //   Number(mes),
    //   Number(marca),
    //   Number(zona),
    //   Number(semana)
    // );
  }

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");

  let unidadRetail: number = 0;
  let ventaRetail: number = 0;
  let margenRetail: number = 0;

  let unidadPremium: number = 0;
  let ventaPremium: number = 0;
  let margenPremium: number = 0;

  let unidadFlotas: number = 0;
  let ventaFlotas: number = 0;
  let margenFlotas: number = 0;

  let unidadChevy: number = 0;
  let ventaChevy: number = 0;
  let margenChevy: number = 0;

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const columnsRetail: GridColDef[] = [
    { field: "modelo", headerName: "Modelo", width: 350, editable: false },
    {
      field: "anio",
      headerName: "Año",
      type: "number",
      editable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "costo",
      headerName: "Costo",
      type: "number",
      editable: false,
      align: "right",
      headerAlign: "left",
    },
    {
      field: "pvp",
      headerName: "PVP",
      type: "number",
      editable: true,
      align: "right",
      headerAlign: "left",
    },
    {
      field: "cantidad",
      headerName: "Cantidad",
      type: "number",
      editable: true,
      align: "right",
      headerAlign: "left",
    },
    {
      field: "total",
      headerAlign: "left",
      headerName: "Total",
      editable: false,
      align: "right",
      valueGetter: ({ row }) => {
        handleSaveClickRetail(row);
        if (!row.cantidad || !row.pvp) {
          return null;
        }
        return formatter.format(row.cantidad * row.pvp).replace("$", "$  ");
      },
    },
    {
      field: "margen",
      headerName: "Margen",
      editable: true,
      align: "right",
      headerAlign: "right",
      valueGetter: ({ row }) => {
        handleSaveClickRetail(row);
        if (!row.cantidad || !row.pvp) {
          return null;
        }
        return formatter
          .format((row.pvp - row.costo) * row.cantidad)
          .replace("$", "$  ");
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Acciones",
      width: 100,
      cellClassName: "actions",

      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: "primary.main",
            }}
            onClick={handleSaveClickRetail(row)}
          />,
        ];
      },
    },
  ];

  const columnsPremium: GridColDef[] = [
    { field: "modelo", headerName: "Modelo", width: 350, editable: false },
    {
      field: "anio",
      headerName: "Año",
      type: "number",
      editable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "costo",
      headerName: "Costo",
      type: "number",
      editable: false,
      align: "right",
      headerAlign: "left",
    },
    {
      field: "pvp",
      headerName: "PVP",
      type: "number",
      editable: true,
      align: "right",
      headerAlign: "left",
    },
    {
      field: "cantidad",
      headerName: "Cantidad",
      type: "number",
      editable: true,
      align: "right",
      headerAlign: "left",
    },
    {
      field: "total",
      headerAlign: "left",
      headerName: "Total",
      editable: false,
      align: "right",
      valueGetter: ({ row }) => {
        if (!row.cantidad || !row.pvp) {
          return null;
        }
        return formatter.format(row.cantidad * row.pvp).replace("$", "$  ");
      },
    },
    {
      field: "margen",
      headerName: "Margen",
      editable: true,
      align: "right",
      headerAlign: "right",
      valueGetter: ({ row }) => {
        handleSaveClickPremium(row);
        if (!row.cantidad || !row.pvp) {
          return null;
        }
        return formatter
          .format((row.pvp - row.costo) * row.cantidad)
          .replace("$", "$  ");
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Acciones",
      width: 100,
      cellClassName: "actions",

      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: "primary.main",
            }}
            onClick={handleSaveClickPremium(row)}
          />,
        ];
      },
    },
  ];

  const columnsFlotas: GridColDef[] = [
    { field: "modelo", headerName: "Modelo", width: 350, editable: false },
    {
      field: "anio",
      headerName: "Año",
      type: "number",
      editable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "costo",
      headerName: "Costo",
      type: "number",
      editable: false,
      align: "right",
      headerAlign: "left",
    },
    {
      field: "pvp",
      headerName: "PVP",
      type: "number",
      editable: true,
      align: "right",
      headerAlign: "left",
    },
    {
      field: "cantidad",
      headerName: "Cantidad",
      type: "number",
      editable: true,
      align: "right",
      headerAlign: "left",
    },
    {
      field: "total",
      headerAlign: "left",
      headerName: "Total",
      editable: false,
      align: "right",
      valueGetter: ({ row }) => {
        if (!row.cantidad || !row.pvp) {
          return null;
        }
        return formatter.format(row.cantidad * row.pvp).replace("$", "$  ");
      },
    },
    {
      field: "margen",
      headerName: "Margen",
      editable: true,
      align: "right",
      headerAlign: "right",
      valueGetter: ({ row }) => {
        handleSaveClickFlotas(row);
        if (!row.cantidad || !row.pvp) {
          return null;
        }
        return formatter
          .format((row.pvp - row.costo) * row.cantidad)
          .replace("$", "$  ");
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Acciones",
      width: 100,
      cellClassName: "actions",

      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: "primary.main",
            }}
            onClick={handleSaveClickFlotas(row)}
          />,
        ];
      },
    },
  ];

  const columnsChevy: GridColDef[] = [
    { field: "modelo", headerName: "Modelo", width: 350, editable: false },
    {
      field: "anio",
      headerName: "Año",
      type: "number",
      editable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "costo",
      headerName: "Costo",
      type: "number",
      editable: false,
      align: "right",
      headerAlign: "left",
    },
    {
      field: "pvp",
      headerName: "PVP",
      type: "number",
      editable: true,
      align: "right",
      headerAlign: "left",
    },
    {
      field: "cantidad",
      headerName: "Cantidad",
      type: "number",
      editable: true,
      align: "right",
      headerAlign: "left",
    },
    {
      field: "total",
      headerAlign: "left",
      headerName: "Total",
      editable: false,
      align: "right",
      valueGetter: ({ row }) => {
        if (!row.cantidad || !row.pvp) {
          return null;
        }
        return formatter.format(row.cantidad * row.pvp).replace("$", "$  ");
      },
    },
    {
      field: "margen",
      headerName: "Margen",
      editable: true,
      align: "right",
      headerAlign: "right",
      valueGetter: ({ row }) => {
        handleSaveClickChevy(row);
        if (!row.cantidad || !row.pvp) {
          return null;
        }
        return formatter
          .format((row.pvp - row.costo) * row.cantidad)
          .replace("$", "$  ");
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Acciones",
      width: 100,
      cellClassName: "actions",

      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: "primary.main",
            }}
            onClick={handleSaveClickChevy(row)}
          />,
        ];
      },
    },
  ];

  //const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const handleSaveClickRetail = (row: modelo) => () => {
    // setRowsMod({ ...rowsMod, [id]: { mode: GridRowModes.View } });
    console.log("Este es el row modigicado< >> ", row);

    rowsModeloRetail.map(function (dato) {
      if (dato.id === row.id) {
        dato.cantidad = row.cantidad;
        dato.pvp = row.pvp * dato.cantidad;
        dato.margen = (row.pvp - row.costo) * row.cantidad;
      }
    });
    console.log("Retea", rowsModeloRetail);
    console.log("Prim", rowsModeloPremium);
    console.log("Flotas", rowsModeloFlotas);
    console.log("Chevy", rowsModeloChevy);
  };

  const handleSaveClickPremium = (row: modelo) => () => {
    // setRowsMod({ ...rowsMod, [id]: { mode: GridRowModes.View } });
    console.log("Este es el row modigicado< >> ", row);

    rowsModeloPremium.map(function (dato) {
      if (dato.id === row.id) {
        dato.cantidad = row.cantidad;
        dato.pvp = row.pvp * dato.cantidad;
        dato.margen = (row.pvp - row.costo) * row.cantidad;
      }
    });
  };

  const handleSaveClickFlotas = (row: modelo) => () => {
    // setRowsMod({ ...rowsMod, [id]: { mode: GridRowModes.View } });
    console.log("Este es el row modigicado< >> ", row);

    rowsModeloFlotas.map(function (dato) {
      if (dato.id === row.id) {
        dato.cantidad = row.cantidad;
        dato.pvp = row.pvp * dato.cantidad;
        dato.margen = (row.pvp - row.costo) * row.cantidad;
      }
    });
  };

  const handleSaveClickChevy = (row: modelo) => () => {
    // setRowsMod({ ...rowsMod, [id]: { mode: GridRowModes.View } });
    console.log("Este es el row modigicado< >> ", row);

    rowsModeloChevy.map(function (dato) {
      if (dato.id === row.id) {
        dato.cantidad = row.cantidad;
        dato.pvp = row.pvp * dato.cantidad;
        dato.margen = (row.pvp - row.costo) * row.cantidad;
      }
    });
  };

  const handleAgregarRetail = () => {
    // setRowsMod({ ...rowsMod, [id]: { mode: GridRowModes.View } });

    rowsModeloRetail.map(function (dato) {
      if (dato.cantidad > 0) {
        unidadRetail = Number(unidadRetail) + Number(dato.cantidad);
        ventaRetail = Number(ventaRetail) + Number(dato.pvp);
        margenRetail = Number(margenRetail) + Number(dato.margen);
      }
    });
    datosPoliticaComercial.pocUnidadesRetail = unidadRetail;
    datosPoliticaComercial.pocMontoRetail = ventaRetail;
    datosPoliticaComercial.pocMargenRetail = margenRetail;
  };

  const handleAgregarPremium = () => {
    // setRowsMod({ ...rowsMod, [id]: { mode: GridRowModes.View } });

    rowsModeloPremium.map(function (dato) {
      if (dato.cantidad > 0) {
        unidadPremium = Number(unidadPremium) + Number(dato.cantidad);
        ventaPremium = Number(ventaPremium) + Number(dato.pvp);
        margenPremium = Number(margenPremium) + Number(dato.margen);
      }
    });
    datosPoliticaComercial.pocUnidadesPremium = unidadPremium;
    datosPoliticaComercial.pocMontoPremium = ventaPremium;
    datosPoliticaComercial.pocMargenPremium = margenPremium;
  };

  const handleAgregarFlotas = () => {
    // setRowsMod({ ...rowsMod, [id]: { mode: GridRowModes.View } });

    rowsModeloFlotas.map(function (dato) {
      if (dato.cantidad > 0) {
        unidadFlotas = Number(unidadFlotas) + Number(dato.cantidad);
        ventaFlotas = Number(ventaFlotas) + Number(dato.pvp);
        margenFlotas = Number(margenFlotas) + Number(dato.margen);
      }
    });
    datosPoliticaComercial.pocUnidadesFlotas = unidadFlotas;
    datosPoliticaComercial.pocMontoFlotas = ventaFlotas;
    datosPoliticaComercial.pocMargenFlotas = margenFlotas;
  };

  const handleAgregarChevy = () => {
    // setRowsMod({ ...rowsMod, [id]: { mode: GridRowModes.View } });

    rowsModeloChevy.map(function (dato) {
      if (dato.cantidad > 0) {
        unidadChevy = Number(unidadChevy) + Number(dato.cantidad);
        ventaChevy = Number(ventaChevy) + Number(dato.pvp);
        margenChevy = Number(margenChevy) + Number(dato.margen);
      }
    });
    datosPoliticaComercial.pocUnidadesChevy = unidadChevy;
    datosPoliticaComercial.pocMontoChevy = ventaChevy;
    datosPoliticaComercial.pocMargenChevy = margenChevy;
  };

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
    } else {
      setName("");
    }
  }, [account]);
  const listadoMarcas: marcaType[] = [];
  const listadoZonas: zonaType[] = [];
  const listadoSemana: semanaType[] = [
    { id: 0, semana: "0" },
    { id: 1, semana: "1" },
    { id: 2, semana: "2" },
    { id: 3, semana: "3" },
    { id: 4, semana: "4" },
  ];
  const isAuthenticated = useIsAuthenticated();
  const [dataZona, setDataZona] = useState<zonaType[]>(listadoZonas);
  const [dataMarca, setDataMarca] = useState<marcaType[]>(listadoMarcas);
  const [dataSemana, setDataSemana] = useState<semanaType[]>(listadoSemana);

  const params = useParams();

  type postPoliticaComercialType = {
    pocId: number;
    idMarca: number;
    idZona: number;
    pocAnio: number;
    pocMes: number;
    pocSemana: number;
    pocObjetivoMarca: number;
    pocUnidadesRetail: number;
    pocUnidadesPremium: number;
    pocUnidadesFlotas: number;
    pocUnidadesChevy: number;
    pocMontoRetail: number;
    pocMontoPremium: number;
    pocMontoFlotas: number;
    pocMontoChevy: number;
    pocMargenRetail: number;
    pocMargenPremium: number;
    pocMargenFlotas: number;
    pocMargenChevy: number;
    totalUnidades: number;
    totalMonto: number;
    totalMargen: number;
  };

  type zonaType = {
    zonId: number;
    zonNombre: string;
  };

  type marcaType = {
    marId: number;
    marNombre: string;
  };

  type semanaType = {
    id: number;
    semana: string;
  };
  type polComercialVentaType = {
    id: number;
    idZona: number;
    idMarca: number;
    marca: string;
    anio: number;
    mes: number;
    canal: string;
    cantidad: number;
    costo: number;
    venta: number;
    ventaConRebate: number;
    semana: number;
  };

 

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1100,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [openRetail, setOpenRetail] = React.useState(false);
  const [openPremium, setOpenPremium] = React.useState(false);
  const [openFlotas, setOpenFlotas] = React.useState(false);
  const [openChevy, setOpenChevy] = React.useState(false);
  const handleOpenRetail = () => setOpenRetail(true);
  const handleOpenPremium = () => setOpenPremium(true);
  const handleOpenFlotas = () => setOpenFlotas(true);
  const handleOpenChevy = () => setOpenChevy(true);
  const handleCloseRetail = () => setOpenRetail(false);
  const handleClosePremium = () => setOpenPremium(false);
  const handleCloseFlotas = () => setOpenFlotas(false);
  const handleCloseChevy = () => setOpenChevy(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [zona, setZona] = React.useState(0);
  const [marca, setMarca] = React.useState(0);
  const [semana, setSemana] = React.useState(0);
  const [mes, setMes] = React.useState(0);

  const handleChangeZona = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZona(Number(event.target.value));
  };
  const handleChangeMarca = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMarca(Number(event.target.value));
  };
  const handleChangeSemana = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSemana(Number(event.target.value));
  };

  interface modelo {
    id: number;
    modelo: string;
    costo: number;
    anio: number;
    pvp: number;
    marca: string;
    rebate: number;
    orden: number;
    tipo: string;
    cantidad: number;
    total: number;
    margen: number;
  }
  const originalRowsMod1: modelo[] = [];
  const originalRowsMod2: modelo[] = [];
  const originalRowsMod3: modelo[] = [];
  const originalRowsMod4: modelo[] = [];
  const [rowsModeloRetail, setRowsModeloRetail] =
    useState<modelo[]>(originalRowsMod1);
  const [rowsModeloPremium, setRowsModeloPremium] =
    useState<modelo[]>(originalRowsMod2);
  const [rowsModeloFlotas, setRowsModeloFlotas] =
    useState<modelo[]>(originalRowsMod3);
  const [rowsModeloChevy, setRowsModeloChevy] =
    useState<modelo[]>(originalRowsMod4);

  

  const [datosRetail0, setDatosRetail0] = useState<polComercialVentaType>({
    id: 0,
    idZona: 0,
    idMarca: 0,
    marca: "",
    anio: 0,
    mes: 0,
    canal: "",
    cantidad: 0,
    costo: 0,
    venta: 0,
    ventaConRebate: 0,
    semana: 0,
  });
  const [datosPremium0, setDatosPremium0] = useState<polComercialVentaType>({
    id: 0,
    idZona: 0,
    idMarca: 0,
    marca: "",
    anio: 0,
    mes: 0,
    canal: "",
    cantidad: 0,
    costo: 0,
    venta: 0,
    ventaConRebate: 0,
    semana: 0,
  });
  const [datosFlotas0, setDatosFlotas0] = useState<polComercialVentaType>({
    id: 0,
    idZona: 0,
    idMarca: 0,
    marca: "",
    anio: 0,
    mes: 0,
    canal: "",
    cantidad: 0,
    costo: 0,
    venta: 0,
    ventaConRebate: 0,
    semana: 0,
  });
  const [datosChevy0, setDatosChevy0] = useState<polComercialVentaType>({
    id: 0,
    idZona: 0,
    idMarca: 0,
    marca: "",
    anio: 0,
    mes: 0,
    canal: "",
    cantidad: 0,
    costo: 0,
    venta: 0,
    ventaConRebate: 0,
    semana: 0,
  });

  const [datosRetail1, setDatosRetail1] = useState<polComercialVentaType>({
    id: 0,
    idZona: 0,
    idMarca: 0,
    marca: "",
    anio: 0,
    mes: 0,
    canal: "",
    cantidad: 0,
    costo: 0,
    venta: 0,
    ventaConRebate: 0,
    semana: 0,
  });
  const [datosPremium1, setDatosPremium1] = useState<polComercialVentaType>({
    id: 0,
    idZona: 0,
    idMarca: 0,
    marca: "",
    anio: 0,
    mes: 0,
    canal: "",
    cantidad: 0,
    costo: 0,
    venta: 0,
    ventaConRebate: 0,
    semana: 0,
  });
  const [datosFlotas1, setDatosFlotas1] = useState<polComercialVentaType>({
    id: 0,
    idZona: 0,
    idMarca: 0,
    marca: "",
    anio: 0,
    mes: 0,
    canal: "",
    cantidad: 0,
    costo: 0,
    venta: 0,
    ventaConRebate: 0,
    semana: 0,
  });
  const [datosChevy1, setDatosChevy1] = useState<polComercialVentaType>({
    id: 0,
    idZona: 0,
    idMarca: 0,
    marca: "",
    anio: 0,
    mes: 0,
    canal: "",
    cantidad: 0,
    costo: 0,
    venta: 0,
    ventaConRebate: 0,
    semana: 0,
  });

  const [datosPoliticaComercial, setDatosPoliticaComercial] =
    React.useState<postPoliticaComercialType>({

       pocId: 0,
    idMarca: 0,
    idZona: 0,
    pocAnio: 0,
    pocMes: 0,
    pocSemana: 0,
    pocObjetivoMarca: 0,
    pocUnidadesRetail: 0,
    pocUnidadesPremium: 0,
    pocUnidadesFlotas: 0,
    pocUnidadesChevy: 0,
    pocMontoRetail: 0,
    pocMontoPremium: 0,
    pocMontoFlotas: 0,
    pocMontoChevy: 0,
    pocMargenRetail: 0,
    pocMargenPremium: 0,
    pocMargenFlotas: 0,
    pocMargenChevy: 0,
    totalUnidades:0,
    totalMonto:0,
    totalMargen: 0
    });

  const [datosRowModelo, setDatosRowModelo] = React.useState<modelo>({
    id: 0,
    modelo: "",
    costo: 0,
    anio: 0,
    pvp: 0,
    marca: "",
    rebate: 0,
    orden: 0,
    tipo: "",
    cantidad: 0,
    total: 0,
    margen: 0,
  });
  const dataPolComercial = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDatosPoliticaComercial({
      ...datosPoliticaComercial,
      [e.target.name]: e.target.value,
    });
    datosPoliticaComercial.totalUnidades =
      Number(datosPoliticaComercial.pocUnidadesChevy) +
      Number(datosPoliticaComercial.pocUnidadesFlotas) +
      Number(datosPoliticaComercial.pocUnidadesPremium) +
      Number(datosPoliticaComercial.pocUnidadesRetail);
    datosPoliticaComercial.totalMonto =
      Number(datosPoliticaComercial.pocMontoChevy) +
      Number(datosPoliticaComercial.pocMontoFlotas) +
      Number(datosPoliticaComercial.pocMontoPremium) +
      Number(datosPoliticaComercial.pocMontoRetail);
    datosPoliticaComercial.totalMargen =
      Number(datosPoliticaComercial.pocMargenChevy) +
      Number(datosPoliticaComercial.pocMargenFlotas) +
      Number(datosPoliticaComercial.pocMargenPremium) +
      Number(datosPoliticaComercial.pocMargenRetail);
  };

  const dataRowModelo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDatosRowModelo({
      ...datosRowModelo,
      [e.target.name]: e.target.value,
    });
  };

  function consultarMod(marca: string, tipo: string) {
    getModelosChevy(marca, tipo);
    getModelosPremium(marca, tipo);
    getModelosRetail(marca, tipo);
    getModelosFlotas(marca, tipo);
  }

  function consultarVentas(
    id_marca: number,
    id_zona: number,
    id_semana: number,
    mes :number
  ) {
    getVentasPorSemana("RETAIL", mes, id_marca, id_zona, id_semana);
    getVentasPorSemana("FLOTAS", mes, id_marca, id_zona, id_semana);
    getVentasPorSemana("CHEVYPLAN", mes, id_marca, id_zona, id_semana);
    getVentasPorSemana("PREMIUM", mes, id_marca, id_zona, id_semana);
    console.log("esta es mis datos", datosRetail0);
  }

  async function getVentasPorSemana(
    canal: string,
    mes: number,
    marca: number,
    zona: number,
    semana: number
  ) {
    //obtine modelos para el modal
    try {
      await axios
        .get<polComercialVentaType>(
          "https://apisatelite.azurewebsites.net/VPoliticaComercialVenta/" +
            canal +
            "/" +
            String(mes) +
            "/" +
            String(marca) +
            "/" +
            String(zona) +
            "/" +
            String(semana)
        )
        .then((response) => {
          canal === "RETAIL"
            ? setDatosRetail0(response.data)
            : canal === "CHEVYPLAN"
            ? setDatosChevy0(response.data)
            : canal === "FLOTAS"
            ? setDatosFlotas0(response.data)
            : setDatosPremium0(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
          //setError(error);
        });

      // console.log("data es >>", JSON.stringify(data2, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getPoliticaComercial(id:number) {
    try {
      await axios
        .get<postPoliticaComercialType>(
          "https://apisatelite.azurewebsites.net/politicacomercial/" + String(id)
        )
        .then((response) => {
          setDatosPoliticaComercial(response.data);         
        })
        .catch((error) => {
          console.log("algo paso getPoliticaComercial >> ", error);
          //setError(error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getModelosChevy(marca: string, tipo: string) {
    //obtine modelos para el modal
    try {
      await axios
        .get<modelo[]>(
          tipo === ""
            ? "https://apisatelite.azurewebsites.net/vpoliticacomercialmodelo/" +
                marca
            : "https://apisatelite.azurewebsites.net/vpoliticacomercialmodelo/" +
                marca +
                "/" +
                tipo
        )
        .then((response) => {
          setRowsModeloChevy(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
          //setError(error);
        });

      // console.log("data es >>", JSON.stringify(data2, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getModelosRetail(marca: string, tipo: string) {
    //obtine modelos para el modal
    try {
      await axios
        .get<modelo[]>(
          "https://apisatelite.azurewebsites.net/vpoliticacomercialmodelo/" +
            marca +
            "/RETAIL"
        )
        .then((response) => {
          setRowsModeloRetail(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
          //setError(error);
        });

      // console.log("data es >>", JSON.stringify(data2, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getModelosFlotas(marca: string, tipo: string) {
    //obtine modelos para el modal
    try {
      await axios
        .get<modelo[]>(
          tipo === ""
            ? "https://apisatelite.azurewebsites.net/vpoliticacomercialmodelo/" +
                marca
            : "https://apisatelite.azurewebsites.net/vpoliticacomercialmodelo/" +
                marca +
                "/" +
                tipo
        )
        .then((response) => {
          setRowsModeloFlotas(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
          //setError(error);
        });

      // console.log("data es >>", JSON.stringify(data2, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getModelosPremium(marca: string, tipo: string) {
    //obtine modelos para el modal
    try {
      await axios
        .get<modelo[]>(
          "https://apisatelite.azurewebsites.net/vpoliticacomercialmodelo/" +
            marca +
            "/PREMIUM"
        )
        .then((response) => {
          setRowsModeloPremium(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
          //setError(error);
        });

      // console.log("data es >>", JSON.stringify(data2, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getMarcas() {
    try {
      await axios
        .get<marcaType[]>("https://apisatelite.azurewebsites.net/dimmarca")
        .then((response) => {
          setDataMarca(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataMarca, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }
  async function getZonas() {
    try {
      await axios
        .get<zonaType[]>("https://apisatelite.azurewebsites.net/dimzona")
        .then((response) => {
          setDataZona(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataZona, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Simulación - Política Comercial
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
              }}
            >
              <Box>
                <Box>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Marca"
                    //defaultValue={data?.idZona}
                    //helperText="Please select your currency"
                    size="small"
                    margin="normal"
                    value={marca}
                    onChange={handleChangeMarca}
                    sx={{ mt: 1.5, mb: 1.5, width: "20%" }}
                  >
                    {dataMarca.map((option) => (
                      <MenuItem key={option.marId} value={option.marId}>
                        {option.marNombre}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Zona"
                    //defaultValue={data?.idZona}
                    //helperText="Please select your currency"
                    size="small"
                    margin="normal"
                    value={zona}
                    onChange={handleChangeZona}
                    sx={{ mt: 1.5, mb: 1.5, marginLeft: "2%", width: "20%" }}
                  >
                    {dataZona.map((option) => (
                      <MenuItem key={option.zonId} value={option.zonId}>
                        {option.zonNombre}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Semana"
                    //defaultValue={data?.idZona}
                    //helperText="Please select your currency"
                    size="small"
                    margin="normal"
                    value={semana}
                    onChange={handleChangeSemana}
                    sx={{ mt: 1.5, mb: 1.5, marginLeft: "2%", width: "20%" }}
                  >
                    {dataSemana.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.semana}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    id="outlined-ncy"
                    label="Mes"
                    size="small"
                    margin="normal"
                    value= "Enero"//{mes === 9? "Septiembre": mes === 10? "Octubre":mes === 11? "Noviembre":"Diciembre"}
                    sx={{ mt: 1.5, mb: 1.5, marginLeft: "2%", width: "13%" }}
                  ></TextField>
                  <Button
                    variant="contained"
                    sx={{
                      mt: 1.5,
                      mb: 1.5,
                      marginLeft: "6%",
                      width: "15%",
                      backgroundColor: "rgba(21,57,105,0.75)",
                    }}
                    onClick={function (event) {
                      console.log("mi mes es >>>>>>>", mes)
                      consultarVentas(marca, zona, semana, mes);
                      consultarMod(
                        marca === 1
                          ? "CHEVROLET"
                          : marca === 2
                          ? "GAC"
                          : "VOLKSWAGEN",
                        ""
                      );
                    }}
                  >
                    Consultar
                  </Button>
                </Box>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <Typography
                  //id="modal-modal-title"
                  sx={{ mt: 0, mb: 0, width: "100%" }}
                  //variant="h5"
                  //textAlign={"center"}
                  //style={{  display: "inline-block"}}
                >
                  Semana: <strong>{semana}</strong>
                </Typography>
                <Divider sx={{ mt: 1, mb: 0.2 }} />
                <Box>
                  <Typography
                    //id="modal-modal-title"
                    sx={{ mt: 0, mb: 0.2, width: "9%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    Livianos
                  </Typography>
                  <Typography
                    //id="modal-modal-title"
                    sx={{ mt: 0, mb: 0, width: "13%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    Ventas unidades
                  </Typography>
                  <Typography
                    //id="modal-modal-title"
                    sx={{ mt: 0, mb: 0, width: "13%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    Ventas netas
                  </Typography>
                  <Typography
                    //id="modal-modal-title"
                    sx={{ mt: 0, mb: 0, width: "13%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    Margen bruto real
                  </Typography>
                  <Typography
                    //id="modal-modal-title"
                    sx={{ mt: 0, mb: 0, width: "13%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    Margen bruto
                  </Typography>
                  <Typography
                    //id="modal-modal-title"
                    sx={{ mt: 0, mb: 0, width: "13%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    Incentivo esperado
                  </Typography>
                  <Typography
                    //id="modal-modal-title"
                    sx={{ mt: 0, mb: 0, width: "14%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    USD
                  </Typography>
                  <Typography
                    //id="modal-modal-title"
                    sx={{ mt: 0, mb: 0, width: "12%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    Utilidad total
                  </Typography>
                </Box>
                <Divider sx={{ mt: 0, mb: 0 }} />
                <Grid>
                  <Typography
                    sx={{ mt: 1.5, mb: 1.5, width: "10%" }}
                    style={{ display: "inline-block" }}
                  >
                    Retail:
                  </Typography>
                  <TextField
                    name="dealear_net"
                    size="small"
                    margin="normal"
                    type="number"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={datosRetail0 && Number(datosRetail0.cantidad)}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(datosRetail0.ventaConRebate)
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      datosRetail0 &&
                      formatter
                        .format(
                          Number(datosRetail0.ventaConRebate) -
                            Number(datosRetail0.costo)
                        )
                        .replace("$", "$  ")
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      Number(datosRetail0.ventaConRebate) > 0
                        ? datosRetail0 &&
                          Number(
                            (Number(
                              Number(datosRetail0.ventaConRebate) -
                                Number(datosRetail0.costo)
                            ) /
                              Number(datosRetail0.ventaConRebate)) *
                              100
                          ).toFixed(2) + "  %"
                        : "0 %"
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      datosPoliticaComercial.pocObjetivoMarca > 0 &&
                      Number(
                        Number(
                          Number(
                            Number(
                              (Number(datosRetail0.cantidad) +
                                Number(datosPremium0.cantidad) +
                                Number(datosFlotas0.cantidad) +
                                Number(datosChevy0.cantidad)) /
                                datosPoliticaComercial.pocObjetivoMarca
                            ) * 100
                          ) /
                            (semana === 0
                              ? 0
                              : semana === 1
                              ? 15
                              : semana === 2
                              ? 40
                              : semana === 3
                              ? 75
                              : 100)
                        ) * 100
                      ) < 95
                        ? "0 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(datosRetail0.cantidad) +
                                    Number(datosPremium0.cantidad) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(datosChevy0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana === 0
                                  ? 0
                                  : semana === 1
                                  ? 15
                                  : semana === 2
                                  ? 40
                                  : semana === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 100
                        ? "0.4 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(datosRetail0.cantidad) +
                                    Number(datosPremium0.cantidad) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(datosChevy0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana === 0
                                  ? 0
                                  : semana === 1
                                  ? 15
                                  : semana === 2
                                  ? 40
                                  : semana === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 104
                        ? "0.8 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(datosRetail0.cantidad) +
                                    Number(datosPremium0.cantidad) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(datosChevy0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana === 0
                                  ? 0
                                  : semana === 1
                                  ? 15
                                  : semana === 2
                                  ? 40
                                  : semana === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 110
                        ? "1 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(datosRetail0.cantidad) +
                                    Number(datosPremium0.cantidad) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(datosChevy0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana === 0
                                  ? 0
                                  : semana === 1
                                  ? 15
                                  : semana === 2
                                  ? 40
                                  : semana === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) >= 100
                        ? "1.3 %"
                        : "0 %"
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        (datosPoliticaComercial.pocObjetivoMarca > 0 &&
                        Number(
                          Number(
                            Number(
                              Number(
                                (Number(datosRetail0.cantidad) +
                                  Number(datosPremium0.cantidad) +
                                  Number(datosFlotas0.cantidad) +
                                  Number(datosChevy0.cantidad)) /
                                  datosPoliticaComercial.pocObjetivoMarca
                              ) * 100
                            ) /
                              (semana === 0
                                ? 0
                                : semana === 1
                                ? 15
                                : semana === 2
                                ? 40
                                : semana === 3
                                ? 75
                                : 100)
                          ) * 100
                        ) < 95
                          ? 0
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 100
                          ? 0.4
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 104
                          ? 0.8
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 110
                          ? 1
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) >= 100
                          ? 1.3
                          : 0) *
                          datosRetail0.ventaConRebate *
                          (datosPoliticaComercial.pocObjetivoMarca > 0
                            ? Number(
                                Number(
                                  Number(
                                    Number(
                                      (Number(datosRetail0.cantidad) +
                                        Number(datosPremium0.cantidad) +
                                        Number(datosFlotas0.cantidad) +
                                        Number(datosChevy0.cantidad)) /
                                        datosPoliticaComercial.pocObjetivoMarca
                                    )
                                  ) /
                                    (semana === 0
                                      ? 0
                                      : semana === 1
                                      ? 15
                                      : semana === 2
                                      ? 40
                                      : semana === 3
                                      ? 75
                                      : 100)
                                )
                              )
                            : 0)
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        (datosPoliticaComercial.pocObjetivoMarca > 0 &&
                        Number(
                          Number(
                            Number(
                              Number(
                                (Number(datosRetail0.cantidad) +
                                  Number(datosPremium0.cantidad) +
                                  Number(datosFlotas0.cantidad) +
                                  Number(datosChevy0.cantidad)) /
                                  datosPoliticaComercial.pocObjetivoMarca
                              ) * 100
                            ) /
                              (semana === 0
                                ? 0
                                : semana === 1
                                ? 15
                                : semana === 2
                                ? 40
                                : semana === 3
                                ? 75
                                : 100)
                          ) * 100
                        ) < 95
                          ? 0
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 100
                          ? 0.4
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 104
                          ? 0.8
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 110
                          ? 1
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) >= 100
                          ? 1.3
                          : 0) *
                          datosRetail0.ventaConRebate *
                          (datosPoliticaComercial.pocObjetivoMarca > 0
                            ? Number(
                                Number(
                                  Number(
                                    Number(
                                      (Number(datosRetail0.cantidad) +
                                        Number(datosPremium0.cantidad) +
                                        Number(datosFlotas0.cantidad) +
                                        Number(datosChevy0.cantidad)) /
                                        datosPoliticaComercial.pocObjetivoMarca
                                    )
                                  ) /
                                    (semana === 0
                                      ? 0
                                      : semana === 1
                                      ? 15
                                      : semana === 2
                                      ? 40
                                      : semana === 3
                                      ? 75
                                      : 100)
                                )
                              )
                            : 0) +
                          Number(
                            datosRetail0.ventaConRebate - datosRetail0.costo
                          )
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                </Grid>
                <Grid>
                  <Typography
                    sx={{ mt: 1.5, mb: 1.5, width: "10%" }}
                    style={{ display: "inline-block" }}
                  >
                    Premium:
                  </Typography>
                  <TextField
                    name="dealear_net"
                    size="small"
                    margin="normal"
                    type="number"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={datosPremium0 && Number(datosPremium0.cantidad)}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(datosPremium0.ventaConRebate)
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      datosPremium0 &&
                      formatter
                        .format(
                          Number(datosPremium0.ventaConRebate) -
                            Number(datosPremium0.costo)
                        )
                        .replace("$", "$  ")
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      Number(datosPremium0.ventaConRebate) > 0
                        ? datosPremium0 &&
                          Number(
                            (Number(
                              Number(datosPremium0.ventaConRebate) -
                                Number(datosPremium0.costo)
                            ) /
                              Number(datosPremium0.ventaConRebate)) *
                              100
                          ).toFixed(2) + "  %"
                        : "0 %"
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      datosPoliticaComercial.pocObjetivoMarca > 0 &&
                      Number(
                        Number(
                          Number(
                            Number(
                              (Number(datosRetail0.cantidad) +
                                Number(datosPremium0.cantidad) +
                                Number(datosFlotas0.cantidad) +
                                Number(datosChevy0.cantidad)) /
                                datosPoliticaComercial.pocObjetivoMarca
                            ) * 100
                          ) /
                            (semana === 0
                              ? 0
                              : semana === 1
                              ? 15
                              : semana === 2
                              ? 40
                              : semana === 3
                              ? 75
                              : 100)
                        ) * 100
                      ) < 95
                        ? "0 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(datosRetail0.cantidad) +
                                    Number(datosPremium0.cantidad) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(datosChevy0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana === 0
                                  ? 0
                                  : semana === 1
                                  ? 15
                                  : semana === 2
                                  ? 40
                                  : semana === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 100
                        ? "0.6 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(datosRetail0.cantidad) +
                                    Number(datosPremium0.cantidad) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(datosChevy0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana === 0
                                  ? 0
                                  : semana === 1
                                  ? 15
                                  : semana === 2
                                  ? 40
                                  : semana === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 104
                        ? "1.0 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(datosRetail0.cantidad) +
                                    Number(datosPremium0.cantidad) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(datosChevy0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana === 0
                                  ? 0
                                  : semana === 1
                                  ? 15
                                  : semana === 2
                                  ? 40
                                  : semana === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 110
                        ? "1.2 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(datosRetail0.cantidad) +
                                    Number(datosPremium0.cantidad) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(datosChevy0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana === 0
                                  ? 0
                                  : semana === 1
                                  ? 15
                                  : semana === 2
                                  ? 40
                                  : semana === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) >= 100
                        ? "1.6 %"
                        : "0 %"
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        (datosPoliticaComercial.pocObjetivoMarca > 0 &&
                        Number(
                          Number(
                            Number(
                              Number(
                                (Number(datosRetail0.cantidad) +
                                  Number(datosPremium0.cantidad) +
                                  Number(datosFlotas0.cantidad) +
                                  Number(datosChevy0.cantidad)) /
                                  datosPoliticaComercial.pocObjetivoMarca
                              ) * 100
                            ) /
                              (semana === 0
                                ? 0
                                : semana === 1
                                ? 15
                                : semana === 2
                                ? 40
                                : semana === 3
                                ? 75
                                : 100)
                          ) * 100
                        ) < 95
                          ? 0
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 100
                          ? 0.4
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 104
                          ? 0.8
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 110
                          ? 1
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) >= 100
                          ? 1.3
                          : 0) *
                          datosPremium0.ventaConRebate *
                          (datosPoliticaComercial.pocObjetivoMarca > 0
                            ? Number(
                                Number(
                                  Number(
                                    Number(
                                      (Number(datosRetail0.cantidad) +
                                        Number(datosPremium0.cantidad) +
                                        Number(datosFlotas0.cantidad) +
                                        Number(datosChevy0.cantidad)) /
                                        datosPoliticaComercial.pocObjetivoMarca
                                    )
                                  ) /
                                    (semana === 0
                                      ? 0
                                      : semana === 1
                                      ? 15
                                      : semana === 2
                                      ? 40
                                      : semana === 3
                                      ? 75
                                      : 100)
                                )
                              )
                            : 0)
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        (datosPoliticaComercial.pocObjetivoMarca > 0 &&
                        Number(
                          Number(
                            Number(
                              Number(
                                (Number(datosRetail0.cantidad) +
                                  Number(datosPremium0.cantidad) +
                                  Number(datosFlotas0.cantidad) +
                                  Number(datosChevy0.cantidad)) /
                                  datosPoliticaComercial.pocObjetivoMarca
                              ) * 100
                            ) /
                              (semana === 0
                                ? 0
                                : semana === 1
                                ? 15
                                : semana === 2
                                ? 40
                                : semana === 3
                                ? 75
                                : 100)
                          ) * 100
                        ) < 95
                          ? 0
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 100
                          ? 0.4
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 104
                          ? 0.8
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 110
                          ? 1
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) >= 100
                          ? 1.3
                          : 0) *
                          datosPremium0.ventaConRebate *
                          (datosPoliticaComercial.pocObjetivoMarca > 0
                            ? Number(
                                Number(
                                  Number(
                                    Number(
                                      (Number(datosRetail0.cantidad) +
                                        Number(datosPremium0.cantidad) +
                                        Number(datosFlotas0.cantidad) +
                                        Number(datosChevy0.cantidad)) /
                                        datosPoliticaComercial.pocObjetivoMarca
                                    )
                                  ) /
                                    (semana === 0
                                      ? 0
                                      : semana === 1
                                      ? 15
                                      : semana === 2
                                      ? 40
                                      : semana === 3
                                      ? 75
                                      : 100)
                                )
                              )
                            : 0) +
                          Number(
                            datosPremium0.ventaConRebate - datosPremium0.costo
                          )
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                </Grid>
                <Grid>
                  <Typography
                    sx={{ mt: 1.5, mb: 1.5, width: "10%" }}
                    style={{ display: "inline-block" }}
                  >
                    Flotas:
                  </Typography>
                  <TextField
                    name="dealear_net"
                    size="small"
                    margin="normal"
                    type="number"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={datosFlotas0 && Number(datosFlotas0.cantidad)}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(datosFlotas0.ventaConRebate)
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      datosFlotas0 &&
                      formatter
                        .format(
                          Number(datosFlotas0.ventaConRebate) -
                            Number(datosFlotas0.costo)
                        )
                        .replace("$", "$  ")
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      Number(datosFlotas0.ventaConRebate) > 0
                        ? datosFlotas0 &&
                          Number(
                            (Number(
                              Number(datosFlotas0.ventaConRebate) -
                                Number(datosFlotas0.costo)
                            ) /
                              Number(datosFlotas0.ventaConRebate)) *
                              100
                          ).toFixed(2) + "  %"
                        : "0 %"
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      datosPoliticaComercial.pocObjetivoMarca > 0 &&
                      Number(
                        Number(
                          Number(
                            Number(
                              (Number(datosRetail0.cantidad) +
                                Number(datosPremium0.cantidad) +
                                Number(datosFlotas0.cantidad) +
                                Number(datosChevy0.cantidad)) /
                                datosPoliticaComercial.pocObjetivoMarca
                            ) * 100
                          ) /
                            (semana === 0
                              ? 0
                              : semana === 1
                              ? 15
                              : semana === 2
                              ? 40
                              : semana === 3
                              ? 75
                              : 100)
                        ) * 100
                      ) < 95
                        ? "0 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(datosRetail0.cantidad) +
                                    Number(datosPremium0.cantidad) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(datosChevy0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana === 0
                                  ? 0
                                  : semana === 1
                                  ? 15
                                  : semana === 2
                                  ? 40
                                  : semana === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 100
                        ? "0.4 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(datosRetail0.cantidad) +
                                    Number(datosPremium0.cantidad) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(datosChevy0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana === 0
                                  ? 0
                                  : semana === 1
                                  ? 15
                                  : semana === 2
                                  ? 40
                                  : semana === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 104
                        ? "0.8 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(datosRetail0.cantidad) +
                                    Number(datosPremium0.cantidad) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(datosChevy0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana === 0
                                  ? 0
                                  : semana === 1
                                  ? 15
                                  : semana === 2
                                  ? 40
                                  : semana === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 110
                        ? "1.0 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(datosRetail0.cantidad) +
                                    Number(datosPremium0.cantidad) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(datosChevy0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana === 0
                                  ? 0
                                  : semana === 1
                                  ? 15
                                  : semana === 2
                                  ? 40
                                  : semana === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) >= 100
                        ? "1.3 %"
                        : "0 %"
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        (datosPoliticaComercial.pocObjetivoMarca > 0 &&
                        Number(
                          Number(
                            Number(
                              Number(
                                (Number(datosRetail0.cantidad) +
                                  Number(datosPremium0.cantidad) +
                                  Number(datosFlotas0.cantidad) +
                                  Number(datosChevy0.cantidad)) /
                                  datosPoliticaComercial.pocObjetivoMarca
                              ) * 100
                            ) /
                              (semana === 0
                                ? 0
                                : semana === 1
                                ? 15
                                : semana === 2
                                ? 40
                                : semana === 3
                                ? 75
                                : 100)
                          ) * 100
                        ) < 95
                          ? 0
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 100
                          ? 0.4
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 104
                          ? 0.8
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 110
                          ? 1
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) >= 100
                          ? 1.3
                          : 0) *
                          datosFlotas0.ventaConRebate *
                          (datosPoliticaComercial.pocObjetivoMarca > 0
                            ? Number(
                                Number(
                                  Number(
                                    Number(
                                      (Number(datosRetail0.cantidad) +
                                        Number(datosPremium0.cantidad) +
                                        Number(datosFlotas0.cantidad) +
                                        Number(datosChevy0.cantidad)) /
                                        datosPoliticaComercial.pocObjetivoMarca
                                    )
                                  ) /
                                    (semana === 0
                                      ? 0
                                      : semana === 1
                                      ? 15
                                      : semana === 2
                                      ? 40
                                      : semana === 3
                                      ? 75
                                      : 100)
                                )
                              )
                            : 0)
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        (datosPoliticaComercial.pocObjetivoMarca > 0 &&
                        Number(
                          Number(
                            Number(
                              Number(
                                (Number(datosRetail0.cantidad) +
                                  Number(datosPremium0.cantidad) +
                                  Number(datosFlotas0.cantidad) +
                                  Number(datosChevy0.cantidad)) /
                                  datosPoliticaComercial.pocObjetivoMarca
                              ) * 100
                            ) /
                              (semana === 0
                                ? 0
                                : semana === 1
                                ? 15
                                : semana === 2
                                ? 40
                                : semana === 3
                                ? 75
                                : 100)
                          ) * 100
                        ) < 95
                          ? 0
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 100
                          ? 0.4
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 104
                          ? 0.8
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 110
                          ? 1
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) >= 100
                          ? 1.3
                          : 0) *
                          datosFlotas0.ventaConRebate *
                          (datosPoliticaComercial.pocObjetivoMarca > 0
                            ? Number(
                                Number(
                                  Number(
                                    Number(
                                      (Number(datosRetail0.cantidad) +
                                        Number(datosPremium0.cantidad) +
                                        Number(datosFlotas0.cantidad) +
                                        Number(datosChevy0.cantidad)) /
                                        datosPoliticaComercial.pocObjetivoMarca
                                    )
                                  ) /
                                    (semana === 0
                                      ? 0
                                      : semana === 1
                                      ? 15
                                      : semana === 2
                                      ? 40
                                      : semana === 3
                                      ? 75
                                      : 100)
                                )
                              )
                            : 0) +
                          Number(
                            datosFlotas0.ventaConRebate - datosFlotas0.costo
                          )
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                </Grid>
                <Grid>
                  <Typography
                    sx={{ mt: 1.5, mb: 1.5, width: "10%" }}
                    style={{ display: "inline-block" }}
                  >
                    Chevy:
                  </Typography>
                  <TextField
                    name="dealear_net"
                    size="small"
                    margin="normal"
                    type="number"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={datosChevy0 && Number(datosChevy0.cantidad)}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(datosChevy0.ventaConRebate)
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      datosChevy0 &&
                      formatter
                        .format(
                          Number(datosChevy0.ventaConRebate) -
                            Number(datosChevy0.costo)
                        )
                        .replace("$", "$  ")
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      Number(datosChevy0.ventaConRebate) > 0
                        ? datosChevy0 &&
                          Number(
                            (Number(
                              Number(datosChevy0.ventaConRebate) -
                                Number(datosChevy0.costo)
                            ) /
                              Number(datosChevy0.ventaConRebate)) *
                              100
                          ).toFixed(2) + "  %"
                        : "0 %"
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="chevy"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      datosPoliticaComercial.pocObjetivoMarca > 0 &&
                      Number(
                        Number(
                          Number(
                            Number(
                              (Number(datosRetail0.cantidad) +
                                Number(datosPremium0.cantidad) +
                                Number(datosFlotas0.cantidad) +
                                Number(datosChevy0.cantidad)) /
                                datosPoliticaComercial.pocObjetivoMarca
                            ) * 100
                          ) /
                            (semana === 0
                              ? 0
                              : semana === 1
                              ? 15
                              : semana === 2
                              ? 40
                              : semana === 3
                              ? 75
                              : 100)
                        ) * 100
                      ) < 95
                        ? "0 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(datosRetail0.cantidad) +
                                    Number(datosPremium0.cantidad) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(datosChevy0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana === 0
                                  ? 0
                                  : semana === 1
                                  ? 15
                                  : semana === 2
                                  ? 40
                                  : semana === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 100
                        ? "0.7 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(datosRetail0.cantidad) +
                                    Number(datosPremium0.cantidad) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(datosChevy0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana === 0
                                  ? 0
                                  : semana === 1
                                  ? 15
                                  : semana === 2
                                  ? 40
                                  : semana === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 104
                        ? "0.7 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(datosRetail0.cantidad) +
                                    Number(datosPremium0.cantidad) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(datosChevy0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana === 0
                                  ? 0
                                  : semana === 1
                                  ? 15
                                  : semana === 2
                                  ? 40
                                  : semana === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 110
                        ? "0.7 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(datosRetail0.cantidad) +
                                    Number(datosPremium0.cantidad) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(datosChevy0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana === 0
                                  ? 0
                                  : semana === 1
                                  ? 15
                                  : semana === 2
                                  ? 40
                                  : semana === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) >= 100
                        ? "0.7 %"
                        : "0 %"
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }} //esperado * las ventas netas * proyectado
                    value={formatter
                      .format(
                        (datosPoliticaComercial.pocObjetivoMarca > 0 &&
                        Number(
                          Number(
                            Number(
                              Number(
                                (Number(datosRetail0.cantidad) +
                                  Number(datosPremium0.cantidad) +
                                  Number(datosFlotas0.cantidad) +
                                  Number(datosChevy0.cantidad)) /
                                  datosPoliticaComercial.pocObjetivoMarca
                              ) * 100
                            ) /
                              (semana === 0
                                ? 0
                                : semana === 1
                                ? 15
                                : semana === 2
                                ? 40
                                : semana === 3
                                ? 75
                                : 100)
                          ) * 100
                        ) < 95
                          ? 0
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 100
                          ? 0.7
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 104
                          ? 0.7
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 110
                          ? 0.7
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) >= 100
                          ? 0.7
                          : 0) *
                          datosChevy0.ventaConRebate *
                          (datosPoliticaComercial.pocObjetivoMarca > 0
                            ? Number(
                                Number(
                                  Number(
                                    Number(
                                      (Number(datosRetail0.cantidad) +
                                        Number(datosPremium0.cantidad) +
                                        Number(datosFlotas0.cantidad) +
                                        Number(datosChevy0.cantidad)) /
                                        datosPoliticaComercial.pocObjetivoMarca
                                    )
                                  ) /
                                    (semana === 0
                                      ? 0
                                      : semana === 1
                                      ? 15
                                      : semana === 2
                                      ? 40
                                      : semana === 3
                                      ? 75
                                      : 100)
                                )
                              )
                            : 0)
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        (datosPoliticaComercial.pocObjetivoMarca > 0 &&
                        Number(
                          Number(
                            Number(
                              Number(
                                (Number(datosRetail0.cantidad) +
                                  Number(datosPremium0.cantidad) +
                                  Number(datosFlotas0.cantidad) +
                                  Number(datosChevy0.cantidad)) /
                                  datosPoliticaComercial.pocObjetivoMarca
                              ) * 100
                            ) /
                              (semana === 0
                                ? 0
                                : semana === 1
                                ? 15
                                : semana === 2
                                ? 40
                                : semana === 3
                                ? 75
                                : 100)
                          ) * 100
                        ) < 95
                          ? 0
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 100
                          ? 0.7
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 104
                          ? 0.7
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 110
                          ? 0.7
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) >= 100
                          ? 0.7
                          : 0) *
                          datosChevy0.ventaConRebate *
                          (datosPoliticaComercial.pocObjetivoMarca > 0
                            ? Number(
                                Number(
                                  Number(
                                    Number(
                                      (Number(datosRetail0.cantidad) +
                                        Number(datosPremium0.cantidad) +
                                        Number(datosFlotas0.cantidad) +
                                        Number(datosChevy0.cantidad)) /
                                        datosPoliticaComercial.pocObjetivoMarca
                                    )
                                  ) /
                                    (semana === 0
                                      ? 0
                                      : semana === 1
                                      ? 15
                                      : semana === 2
                                      ? 40
                                      : semana === 3
                                      ? 75
                                      : 100)
                                )
                              )
                            : 0) +
                          Number(datosChevy0.ventaConRebate - datosChevy0.costo)
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                </Grid>
                <Grid>
                  <Typography
                    sx={{ mt: 1.5, mb: 1.5, width: "10%" }}
                    style={{ display: "inline-block" }}
                  >
                    <strong>Total:</strong>
                  </Typography>
                  <TextField
                    name="dealear_net"
                    size="small"
                    margin="normal"
                    type="number"
                    style={{ backgroundColor: "rgb(240,248,255)" }}
                    value={
                      datosChevy0 &&
                      Number(datosRetail0.cantidad) +
                        Number(datosPremium0.cantidad) +
                        Number(datosFlotas0.cantidad) +
                        Number(datosChevy0.cantidad)
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(240,248,255)" }}
                    value={formatter
                      .format(
                        Number(datosRetail0.ventaConRebate) +
                          Number(datosPremium0.ventaConRebate) +
                          Number(datosFlotas0.ventaConRebate) +
                          Number(datosChevy0.ventaConRebate)
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(240,248,255)" }}
                    value={
                      datosChevy0 &&
                      formatter
                        .format(
                          Number(datosRetail0.ventaConRebate) +
                            Number(datosPremium0.ventaConRebate) +
                            Number(datosFlotas0.ventaConRebate) +
                            Number(datosChevy0.ventaConRebate) -
                            (Number(datosRetail0.costo) +
                              Number(datosPremium0.costo) +
                              Number(datosFlotas0.costo) +
                              Number(datosChevy0.costo))
                        )
                        .replace("$", "$  ")
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(240,248,255)" }}
                    value={
                      Number(datosRetail0.ventaConRebate) +
                        Number(datosPremium0.ventaConRebate) +
                        Number(datosFlotas0.ventaConRebate) +
                        Number(datosChevy0.ventaConRebate) >
                      0
                        ? datosChevy0 &&
                          Number(
                            (Number(
                              Number(datosRetail0.ventaConRebate) +
                                Number(datosPremium0.ventaConRebate) +
                                Number(datosFlotas0.ventaConRebate) +
                                Number(datosChevy0.ventaConRebate) -
                                (Number(datosRetail0.costo) +
                                  Number(datosPremium0.costo) +
                                  Number(datosFlotas0.costo) +
                                  Number(datosChevy0.costo))
                            ) /
                              (Number(datosRetail0.ventaConRebate) +
                                Number(datosPremium0.ventaConRebate) +
                                Number(datosFlotas0.ventaConRebate) +
                                Number(datosChevy0.ventaConRebate))) *
                              100
                          ).toFixed(2) + "  %"
                        : "0 %"
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />

                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(240,248,255)" }}
                    value={formatter
                      .format(
                        Number(
                          (datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(datosRetail0.cantidad) +
                                    Number(datosPremium0.cantidad) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(datosChevy0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana === 0
                                  ? 0
                                  : semana === 1
                                  ? 15
                                  : semana === 2
                                  ? 40
                                  : semana === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 95
                            ? 0
                            : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                              Number(
                                Number(
                                  Number(
                                    Number(
                                      (Number(datosRetail0.cantidad) +
                                        Number(datosPremium0.cantidad) +
                                        Number(datosFlotas0.cantidad) +
                                        Number(datosChevy0.cantidad)) /
                                        datosPoliticaComercial.pocObjetivoMarca
                                    ) * 100
                                  ) /
                                    (semana === 0
                                      ? 0
                                      : semana === 1
                                      ? 15
                                      : semana === 2
                                      ? 40
                                      : semana === 3
                                      ? 75
                                      : 100)
                                ) * 100
                              ) < 100
                            ? 0.4
                            : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                              Number(
                                Number(
                                  Number(
                                    Number(
                                      (Number(datosRetail0.cantidad) +
                                        Number(datosPremium0.cantidad) +
                                        Number(datosFlotas0.cantidad) +
                                        Number(datosChevy0.cantidad)) /
                                        datosPoliticaComercial.pocObjetivoMarca
                                    ) * 100
                                  ) /
                                    (semana === 0
                                      ? 0
                                      : semana === 1
                                      ? 15
                                      : semana === 2
                                      ? 40
                                      : semana === 3
                                      ? 75
                                      : 100)
                                ) * 100
                              ) < 104
                            ? 0.8
                            : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                              Number(
                                Number(
                                  Number(
                                    Number(
                                      (Number(datosRetail0.cantidad) +
                                        Number(datosPremium0.cantidad) +
                                        Number(datosFlotas0.cantidad) +
                                        Number(datosChevy0.cantidad)) /
                                        datosPoliticaComercial.pocObjetivoMarca
                                    ) * 100
                                  ) /
                                    (semana === 0
                                      ? 0
                                      : semana === 1
                                      ? 15
                                      : semana === 2
                                      ? 40
                                      : semana === 3
                                      ? 75
                                      : 100)
                                ) * 100
                              ) < 110
                            ? 1
                            : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                              Number(
                                Number(
                                  Number(
                                    Number(
                                      (Number(datosRetail0.cantidad) +
                                        Number(datosPremium0.cantidad) +
                                        Number(datosFlotas0.cantidad) +
                                        Number(datosChevy0.cantidad)) /
                                        datosPoliticaComercial.pocObjetivoMarca
                                    ) * 100
                                  ) /
                                    (semana === 0
                                      ? 0
                                      : semana === 1
                                      ? 15
                                      : semana === 2
                                      ? 40
                                      : semana === 3
                                      ? 75
                                      : 100)
                                ) * 100
                              ) >= 100
                            ? 1.3
                            : 0) *
                            datosRetail0.ventaConRebate *
                            (datosPoliticaComercial.pocObjetivoMarca > 0
                              ? Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      )
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  )
                                )
                              : 0)
                        ) +
                          Number(
                            (datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 95
                              ? 0
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 100
                              ? 0.4
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 104
                              ? 0.8
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 110
                              ? 1
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) >= 100
                              ? 1.3
                              : 0) *
                              datosPremium0.ventaConRebate *
                              (datosPoliticaComercial.pocObjetivoMarca > 0
                                ? Number(
                                    Number(
                                      Number(
                                        Number(
                                          (Number(datosRetail0.cantidad) +
                                            Number(datosPremium0.cantidad) +
                                            Number(datosFlotas0.cantidad) +
                                            Number(datosChevy0.cantidad)) /
                                            datosPoliticaComercial.pocObjetivoMarca
                                        )
                                      ) /
                                        (semana === 0
                                          ? 0
                                          : semana === 1
                                          ? 15
                                          : semana === 2
                                          ? 40
                                          : semana === 3
                                          ? 75
                                          : 100)
                                    )
                                  )
                                : 0)
                          ) +
                          Number(
                            (datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 95
                              ? 0
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 100
                              ? 0.4
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 104
                              ? 0.8
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 110
                              ? 1
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) >= 100
                              ? 1.3
                              : 0) *
                              datosFlotas0.ventaConRebate *
                              (datosPoliticaComercial.pocObjetivoMarca > 0
                                ? Number(
                                    Number(
                                      Number(
                                        Number(
                                          (Number(datosRetail0.cantidad) +
                                            Number(datosPremium0.cantidad) +
                                            Number(datosFlotas0.cantidad) +
                                            Number(datosChevy0.cantidad)) /
                                            datosPoliticaComercial.pocObjetivoMarca
                                        )
                                      ) /
                                        (semana === 0
                                          ? 0
                                          : semana === 1
                                          ? 15
                                          : semana === 2
                                          ? 40
                                          : semana === 3
                                          ? 75
                                          : 100)
                                    )
                                  )
                                : 0)
                          ) +
                          Number(
                            (datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 95
                              ? 0
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 100
                              ? 0.7
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 104
                              ? 0.7
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 110
                              ? 0.7
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) >= 100
                              ? 0.7
                              : 0) *
                              datosChevy0.ventaConRebate *
                              (datosPoliticaComercial.pocObjetivoMarca > 0
                                ? Number(
                                    Number(
                                      Number(
                                        Number(
                                          (Number(datosRetail0.cantidad) +
                                            Number(datosPremium0.cantidad) +
                                            Number(datosFlotas0.cantidad) +
                                            Number(datosChevy0.cantidad)) /
                                            datosPoliticaComercial.pocObjetivoMarca
                                        )
                                      ) /
                                        (semana === 0
                                          ? 0
                                          : semana === 1
                                          ? 15
                                          : semana === 2
                                          ? 40
                                          : semana === 3
                                          ? 75
                                          : 100)
                                    )
                                  )
                                : 0)
                          )
                      )
                      .replace("$", "$  ")}
                    sx={{
                      mt: 0.5,
                      mb: 0.5,
                      marginRight: "1%",
                      width: "12%",
                      marginLeft: "13%",
                    }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(240,248,255)" }}
                    value={formatter
                      .format(
                        Number(
                          (datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(datosRetail0.cantidad) +
                                    Number(datosPremium0.cantidad) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(datosChevy0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana === 0
                                  ? 0
                                  : semana === 1
                                  ? 15
                                  : semana === 2
                                  ? 40
                                  : semana === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 95
                            ? 0
                            : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                              Number(
                                Number(
                                  Number(
                                    Number(
                                      (Number(datosRetail0.cantidad) +
                                        Number(datosPremium0.cantidad) +
                                        Number(datosFlotas0.cantidad) +
                                        Number(datosChevy0.cantidad)) /
                                        datosPoliticaComercial.pocObjetivoMarca
                                    ) * 100
                                  ) /
                                    (semana === 0
                                      ? 0
                                      : semana === 1
                                      ? 15
                                      : semana === 2
                                      ? 40
                                      : semana === 3
                                      ? 75
                                      : 100)
                                ) * 100
                              ) < 100
                            ? 0.4
                            : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                              Number(
                                Number(
                                  Number(
                                    Number(
                                      (Number(datosRetail0.cantidad) +
                                        Number(datosPremium0.cantidad) +
                                        Number(datosFlotas0.cantidad) +
                                        Number(datosChevy0.cantidad)) /
                                        datosPoliticaComercial.pocObjetivoMarca
                                    ) * 100
                                  ) /
                                    (semana === 0
                                      ? 0
                                      : semana === 1
                                      ? 15
                                      : semana === 2
                                      ? 40
                                      : semana === 3
                                      ? 75
                                      : 100)
                                ) * 100
                              ) < 104
                            ? 0.8
                            : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                              Number(
                                Number(
                                  Number(
                                    Number(
                                      (Number(datosRetail0.cantidad) +
                                        Number(datosPremium0.cantidad) +
                                        Number(datosFlotas0.cantidad) +
                                        Number(datosChevy0.cantidad)) /
                                        datosPoliticaComercial.pocObjetivoMarca
                                    ) * 100
                                  ) /
                                    (semana === 0
                                      ? 0
                                      : semana === 1
                                      ? 15
                                      : semana === 2
                                      ? 40
                                      : semana === 3
                                      ? 75
                                      : 100)
                                ) * 100
                              ) < 110
                            ? 1
                            : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                              Number(
                                Number(
                                  Number(
                                    Number(
                                      (Number(datosRetail0.cantidad) +
                                        Number(datosPremium0.cantidad) +
                                        Number(datosFlotas0.cantidad) +
                                        Number(datosChevy0.cantidad)) /
                                        datosPoliticaComercial.pocObjetivoMarca
                                    ) * 100
                                  ) /
                                    (semana === 0
                                      ? 0
                                      : semana === 1
                                      ? 15
                                      : semana === 2
                                      ? 40
                                      : semana === 3
                                      ? 75
                                      : 100)
                                ) * 100
                              ) >= 100
                            ? 1.3
                            : 0) *
                            datosRetail0.ventaConRebate *
                            (datosPoliticaComercial.pocObjetivoMarca > 0
                              ? Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      )
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  )
                                )
                              : 0)
                        ) +
                          Number(
                            (datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 95
                              ? 0
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 100
                              ? 0.4
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 104
                              ? 0.8
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 110
                              ? 1
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) >= 100
                              ? 1.3
                              : 0) *
                              datosPremium0.ventaConRebate *
                              (datosPoliticaComercial.pocObjetivoMarca > 0
                                ? Number(
                                    Number(
                                      Number(
                                        Number(
                                          (Number(datosRetail0.cantidad) +
                                            Number(datosPremium0.cantidad) +
                                            Number(datosFlotas0.cantidad) +
                                            Number(datosChevy0.cantidad)) /
                                            datosPoliticaComercial.pocObjetivoMarca
                                        )
                                      ) /
                                        (semana === 0
                                          ? 0
                                          : semana === 1
                                          ? 15
                                          : semana === 2
                                          ? 40
                                          : semana === 3
                                          ? 75
                                          : 100)
                                    )
                                  )
                                : 0)
                          ) +
                          Number(
                            (datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 95
                              ? 0
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 100
                              ? 0.4
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 104
                              ? 0.8
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 110
                              ? 1
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) >= 100
                              ? 1.3
                              : 0) *
                              datosFlotas0.ventaConRebate *
                              (datosPoliticaComercial.pocObjetivoMarca > 0
                                ? Number(
                                    Number(
                                      Number(
                                        Number(
                                          (Number(datosRetail0.cantidad) +
                                            Number(datosPremium0.cantidad) +
                                            Number(datosFlotas0.cantidad) +
                                            Number(datosChevy0.cantidad)) /
                                            datosPoliticaComercial.pocObjetivoMarca
                                        )
                                      ) /
                                        (semana === 0
                                          ? 0
                                          : semana === 1
                                          ? 15
                                          : semana === 2
                                          ? 40
                                          : semana === 3
                                          ? 75
                                          : 100)
                                    )
                                  )
                                : 0)
                          ) +
                          Number(
                            (datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 95
                              ? 0
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 100
                              ? 0.7
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 104
                              ? 0.7
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 110
                              ? 0.7
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) >= 100
                              ? 0.7
                              : 0) *
                              datosChevy0.ventaConRebate *
                              (datosPoliticaComercial.pocObjetivoMarca > 0
                                ? Number(
                                    Number(
                                      Number(
                                        Number(
                                          (Number(datosRetail0.cantidad) +
                                            Number(datosPremium0.cantidad) +
                                            Number(datosFlotas0.cantidad) +
                                            Number(datosChevy0.cantidad)) /
                                            datosPoliticaComercial.pocObjetivoMarca
                                        )
                                      ) /
                                        (semana === 0
                                          ? 0
                                          : semana === 1
                                          ? 15
                                          : semana === 2
                                          ? 40
                                          : semana === 3
                                          ? 75
                                          : 100)
                                    )
                                  )
                                : 0)
                          ) +
                          Number(
                            Number(datosRetail0.ventaConRebate) +
                              Number(datosPremium0.ventaConRebate) +
                              Number(datosFlotas0.ventaConRebate) +
                              Number(datosChevy0.ventaConRebate) -
                              (Number(datosRetail0.costo) +
                                Number(datosPremium0.costo) +
                                Number(datosFlotas0.costo) +
                                Number(datosChevy0.costo))
                          )
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                </Grid>

                <br />
                <Grid>
                  <Typography
                    sx={{ mt: 3, mb: 0.3, width: "15%", marginLeft: "35%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    Objetivo Marca:
                  </Typography>
                  <TextField
                    name="pocObjetivoMarca"
                    size="small"
                    margin="normal"
                    type="number"
                    onChange={dataPolComercial}
                    //¿value={}
                    sx={{ width: "15%", marginLeft: "1%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                </Grid>
                <br />
                <Grid>
                  <Typography
                    sx={{ mt: 0.3, mb: 0.3, width: "15%", marginLeft: "35%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    % Cumplimiento:
                  </Typography>
                  <Typography
                    sx={{ mt: 0.3, mb: 0.3, width: "15%", marginLeft: "1%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgb(240,248,255)",
                      display: "inline-block",
                    }}
                  >
                    {datosPoliticaComercial.pocObjetivoMarca > 0
                      ? Number(
                          Number(
                            (Number(datosRetail0.cantidad) +
                              Number(datosPremium0.cantidad) +
                              Number(datosFlotas0.cantidad) +
                              Number(datosChevy0.cantidad)) /
                              datosPoliticaComercial.pocObjetivoMarca
                          ) * 100
                        ).toFixed(2) + " %"
                      : "0 %"}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    sx={{ mt: 0.3, mb: 0.3, width: "15%", marginLeft: "35%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    % Esperado:
                  </Typography>
                  <Typography
                    sx={{ mt: 0.3, mb: 0.3, width: "15%", marginLeft: "1%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgb(240,248,255)",
                      display: "inline-block",
                    }}
                  >
                    {semana === 0
                      ? "0 %"
                      : semana === 1
                      ? "15 %"
                      : semana === 2
                      ? "40 %"
                      : semana === 3
                      ? "75 %"
                      : "100 %"}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    sx={{ mt: 0.3, mb: 0.3, width: "15%", marginLeft: "35%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    % Proyectado:
                  </Typography>
                  <Typography
                    sx={{ mt: 0.3, mb: 0.3, width: "15%", marginLeft: "1%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgb(240,248,255)",
                      display: "inline-block",
                    }}
                  >
                    {datosPoliticaComercial.pocObjetivoMarca > 0
                      ? Number(
                          Number(
                            Number(
                              Number(
                                (Number(datosRetail0.cantidad) +
                                  Number(datosPremium0.cantidad) +
                                  Number(datosFlotas0.cantidad) +
                                  Number(datosChevy0.cantidad)) /
                                  datosPoliticaComercial.pocObjetivoMarca
                              ) * 100
                            ) /
                              (semana === 0
                                ? 0
                                : semana === 1
                                ? 15
                                : semana === 2
                                ? 40
                                : semana === 3
                                ? 75
                                : 100)
                          ) * 100
                        ).toFixed(2) + " %"
                      : "0 %"}
                  </Typography>
                </Grid>

                {/* -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/}
                {/* -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/}
                {/* ------------------------------------------------------------------------------------------------------- segunda tabla -------------------------------------------------------------------------------------------------------*/}
                {/* -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/}
                {/* -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/}

                <Divider sx={{ mt: 1, mb: 1 }} />

                <Typography
                  //id="modal-modal-title"
                  sx={{ mt: 0, mb: 0, width: "100%" }}
                  //variant="h5"
                  //textAlign={"center"}
                  //style={{  display: "inline-block"}}
                >
                  Semana Proyectada:{" "}
                  <strong>{semana === 4 ? 4 : semana + 1}</strong>
                </Typography>
                {/* <Divider sx={{ mt: 1, mb: 0.2 }} /> */}
                <Box>
                  <Typography
                    //id="modal-modal-title"
                    sx={{ mt: 0, mb: 0.2, width: "9%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    Livianos
                  </Typography>
                  <Typography
                    //id="modal-modal-title"
                    sx={{ mt: 0, mb: 0, width: "13%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    Ventas unidades
                  </Typography>
                  <Typography
                    //id="modal-modal-title"
                    sx={{ mt: 0, mb: 0, width: "13%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    Ventas netas
                  </Typography>
                  <Typography
                    //id="modal-modal-title"
                    sx={{ mt: 0, mb: 0, width: "13%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    Margen bruto real
                  </Typography>
                  <Typography
                    //id="modal-modal-title"
                    sx={{ mt: 0, mb: 0, width: "14%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    Margen bruto
                  </Typography>

                  <Typography
                    //id="modal-modal-title"
                    sx={{ mt: 0, mb: 0, width: "12%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    Agregar
                  </Typography>
                </Box>
                {/* <Divider sx={{ mt: 0, mb: 0 }} /> */}
                <Grid>
                  <Typography
                    sx={{ mt: 1.5, mb: 1.5, marginRight: "1%", width: "9%" }}
                    style={{ display: "inline-block" }}
                  >
                    Retail:
                  </Typography>
                  <TextField
                    name="pocUnidadesRetail"
                    size="small"
                    margin="normal"
                    type="number"
                    // style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={Number(
                      datosPoliticaComercial.pocUnidadesRetail
                    )}
                    onChange={dataPolComercial}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="actual_retail_venta"
                    size="small"
                    margin="normal"
                    //  style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        Number(datosPoliticaComercial.pocMontoRetail)
                      )
                      .replace("$", "$  ")}
                    onChange={dataPolComercial}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="actual_retail_marge"
                    size="small"
                    margin="normal"
                    //  style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        Number(datosPoliticaComercial.pocMargenRetail)
                      )
                      .replace("$", "$  ")}
                    onChange={dataPolComercial}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      datosPoliticaComercial.pocMontoRetail > 0
                        ? Number(
                            (Number(
                              datosPoliticaComercial.pocMargenRetail
                            ) /
                              Number(
                                datosPoliticaComercial.pocMontoRetail
                              )) *
                              100
                          ).toFixed(2) + "  %"
                        : "0  %"
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <Button
                    sx={{ width: "12%" }}
                    onClick={function (event) {
                      handleOpenRetail();
                    }}
                  >
                    Abrir
                  </Button>
                  <Modal
                    open={openRetail}
                    onClose={handleCloseRetail}
                    aria-labelledby="modal-modal-titlere"
                    aria-describedby="modal-modal-descriptionre"
                  >
                    <Box sx={styleModal}>
                      Retail
                      <DataGrid
                        sx={{ height: 400 }}
                        editMode="row"
                        rows={rowsModeloRetail}
                        columns={columnsRetail}
                      />
                      <Button
                        variant="contained"
                        sx={{
                          mt: 1.5,
                          mb: 1.5,
                          width: "20%",
                          marginLeft: "80%",
                        }}
                        onClick={function (event) {
                          handleAgregarRetail();
                          handleCloseRetail();
                        }}
                      >
                        Agregar
                      </Button>
                    </Box>
                  </Modal>
                  <Modal
                    open={openPremium}
                    onClose={handleClosePremium}
                    aria-labelledby="modal-modal-titlepre"
                    aria-describedby="modal-modal-descriptionpre"
                  >
                    <Box sx={styleModal}>
                      Premium
                      <DataGrid
                        sx={{ height: 400 }}
                        editMode="row"
                        rows={rowsModeloPremium}
                        columns={columnsPremium}
                      />
                      <Button
                        variant="contained"
                        sx={{
                          mt: 1.5,
                          mb: 1.5,
                          width: "20%",
                          marginLeft: "80%",
                        }}
                        onClick={function (event) {
                          handleAgregarPremium();
                          handleClosePremium();
                        }}
                      >
                        Agregar
                      </Button>
                    </Box>
                  </Modal>
                  <Modal
                    open={openFlotas}
                    onClose={handleCloseFlotas}
                    aria-labelledby="modal-modal-titleflo"
                    aria-describedby="modal-modal-descriptionflo"
                  >
                    <Box sx={styleModal}>
                      Flotas
                      <DataGrid
                        sx={{ height: 400 }}
                        editMode="row"
                        rows={rowsModeloFlotas}
                        columns={columnsFlotas}
                      />
                      <Button
                        variant="contained"
                        sx={{
                          mt: 1.5,
                          mb: 1.5,
                          width: "20%",
                          marginLeft: "80%",
                        }}
                        onClick={function (event) {
                          handleAgregarFlotas();
                          handleCloseFlotas();
                        }}
                      >
                        Agregar
                      </Button>
                    </Box>
                  </Modal>
                  <Modal
                    open={openChevy}
                    onClose={handleCloseChevy}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={styleModal}>
                      Chevy
                      <DataGrid
                        sx={{ height: 400 }}
                        editMode="row"
                        rows={rowsModeloChevy}
                        columns={columnsChevy}
                      />
                      <Button
                        variant="contained"
                        sx={{
                          mt: 1.5,
                          mb: 1.5,
                          width: "20%",
                          marginLeft: "80%",
                        }}
                        onClick={function (event) {
                          handleAgregarChevy();
                          handleCloseChevy();
                        }}
                      >
                        Agregar
                      </Button>
                    </Box>
                  </Modal>
                </Grid>
                <Grid>
                  <Typography
                    sx={{ mt: 1.5, mb: 1.5, marginRight: "1%", width: "9%" }}
                    style={{ display: "inline-block" }}
                  >
                    Premium:
                  </Typography>
                  <TextField
                    name="pocUnidadesPremium"
                    size="small"
                    margin="normal"
                    type="number"
                    // style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={Number(
                      datosPoliticaComercial.pocUnidadesPremium
                    )}
                    onChange={dataPolComercial}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="actual_premium_venta"
                    size="small"
                    margin="normal"
                    // style={{ backgroundColor: "rgb(242,242,242)" }
                    //value={  formatter.format(Number(datosPoliticaComercial.pocMontoPremium)).replace("$", "$  ")}
                    value={formatter
                      .format(
                        Number(datosPoliticaComercial.pocMontoPremium)
                      )
                      .replace("$", "$  ")}
                    onChange={dataPolComercial}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="actual_premium_marge"
                    size="small"
                    margin="normal"
                    //   style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        Number(datosPoliticaComercial.pocMargenPremium)
                      )
                      .replace("$", "$  ")}
                    onChange={dataPolComercial}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      datosPoliticaComercial.pocMontoPremium > 0
                        ? Number(
                            (Number(
                              datosPoliticaComercial.pocMargenPremium
                            ) /
                              Number(
                                datosPoliticaComercial.pocMontoPremium
                              )) *
                              100
                          ).toFixed(2) + "  %"
                        : "0  %"
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <Button
                    sx={{ width: "12%" }}
                    onClick={function (event) {
                      handleOpenPremium();
                    }}
                  >
                    Abrir
                  </Button>
                </Grid>
                <Grid>
                  <Typography
                    sx={{ mt: 1.5, mb: 1.5, marginRight: "1%", width: "9%" }}
                    style={{ display: "inline-block" }}
                  >
                    Flotas:
                  </Typography>
                  <TextField
                    name="pocUnidadesFlotas"
                    size="small"
                    margin="normal"
                    type="number"
                    //  style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={Number(
                      datosPoliticaComercial.pocUnidadesFlotas
                    )}
                    onChange={dataPolComercial}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="pocMontoFlotas"
                    size="small"
                    margin="normal"
                    //    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        Number(datosPoliticaComercial.pocMontoFlotas)
                      )
                      .replace("$", "$  ")}
                    onChange={dataPolComercial}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="pocMontoFlotas"
                    size="small"
                    margin="normal"
                    //   style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        Number(datosPoliticaComercial.pocMontoFlotas)
                      )
                      .replace("$", "$  ")}
                    onChange={dataPolComercial}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      datosPoliticaComercial.pocMontoFlotas > 0
                        ? Number(
                            (Number(
                              datosPoliticaComercial.pocMontoFlotas
                            ) /
                              Number(
                                datosPoliticaComercial.pocMontoFlotas
                              )) *
                              100
                          ).toFixed(2) + "  %"
                        : "0  %"
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <Button
                    sx={{ width: "12%" }}
                    onClick={function (event) {
                      handleOpenFlotas();
                    }}
                  >
                    Abrir
                  </Button>
                </Grid>
                <Grid>
                  <Typography
                    sx={{ mt: 1.5, mb: 1.5, marginRight: "1%", width: "9%" }}
                    style={{ display: "inline-block" }}
                  >
                    Chevy:
                  </Typography>
                  <TextField
                    name="pocUnidadesChevy"
                    size="small"
                    margin="normal"
                    type="number"
                    // style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={Number(datosPoliticaComercial.pocUnidadesChevy)}
                    onChange={dataPolComercial}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="pocUnidadesChevy"
                    size="small"
                    margin="normal"
                    //  style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        Number(datosPoliticaComercial.pocUnidadesChevy)
                      )
                      .replace("$", "$  ")}
                    onChange={dataPolComercial}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="pocMargenChevy"
                    size="small"
                    margin="normal"
                    // style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        Number(datosPoliticaComercial.pocMargenChevy)
                      )
                      .replace("$", "$  ")}
                    onChange={dataPolComercial}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      datosPoliticaComercial.pocUnidadesChevy > 0
                        ? Number(
                            (Number(
                              datosPoliticaComercial.pocMargenChevy
                            ) /
                              Number(
                                datosPoliticaComercial.pocUnidadesChevy
                              )) *
                              100
                          ).toFixed(2) + "  %"
                        : "0  %"
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <Button
                    sx={{ width: "12%" }}
                    onClick={function (event) {
                      handleOpenChevy();
                    }}
                  >
                    Abrir
                  </Button>
                </Grid>
                <Grid>
                  <Typography
                    sx={{ mt: 1.5, mb: 1.5, width: "10%" }}
                    style={{ display: "inline-block" }}
                  >
                    <strong>Total:</strong>
                  </Typography>
                  <TextField
                    name="tot1"
                    size="small"
                    margin="normal"
                    type="number"
                    style={{ backgroundColor: "rgb(240,248,255)" }}
                    value={Number(
                      Number(datosPoliticaComercial.pocUnidadesChevy) +
                        Number(datosPoliticaComercial.pocUnidadesFlotas) +
                        Number(datosPoliticaComercial.pocUnidadesPremium) +
                        Number(datosPoliticaComercial.pocUnidadesRetail)
                    )}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(240,248,255)" }}
                    //value={  formatter.format(Number(datosPoliticaComercial.pocMontoPremium)).replace("$", "$  ")}
                    value={formatter
                      .format(
                        Number(
                          Number(datosPoliticaComercial.pocUnidadesChevy) +
                            Number(
                              datosPoliticaComercial.pocMontoFlotas
                            ) +
                            Number(
                              datosPoliticaComercial.pocMontoPremium
                            ) +
                            Number(datosPoliticaComercial.pocMontoRetail)
                        )
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(240,248,255)" }}
                    value={formatter
                      .format(
                        Number(
                          Number(datosPoliticaComercial.pocMargenChevy) +
                            Number(
                              datosPoliticaComercial.pocMontoFlotas
                            ) +
                            Number(
                              datosPoliticaComercial.pocMargenPremium
                            ) +
                            Number(datosPoliticaComercial.pocMargenRetail)
                        )
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(240,248,255)" }}
                    value={
                      Number(datosPoliticaComercial.pocUnidadesChevy) +
                        Number(datosPoliticaComercial.pocMontoFlotas) +
                        Number(datosPoliticaComercial.pocMontoPremium) +
                        Number(datosPoliticaComercial.pocMontoRetail) >
                      0
                        ? Number(
                            ((Number(
                              datosPoliticaComercial.pocMargenChevy
                            ) +
                              Number(
                                datosPoliticaComercial.pocMontoFlotas
                              ) +
                              Number(
                                datosPoliticaComercial.pocMargenPremium
                              ) +
                              Number(
                                datosPoliticaComercial.pocMargenRetail
                              )) /
                              (Number(
                                datosPoliticaComercial.pocUnidadesChevy
                              ) +
                                Number(
                                  datosPoliticaComercial.pocMontoFlotas
                                ) +
                                Number(
                                  datosPoliticaComercial.pocMontoPremium
                                ) +
                                Number(
                                  datosPoliticaComercial.pocMontoRetail
                                ))) *
                              100
                          ).toFixed(2) + "  %"
                        : "0  %"
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                </Grid>
                <Divider sx={{ mt: 1, mb: 1 }} />

                <Typography
                  //id="modal-modal-title"
                  sx={{ mt: 0, mb: 0, width: "100%" }}
                  //variant="h5"
                  //textAlign={"center"}
                  //style={{  display: "inline-block"}}
                >
                  {/* --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  */}
                  {/* --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  */}
                  {/* -------------------------------------------------------------------------------------------------- tercera tabla  --------------------------------------------------------------------------------------------------  */}
                  {/* --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  */}
                  {/* --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  */}
                  <strong> Resultados esperados: </strong>
                </Typography>
                <Divider sx={{ mt: 1, mb: 0.2 }} />

                <Box>
                  <Typography
                    //id="modal-modal-title"
                    sx={{ mt: 0, mb: 0.2, width: "9%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    Livianos
                  </Typography>
                  <Typography
                    //id="modal-modal-title"
                    sx={{ mt: 0, mb: 0, width: "13%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    Ventas unidades
                  </Typography>
                  <Typography
                    //id="modal-modal-title"
                    sx={{ mt: 0, mb: 0, width: "13%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    Ventas netas
                  </Typography>
                  <Typography
                    //id="modal-modal-title"
                    sx={{ mt: 0, mb: 0, width: "13%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    Margen bruto real
                  </Typography>
                  <Typography
                    //id="modal-modal-title"
                    sx={{ mt: 0, mb: 0, width: "13%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    Margen bruto
                  </Typography>
                  <Typography
                    //id="modal-modal-title"
                    sx={{ mt: 0, mb: 0, width: "13%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    Incentivo esperado
                  </Typography>
                  <Typography
                    //id="modal-modal-title"
                    sx={{ mt: 0, mb: 0, width: "14%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    USD
                  </Typography>
                  <Typography
                    //id="modal-modal-title"
                    sx={{ mt: 0, mb: 0, width: "12%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    Utilidad total
                  </Typography>
                </Box>
                <Divider sx={{ mt: 0, mb: 0 }} />
                <Grid>
                  <Typography
                    sx={{ mt: 1.5, mb: 1.5, marginRight: "1%", width: "9%" }}
                    style={{ display: "inline-block" }}
                  >
                    Retail:
                  </Typography>
                  <TextField
                    name="dealear_net"
                    size="small"
                    margin="normal"
                    type="number"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      Number(datosRetail0.cantidad) +
                      Number(datosPoliticaComercial.pocUnidadesRetail)
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        Number(datosRetail0.ventaConRebate) +
                          Number(datosPoliticaComercial.pocMontoRetail)
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        Number(datosPoliticaComercial.pocMargenRetail) +
                          Number(
                            Number(datosRetail0.ventaConRebate) -
                              Number(datosRetail0.costo)
                          )
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      Number(
                        Number(datosRetail0.ventaConRebate) +
                          Number(datosPoliticaComercial.pocMontoRetail)
                      ) > 0
                        ? Number(
                            (Number(
                              Number(
                                datosPoliticaComercial.pocMargenRetail
                              ) +
                                Number(
                                  Number(datosRetail0.ventaConRebate) -
                                    Number(datosRetail0.costo)
                                )
                            ) /
                              Number(
                                Number(datosRetail0.ventaConRebate) +
                                  Number(
                                    datosPoliticaComercial.pocMontoRetail
                                  )
                              )) *
                              100
                          ).toFixed(2) + "  %"
                        : "0 %"
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      datosPoliticaComercial.pocObjetivoMarca > 0 &&
                      Number(
                        Number(
                          Number(
                            Number(
                              (Number(
                                datosPoliticaComercial.pocUnidadesChevy
                              ) +
                                Number(datosChevy0.cantidad) +
                                Number(
                                  datosPoliticaComercial.pocUnidadesFlotas
                                ) +
                                Number(datosFlotas0.cantidad) +
                                Number(
                                  datosPoliticaComercial.pocUnidadesPremium
                                ) +
                                Number(datosPremium0.cantidad) +
                                Number(
                                  datosPoliticaComercial.pocUnidadesRetail
                                ) +
                                Number(datosRetail0.cantidad)) /
                                datosPoliticaComercial.pocObjetivoMarca
                            ) * 100
                          ) /
                            (semana + 1 === 0
                              ? 0
                              : semana + 1 === 1
                              ? 15
                              : semana + 1 === 2
                              ? 40
                              : semana + 1 === 3
                              ? 75
                              : 100)
                        ) * 100
                      ) < 95
                        ? "0 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(
                                    datosPoliticaComercial.pocUnidadesChevy
                                  ) +
                                    Number(datosChevy0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesFlotas
                                    ) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesPremium
                                    ) +
                                    Number(datosPremium0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesRetail
                                    ) +
                                    Number(datosRetail0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana + 1 === 0
                                  ? 0
                                  : semana + 1 === 1
                                  ? 15
                                  : semana + 1 === 2
                                  ? 40
                                  : semana + 1 === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 100
                        ? "0.4 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(
                                    datosPoliticaComercial.pocUnidadesChevy
                                  ) +
                                    Number(datosChevy0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesFlotas
                                    ) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesPremium
                                    ) +
                                    Number(datosPremium0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesRetail
                                    ) +
                                    Number(datosRetail0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana + 1 === 0
                                  ? 0
                                  : semana + 1 === 1
                                  ? 15
                                  : semana + 1 === 2
                                  ? 40
                                  : semana + 1 === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 104
                        ? "0.8 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(
                                    datosPoliticaComercial.pocUnidadesChevy
                                  ) +
                                    Number(datosChevy0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesFlotas
                                    ) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesPremium
                                    ) +
                                    Number(datosPremium0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesRetail
                                    ) +
                                    Number(datosRetail0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana + 1 === 0
                                  ? 0
                                  : semana + 1 === 1
                                  ? 15
                                  : semana + 1 === 2
                                  ? 40
                                  : semana + 1 === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 110
                        ? "1 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(
                                    datosPoliticaComercial.pocUnidadesChevy
                                  ) +
                                    Number(datosChevy0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesFlotas
                                    ) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesPremium
                                    ) +
                                    Number(datosPremium0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesRetail
                                    ) +
                                    Number(datosRetail0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana + 1 === 0
                                  ? 0
                                  : semana + 1 === 1
                                  ? 15
                                  : semana + 1 === 2
                                  ? 40
                                  : semana + 1 === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) >= 100
                        ? "1.3 %"
                        : "0 %"
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        ((datosPoliticaComercial.pocObjetivoMarca > 0 &&
                        Number(
                          Number(
                            Number(
                              Number(
                                (Number(
                                  datosPoliticaComercial.pocUnidadesChevy
                                ) +
                                  Number(datosChevy0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesFlotas
                                  ) +
                                  Number(datosFlotas0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesPremium
                                  ) +
                                  Number(datosPremium0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesRetail
                                  ) +
                                  Number(datosRetail0.cantidad)) /
                                  datosPoliticaComercial.pocObjetivoMarca
                              ) * 100
                            ) /
                              (semana + 1 === 0
                                ? 0
                                : semana + 1 === 1
                                ? 15
                                : semana + 1 === 2
                                ? 40
                                : semana + 1 === 3
                                ? 75
                                : 100)
                          ) * 100
                        ) < 95
                          ? 0
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 100
                          ? 0.4
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 104
                          ? 0.8
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 110
                          ? 1
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) >= 100
                          ? 1.3
                          : 0) /
                          100) *
                          (Number(datosRetail0.ventaConRebate) +
                            Number(datosPoliticaComercial.pocMontoRetail))
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        ((datosPoliticaComercial.pocObjetivoMarca > 0 &&
                        Number(
                          Number(
                            Number(
                              Number(
                                (Number(
                                  datosPoliticaComercial.pocUnidadesChevy
                                ) +
                                  Number(datosChevy0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesFlotas
                                  ) +
                                  Number(datosFlotas0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesPremium
                                  ) +
                                  Number(datosPremium0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesRetail
                                  ) +
                                  Number(datosRetail0.cantidad)) /
                                  datosPoliticaComercial.pocObjetivoMarca
                              ) * 100
                            ) /
                              (semana + 1 === 0
                                ? 0
                                : semana + 1 === 1
                                ? 15
                                : semana + 1 === 2
                                ? 40
                                : semana + 1 === 3
                                ? 75
                                : 100)
                          ) * 100
                        ) < 95
                          ? 0
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 100
                          ? 0.4
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 104
                          ? 0.8
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 110
                          ? 1
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) >= 100
                          ? 1.3
                          : 0) /
                          100) *
                          (Number(datosRetail0.ventaConRebate) +
                            Number(
                              datosPoliticaComercial.pocMontoRetail
                            )) +
                          Number(
                            Number(datosRetail0.ventaConRebate) -
                              Number(datosRetail0.costo)
                          ) +
                          Number(datosPoliticaComercial.pocMargenRetail)
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                </Grid>
                <Grid>
                  <Typography
                    sx={{ mt: 1.5, mb: 1.5, marginRight: "1%", width: "9%" }}
                    style={{ display: "inline-block" }}
                  >
                    Premium:
                  </Typography>
                  <TextField
                    name="dealear_net"
                    size="small"
                    margin="normal"
                    type="number"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      Number(datosPremium0.cantidad) +
                      Number(datosPoliticaComercial.pocUnidadesPremium)
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        Number(datosPremium0.ventaConRebate) +
                          Number(datosPoliticaComercial.pocMontoPremium)
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        Number(datosPoliticaComercial.pocMargenPremium) +
                          Number(
                            Number(datosPremium0.ventaConRebate) -
                              Number(datosPremium0.costo)
                          )
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      Number(
                        Number(datosPremium0.ventaConRebate) +
                          Number(datosPoliticaComercial.pocMontoPremium)
                      ) > 0
                        ? Number(
                            (Number(
                              Number(
                                datosPoliticaComercial.pocMargenPremium
                              ) +
                                Number(
                                  Number(datosPremium0.ventaConRebate) -
                                    Number(datosPremium0.costo)
                                )
                            ) /
                              Number(
                                Number(datosPremium0.ventaConRebate) +
                                  Number(
                                    datosPoliticaComercial.pocMontoPremium
                                  )
                              )) *
                              100
                          ).toFixed(2) + "  %"
                        : "0 %"
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      datosPoliticaComercial.pocObjetivoMarca > 0 &&
                      Number(
                        Number(
                          Number(
                            Number(
                              (Number(
                                datosPoliticaComercial.pocUnidadesChevy
                              ) +
                                Number(datosChevy0.cantidad) +
                                Number(
                                  datosPoliticaComercial.pocUnidadesFlotas
                                ) +
                                Number(datosFlotas0.cantidad) +
                                Number(
                                  datosPoliticaComercial.pocUnidadesPremium
                                ) +
                                Number(datosPremium0.cantidad) +
                                Number(
                                  datosPoliticaComercial.pocUnidadesRetail
                                ) +
                                Number(datosRetail0.cantidad)) /
                                datosPoliticaComercial.pocObjetivoMarca
                            ) * 100
                          ) /
                            (semana + 1 === 0
                              ? 0
                              : semana + 1 === 1
                              ? 15
                              : semana + 1 === 2
                              ? 40
                              : semana + 1 === 3
                              ? 75
                              : 100)
                        ) * 100
                      ) < 95
                        ? "0 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(
                                    datosPoliticaComercial.pocUnidadesChevy
                                  ) +
                                    Number(datosChevy0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesFlotas
                                    ) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesPremium
                                    ) +
                                    Number(datosPremium0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesRetail
                                    ) +
                                    Number(datosRetail0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana + 1 === 0
                                  ? 0
                                  : semana + 1 === 1
                                  ? 15
                                  : semana + 1 === 2
                                  ? 40
                                  : semana + 1 === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 100
                        ? "0.6 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(
                                    datosPoliticaComercial.pocUnidadesChevy
                                  ) +
                                    Number(datosChevy0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesFlotas
                                    ) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesPremium
                                    ) +
                                    Number(datosPremium0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesRetail
                                    ) +
                                    Number(datosRetail0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana + 1 === 0
                                  ? 0
                                  : semana + 1 === 1
                                  ? 15
                                  : semana + 1 === 2
                                  ? 40
                                  : semana + 1 === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 104
                        ? "1.0 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(
                                    datosPoliticaComercial.pocUnidadesChevy
                                  ) +
                                    Number(datosChevy0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesFlotas
                                    ) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesPremium
                                    ) +
                                    Number(datosPremium0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesRetail
                                    ) +
                                    Number(datosRetail0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana + 1 === 0
                                  ? 0
                                  : semana + 1 === 1
                                  ? 15
                                  : semana + 1 === 2
                                  ? 40
                                  : semana + 1 === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 110
                        ? "1.2 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(
                                    datosPoliticaComercial.pocUnidadesChevy
                                  ) +
                                    Number(datosChevy0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesFlotas
                                    ) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesPremium
                                    ) +
                                    Number(datosPremium0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesRetail
                                    ) +
                                    Number(datosRetail0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana + 1 === 0
                                  ? 0
                                  : semana + 1 === 1
                                  ? 15
                                  : semana + 1 === 2
                                  ? 40
                                  : semana + 1 === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) >= 100
                        ? "1.6 %"
                        : "0 %"
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        ((datosPoliticaComercial.pocObjetivoMarca > 0 &&
                        Number(
                          Number(
                            Number(
                              Number(
                                (Number(
                                  datosPoliticaComercial.pocUnidadesChevy
                                ) +
                                  Number(datosChevy0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesFlotas
                                  ) +
                                  Number(datosFlotas0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesPremium
                                  ) +
                                  Number(datosPremium0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesRetail
                                  ) +
                                  Number(datosRetail0.cantidad)) /
                                  datosPoliticaComercial.pocObjetivoMarca
                              ) * 100
                            ) /
                              (semana + 1 === 0
                                ? 0
                                : semana + 1 === 1
                                ? 15
                                : semana + 1 === 2
                                ? 40
                                : semana + 1 === 3
                                ? 75
                                : 100)
                          ) * 100
                        ) < 95
                          ? 0
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 100
                          ? 0.6
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 104
                          ? 1
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 110
                          ? 1.2
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) >= 100
                          ? 1.6
                          : 0) /
                          100) *
                          (Number(datosPremium0.ventaConRebate) +
                            Number(
                              datosPoliticaComercial.pocMontoPremium
                            ))
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        ((datosPoliticaComercial.pocObjetivoMarca > 0 &&
                        Number(
                          Number(
                            Number(
                              Number(
                                (Number(
                                  datosPoliticaComercial.pocUnidadesChevy
                                ) +
                                  Number(datosChevy0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesFlotas
                                  ) +
                                  Number(datosFlotas0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesPremium
                                  ) +
                                  Number(datosPremium0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesRetail
                                  ) +
                                  Number(datosRetail0.cantidad)) /
                                  datosPoliticaComercial.pocObjetivoMarca
                              ) * 100
                            ) /
                              (semana + 1 === 0
                                ? 0
                                : semana + 1 === 1
                                ? 15
                                : semana + 1 === 2
                                ? 40
                                : semana + 1 === 3
                                ? 75
                                : 100)
                          ) * 100
                        ) < 95
                          ? 0
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 100
                          ? 0.6
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 104
                          ? 1
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 110
                          ? 1.2
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) >= 100
                          ? 1.6
                          : 0) /
                          100) *
                          (Number(datosPremium0.ventaConRebate) +
                            Number(
                              datosPoliticaComercial.pocMontoPremium
                            )) +
                          Number(
                            Number(datosPremium0.ventaConRebate) -
                              Number(datosPremium0.costo)
                          ) +
                          Number(datosPoliticaComercial.pocMargenPremium)
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                </Grid>
                <Grid>
                  <Typography
                    sx={{ mt: 1.5, mb: 1.5, marginRight: "1%", width: "9%" }}
                    style={{ display: "inline-block" }}
                  >
                    Flotas:
                  </Typography>
                  <TextField
                    name="dealear_net"
                    size="small"
                    margin="normal"
                    type="number"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      Number(datosFlotas0.cantidad) +
                      Number(datosPoliticaComercial.pocUnidadesFlotas)
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        Number(datosFlotas0.ventaConRebate) +
                          Number(datosPoliticaComercial.pocMontoFlotas)
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        Number(datosPoliticaComercial.pocMontoFlotas) +
                          Number(
                            Number(datosFlotas0.ventaConRebate) -
                              Number(datosFlotas0.costo)
                          )
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      Number(
                        Number(datosFlotas0.ventaConRebate) +
                          Number(datosPoliticaComercial.pocMontoFlotas)
                      ) > 0
                        ? Number(
                            (Number(
                              Number(
                                datosPoliticaComercial.pocMontoFlotas
                              ) +
                                Number(
                                  Number(datosFlotas0.ventaConRebate) -
                                    Number(datosFlotas0.costo)
                                )
                            ) /
                              Number(
                                Number(datosFlotas0.ventaConRebate) +
                                  Number(
                                    datosPoliticaComercial.pocMontoFlotas
                                  )
                              )) *
                              100
                          ).toFixed(2) + "  %"
                        : "0 %"
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      datosPoliticaComercial.pocObjetivoMarca > 0 &&
                      Number(
                        Number(
                          Number(
                            Number(
                              (Number(
                                datosPoliticaComercial.pocUnidadesChevy
                              ) +
                                Number(datosChevy0.cantidad) +
                                Number(
                                  datosPoliticaComercial.pocUnidadesFlotas
                                ) +
                                Number(datosFlotas0.cantidad) +
                                Number(
                                  datosPoliticaComercial.pocUnidadesPremium
                                ) +
                                Number(datosPremium0.cantidad) +
                                Number(
                                  datosPoliticaComercial.pocUnidadesRetail
                                ) +
                                Number(datosRetail0.cantidad)) /
                                datosPoliticaComercial.pocObjetivoMarca
                            ) * 100
                          ) /
                            (semana + 1 === 0
                              ? 0
                              : semana + 1 === 1
                              ? 15
                              : semana + 1 === 2
                              ? 40
                              : semana + 1 === 3
                              ? 75
                              : 100)
                        ) * 100
                      ) < 95
                        ? "0 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(
                                    datosPoliticaComercial.pocUnidadesChevy
                                  ) +
                                    Number(datosChevy0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesFlotas
                                    ) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesPremium
                                    ) +
                                    Number(datosPremium0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesRetail
                                    ) +
                                    Number(datosRetail0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana + 1 === 0
                                  ? 0
                                  : semana + 1 === 1
                                  ? 15
                                  : semana + 1 === 2
                                  ? 40
                                  : semana + 1 === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 100
                        ? "0.4 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(
                                    datosPoliticaComercial.pocUnidadesChevy
                                  ) +
                                    Number(datosChevy0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesFlotas
                                    ) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesPremium
                                    ) +
                                    Number(datosPremium0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesRetail
                                    ) +
                                    Number(datosRetail0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana + 1 === 0
                                  ? 0
                                  : semana + 1 === 1
                                  ? 15
                                  : semana + 1 === 2
                                  ? 40
                                  : semana + 1 === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 104
                        ? "0.8 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(
                                    datosPoliticaComercial.pocUnidadesChevy
                                  ) +
                                    Number(datosChevy0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesFlotas
                                    ) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesPremium
                                    ) +
                                    Number(datosPremium0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesRetail
                                    ) +
                                    Number(datosRetail0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana + 1 === 0
                                  ? 0
                                  : semana + 1 === 1
                                  ? 15
                                  : semana + 1 === 2
                                  ? 40
                                  : semana + 1 === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 110
                        ? "1.0 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(
                                    datosPoliticaComercial.pocUnidadesChevy
                                  ) +
                                    Number(datosChevy0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesFlotas
                                    ) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesPremium
                                    ) +
                                    Number(datosPremium0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesRetail
                                    ) +
                                    Number(datosRetail0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana + 1 === 0
                                  ? 0
                                  : semana + 1 === 1
                                  ? 15
                                  : semana + 1 === 2
                                  ? 40
                                  : semana + 1 === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) >= 100
                        ? "1.3 %"
                        : "0 %"
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        ((datosPoliticaComercial.pocObjetivoMarca > 0 &&
                        Number(
                          Number(
                            Number(
                              Number(
                                (Number(
                                  datosPoliticaComercial.pocUnidadesChevy
                                ) +
                                  Number(datosChevy0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesFlotas
                                  ) +
                                  Number(datosFlotas0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesPremium
                                  ) +
                                  Number(datosPremium0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesRetail
                                  ) +
                                  Number(datosRetail0.cantidad)) /
                                  datosPoliticaComercial.pocObjetivoMarca
                              ) * 100
                            ) /
                              (semana + 1 === 0
                                ? 0
                                : semana + 1 === 1
                                ? 15
                                : semana + 1 === 2
                                ? 40
                                : semana + 1 === 3
                                ? 75
                                : 100)
                          ) * 100
                        ) < 95
                          ? 0
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 100
                          ? 0.4
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 104
                          ? 0.8
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 110
                          ? 1
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) >= 100
                          ? 1.3
                          : 0) /
                          100) *
                          (Number(datosFlotas0.ventaConRebate) +
                            Number(datosPoliticaComercial.pocMontoFlotas))
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        ((datosPoliticaComercial.pocObjetivoMarca > 0 &&
                        Number(
                          Number(
                            Number(
                              Number(
                                (Number(
                                  datosPoliticaComercial.pocUnidadesChevy
                                ) +
                                  Number(datosChevy0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesFlotas
                                  ) +
                                  Number(datosFlotas0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesPremium
                                  ) +
                                  Number(datosPremium0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesRetail
                                  ) +
                                  Number(datosRetail0.cantidad)) /
                                  datosPoliticaComercial.pocObjetivoMarca
                              ) * 100
                            ) /
                              (semana + 1 === 0
                                ? 0
                                : semana + 1 === 1
                                ? 15
                                : semana + 1 === 2
                                ? 40
                                : semana + 1 === 3
                                ? 75
                                : 100)
                          ) * 100
                        ) < 95
                          ? 0
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 100
                          ? 0.4
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 104
                          ? 0.8
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 110
                          ? 1
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) >= 100
                          ? 1.3
                          : 0) /
                          100) *
                          (Number(datosFlotas0.ventaConRebate) +
                            Number(
                              datosPoliticaComercial.pocMontoFlotas
                            )) +
                          Number(
                            Number(datosFlotas0.ventaConRebate) -
                              Number(datosFlotas0.costo)
                          ) +
                          Number(datosPoliticaComercial.pocMontoFlotas)
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                </Grid>
                <Grid>
                  <Typography
                    sx={{ mt: 1.5, mb: 1.5, marginRight: "1%", width: "9%" }}
                    style={{ display: "inline-block" }}
                  >
                    Chevy:
                  </Typography>
                  <TextField
                    name="dealear_net"
                    size="small"
                    margin="normal"
                    type="number"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      Number(datosChevy0.cantidad) +
                      Number(datosPoliticaComercial.pocUnidadesChevy)
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        Number(datosChevy0.ventaConRebate) +
                          Number(datosPoliticaComercial.pocUnidadesChevy)
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        Number(datosPoliticaComercial.pocMargenChevy) +
                          Number(
                            Number(datosChevy0.ventaConRebate) -
                              Number(datosChevy0.costo)
                          )
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      Number(
                        Number(datosChevy0.ventaConRebate) +
                          Number(datosPoliticaComercial.pocUnidadesChevy)
                      ) > 0
                        ? Number(
                            (Number(
                              Number(
                                datosPoliticaComercial.pocMargenChevy
                              ) +
                                Number(
                                  Number(datosChevy0.ventaConRebate) -
                                    Number(datosChevy0.costo)
                                )
                            ) /
                              Number(
                                Number(datosChevy0.ventaConRebate) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesChevy
                                  )
                              )) *
                              100
                          ).toFixed(2) + "  %"
                        : "0 %"
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      datosPoliticaComercial.pocObjetivoMarca > 0 &&
                      Number(
                        Number(
                          Number(
                            Number(
                              (Number(
                                datosPoliticaComercial.pocUnidadesChevy
                              ) +
                                Number(datosChevy0.cantidad) +
                                Number(
                                  datosPoliticaComercial.pocUnidadesFlotas
                                ) +
                                Number(datosFlotas0.cantidad) +
                                Number(
                                  datosPoliticaComercial.pocUnidadesPremium
                                ) +
                                Number(datosPremium0.cantidad) +
                                Number(
                                  datosPoliticaComercial.pocUnidadesRetail
                                ) +
                                Number(datosRetail0.cantidad)) /
                                datosPoliticaComercial.pocObjetivoMarca
                            ) * 100
                          ) /
                            (semana + 1 === 0
                              ? 0
                              : semana + 1 === 1
                              ? 15
                              : semana + 1 === 2
                              ? 40
                              : semana + 1 === 3
                              ? 75
                              : 100)
                        ) * 100
                      ) < 95
                        ? "0 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(
                                    datosPoliticaComercial.pocUnidadesChevy
                                  ) +
                                    Number(datosChevy0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesFlotas
                                    ) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesPremium
                                    ) +
                                    Number(datosPremium0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesRetail
                                    ) +
                                    Number(datosRetail0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana + 1 === 0
                                  ? 0
                                  : semana + 1 === 1
                                  ? 15
                                  : semana + 1 === 2
                                  ? 40
                                  : semana + 1 === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 100
                        ? "0.7 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(
                                    datosPoliticaComercial.pocUnidadesChevy
                                  ) +
                                    Number(datosChevy0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesFlotas
                                    ) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesPremium
                                    ) +
                                    Number(datosPremium0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesRetail
                                    ) +
                                    Number(datosRetail0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana + 1 === 0
                                  ? 0
                                  : semana + 1 === 1
                                  ? 15
                                  : semana + 1 === 2
                                  ? 40
                                  : semana + 1 === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 104
                        ? "0.7 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(
                                    datosPoliticaComercial.pocUnidadesChevy
                                  ) +
                                    Number(datosChevy0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesFlotas
                                    ) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesPremium
                                    ) +
                                    Number(datosPremium0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesRetail
                                    ) +
                                    Number(datosRetail0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana + 1 === 0
                                  ? 0
                                  : semana + 1 === 1
                                  ? 15
                                  : semana + 1 === 2
                                  ? 40
                                  : semana + 1 === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 110
                        ? "0.7 %"
                        : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(
                                    datosPoliticaComercial.pocUnidadesChevy
                                  ) +
                                    Number(datosChevy0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesFlotas
                                    ) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesPremium
                                    ) +
                                    Number(datosPremium0.cantidad) +
                                    Number(
                                      datosPoliticaComercial.pocUnidadesRetail
                                    ) +
                                    Number(datosRetail0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana + 1 === 0
                                  ? 0
                                  : semana + 1 === 1
                                  ? 15
                                  : semana + 1 === 2
                                  ? 40
                                  : semana + 1 === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) >= 100
                        ? "0.7 %"
                        : "0 %"
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        ((datosPoliticaComercial.pocObjetivoMarca > 0 &&
                        Number(
                          Number(
                            Number(
                              Number(
                                (Number(
                                  datosPoliticaComercial.pocUnidadesChevy
                                ) +
                                  Number(datosChevy0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesFlotas
                                  ) +
                                  Number(datosFlotas0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesPremium
                                  ) +
                                  Number(datosPremium0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesRetail
                                  ) +
                                  Number(datosRetail0.cantidad)) /
                                  datosPoliticaComercial.pocObjetivoMarca
                              ) * 100
                            ) /
                              (semana + 1 === 0
                                ? 0
                                : semana + 1 === 1
                                ? 15
                                : semana + 1 === 2
                                ? 40
                                : semana + 1 === 3
                                ? 75
                                : 100)
                          ) * 100
                        ) < 95
                          ? 0
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 100
                          ? 0.7
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 104
                          ? 0.7
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 110
                          ? 0.7
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) >= 100
                          ? 0.7
                          : 0) /
                          100) *
                          (Number(datosChevy0.ventaConRebate) +
                            Number(datosPoliticaComercial.pocUnidadesChevy))
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={formatter
                      .format(
                        ((datosPoliticaComercial.pocObjetivoMarca > 0 &&
                        Number(
                          Number(
                            Number(
                              Number(
                                (Number(
                                  datosPoliticaComercial.pocUnidadesChevy
                                ) +
                                  Number(datosChevy0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesFlotas
                                  ) +
                                  Number(datosFlotas0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesPremium
                                  ) +
                                  Number(datosPremium0.cantidad) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesRetail
                                  ) +
                                  Number(datosRetail0.cantidad)) /
                                  datosPoliticaComercial.pocObjetivoMarca
                              ) * 100
                            ) /
                              (semana + 1 === 0
                                ? 0
                                : semana + 1 === 1
                                ? 15
                                : semana + 1 === 2
                                ? 40
                                : semana + 1 === 3
                                ? 75
                                : 100)
                          ) * 100
                        ) < 95
                          ? 0
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 100
                          ? 0.7
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 104
                          ? 0.7
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 110
                          ? 0.7
                          : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) >= 100
                          ? 0.7
                          : 0) /
                          100) *
                          (Number(datosChevy0.ventaConRebate) +
                            Number(
                              datosPoliticaComercial.pocUnidadesChevy
                            )) +
                          Number(
                            Number(datosChevy0.ventaConRebate) -
                              Number(datosChevy0.costo)
                          ) +
                          Number(datosPoliticaComercial.pocMargenChevy)
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                </Grid>
                <Grid>
                  <Typography
                    sx={{ mt: 1.5, mb: 1.5, width: "10%" }}
                    style={{ display: "inline-block" }}
                  >
                    <strong>Total:</strong>
                  </Typography>
                  <TextField
                    name="dealear_net"
                    size="small"
                    margin="normal"
                    type="number"
                    style={{ backgroundColor: "rgb(240,248,255)" }}
                    value={
                      Number(datosChevy0.cantidad) +
                      Number(datosFlotas0.cantidad) +
                      Number(datosPremium0.cantidad) +
                      Number(datosRetail0.cantidad) +
                      Number(datosPoliticaComercial.pocUnidadesChevy) +
                      Number(datosPoliticaComercial.pocUnidadesFlotas) +
                      Number(datosPoliticaComercial.pocUnidadesPremium) +
                      Number(datosPoliticaComercial.pocUnidadesRetail)
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(240,248,255)" }}
                    value={formatter
                      .format(
                        Number(datosChevy0.ventaConRebate) +
                          Number(datosFlotas0.ventaConRebate) +
                          Number(datosPremium0.ventaConRebate) +
                          Number(datosRetail0.ventaConRebate) +
                          Number(datosPoliticaComercial.pocUnidadesChevy) +
                          Number(datosPoliticaComercial.pocMontoFlotas) +
                          Number(datosPoliticaComercial.pocMontoPremium) +
                          Number(datosPoliticaComercial.pocMontoRetail)
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(240,248,255)" }}
                    value={formatter
                      .format(
                        Number(datosChevy0.ventaConRebate) -
                          Number(datosChevy0.costo) +
                          (Number(datosFlotas0.ventaConRebate) -
                            Number(datosFlotas0.costo)) +
                          (Number(datosPremium0.ventaConRebate) -
                            Number(datosPremium0.costo)) +
                          (Number(datosRetail0.ventaConRebate) -
                            Number(datosRetail0.costo)) +
                          Number(datosPoliticaComercial.pocMargenChevy) +
                          Number(datosPoliticaComercial.pocMontoFlotas) +
                          Number(datosPoliticaComercial.pocMargenPremium) +
                          Number(datosPoliticaComercial.pocMargenRetail)
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(240,248,255)" }}
                    value={
                      Number(datosChevy0.ventaConRebate) +
                        Number(datosFlotas0.ventaConRebate) +
                        Number(datosPremium0.ventaConRebate) +
                        Number(datosRetail0.ventaConRebate) +
                        Number(datosPoliticaComercial.pocUnidadesChevy) +
                        Number(datosPoliticaComercial.pocMontoFlotas) +
                        Number(datosPoliticaComercial.pocMontoPremium) +
                        Number(datosPoliticaComercial.pocMontoRetail) >
                      0
                        ? Number(
                            ((Number(datosChevy0.ventaConRebate) -
                              Number(datosChevy0.costo) +
                              (Number(datosFlotas0.ventaConRebate) -
                                Number(datosFlotas0.costo)) +
                              (Number(datosPremium0.ventaConRebate) -
                                Number(datosPremium0.costo)) +
                              (Number(datosRetail0.ventaConRebate) -
                                Number(datosRetail0.costo)) +
                              Number(
                                datosPoliticaComercial.pocMargenChevy
                              ) +
                              Number(
                                datosPoliticaComercial.pocMontoFlotas
                              ) +
                              Number(
                                datosPoliticaComercial.pocMargenPremium
                              ) +
                              Number(
                                datosPoliticaComercial.pocMargenRetail
                              )) /
                              Number(
                                Number(datosChevy0.ventaConRebate) +
                                  Number(datosFlotas0.ventaConRebate) +
                                  Number(datosPremium0.ventaConRebate) +
                                  Number(datosRetail0.ventaConRebate) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesChevy
                                  ) +
                                  Number(
                                    datosPoliticaComercial.pocMontoFlotas
                                  ) +
                                  Number(
                                    datosPoliticaComercial.pocMontoPremium
                                  ) +
                                  Number(
                                    datosPoliticaComercial.pocMontoRetail
                                  )
                              )) *
                              100
                          ).toFixed(2) + "  %"
                        : "0  %"
                    }
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(240,248,255)" }}
                    value={formatter
                      .format(
                        Number(
                          Number(
                            ((datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(
                                      datosPoliticaComercial.pocUnidadesChevy
                                    ) +
                                      Number(datosChevy0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesFlotas
                                      ) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesPremium
                                      ) +
                                      Number(datosPremium0.cantidad) +
                                      Number(
                                        datosPoliticaComercial.pocUnidadesRetail
                                      ) +
                                      Number(datosRetail0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana + 1 === 0
                                    ? 0
                                    : semana + 1 === 1
                                    ? 15
                                    : semana + 1 === 2
                                    ? 40
                                    : semana + 1 === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 95
                              ? 0
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(
                                          datosPoliticaComercial.pocUnidadesChevy
                                        ) +
                                          Number(datosChevy0.cantidad) +
                                          Number(
                                            datosPoliticaComercial.pocUnidadesFlotas
                                          ) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(
                                            datosPoliticaComercial.pocUnidadesPremium
                                          ) +
                                          Number(datosPremium0.cantidad) +
                                          Number(
                                            datosPoliticaComercial.pocUnidadesRetail
                                          ) +
                                          Number(datosRetail0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana + 1 === 0
                                        ? 0
                                        : semana + 1 === 1
                                        ? 15
                                        : semana + 1 === 2
                                        ? 40
                                        : semana + 1 === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 100
                              ? 0.4
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(
                                          datosPoliticaComercial.pocUnidadesChevy
                                        ) +
                                          Number(datosChevy0.cantidad) +
                                          Number(
                                            datosPoliticaComercial.pocUnidadesFlotas
                                          ) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(
                                            datosPoliticaComercial.pocUnidadesPremium
                                          ) +
                                          Number(datosPremium0.cantidad) +
                                          Number(
                                            datosPoliticaComercial.pocUnidadesRetail
                                          ) +
                                          Number(datosRetail0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana + 1 === 0
                                        ? 0
                                        : semana + 1 === 1
                                        ? 15
                                        : semana + 1 === 2
                                        ? 40
                                        : semana + 1 === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 104
                              ? 0.8
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(
                                          datosPoliticaComercial.pocUnidadesChevy
                                        ) +
                                          Number(datosChevy0.cantidad) +
                                          Number(
                                            datosPoliticaComercial.pocUnidadesFlotas
                                          ) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(
                                            datosPoliticaComercial.pocUnidadesPremium
                                          ) +
                                          Number(datosPremium0.cantidad) +
                                          Number(
                                            datosPoliticaComercial.pocUnidadesRetail
                                          ) +
                                          Number(datosRetail0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana + 1 === 0
                                        ? 0
                                        : semana + 1 === 1
                                        ? 15
                                        : semana + 1 === 2
                                        ? 40
                                        : semana + 1 === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 110
                              ? 1
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(
                                          datosPoliticaComercial.pocUnidadesChevy
                                        ) +
                                          Number(datosChevy0.cantidad) +
                                          Number(
                                            datosPoliticaComercial.pocUnidadesFlotas
                                          ) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(
                                            datosPoliticaComercial.pocUnidadesPremium
                                          ) +
                                          Number(datosPremium0.cantidad) +
                                          Number(
                                            datosPoliticaComercial.pocUnidadesRetail
                                          ) +
                                          Number(datosRetail0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana + 1 === 0
                                        ? 0
                                        : semana + 1 === 1
                                        ? 15
                                        : semana + 1 === 2
                                        ? 40
                                        : semana + 1 === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) >= 100
                              ? 1.3
                              : 0) /
                              100) *
                              (Number(datosRetail0.ventaConRebate) +
                                Number(
                                  datosPoliticaComercial.pocMontoRetail
                                ))
                          ) +
                            Number(
                              ((datosPoliticaComercial.pocObjetivoMarca > 0 &&
                              Number(
                                Number(
                                  Number(
                                    Number(
                                      (Number(
                                        datosPoliticaComercial.pocUnidadesChevy
                                      ) +
                                        Number(datosChevy0.cantidad) +
                                        Number(
                                          datosPoliticaComercial.pocUnidadesFlotas
                                        ) +
                                        Number(datosFlotas0.cantidad) +
                                        Number(
                                          datosPoliticaComercial.pocUnidadesPremium
                                        ) +
                                        Number(datosPremium0.cantidad) +
                                        Number(
                                          datosPoliticaComercial.pocUnidadesRetail
                                        ) +
                                        Number(datosRetail0.cantidad)) /
                                        datosPoliticaComercial.pocObjetivoMarca
                                    ) * 100
                                  ) /
                                    (semana + 1 === 0
                                      ? 0
                                      : semana + 1 === 1
                                      ? 15
                                      : semana + 1 === 2
                                      ? 40
                                      : semana + 1 === 3
                                      ? 75
                                      : 100)
                                ) * 100
                              ) < 95
                                ? 0
                                : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                  Number(
                                    Number(
                                      Number(
                                        Number(
                                          (Number(
                                            datosPoliticaComercial.pocUnidadesChevy
                                          ) +
                                            Number(datosChevy0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesFlotas
                                            ) +
                                            Number(datosFlotas0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesPremium
                                            ) +
                                            Number(datosPremium0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesRetail
                                            ) +
                                            Number(datosRetail0.cantidad)) /
                                            datosPoliticaComercial.pocObjetivoMarca
                                        ) * 100
                                      ) /
                                        (semana + 1 === 0
                                          ? 0
                                          : semana + 1 === 1
                                          ? 15
                                          : semana + 1 === 2
                                          ? 40
                                          : semana + 1 === 3
                                          ? 75
                                          : 100)
                                    ) * 100
                                  ) < 100
                                ? 0.6
                                : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                  Number(
                                    Number(
                                      Number(
                                        Number(
                                          (Number(
                                            datosPoliticaComercial.pocUnidadesChevy
                                          ) +
                                            Number(datosChevy0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesFlotas
                                            ) +
                                            Number(datosFlotas0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesPremium
                                            ) +
                                            Number(datosPremium0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesRetail
                                            ) +
                                            Number(datosRetail0.cantidad)) /
                                            datosPoliticaComercial.pocObjetivoMarca
                                        ) * 100
                                      ) /
                                        (semana + 1 === 0
                                          ? 0
                                          : semana + 1 === 1
                                          ? 15
                                          : semana + 1 === 2
                                          ? 40
                                          : semana + 1 === 3
                                          ? 75
                                          : 100)
                                    ) * 100
                                  ) < 104
                                ? 1
                                : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                  Number(
                                    Number(
                                      Number(
                                        Number(
                                          (Number(
                                            datosPoliticaComercial.pocUnidadesChevy
                                          ) +
                                            Number(datosChevy0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesFlotas
                                            ) +
                                            Number(datosFlotas0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesPremium
                                            ) +
                                            Number(datosPremium0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesRetail
                                            ) +
                                            Number(datosRetail0.cantidad)) /
                                            datosPoliticaComercial.pocObjetivoMarca
                                        ) * 100
                                      ) /
                                        (semana + 1 === 0
                                          ? 0
                                          : semana + 1 === 1
                                          ? 15
                                          : semana + 1 === 2
                                          ? 40
                                          : semana + 1 === 3
                                          ? 75
                                          : 100)
                                    ) * 100
                                  ) < 110
                                ? 1.2
                                : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                  Number(
                                    Number(
                                      Number(
                                        Number(
                                          (Number(
                                            datosPoliticaComercial.pocUnidadesChevy
                                          ) +
                                            Number(datosChevy0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesFlotas
                                            ) +
                                            Number(datosFlotas0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesPremium
                                            ) +
                                            Number(datosPremium0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesRetail
                                            ) +
                                            Number(datosRetail0.cantidad)) /
                                            datosPoliticaComercial.pocObjetivoMarca
                                        ) * 100
                                      ) /
                                        (semana + 1 === 0
                                          ? 0
                                          : semana + 1 === 1
                                          ? 15
                                          : semana + 1 === 2
                                          ? 40
                                          : semana + 1 === 3
                                          ? 75
                                          : 100)
                                    ) * 100
                                  ) >= 100
                                ? 1.6
                                : 0) /
                                100) *
                                (Number(datosPremium0.ventaConRebate) +
                                  Number(
                                    datosPoliticaComercial.pocMontoPremium
                                  ))
                            ) +
                            Number(
                              ((datosPoliticaComercial.pocObjetivoMarca > 0 &&
                              Number(
                                Number(
                                  Number(
                                    Number(
                                      (Number(
                                        datosPoliticaComercial.pocUnidadesChevy
                                      ) +
                                        Number(datosChevy0.cantidad) +
                                        Number(
                                          datosPoliticaComercial.pocUnidadesFlotas
                                        ) +
                                        Number(datosFlotas0.cantidad) +
                                        Number(
                                          datosPoliticaComercial.pocUnidadesPremium
                                        ) +
                                        Number(datosPremium0.cantidad) +
                                        Number(
                                          datosPoliticaComercial.pocUnidadesRetail
                                        ) +
                                        Number(datosRetail0.cantidad)) /
                                        datosPoliticaComercial.pocObjetivoMarca
                                    ) * 100
                                  ) /
                                    (semana + 1 === 0
                                      ? 0
                                      : semana + 1 === 1
                                      ? 15
                                      : semana + 1 === 2
                                      ? 40
                                      : semana + 1 === 3
                                      ? 75
                                      : 100)
                                ) * 100
                              ) < 95
                                ? 0
                                : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                  Number(
                                    Number(
                                      Number(
                                        Number(
                                          (Number(
                                            datosPoliticaComercial.pocUnidadesChevy
                                          ) +
                                            Number(datosChevy0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesFlotas
                                            ) +
                                            Number(datosFlotas0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesPremium
                                            ) +
                                            Number(datosPremium0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesRetail
                                            ) +
                                            Number(datosRetail0.cantidad)) /
                                            datosPoliticaComercial.pocObjetivoMarca
                                        ) * 100
                                      ) /
                                        (semana + 1 === 0
                                          ? 0
                                          : semana + 1 === 1
                                          ? 15
                                          : semana + 1 === 2
                                          ? 40
                                          : semana + 1 === 3
                                          ? 75
                                          : 100)
                                    ) * 100
                                  ) < 100
                                ? 0.4
                                : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                  Number(
                                    Number(
                                      Number(
                                        Number(
                                          (Number(
                                            datosPoliticaComercial.pocUnidadesChevy
                                          ) +
                                            Number(datosChevy0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesFlotas
                                            ) +
                                            Number(datosFlotas0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesPremium
                                            ) +
                                            Number(datosPremium0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesRetail
                                            ) +
                                            Number(datosRetail0.cantidad)) /
                                            datosPoliticaComercial.pocObjetivoMarca
                                        ) * 100
                                      ) /
                                        (semana + 1 === 0
                                          ? 0
                                          : semana + 1 === 1
                                          ? 15
                                          : semana + 1 === 2
                                          ? 40
                                          : semana + 1 === 3
                                          ? 75
                                          : 100)
                                    ) * 100
                                  ) < 104
                                ? 0.8
                                : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                  Number(
                                    Number(
                                      Number(
                                        Number(
                                          (Number(
                                            datosPoliticaComercial.pocUnidadesChevy
                                          ) +
                                            Number(datosChevy0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesFlotas
                                            ) +
                                            Number(datosFlotas0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesPremium
                                            ) +
                                            Number(datosPremium0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesRetail
                                            ) +
                                            Number(datosRetail0.cantidad)) /
                                            datosPoliticaComercial.pocObjetivoMarca
                                        ) * 100
                                      ) /
                                        (semana + 1 === 0
                                          ? 0
                                          : semana + 1 === 1
                                          ? 15
                                          : semana + 1 === 2
                                          ? 40
                                          : semana + 1 === 3
                                          ? 75
                                          : 100)
                                    ) * 100
                                  ) < 110
                                ? 1
                                : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                  Number(
                                    Number(
                                      Number(
                                        Number(
                                          (Number(
                                            datosPoliticaComercial.pocUnidadesChevy
                                          ) +
                                            Number(datosChevy0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesFlotas
                                            ) +
                                            Number(datosFlotas0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesPremium
                                            ) +
                                            Number(datosPremium0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesRetail
                                            ) +
                                            Number(datosRetail0.cantidad)) /
                                            datosPoliticaComercial.pocObjetivoMarca
                                        ) * 100
                                      ) /
                                        (semana + 1 === 0
                                          ? 0
                                          : semana + 1 === 1
                                          ? 15
                                          : semana + 1 === 2
                                          ? 40
                                          : semana + 1 === 3
                                          ? 75
                                          : 100)
                                    ) * 100
                                  ) >= 100
                                ? 1.3
                                : 0) /
                                100) *
                                (Number(datosFlotas0.ventaConRebate) +
                                  Number(
                                    datosPoliticaComercial.pocMontoFlotas
                                  ))
                            ) +
                            Number(
                              ((datosPoliticaComercial.pocObjetivoMarca > 0 &&
                              Number(
                                Number(
                                  Number(
                                    Number(
                                      (Number(
                                        datosPoliticaComercial.pocUnidadesChevy
                                      ) +
                                        Number(datosChevy0.cantidad) +
                                        Number(
                                          datosPoliticaComercial.pocUnidadesFlotas
                                        ) +
                                        Number(datosFlotas0.cantidad) +
                                        Number(
                                          datosPoliticaComercial.pocUnidadesPremium
                                        ) +
                                        Number(datosPremium0.cantidad) +
                                        Number(
                                          datosPoliticaComercial.pocUnidadesRetail
                                        ) +
                                        Number(datosRetail0.cantidad)) /
                                        datosPoliticaComercial.pocObjetivoMarca
                                    ) * 100
                                  ) /
                                    (semana + 1 === 0
                                      ? 0
                                      : semana + 1 === 1
                                      ? 15
                                      : semana + 1 === 2
                                      ? 40
                                      : semana + 1 === 3
                                      ? 75
                                      : 100)
                                ) * 100
                              ) < 95
                                ? 0
                                : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                  Number(
                                    Number(
                                      Number(
                                        Number(
                                          (Number(
                                            datosPoliticaComercial.pocUnidadesChevy
                                          ) +
                                            Number(datosChevy0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesFlotas
                                            ) +
                                            Number(datosFlotas0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesPremium
                                            ) +
                                            Number(datosPremium0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesRetail
                                            ) +
                                            Number(datosRetail0.cantidad)) /
                                            datosPoliticaComercial.pocObjetivoMarca
                                        ) * 100
                                      ) /
                                        (semana + 1 === 0
                                          ? 0
                                          : semana + 1 === 1
                                          ? 15
                                          : semana + 1 === 2
                                          ? 40
                                          : semana + 1 === 3
                                          ? 75
                                          : 100)
                                    ) * 100
                                  ) < 100
                                ? 0.7
                                : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                  Number(
                                    Number(
                                      Number(
                                        Number(
                                          (Number(
                                            datosPoliticaComercial.pocUnidadesChevy
                                          ) +
                                            Number(datosChevy0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesFlotas
                                            ) +
                                            Number(datosFlotas0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesPremium
                                            ) +
                                            Number(datosPremium0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesRetail
                                            ) +
                                            Number(datosRetail0.cantidad)) /
                                            datosPoliticaComercial.pocObjetivoMarca
                                        ) * 100
                                      ) /
                                        (semana + 1 === 0
                                          ? 0
                                          : semana + 1 === 1
                                          ? 15
                                          : semana + 1 === 2
                                          ? 40
                                          : semana + 1 === 3
                                          ? 75
                                          : 100)
                                    ) * 100
                                  ) < 104
                                ? 0.7
                                : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                  Number(
                                    Number(
                                      Number(
                                        Number(
                                          (Number(
                                            datosPoliticaComercial.pocUnidadesChevy
                                          ) +
                                            Number(datosChevy0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesFlotas
                                            ) +
                                            Number(datosFlotas0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesPremium
                                            ) +
                                            Number(datosPremium0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesRetail
                                            ) +
                                            Number(datosRetail0.cantidad)) /
                                            datosPoliticaComercial.pocObjetivoMarca
                                        ) * 100
                                      ) /
                                        (semana + 1 === 0
                                          ? 0
                                          : semana + 1 === 1
                                          ? 15
                                          : semana + 1 === 2
                                          ? 40
                                          : semana + 1 === 3
                                          ? 75
                                          : 100)
                                    ) * 100
                                  ) < 110
                                ? 0.7
                                : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                  Number(
                                    Number(
                                      Number(
                                        Number(
                                          (Number(
                                            datosPoliticaComercial.pocUnidadesChevy
                                          ) +
                                            Number(datosChevy0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesFlotas
                                            ) +
                                            Number(datosFlotas0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesPremium
                                            ) +
                                            Number(datosPremium0.cantidad) +
                                            Number(
                                              datosPoliticaComercial.pocUnidadesRetail
                                            ) +
                                            Number(datosRetail0.cantidad)) /
                                            datosPoliticaComercial.pocObjetivoMarca
                                        ) * 100
                                      ) /
                                        (semana + 1 === 0
                                          ? 0
                                          : semana + 1 === 1
                                          ? 15
                                          : semana + 1 === 2
                                          ? 40
                                          : semana + 1 === 3
                                          ? 75
                                          : 100)
                                    ) * 100
                                  ) >= 100
                                ? 0.7
                                : 0) /
                                100) *
                                (Number(datosChevy0.ventaConRebate) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesChevy
                                  ))
                            )
                        )
                      )
                      .replace("$", "$  ")}
                    sx={{
                      mt: 0.5,
                      mb: 0.5,
                      marginRight: "1%",
                      width: "12%",
                      marginLeft: "13%",
                    }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                  <TextField
                    name="dealear_net_iva"
                    size="small"
                    margin="normal"
                    style={{ backgroundColor: "rgb(240,248,255)" }}
                    value={formatter
                      .format(
                        Number(
                          (datosPoliticaComercial.pocObjetivoMarca > 0 &&
                          Number(
                            Number(
                              Number(
                                Number(
                                  (Number(datosRetail0.cantidad) +
                                    Number(datosPremium0.cantidad) +
                                    Number(datosFlotas0.cantidad) +
                                    Number(datosChevy0.cantidad)) /
                                    datosPoliticaComercial.pocObjetivoMarca
                                ) * 100
                              ) /
                                (semana === 0
                                  ? 0
                                  : semana === 1
                                  ? 15
                                  : semana === 2
                                  ? 40
                                  : semana === 3
                                  ? 75
                                  : 100)
                            ) * 100
                          ) < 95
                            ? 0
                            : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                              Number(
                                Number(
                                  Number(
                                    Number(
                                      (Number(datosRetail0.cantidad) +
                                        Number(datosPremium0.cantidad) +
                                        Number(datosFlotas0.cantidad) +
                                        Number(datosChevy0.cantidad)) /
                                        datosPoliticaComercial.pocObjetivoMarca
                                    ) * 100
                                  ) /
                                    (semana === 0
                                      ? 0
                                      : semana === 1
                                      ? 15
                                      : semana === 2
                                      ? 40
                                      : semana === 3
                                      ? 75
                                      : 100)
                                ) * 100
                              ) < 100
                            ? 0.4
                            : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                              Number(
                                Number(
                                  Number(
                                    Number(
                                      (Number(datosRetail0.cantidad) +
                                        Number(datosPremium0.cantidad) +
                                        Number(datosFlotas0.cantidad) +
                                        Number(datosChevy0.cantidad)) /
                                        datosPoliticaComercial.pocObjetivoMarca
                                    ) * 100
                                  ) /
                                    (semana === 0
                                      ? 0
                                      : semana === 1
                                      ? 15
                                      : semana === 2
                                      ? 40
                                      : semana === 3
                                      ? 75
                                      : 100)
                                ) * 100
                              ) < 104
                            ? 0.8
                            : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                              Number(
                                Number(
                                  Number(
                                    Number(
                                      (Number(datosRetail0.cantidad) +
                                        Number(datosPremium0.cantidad) +
                                        Number(datosFlotas0.cantidad) +
                                        Number(datosChevy0.cantidad)) /
                                        datosPoliticaComercial.pocObjetivoMarca
                                    ) * 100
                                  ) /
                                    (semana === 0
                                      ? 0
                                      : semana === 1
                                      ? 15
                                      : semana === 2
                                      ? 40
                                      : semana === 3
                                      ? 75
                                      : 100)
                                ) * 100
                              ) < 110
                            ? 1
                            : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                              Number(
                                Number(
                                  Number(
                                    Number(
                                      (Number(datosRetail0.cantidad) +
                                        Number(datosPremium0.cantidad) +
                                        Number(datosFlotas0.cantidad) +
                                        Number(datosChevy0.cantidad)) /
                                        datosPoliticaComercial.pocObjetivoMarca
                                    ) * 100
                                  ) /
                                    (semana === 0
                                      ? 0
                                      : semana === 1
                                      ? 15
                                      : semana === 2
                                      ? 40
                                      : semana === 3
                                      ? 75
                                      : 100)
                                ) * 100
                              ) >= 100
                            ? 1.3
                            : 0) *
                            datosRetail0.ventaConRebate *
                            (datosPoliticaComercial.pocObjetivoMarca > 0
                              ? Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      )
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  )
                                )
                              : 0)
                        ) +
                          Number(
                            (datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 95
                              ? 0
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 100
                              ? 0.4
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 104
                              ? 0.8
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 110
                              ? 1
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) >= 100
                              ? 1.3
                              : 0) *
                              datosPremium0.ventaConRebate *
                              (datosPoliticaComercial.pocObjetivoMarca > 0
                                ? Number(
                                    Number(
                                      Number(
                                        Number(
                                          (Number(datosRetail0.cantidad) +
                                            Number(datosPremium0.cantidad) +
                                            Number(datosFlotas0.cantidad) +
                                            Number(datosChevy0.cantidad)) /
                                            datosPoliticaComercial.pocObjetivoMarca
                                        )
                                      ) /
                                        (semana === 0
                                          ? 0
                                          : semana === 1
                                          ? 15
                                          : semana === 2
                                          ? 40
                                          : semana === 3
                                          ? 75
                                          : 100)
                                    )
                                  )
                                : 0)
                          ) +
                          Number(
                            (datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 95
                              ? 0
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 100
                              ? 0.4
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 104
                              ? 0.8
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 110
                              ? 1
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) >= 100
                              ? 1.3
                              : 0) *
                              datosFlotas0.ventaConRebate *
                              (datosPoliticaComercial.pocObjetivoMarca > 0
                                ? Number(
                                    Number(
                                      Number(
                                        Number(
                                          (Number(datosRetail0.cantidad) +
                                            Number(datosPremium0.cantidad) +
                                            Number(datosFlotas0.cantidad) +
                                            Number(datosChevy0.cantidad)) /
                                            datosPoliticaComercial.pocObjetivoMarca
                                        )
                                      ) /
                                        (semana === 0
                                          ? 0
                                          : semana === 1
                                          ? 15
                                          : semana === 2
                                          ? 40
                                          : semana === 3
                                          ? 75
                                          : 100)
                                    )
                                  )
                                : 0)
                          ) +
                          Number(
                            (datosPoliticaComercial.pocObjetivoMarca > 0 &&
                            Number(
                              Number(
                                Number(
                                  Number(
                                    (Number(datosRetail0.cantidad) +
                                      Number(datosPremium0.cantidad) +
                                      Number(datosFlotas0.cantidad) +
                                      Number(datosChevy0.cantidad)) /
                                      datosPoliticaComercial.pocObjetivoMarca
                                  ) * 100
                                ) /
                                  (semana === 0
                                    ? 0
                                    : semana === 1
                                    ? 15
                                    : semana === 2
                                    ? 40
                                    : semana === 3
                                    ? 75
                                    : 100)
                              ) * 100
                            ) < 95
                              ? 0
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 100
                              ? 0.7
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 104
                              ? 0.7
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) < 110
                              ? 0.7
                              : datosPoliticaComercial.pocObjetivoMarca > 0 &&
                                Number(
                                  Number(
                                    Number(
                                      Number(
                                        (Number(datosRetail0.cantidad) +
                                          Number(datosPremium0.cantidad) +
                                          Number(datosFlotas0.cantidad) +
                                          Number(datosChevy0.cantidad)) /
                                          datosPoliticaComercial.pocObjetivoMarca
                                      ) * 100
                                    ) /
                                      (semana === 0
                                        ? 0
                                        : semana === 1
                                        ? 15
                                        : semana === 2
                                        ? 40
                                        : semana === 3
                                        ? 75
                                        : 100)
                                  ) * 100
                                ) >= 100
                              ? 0.7
                              : 0) *
                              datosChevy0.ventaConRebate *
                              (datosPoliticaComercial.pocObjetivoMarca > 0
                                ? Number(
                                    Number(
                                      Number(
                                        Number(
                                          (Number(datosRetail0.cantidad) +
                                            Number(datosPremium0.cantidad) +
                                            Number(datosFlotas0.cantidad) +
                                            Number(datosChevy0.cantidad)) /
                                            datosPoliticaComercial.pocObjetivoMarca
                                        )
                                      ) /
                                        (semana === 0
                                          ? 0
                                          : semana === 1
                                          ? 15
                                          : semana === 2
                                          ? 40
                                          : semana === 3
                                          ? 75
                                          : 100)
                                    )
                                  )
                                : 0)
                          ) +
                          Number(
                            Number(datosRetail0.ventaConRebate) +
                              Number(datosPremium0.ventaConRebate) +
                              Number(datosFlotas0.ventaConRebate) +
                              Number(datosChevy0.ventaConRebate) -
                              (Number(datosRetail0.costo) +
                                Number(datosPremium0.costo) +
                                Number(datosFlotas0.costo) +
                                Number(datosChevy0.costo))
                          ) +
                          Number(
                            datosPoliticaComercial.pocMontoFlotas +
                              datosPoliticaComercial.pocMargenRetail +
                              datosPoliticaComercial.pocMargenChevy +
                              datosPoliticaComercial.pocMargenPremium
                          )
                      )
                      .replace("$", "$  ")}
                    sx={{ mt: 0.5, mb: 0.5, width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  />
                </Grid>

                <br />
                <Grid>
                  <Typography
                    sx={{ mt: 0.3, mb: 0.3, width: "15%", marginLeft: "35%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    Objetivo Marca:
                  </Typography>
                  <Typography
                    sx={{ mt: 0.3, mb: 0.3, width: "15%", marginLeft: "1%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgb(240,248,255)",
                      display: "inline-block",
                    }}
                  >
                    {datosPoliticaComercial.pocObjetivoMarca}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    sx={{ mt: 0.3, mb: 0.3, width: "15%", marginLeft: "35%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    % Cumplimiento:
                  </Typography>
                  <Typography
                    sx={{ mt: 0.3, mb: 0.3, width: "15%", marginLeft: "1%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgb(240,248,255)",
                      display: "inline-block",
                    }}
                  >
                    {datosPoliticaComercial.pocObjetivoMarca > 0
                      ? Number(
                          Number(
                            (Number(
                              datosPoliticaComercial.pocUnidadesChevy
                            ) +
                              Number(
                                datosPoliticaComercial.pocUnidadesFlotas
                              ) +
                              Number(
                                datosPoliticaComercial.pocUnidadesPremium
                              ) +
                              Number(
                                datosPoliticaComercial.pocUnidadesRetail
                              ) +
                              Number(datosRetail0.cantidad) +
                              Number(datosPremium0.cantidad) +
                              Number(datosFlotas0.cantidad) +
                              Number(datosChevy0.cantidad)) /
                              datosPoliticaComercial.pocObjetivoMarca
                          ) * 100
                        ).toFixed(2) + " %"
                      : "0 %"}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    sx={{ mt: 0.3, mb: 0.3, width: "15%", marginLeft: "35%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    % Esperado:
                  </Typography>
                  <Typography
                    sx={{ mt: 0.3, mb: 0.3, width: "15%", marginLeft: "1%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgb(240,248,255)",
                      display: "inline-block",
                    }}
                  >
                    {semana + 1 === 0
                      ? "0 %"
                      : semana + 1 === 1
                      ? "15 %"
                      : semana + 1 === 2
                      ? "40 %"
                      : semana + 1 === 3
                      ? "75 %"
                      : "100 %"}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    sx={{ mt: 0.3, mb: 0.3, width: "15%", marginLeft: "35%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    % Proyectado:
                  </Typography>
                  <Typography
                    sx={{ mt: 0.3, mb: 0.3, width: "15%", marginLeft: "1%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgb(240,248,255)",
                      display: "inline-block",
                    }}
                  >
                    {datosPoliticaComercial.pocObjetivoMarca > 0
                      ? Number(
                          Number(
                            Number(
                              Number(
                                (Number(
                                  datosPoliticaComercial.pocUnidadesChevy
                                ) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesFlotas
                                  ) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesPremium
                                  ) +
                                  Number(
                                    datosPoliticaComercial.pocUnidadesRetail
                                  ) +
                                  Number(datosRetail0.cantidad) +
                                  Number(datosPremium0.cantidad) +
                                  Number(datosFlotas0.cantidad) +
                                  Number(datosChevy0.cantidad)) /
                                  datosPoliticaComercial.pocObjetivoMarca
                              ) * 100
                            ) /
                              (semana + 1 === 0
                                ? 0
                                : semana + 1 === 1
                                ? 15
                                : semana + 1 === 2
                                ? 40
                                : semana + 1 === 3
                                ? 75
                                : 100)
                          ) * 100
                        ).toFixed(2) + " %"
                      : "0 %"}
                  </Typography>
                </Grid>

                {/* <Grid>
                  <Typography
                    sx={{ mt: 0.3, mb: 0.3, width: "15%", marginLeft: "35%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "white",
                      display: "inline-block",
                    }}
                  >
                    % Rentabilidad:
                  </Typography>
                  <Typography
                    sx={{ mt: 0.3, mb: 0.3, width: "15%", marginLeft: "1%" }}
                    textAlign={"center"}
                    style={{
                      backgroundColor: "rgb(240,248,255)",
                      display: "inline-block",
                    }}
                  >
                    0 %
                  </Typography>
                </Grid> */}

                <Button
                  variant="contained"
                  sx={{
                    mt: 1.5,
                    mb: 1.5,
                    width: "15%",
                    marginLeft: "85%",
                  }}
                  onClick={function (event) {
                    guardarDatos((Number(params.id)), Number(marca), Number(zona), Number(2025), Number(1), Number(semana), Number(datosPoliticaComercial.pocObjetivoMarca), Number( datosPoliticaComercial.pocUnidadesRetail), Number( datosPoliticaComercial.pocUnidadesPremium), Number( datosPoliticaComercial.pocUnidadesFlotas), Number( datosPoliticaComercial.pocUnidadesChevy), Number( datosPoliticaComercial.pocMontoRetail), Number( datosPoliticaComercial.pocMontoPremium), Number( datosPoliticaComercial.pocMontoFlotas), Number(datosPoliticaComercial.pocUnidadesChevy), Number( datosPoliticaComercial.pocMargenRetail), Number( datosPoliticaComercial.pocMargenPremium), Number( datosPoliticaComercial.pocMontoFlotas), Number( datosPoliticaComercial.pocMargenChevy));
                  }}
                >
                  Guardar
                </Button>
                <Dialog
                  open={openDialog}
                  //onClose={handleCloseDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Correcto"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Datos guardados correctamente
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      href="/politicacomercial/lista"
                      onClick={function (event) {setOpenDialog(false)}}
                      autoFocus
                    >
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>
                <Divider sx={{ mt: 1, mb: 1 }} />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
