import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  AnoType,
  DataCleaningErroresType,
  DataCleaningType,
  MesType,
  VSateliteUsuarioType,
  VSegurosFI,
  VTraficoCrmType,
  bodegaType,
  marcaType,
} from "../../types";
import * as XLSX from "xlsx";
import { DataGrid } from "@mui/x-data-grid";
import { GridColDef } from "@mui/x-data-grid";

export const TraficoCrmChePage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    getMarcas();
    getBodegas();
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
      getUsuario(account.username);
    } else {
      setName("");
    }
  }, [account]);

  const listadoMarcas: marcaType[] = [
    { marId: 1, marNombre: "Chevrolet" },
    { marId: 2, marNombre: "Gac" },
    { marId: 3, marNombre: "Volkswagen" },
  ];
  const listadoAgencias: bodegaType[] = [];
  const listaAnos: AnoType[] = [
    { id: 2023, ano: 2023 },
    { id: 2024, ano: 2024 },
    { id: 2025, ano: 2025 },
  ];
  const listaMes: MesType[] = [
    { id: 1, mes: "Enero" },
    { id: 2, mes: "Febrero" },
    { id: 3, mes: "Marzo" },
    { id: 4, mes: "Abril" },
    { id: 5, mes: "Mayo" },
    { id: 6, mes: "Junio" },
    { id: 7, mes: "Julio" },
    { id: 8, mes: "Agosto" },
    { id: 9, mes: "Septiembre" },
    { id: 10, mes: "Octubre" },
    { id: 11, mes: "Noviembre" },
    { id: 12, mes: "Diciembre" },
  ];

  const fechaActual = new Date();
  const anioActual = fechaActual.getFullYear();
  const mesActual = fechaActual.getMonth() + 1;

  const [sateliteUsuario, setSateliteUsuario] = useState<VSateliteUsuarioType>({
    id: 0,
    nombre: "",
    correo: "",
    idMarca: 0,
    idBodega: 0,
    usuSupervisor: 0,
    usuJefeAgencia: 0,
    agenciaCrm: "",
    marca: "",
    bodega: ""
  });

  const [marca, setMarca] = React.useState(0);
  const [ano, setAno] = React.useState(anioActual);
  const [mes, setMes] = React.useState(mesActual);
  const [dataMarca, setDataMarca] = useState<marcaType[]>(listadoMarcas);
  const [dataAgencia, setDataAgencia] = useState<bodegaType[]>(listadoAgencias);
  const [dataAno, setDataAno] = useState<AnoType[]>(listaAnos);
  const [dataMes, setDataMes] = useState<MesType[]>(listaMes);
  const [agencia, setAgencia] = React.useState(0);

  const handleChangeMarca = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMarca(Number(event.target.value));
  };
  const handleChangeAno = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAno(Number(event.target.value));
  };
  const handleChangeMes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMes(Number(event.target.value));
  };
  const handleChangeAgencia = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgencia(Number(event.target.value));

    console.log(agencia);
  };

  async function getMarcas() {
    try {
      await axios
        .get<marcaType[]>("https://apisatelite.azurewebsites.net/dimmarca")
        .then((response) => {
          setDataMarca(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getBodegas() {
    try {
      await axios
        .get<bodegaType[]>(
          "https://apisatelite.azurewebsites.net/dimbodega" ///zona/" + idZona
        )
        .then((response) => {
          setDataAgencia(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataAgencia, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const [listaDatos, setListaDatos] = useState<DataCleaningType[]>([]);
  const [listaDatosError, setListaDatosError] = useState<
    DataCleaningErroresType[]
  >([]);

  function exportar2(data: DataCleaningType[]) {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "datos"; // Nombre del archivo Excel

    // Mapeo de encabezados
    const headersMapping: Record<string, string> = {
      agencia: "agencia",
      codigo_agencia: "codigo_agencia",
      fecha: "fecha",
      numero_documento: "numero_documento",
      nombre_cliente: "nombre_cliente",
      telefono_celular: "telefono_celular",
      email: "email",
      cedula_asesor: "cedula_asesor",
      nombre_asesor: "nombre_asesor",
      modelo: "modelo",
      canal: "canal",
      cotizacion: "cotizacion",
      test_drive: "test_drive",
      solicitud: "solicitud",
      estado_credito: "estado_credito",
      // ... otros encabezados mapeados
    };

    const formattedData = data.map((item) => {
      const newItem: Record<string, any> = {};
      Object.keys(item).forEach((key) => {
        if (headersMapping[key]) {
          newItem[headersMapping[key]] = (item as any)[key];
        }
      });
      return newItem;
    });

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: fileType });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName + fileExtension;
    a.click();
    URL.revokeObjectURL(url);
  }

  function exportarError(data: DataCleaningErroresType[]) {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "datosError"; // Nombre del archivo Excel

    // Mapeo de encabezados
    const headersMapping: Record<string, string> = {
      agencia: "agencia",
      codigo_agencia: "codigo_agencia",
      fecha: "fecha",
      numero_documento: "numero_documento",
      nombre_cliente: "nombre_cliente",
      telefono_celular: "telefono_celular",
      email: "email",
      cedula_asesor: "cedula_asesor",
      nombre_asesor: "nombre_asesor",
      modelo: "modelo",
      canal: "canal",
      cotizacion: "cotizacion",
      test_drive: "test_drive",
      solicitud: "solicitud",
      estado_credito: "estado_credito",
      validador: "validador",
      id_crm: "id_crm",
      // ... otros encabezados mapeados
    };

    const formattedData = data.map((item) => {
      const newItem: Record<string, any> = {};
      Object.keys(item).forEach((key) => {
        if (headersMapping[key]) {
          newItem[headersMapping[key]] = (item as any)[key];
        }
      });
      return newItem;
    });

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: fileType });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName + fileExtension;
    a.click();
    URL.revokeObjectURL(url);
  }

  const [listaTrafico, setListaTrafico] = useState<VTraficoCrmType[]>([]);
  const [contCoticacion, setContCoticacion] = React.useState(0);
  const [contSolicitud, setContSolicitud] = React.useState(0);

  async function conteo(lista: VTraficoCrmType[]) {
    const condicionCotizacio = (objeto: VTraficoCrmType) =>
      objeto.cotizado === "Si";
      const condicionSolicitud = (objeto: VTraficoCrmType) =>
      objeto.solicitud === "Si";
    const objetosCotizacion = lista.filter(condicionCotizacio);
    setContCoticacion(Number(objetosCotizacion.length));
    const objetosSolicitud = lista.filter(condicionSolicitud);
    setContSolicitud(Number(objetosSolicitud.length));
  }

  const values = {
    hoy: new Date(Date.now()).toISOString().substring(0, 10),
  };

  const [data, setData] = useState<string>(values.hoy.substring(0, 10));

  const dataDatos = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
    setData(e.target.value);
  };

  const [mostrar, setMostrar] = React.useState(false);

  async function getListadoFecha(
    id_marca: number,
    id_bodega: number,
    fecha: string
  ) {
    try {
      await axios
        .get<VTraficoCrmType[]>(
          "https://apisatelite.azurewebsites.net/v-trafico-crm/consultarfecha/" +
            String(id_marca) +
            "/" +
            String(id_bodega) +
            "/" +
            data
        )
        .then((response) => {
          setListaTrafico(response.data);
          setMostrar(true);
          conteo(response.data);
          console.log("mi respuesta de Tabla renta acc: ", response.data);
        })
        .catch((error) => {
          console.log("entre al catch >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const columns: GridColDef[] = [
    {
      field: "createdate",
      headerName: "F.Creacion",
      editable: false,
      width: 80,
      valueGetter: ({ row }) => {
        return String(row.createdate).substring(0, 10);
      },
    },
    {
      field: "appointmentdate",
      headerName: "F.Cita",
      editable: false,
      width: 80,
      valueGetter: ({ row }) => {
        return String(row.appointmentdate).substring(0, 10);
      },
    },
    { field: "dealname", headerName: "Negocio", editable: false, width: 225 },
    { field: "origen", headerName: "Origen", editable: false, width: 100 },
    { field: "asesor", headerName: "Asesor", editable: false, width: 150 },
    { field: "financingtype", headerName: "Tipo", editable: false, width: 90 },
    { field: "modelo", headerName: "Modelo", editable: false, width: 100 },
    { field: "cotizado", headerName: "Cotizado", editable: false, width: 70 },
    { field: "solicitud", headerName: "Solicitud", editable: false, width: 70 },
    { field: "usado", headerName: "Usado", editable: false, width: 70 },
    { field: "linea", headerName: "Línea", editable: false, width: 75 },
    {
      field: "abrir",
      headerName: "CRM",
      editable: false,
      width: 70,
      renderCell: (params) => {
        return (
          <div>
            <Button
              sx={{ width: "100%" }}
              target="_blank"
              component="a"
              href={
                marca === 1
                  ? `https://app.hubspot.com/contacts/8008779/record/0-3/${params.row.id_crm}`
                  : marca === 2
                  ? `https://app.hubspot.com/contacts/9244718/record/0-3/${params.row.id_crm}`
                  : `https://app.hubspot.com/contacts/9269500/record/0-3/${params.row.id_crm}`
              }
              size="small"
            >
              Ir
            </Button>
          </div>
        );
      },
    },
  ];

  async function getUsuario(correo: string) {
    try {
      await axios
        .get<VSateliteUsuarioType>(
          "https://apisatelite.azurewebsites.net/vsateliteusuario/correo/" +
            correo
        )
        .then((response) => {
          console.log("mi user es >>>>>>", response.data);
          setSateliteUsuario(response.data);
          setMarca(response.data.idMarca);
          setAgencia(response.data.idBodega);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(sateliteUsuario, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                {/* Tráfico CRM {marca === 1 ? "Chevrolet": marca === 2 ? "GAC" : "Volkswagen"} */}
                Tráfico Diario usando fecha de cita
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "20%",
                }}
                style={{ display: "inline-block" }}
              >
                Marca:
              </Typography>
              <TextField
                id="combomarca"
                select
                size="small"
                margin="normal"
                value={marca}
                onChange={handleChangeMarca}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "5%", width: "19%" }}
              >
                {dataMarca.map((option) => (
                  <MenuItem key={option.marId} value={option.marId}>
                    {option.marNombre}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                }}
                style={{ display: "inline-block" }}
              >
                Agencia:
              </Typography>
              <TextField
                id="comboagencia"
                select
                size="small"
                margin="normal"
                value={agencia}
                onChange={handleChangeAgencia}
                sx={{ mt: 0.5, mb: 0.5, width: "19%", marginRight: "3%" }}
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {dataAgencia.map((option) => (
                  <MenuItem key={option.bodId} value={option.bodId}>
                    {option.agencia}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "20%",
                }}
                style={{ display: "inline-block" }}
              >
                F.Cita:
              </Typography>
              <TextField
                id="combomarca"
                type="date"
                size="small"
                margin="normal"
                sx={{ mt: 0.5, mb: 0.5, marginRight: "5%", width: "19%" }}
                onChange={dataDatos}
                value={data}
              />

              <Button
                id="obtenerxls"
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 1,
                  width: "12%",
                  marginLeft: "44%",
                  marginRight: "44%",
                }}
                onClick={function (event) {
                  getListadoFecha(marca, agencia, "");
                }}
              >
                Consultar
              </Button>

              {mostrar ? (
                <>
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "100%",
                      textAlign: "center",
                    }}
                    //style={{ display: "inline-block" }}
                  >
                    Cantidad de Tráfico: <strong> {listaTrafico.length}</strong>{" "}
                    {" - "} Cantidad de Cotizaciones:{" "}
                    <strong> {contCoticacion}</strong>
                    {" - "}
                    Cantidad de Solicitud: <strong> {contSolicitud}</strong>
                  </Typography>
                </>
              ) : (
                <></>
              )}
            </Paper>

            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
              }}
            >
              <DataGrid
                getRowId={(row) => row.id}
                sx={{
                  minHeight: 400,
                  // marginLeft: "10%",
                  // width: "80%",
                  mt: 3,
                  mb: 3,
                }}
                editMode="row"
                rows={listaTrafico}
                columns={columns}
                // slots={{ toolbar: GridToolbarQuickFilter }}
                initialState={{
                  pagination: { paginationModel: { pageSize: 20 } },
                }}
                pageSizeOptions={[20, 30, 50]}
                slotProps={{
                  pagination: {
                    labelRowsPerPage: "Registros por página:",
                  },
                }}
                localeText={{
                  noRowsLabel: "No se ha encontrado datos.",
                  noResultsOverlayLabel: "No se ha encontrado ningún resultado",
                  toolbarColumns: "Columnas",
                  toolbarColumnsLabel: "Seleccionar columnas",
                  toolbarFilters: "Filtros",
                  toolbarFiltersLabel: "Ver filtros",
                  toolbarFiltersTooltipHide: "Quitar filtros",
                  toolbarFiltersTooltipShow: "Ver filtros",
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
