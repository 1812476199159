import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Paper,
    TextField,
    Typography, 
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    IconButton,
    Input,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, useMemo } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Box } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { MesType, SateliteTestDrive, SateliteTestDriveImagen, SateliteTestDriveImagenMostrar, SateliteTestDriveRuta, VSateliteTestDriveRuta, vventassatelitecrmType } from "../../types";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export const ResumenTestDrivePage: React.FC<{}> = () => {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const isAuthenticated = useIsAuthenticated();
    const params = useParams();
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [rutas, setRutas] = useState<SateliteTestDriveRuta[]>([]);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const userAgent = navigator.userAgent;
    const mobileKeywords = [
        "Mobile",
        "Android",
        "iPhone",
        "iPad",
        "Windows Phone",
    ];
    const checkDeviceType = () => {
        setIsMobile(
            mobileKeywords.some((keyword) => userAgent.includes(keyword))
        );
    };

    useEffect(() => {
        if (account && account.name) {
            console.log("este son los atributos de msal >>>>>>", account);
            setName(account.name);
            setUsername(account.username);
            console.log(account);
            checkDeviceType();
            getDatosTestDrive();
            getTestDriveImages(Number(params.id));
        } else {
            setName("");
        }
    }, [account]);  



    const [dataTestDrive, setDataTestDrive] = useState<SateliteTestDrive>({
        stdId: 0,
        stdFecha: new Date(),
        stdFechaIni: new Date(),
        stdFechaFin: new Date(),
        stdFinalizado: 0,
        idBodega: 0,
        idCrm: "",
        sruId: 0,
        stdEsInventario: 0,
        stdVinPlaca: "",
        stdUsuCorreoCrea: "",
        stdUsuNombreCrea: "",
        stdUsuCorreoResponsable: "",
        stdUsuNombreResponsable: "",
        stdUsuCorreoFin: "",
        stdUsuNombreFin: "",
        stdObservacion1: "",
        stdObservacion2: "",
        stdKmInicio: 0,
        stdKmFin: 0,
    });

    const [dataNegocio, setDataNegocio] = useState<vventassatelitecrmType>({
        id: 0,
        idcrm: "",
        createdate: "",
        cedula: "",
        cliente: "",
        asesor: "",
        dealname: "",
        agencia: "",
        modelo: "",
        correo: "",
        ageDireccion: "",
    });

    const [dataVRuta, setDataVRuta] = useState<VSateliteTestDriveRuta>({
        sruId: 0,
        idBodega: 0,
        idZona: 0,
        agencia: "",
        zona: "",
        sruDescripcion: "",
        sruMetrosDistancia: 0,
        sruObservacion: "",
        ruMetrosDistanciaTolerancia: 0,
        sruMinutos: 0,
        sruMinutosTolerancia: 0,
    });


    const [images, setImages] = useState<SateliteTestDriveImagenMostrar[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);

    const handleImageClick = (index: number) => {
        setCurrentIndex(index);
        setOpenDialog(true);
    };

    async function getDatosTestDrive() {
        try {
            await axios
                .get<SateliteTestDrive>("https://apisatelite.azurewebsites.net/testdrive/buscar/" + params.id)
                .then((response) => {
                    setDataTestDrive(response.data);
                    getDatosNegocio(String(response.data.idCrm));
                    getDatosRuta(Number(response.data.sruId));
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getDatosNegocio(idcrm: string) {
        try {
            await axios
                .get<vventassatelitecrmType[]>("https://apisatelite.azurewebsites.net/vventassatelitecrm/idcrm/" + idcrm)
                .then((response) => {
                    setDataNegocio(response.data[0]);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getDatosRuta(id: number) {
        try {
            await axios
                .get<VSateliteTestDriveRuta>("https://apisatelite.azurewebsites.net/vsatelitetestdriveruta/buscar/" + String(id))
                .then((response) => {
                    setDataVRuta(response.data);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getTestDriveImages(id: number) { //: Promise<SateliteTestDriveImagen[]>
        try {
            const response = await axios.get<{ data: SateliteTestDriveImagen[] }>(
                `https://apisatelite.azurewebsites.net/testdriveimagen/testdrive/${id}`
            );
            if (response.status === 200) {
                // Procesamos cada objeto para transformar stiImagen (Buffer) a base64 URL
                const images = response.data.data.map((item) => ({
                    ...item,
                    stiImagen: `data:image/jpeg;base64,${item.stiImagen}`, // O PNG según corresponda
                }));
                setImages(images);
            } else {
                console.error("Error al obtener las imágenes:", response.statusText);
                return [];
            }
        } catch (error) {
            console.error("Error de Axios:", error);
            return [];
        }
    }



    const [tempImages, setTempImages] = useState<SateliteTestDriveImagen[]>([]);
    const [currentImageIndex, setCurrentImageIndex] = useState<number | null>(null);


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];

            setTempImages((prev) => [
                ...prev,
                {
                    stiId: 0, // Asignar un ID único
                    stdId: 0, // ID de ejemplo
                    stiNombre: file.name,
                    stiImagen: file, // Guarda el archivo original
                    stiFecha: new Date().toISOString(),
                },
            ]);
        }
    };

    const handleOpenModal = (index: number) => {
        setCurrentImageIndex(index);
    };

    const handleCloseModal = () => {
        setCurrentImageIndex(null);
    };

    const handleNextImage = () => {
        if (currentImageIndex !== null) {
            setCurrentImageIndex((prevIndex) => (prevIndex! + 1) % tempImages.length);
        }
    };

    const handlePrevImage = () => {
        if (currentImageIndex !== null) {
            setCurrentImageIndex(
                (prevIndex) => (prevIndex! - 1 + tempImages.length) % tempImages.length
            );
        }
    };

    const handlePrevious = () => {
        if (currentIndex !== null && currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleNext = () => {
        if (currentIndex !== null && currentIndex < images.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };


    if (isAuthenticated) {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Grid alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 2,
                            }}
                        >
                            <Typography variant="h5" textAlign={"center"}>
                                Test Drive: Registro de Salida
                            </Typography>
                        </Paper>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 1,
                            }}
                        >
                            {isMobile ? <>
                            </> : <>

                                <Typography sx={{ ml: "10%", mt: 2, mb: 3 }} textAlign={"left"} variant="h6" >
                                    <strong>Información general</strong>
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Identificacion:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataNegocio.cedula}
                                    // onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "15%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Cliente:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataNegocio.cliente}
                                    // onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "35%" }}
                                />


                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Teléfono:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataNegocio.contactphone}
                                    sx={{ mt: 1, mb: 1, width: "15%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Correo:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataNegocio.contactemail}
                                    sx={{ mt: 1, mb: 1, width: "35%" }}
                                />
                                <Divider sx={{ ml: "3%", mr: "3%", mt: 2, mb: 2 }} />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Ruta:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataVRuta.sruDescripcion}
                                    sx={{ mt: 1, mb: 1, width: "35%" }}
                                />

                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "15%",
                                        ml: "8%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Distancia de la ruta:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={`${dataVRuta.sruMetrosDistancia} metros`}
                                    sx={{ mt: 1, mb: 1, width: "12%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Vehículo:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataTestDrive.stdVinPlaca}
                                    sx={{ mt: 1, mb: 1, width: "25%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "3%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    KM de inicio:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataTestDrive.stdKmInicio}
                                    sx={{ mt: 1, mb: 1, width: "11%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "7%",
                                        ml: "3%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    KM final:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataTestDrive.stdKmFin}
                                    sx={{ mt: 1, mb: 1, width: "11%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Responsable:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataNegocio.asesor}
                                    sx={{ mt: 1, mb: 1, width: "25%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Responsable:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataNegocio.asesor}
                                    sx={{ mt: 1, mb: 1, width: "25%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Hora de inicio:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataTestDrive.stdFechaIni}
                                    sx={{ mt: 1, mb: 1, width: "17%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "3%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Hora final:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataTestDrive.stdFechaIni}
                                    sx={{ mt: 1, mb: 1, width: "17%" }}
                                />

                                <Divider sx={{ ml: "3%", mr: "3%", mt: 3, mb: 1 }} />
                                <Typography sx={{ ml: "10%", mt: 2, mb: 3 }} textAlign={"left"} variant="h6" >
                                    <strong>Fotos del registro de test drive</strong>
                                </Typography>

                                <Box sx={{ ml: "10%", mr:"10%" }}>         
                                    <Grid container spacing={2}>
                                        {images.map((image, index) => (
                                            <Grid item xs={6} sm={3} md={3} key={image.stiId}>
                                                <Card>
                                                    <CardActionArea onClick={() => handleImageClick(index)}>
                                                        <CardMedia
                                                            component="img"
                                                            height="200"
                                                            image={String(image.stiImagen)}
                                                            alt={image.stiNombre}
                                                        />
                                                        <CardContent>
                                                            <Typography variant="h6">{image.stiNombre}</Typography>
                                                            <Typography variant="body2" color="text.secondary">
                                                                Fecha: {new Date(image.stiFecha).toLocaleDateString()}
                                                            </Typography>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>

                                    {/* Modal para mostrar imagen ampliada */}
                                    {currentIndex !== null && (
                                    <Dialog open={openDialog} maxWidth="md">
                                        <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <IconButton onClick={handlePrevious} disabled={currentIndex === 0}>
                                                <ArrowBack />
                                            </IconButton>
                                           {images[currentIndex].stiNombre}
                                            <IconButton onClick={handleNext} disabled={currentIndex === null || currentIndex === images.length - 1}>
                                                <ArrowForward />
                                            </IconButton>
                                        </DialogTitle>
                                        <DialogContent>
                                            {currentIndex !== null && (
                                                <Box
                                                    component="img"
                                                    src={String(images[currentIndex].stiImagen)}
                                                    alt="Imagen ampliada"
                                                    sx={{
                                                        width: "100%",
                                                        maxHeight: "80vh",
                                                        objectFit: "contain",
                                                        borderRadius: 2,
                                                    }}
                                                />
                                            )}
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={(e) => setOpenDialog(false)}>Cerrar</Button>
                                        </DialogActions>
                                    </Dialog>
                                    )}
                                </Box>                                                    
                            </>}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        );
    } else {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Paper>
                    <Grid
                        //container
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Typography
                            sx={{ mt: 2, mb: 1.5 }}
                            variant="h6"
                            textAlign={"center"}
                        >
                            Acceso denegado
                        </Typography>
                    </Grid>
                </Paper>
            </Container>
        );
    }
};
