import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import datosCliente from "../../assets/datos_cliente.png";
import tablaRenta from "../../assets/rentabilidad.png";
import conozca from "../../assets/conozca.png";
import uafe from "../../assets/uafe.png";
import accesorizacion from "../../assets/accesorizacion.png";
import crearcliente from "../../assets/crearcliente.png";
import { styled } from "@mui/system";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  CrmUafeType,
  TablaRentaDocumentoType,
  TablaRentaType,
} from "../../types";
import { DataGrid } from "@mui/x-data-grid";
import { GridColDef } from "@mui/x-data-grid";
import { green, orange, red } from "@mui/material/colors";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import EditNoteIcon from '@mui/icons-material/EditNote';
import FileOpenIcon from '@mui/icons-material/FileOpen';

export const HomeVentasPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
      getInfoNegocio(account.username);
      getvTablaRenta();
    } else {
      setName("");
    }
  }, [account]);

  type tarjetaType = {
    titulo: string;
    descripcion: string;
    imagen: string;
    link: string;
  };
  const [dataInfoNegocio, setDataInfoNegocio] =
    React.useState<TablaRentaDocumentoType>({
      id: 0,
      idCrm: "",
      modelo: "",
      agencia: "",
      asesor: "",
      cedula: "",
      nombreCliente: "",
      genero: "",
      civilstatus: "",
      nombreConyuge: "",
      provincia: "",
      ciudad: "",
      direccion: "",
      telefono: "",
      correo: "",
      lugarTrabajo: "",
      cargo: "",
      direccionEmpresa: "",
      telefonoEmpresa: "",
      familia: "",
      nacionalidad: "",
      tipoPersonaCliente: "",
      zona: "",
      ciudadAgencia: "",
      esBloqueado: 0,
      ciudadEmpresa: "",
      provinciaEmpresa: "",
      financiera: ""
    });

  const params = useParams();
  const dataTarjetas: tarjetaType[] = [
    {
      titulo: "1. Datos del Cliente",
      descripcion:
        "Consulta los datos del cliente o registralo para una reserva. Solicita la revisión de las páginas UAFE.",
      imagen: datosCliente,
      link: `/clienterpa/${params.idcrm}`,
    },
    {
      titulo: "2. Tabla de Rentabilidad",
      descripcion:
        "Llena los datos del negocio para el cálculo de la rentabilidad. Puedes consultar historico de tablas de éste negocio.",
      imagen: tablaRenta,
      link: "",
    },
    {
      titulo: "3. Conozca a su Cliente",
      descripcion:
        "Realiza los formularios: Autorización de facturación, Conozca a su Cliente y Terceros.",
      imagen: conozca,
      link: `/conozca/${params.idcrm}`,
    },
    {
      titulo: "4. Revisión UAFE",
      descripcion:
        "Consulta la revisión de páginas necesarias de UAFE una vez completado el paso 1.",
      imagen: uafe,
      link: `/uafe/${params.idcrm}`,
    },
    {
      titulo: "5. Crear Cliente",
      descripcion:
        "Replica los datos del cliente en DMS Advance (0150). Recuerda primero realizar los pasos anteriores.",
      imagen: crearcliente,
      link: `/ventas/cliente/crear/${params.idcrm}`,
    },

    // {titulo: "1. Accesorización", descripcion: "Consulta el catalogo de accesorios.", imagen: accesorizacion, link: `/modelo/familia/${params.idcrm}/${dataInfoNegocio.modelo}`},
    // {titulo: "2. Datos del Cliente", descripcion: "Consulta los datos del cliente o registralo para una reserva. Solicita la revisión de las páginas UAFE.", imagen: datosCliente, link: `/clienterpa/${params.idcrm}`},
    // {titulo: "3. Tabla de Rentabilidad", descripcion: "Llena los datos del negocio para el cálculo de la rentabilidad. Puedes consultar historico de tablas de éste negocio.", imagen: tablaRenta, link: ""},
    // {titulo: "4. Conozca a su Cliente", descripcion: "Realiza los formularios: Autorización de facturación, Conozca a su Cliente y Terceros.", imagen: conozca, link: `/conozca/${params.idcrm}`},
    // {titulo: "5. Revisión UAFE", descripcion: "Consulta la revisión de páginas necesarias de UAFE una vez completado el paso 1.", imagen: uafe, link: `/uafe/${params.idcrm}`},
    // {titulo: "6. Crear Cliente", descripcion: "Replica los datos del cliente en DMS Advance (0150). Recuerda primero realizar los pasos anteriores.", imagen: crearcliente, link: `/ventas/cliente/crear/${params.idcrm}`},
  ];

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const columns: GridColDef[] = [
    { field: "tarId", headerName: "N°", editable: false, width: 60 },
    {
      field: "tarFecha",
      headerName: "Fecha",
      editable: false,
      width: 90,
      valueGetter: ({ row }) => {
        return String(row.tarFecha).substring(0, 10);
      },
    },
    {
      field: "tarTotalFacturar",
      headerName: "A facturar",
      editable: false,
      width: 105,
      valueGetter: ({ row }) => {
        return formatter.format(row.tarTotalFacturar).replace("$", "$  ");
      },
    },
    {
      field: "tarEstado",
      headerName: "Estado",
      editable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <div>
            {params.row.tarEstado === 0
              ? "POR APROBAR"
              : params.row.tarEstado === 1
              ? "APROBADO"
              : params.row.tarEstado === 2
              ? "NEGADO"
            : params.row.tarEstado === 3 ? "SIN ENVIAR": "" }
          </div>
        );
      },
    },
    //{ field: "tarUtilidadBruta", headerName: "Margen", editable: false, width: 60 },

    {
      field: "tarUtilidadBruta",
      headerName: "Margen",
      editable: false,
      width: 65,
      renderCell: (params) => {
        return <div>{params.row.tarUtilidadBruta} %</div>;
      },
    },

    {
      field: "margenNivel",
      headerName: "Nivel",
      editable: false,
      width: 60,
      renderCell: (params) => {
        return (
          <div>
            {params.row.margenNivel === 1 ? (
              <RadioButtonCheckedIcon sx={{ color: red[500] }} />
            ) : params.row.margenNivel === 2 ? (
              <RadioButtonCheckedIcon sx={{ color: orange[500] }} />
            ) : (
              <RadioButtonCheckedIcon sx={{ color: green[500] }} />
            )}
          </div>
        );
      },
    },
    {
      field: "tarDestinatario",
      headerName: "Autoriza",
      editable: false,
      width: 175,
    },
    {
      field: "edit",
      headerName: "Editar",
      headerAlign: "center",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        //{params.row.Id}
        return (
          <Button
          disabled= {params.row.tarEstado === 3 || params.row.tarEstado === 2 ? false:true}
            href={`/tablarenta/tabla/${params.row.tarId}/${params.row.tarNumeroCotizacion}`}
            onClick={function (event) {}}
          >
            <EditNoteIcon/>
          </Button>
        );
      },
    },    
    {
      field: "open",
      headerName: "Abrir",
      headerAlign: "center",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        //{params.row.Id}
        return (
          <Button
            href={`/tablarenta/${params.row.tarId}`}
            onClick={function (event) {}}
          >
           <FileOpenIcon/>
          </Button>
        );
      },
    },
  ];

  const [listaTablas, setListaTablas] = useState<TablaRentaType[]>([]);

  async function getvTablaRenta() {
    try {
      await axios
        .get<TablaRentaType[]>(
          "https://apisatelite.azurewebsites.net/v-tabla-renta/crm/" +
            String(params.idcrm)
        )
        .then((response) => {
          setListaTablas(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getInfoNegocio(correoLoggeado: string) {
    try {
      await axios
        .get<TablaRentaDocumentoType>(
          "https://apisatelite.azurewebsites.net/vtablarentadocumento/" +
            String(params.idcrm)
        )
        .then((response) => {
          if (response.data.id > 0) {
            setDataInfoNegocio(response.data);
            // getPeticionUafe(correoLoggeado,response.data.cedula,"","",response.data.nombreCliente,"", response.data.tipoPersonaCliente)
          }
          console.log("datos de tercero >>> ", response.data);
        })
        .catch((error) => {
          console.log("algo paso >> 5", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const isAuthenticated = useIsAuthenticated();

  type paginaPublicaType = {
    Id: number;
    Fuente: string;
    UrlFuente: string;
    Usuario: string;
    Password: string;
    UrlBot: string;
    Estado: string;
  };

  type paginaType = {
    Id: string;
    PaginaPublica: paginaPublicaType;
    Resultado: string;
    Mensaje: string;
    UrlEvidencia: string;
  };
  type tipoSolicitudRespuestaType = {
    Id: number;
    Nombre: string;
    Estado: string;
  };

  type tipoPersonaResumenType = {
    Id: number;
  };

  type personaType = {
    CI: string;
    PrimerNombre: string;
    SegundoNombre: string;
    PrimerApellido: string;
    SegundoApellido: string;
    TipoPersona: tipoPersonaResumenType;
  };

  type respuestaDetalleType = {
    Id: number;
    TipoSolicitud: tipoSolicitudRespuestaType;
    Fecha: string;
    IpOrigen: string;
    Estado: string;
    Persona: personaType;
    RespuestaPaginasPublicas: paginaType[];
  };

  type respuestaType = {
    Codigo: string;
    Descripcion: string;
    Objeto: respuestaDetalleType;
  };

  async function postGuardarRegistro(parametroGuardar: CrmUafeType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/satelitecrmuafe",
          parametroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: >>>>>>>> ", response);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function guardarCrmUafe(
    iduafe: number,
    idcrm: string,
    fecha: string,
    user: string
  ) {
    const nuevoRegistro: CrmUafeType = {
      idCrm: idcrm,
      idUafe: iduafe,
      scuFecha: fecha,
      scuSolicitante: user,
    };
    console.log("datos a guardar >> ", JSON.stringify(nuevoRegistro, null, 4));
    postGuardarRegistro(nuevoRegistro);
  }

  async function getRegistrarPeticion(parametro: string, user: string) {
    try {
      await axios
        .get<respuestaType>(
          "https://rpa.corporacionproauto.com.ec/TEST/Solicitud/RegistrarSolicitud" +
            parametro
        )
        .then((response) => {
          console.log(
            "respuesta del registro peticion >>>>>>>>>>>>>> :",
            response.data
          );
          console.log(
            "id de respuesta del registro peticion es >>>>>>>>>>>>>> :",
            response.data.Objeto.Id
          );
          guardarCrmUafe(
            response.data.Objeto.Id,
            String(params.idcrm),
            response.data.Objeto.Fecha,
            user
          );
        })
        .catch((error) => {
          console.log("algo paso 1 >> ", error);
        });

      //console.log("data es >>", JSON.stringify(respuestaGet, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function principal(
    cedula: string,
    primerNombre: string,
    segundoNombre: string,
    primerApellido: string,
    segundoApellido: string,
    user: string,
    tipoPersona: number
  ) {
    await getRegistrarPeticion(
      "?token={%26quot;TipoSolicitud%26quot;:{%26quot;Id%26quot;:1},%26quot;Empresa%26quot;:{%26quot;Id%26quot;:%26quot;" +
        String(1) +
        "%26quot;},%26quot;Solicitante%26quot;:{%26quot;Email%26quot;:%26quot;" +
        user +
        "%26quot;},%26quot;Persona%26quot;:{%26quot;CI%26quot;:%26quot;" +
        cedula +
        "%26quot;,%26quot;PrimerNombre%26quot;:%26quot;" +
        primerNombre +
        "%26quot;,%26quot;SegundoNombre%26quot;:%26quot;" +
        segundoNombre +
        "%26quot;,%26quot;PrimerApellido%26quot;:%26quot;" +
        primerApellido +
        "%26quot;,%26quot;SegundoApellido%26quot;:%26quot;" +
        segundoApellido +
        "%26quot;,%26quot;TipoPersona%26quot;:{%26quot;Id%26quot;:" +
        String(tipoPersona) +
        "}}}",
      user
    );
  }

  async function getPeticionUafe(
    user: string,
    cedula: string,
    primer_nombre: string,
    segundo_nombre: string,
    primer_apellido: string,
    segundo_apellido: string,
    tipo_persona: string
  ) {
    try {
      await axios
        .get<CrmUafeType>(
          "https://apisatelite.azurewebsites.net/satelitecrmuafe/" +
            params.idcrm
        )
        .then((response) => {
          console.log("mi respuesta de la peticion UAFE: ", response.data);
          {
            response.data.idUafe > 0
              ? console.log("")
              : principal(
                  cedula,
                  primer_nombre,
                  segundo_nombre,
                  primer_apellido,
                  segundo_apellido,
                  user,
                  tipo_persona === "Persona Natural"
                    ? 1
                    : tipo_persona === "Persona Jurídica"
                    ? 3
                    : 2
                );
          }
        })
        .catch((error) => {
          principal(
            cedula,
            primer_nombre,
            segundo_nombre,
            primer_apellido,
            segundo_apellido,
            user,
            tipo_persona === "Persona Natural"
              ? 1
              : tipo_persona === "Persona Jurídica"
              ? 3
              : 2
          );
          console.log("entre al catch >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 820,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (isAuthenticated) {
    if (dataInfoNegocio.id > 0) {
      if (dataInfoNegocio.esBloqueado === 2) {
        return (
          <Container maxWidth="lg" sx={{ mt: 10 }}>
            <Paper>
              <Grid
                //container
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography
                  sx={{ mt: 5, mb: 1 }}
                  variant="h6"
                  textAlign={"center"}
                >
                  No se puede transaccionar
                </Typography>
                <Typography
                  sx={{ mt: 1, mb: 1 }}
                  variant="h6"
                  textAlign={"center"}
                >
                  <strong>{dataInfoNegocio.cedula}{" - "}{dataInfoNegocio.nombreCliente}</strong>
                </Typography>
                <Typography
                  sx={{ mt: 1, mb: 5 }}
                  variant="h6"
                  textAlign={"center"}
                >
                  Cliente bloqueado por el oficial de cumplimiento
                </Typography>
              </Grid>
            </Paper>
          </Container>
        );
      } else {
        return (
          <Container maxWidth="lg" sx={{ mt: 10 }}>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ minHeight: "90vh" }}
            >
              <Grid item>
                <Paper
                  sx={{
                    padding: "1.2em",
                    borderRadius: "0.5em",
                    backgroundColor: "rgb(255,255,255,0.95)",
                    mt: 2,
                  }}
                >
                  <Typography variant="h5" textAlign={"center"} sx={{ mt: 1 }}>
                    ID CRM: {params.idcrm} - {dataInfoNegocio.nombreCliente} -{" "}
                    {dataInfoNegocio.familia}
                  </Typography>
                </Paper>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={styleModal}>
                    <Typography
                      id="modal-modal-description"
                      sx={{ mt: 2, mb: 2, textAlign: "center" }}
                    >
                      <strong>Historial de Tablas de Rentabilidad</strong>
                    </Typography>
                    <Typography
                      id="modal-modal-description"
                      sx={{ mt: 2, mb: 2, textAlign: "center" }}
                    >
                      ID CRM: {params.idcrm} - {dataInfoNegocio.nombreCliente} -{" "}
                      {dataInfoNegocio.familia}
                    </Typography>
                    <DataGrid
                      getRowId={(row) => row.tarId}
                      sx={{ height: 400 }}
                      editMode="row"
                      rows={listaTablas}
                      columns={columns}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 5 } },
                      }}
                      pageSizeOptions={[5, 10, 20]}
                      slotProps={{
                        pagination: {
                          labelRowsPerPage: "Registros por página:",
                        },
                      }}
                      localeText={{
                        noRowsLabel: "No se ha encontrado datos.",
                        noResultsOverlayLabel:
                          "No se ha encontrado ningún resultado",
                        toolbarColumns: "Columnas",
                        toolbarColumnsLabel: "Seleccionar columnas",
                        toolbarFilters: "Filtros",
                        toolbarFiltersLabel: "Ver filtros",
                        toolbarFiltersTooltipHide: "Quitar filtros",
                        toolbarFiltersTooltipShow: "Ver filtros",
                      }}
                    />

                    <Button
                      variant="contained"
                      sx={{
                        mt: 1.5,
                        mb: 1.5,
                        marginLeft: "40%",
                        width: "20%",
                      }}
                      href={`/tablarenta/tabla/0/${params.idcrm}`}
                      onClick={function (event) {}}
                    >
                      Nuevo
                    </Button>
                  </Box>
                </Modal>
                <Paper
                  sx={{
                    padding: "1.2em",
                    borderRadius: "0.5em",
                    backgroundColor: "rgb(255,255,255,0.7)",
                    mt: 1,
                  }}
                >
                  <Grid sx={{ marginLeft: 20 }}>
                    {dataTarjetas.map((row) => (
                      <Card
                        sx={{
                          width: 240,
                          display: "inline-block",
                          marginRight: 4,
                          mt: 2,
                          mb: 2,
                        }}
                      >
                        <CardMedia
                          sx={{ height: 140 }}
                          image={row.imagen}
                          title="Corporacion Proauto"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h6" component="div">
                            <strong>{row.titulo}</strong>
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ height: 60 }}
                          >
                            {row.descripcion}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          {row.link.length > 0 ? (
                            <>
                              <Button href={row.link} size="small">
                                Abrir
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                onClick={function (event) {
                                  handleOpen();
                                }}
                                size="small"
                              >
                                Abrir
                              </Button>
                            </>
                          )}
                        </CardActions>
                      </Card>
                    ))}
                  </Grid>
                  <Typography
                    variant="h6"
                    textAlign={"center"}
                    sx={{ mt: 3, mb: 1 }}
                  >
                    © Corporación Proauto 2025
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        );
      }
    } else {
      return (
        <Container maxWidth="lg" sx={{ mt: 10 }}>
          <Paper>
            <Grid
              //container
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography
                sx={{ mt: 2, mb: 1.5 }}
                variant="h6"
                textAlign={"center"}
              >
                Cargando, espera por favor..
              </Typography>
            </Grid>
          </Paper>
        </Container>
      );
    }
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
