import {
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    MenuItem,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
    AnoType,
    MesType,
    VTestDrive,
    zonaType,
} from "../../types";
import * as XLSX from "xlsx";

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export const BitacoraTestDrivePage: React.FC<{}> = () => {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const isAuthenticated = useIsAuthenticated();
    const [esNuevo, setEsNuevo] = React.useState(false);

    useEffect(() => {
        if (account && account.name) {
            console.log("este son los atributos de msal >>>>>>", account);
            setName(account.name);
            setUsername(account.username);
            console.log(account);
            getZonas();
        } else {
            setName("");
        }
    }, [account]);

    const listaAnos: AnoType[] = [
        { id: 2024, ano: 2024 },
        { id: 2025, ano: 2025 },
    ];
    const listaMes: MesType[] = [
        { id: 1, mes: "Enero" },
        { id: 2, mes: "Febrero" },
        { id: 3, mes: "Marzo" },
        { id: 4, mes: "Abril" },
        { id: 5, mes: "Mayo" },
        { id: 6, mes: "Junio" },
        { id: 7, mes: "Julio" },
        { id: 8, mes: "Agosto" },
        { id: 9, mes: "Septiembre" },
        { id: 10, mes: "Octubre" },
        { id: 11, mes: "Noviembre" },
        { id: 12, mes: "Diciembre" },
    ];

    const fechaActual = new Date();
    const anioActual = fechaActual.getFullYear();
    const mesActual = fechaActual.getMonth() + 1;
    const [dataAno, setDataAno] = useState<AnoType[]>(listaAnos);
    const [dataMes, setDataMes] = useState<MesType[]>(listaMes);
    const [ano, setAno] = React.useState(anioActual);
    const [mes, setMes] = React.useState(mesActual);
    const [idZona, setIdZona] = React.useState(0);
    const [mostrar, setMostrar] = React.useState<boolean>(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [listaRegistros, setListaRegistros] = useState<VTestDrive[]>([]);
    const [listaZonas, setListaZonas] = useState<zonaType[]>([]);

    const columns: GridColDef[] = [
        {
            headerClassName: "theme-header",
            field: "stdFechaIni",
            headerName: "Fecha",
            editable: false,
            width: 90,
            valueGetter: ({ row }) => {
                return String(row.stdFechaIni).substring(0, 10)
            },
        },
        {
            headerClassName: "theme-header",
            field: "estado",
            headerName: "Estado",
            editable: false,
            width: 90,
        },
        {
            headerClassName: "theme-header",
            field: "agencia",
            headerName: "Agencia",
            editable: false,
            width: 175,
        },
        {
            headerClassName: "theme-header",
            field: "cedula",
            headerName: "Identificación",
            editable: false,
            width: 90,
        },
        {
            headerClassName: "theme-header",
            field: "cliente",
            headerName: "Cliente",
            editable: false,
            width: 200,
        },
        {
            headerClassName: "theme-header",
            field: "telefono",
            headerName: "Télefono",
            editable: false,
            width: 90,
        },
        {
            headerClassName: "theme-header",
            field: "correo",
            headerName: "Correo",
            editable: false,
            width: 150,
        },

        {
            headerClassName: "theme-header",
            field: "ruta",
            headerName: "Ruta",
            editable: false,
            width: 150,
        },

        {
            headerClassName: "theme-header",
            field: "esInventario",
            headerName: "Tipo Veh",
            editable: false,
            width: 125,
        },

        {
            headerClassName: "theme-header",
            field: "stdVinPlaca",
            headerName: "Vehículo",
            editable: false,
            width: 200,
        },

        {
            headerClassName: "theme-header",
            field: "stdUsuNombreResponsable",
            headerName: "Responsable",
            editable: false,
            width: 200,
            align: "center",
        },

        {
            headerClassName: "theme-header",
            field: "stdObservacion1",
            headerName: "Observaciones salida",
            editable: false,
            width: 200,
        },
        {
            headerClassName: "theme-header",
            field: "stdObservacion2",
            headerName: "Observaciones entrada",
            editable: false,
            width: 200,
          
        },
    ];



    async function getZonas() {
        try {
            await axios
                .get<zonaType[]>(
                    "https://apisatelite.azurewebsites.net/dimzona"
                )
                .then((response) => {
                    setListaZonas(response.data);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getLista(id_zona: number, _ano: number, _mes: number) {
        try {
            await axios
                .get<VTestDrive[]>(
                    "https://apisatelite.azurewebsites.net/vtestdrive/" + String(id_zona) + "/" + String(_ano) + "/" + String(_mes)
                )
                .then((response) => {
                    setListaRegistros(response.data);
                    setMostrar(true);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    function exportarExcel(data: VTestDrive[]) {
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const fileName = "Proveeduria_" + String(mes) + "/" + String(ano); // Nombre del archivo Excel

        // Mapeo de encabezados
        const headersMapping: Record<string, string> = {

            stdFechaIni: "stdFechaIni",
            stdFechaFin: "stdFechaFin",
            estado: "estado",
            agencia: "agencia",
            idCrm: "idCrm",
            cedula: "cedula",
            cliente: "cliente",
            telefono: "telefono",
            correo: "correo",
            ruta: "ruta",
            sruMetrosDistancia: "sruMetrosDistancia",
            esInventario: "esInventario",
            stdVinPlaca: "stdVinPlaca",
            stdKmInicio: "stdKmInicio",
            stdKmFin: "stdKmFin",
            stdUsuCorreoCrea: "stdUsuCorreoCrea",
            stdUsuNombreCrea: "stdUsuNombreCrea",
            stdUsuCorreoResponsable: "stdUsuCorreoResponsable",
            stdUsuNombreResponsable: "stdUsuNombreResponsable",
            stdUsuCorreoFin: "stdUsuCorreoFin",
            stdUsuNombreFin: "stdUsuNombreFin",
            stdObservacion1: "stdObservacion1",
            stdObservacion2: "stdObservacion2"
        };

        const formattedData = data.map((item) => {
            const newItem: Record<string, any> = {};
            Object.keys(item).forEach((key) => {
                if (headersMapping[key]) {
                    newItem[headersMapping[key]] = (item as any)[key];
                }
            });
            return newItem;
        });

        const ws = XLSX.utils.json_to_sheet(formattedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: fileType });
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = fileName + fileExtension;
        a.click();
        URL.revokeObjectURL(url);
    }

    if (isAuthenticated) {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Grid alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 2,
                            }}
                        >
                            <Typography variant="h5" textAlign={"center"}>
                                Bitácora de Test Drive
                            </Typography>
                        </Paper>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    mt: 1,
                                    mb: 1,
                                    width: "6%",
                                    marginLeft: "15%",
                                }}
                                style={{ display: "inline-block" }}
                            >
                                Zona:
                            </Typography>
                            <TextField
                                id="outlined-select-currency"
                                select
                                size="small"
                                margin="normal"
                                value={idZona}
                                onChange={(e) => { setIdZona(Number(e.target.value)); setMostrar(false); }}
                                sx={{ mt: 0.5, mb: 0.5, mr: "5%", width: "14%" }}
                            >
                                {listaZonas.map((option) => (
                                    <MenuItem key={option.zonId} value={option.zonId}>
                                        {option.zonNombre}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Typography
                                sx={{
                                    mt: 1,
                                    mb: 1,
                                    width: "6%",
                                }}
                                style={{ display: "inline-block" }}
                            >
                                Año:
                            </Typography>
                            <TextField
                                id="outlined-select-currency"
                                select
                                size="small"
                                margin="normal"
                                value={ano}
                                onChange={(e) => { setAno(Number(e.target.value)); setMostrar(false); }}
                                sx={{ mt: 0.5, mb: 0.5, mr: "5%", width: "14%" }}
                            >
                                {dataAno.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.ano}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Typography
                                sx={{
                                    mt: 1,
                                    mb: 1,
                                    width: "6%",
                                }}
                                style={{ display: "inline-block" }}
                            >
                                Mes:
                            </Typography>
                            <TextField
                                id="outlined-select-currency"
                                select
                                size="small"
                                margin="normal"
                                value={mes}
                                onChange={(e) => { setMes(Number(e.target.value)); setMostrar(false); }}
                                sx={{ mt: 0.5, mb: 0.5, marginRight: "10%", width: "14%" }}
                            >
                                {dataMes.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.mes}
                                    </MenuItem>
                                ))}
                            </TextField>


                            <Button
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 1,
                                    width: "12%",
                                    marginLeft: "44%",
                                }}
                                onClick={function (event) { getLista(idZona, ano, mes) }}
                            >
                                Consultar
                            </Button>
                        </Paper>
                        <Dialog
                            open={openDialog}
                            //onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Datos guardados correctamente
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    //href="/politicacomercial/lista"
                                    onClick={function (event) {
                                        setOpenDialog(false);
                                    }}
                                //autoFocus
                                >
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {mostrar ? (
                            <Paper
                                sx={{
                                    padding: "1.2em",
                                    borderRadius: "0.5em",
                                    backgroundColor: "rgb(255,255,255,0.95)",
                                    mt: 1,
                                    mb: 4,
                                }}
                            >

                                <Box
                                    sx={{
                                        "& .theme-header": {
                                            backgroundColor: "rgba(21,57,105,0.75)",
                                            color: "rgba(255,255,255,1)",
                                        },
                                    }}
                                >
                                    <DataGrid
                                        getRowId={(row) => row.stdId}
                                        sx={{ height: 500 }}
                                        editMode="row"
                                        rows={listaRegistros}
                                        columns={columns}
                                        initialState={{
                                            pagination: { paginationModel: { pageSize: 20 } },
                                        }}
                                        pageSizeOptions={[10, 20, 30]}
                                        slotProps={{
                                            pagination: {
                                                labelRowsPerPage: "Registros por página:",
                                            },
                                        }}
                                        localeText={{
                                            noRowsLabel: "No se ha encontrado datos.",
                                            noResultsOverlayLabel:
                                                "No se ha encontrado ningún resultado",
                                            toolbarColumns: "Columnas",
                                            toolbarColumnsLabel: "Seleccionar columnas",
                                            toolbarFilters: "Filtros",
                                            toolbarFiltersLabel: "Ver filtros",
                                            toolbarFiltersTooltipHide: "Quitar filtros",
                                            toolbarFiltersTooltipShow: "Ver filtros",
                                        }}
                                    />
                                </Box>
                                <Button
                                    variant="contained"
                                    sx={{ ml: "42%", width: "16%", mt: 2 }}
                                    onClick={(e) => {
                                        exportarExcel(listaRegistros)
                                    }}>
                                    Exportar XLSX
                                </Button>
                            </Paper>
                        ) : (
                            <></>
                        )}
                    </Grid>
                </Grid>
            </Container>
        );
    } else {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Paper>
                    <Grid
                        //container
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Typography
                            sx={{ mt: 2, mb: 1.5 }}
                            variant="h6"
                            textAlign={"center"}
                        >
                            Acceso denegado
                        </Typography>
                    </Grid>
                </Paper>
            </Container>
        );
    }
};
