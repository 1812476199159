import {
    Box,
    Button,
    Container,
    Grid,
    MenuItem,
    Modal,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import {
    SateliteProveeduriaItemType,
} from "../../types";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import styled from "@emotion/styled";



const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});


export const ProductosProveduriaPage: React.FC<{}> = () => {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (account && account.name) {
            console.log("este son los atributos de msal >>>>>>", account);
            setName(account.name);
            setUsername(account.username);
            console.log(account);
            getListaProductos()
        } else {
            setName("");
        }
    }, [account]);
    const [listaItems, setListaItems] = useState<SateliteProveeduriaItemType[]>([]);
    const [agencia, setAgencia] = React.useState(0);
    const [esNuevo, setEsNuevo] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [item, setItem] = useState<SateliteProveeduriaItemType>({
        priId: 0,
        priNombre: "",
        priPvp: 0,
        priPresentacion: "",
        priObservaciones: "",
    });

    const handleChangeItem = (e: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            [e.target.name]: e.target.value,
        });

    };

    function seteoItem(
        priId: number,
        priNombre: string,
        priPvp: number,
        priPresentacion: string,
        priObservaciones: string,
    ) {

        if (esNuevo) {
            const nuevoRegistro: SateliteProveeduriaItemType = {
                priNombre: priNombre,
                priPvp: priPvp,
                priPresentacion: priPresentacion,
                priObservaciones: priObservaciones,
            };
            console.log(
                "ruta a guardar >> ",
                JSON.stringify(nuevoRegistro, null, 4)
            );
            postGuardarRegistro(nuevoRegistro);
        } else {
            const nuevoRegistro: SateliteProveeduriaItemType = {
                priId: priId,
                priNombre: priNombre,
                priPvp: priPvp,
                priPresentacion: priPresentacion,
                priObservaciones: priObservaciones,
            };
            console.log(
                "usuario a guardar >> ",
                JSON.stringify(nuevoRegistro, null, 4)
            );
            postGuardarRegistro(nuevoRegistro);
        }
        }

    async function postGuardarRegistro(registro: SateliteProveeduriaItemType) {
        const headers = {
            "Content-Type": "application/json",
        };
        try {
            axios
                .post("https://apisatelite.azurewebsites.net/sateliteproveeduriaitem", registro, {
                    headers: headers,
                })
                .then((response) => {
                    console.log("id guardado es >> ", response);
                    getListaProductos()

                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getItemEdit(_id: number) {
        try {
            await axios
                .get<SateliteProveeduriaItemType>(
                    "https://apisatelite.azurewebsites.net/sateliteproveeduriaitem/" +
                    String(_id)
                )
                .then((response) => {
                    console.log("mi ruta seleccionada es >>", response.data);
                    setItem(response.data);
                    setOpenDialog(true);
                    setEsNuevo(false);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });

        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getListaProductos() {
        try {
            await axios
                .get<SateliteProveeduriaItemType[]>(
                    "https://apisatelite.azurewebsites.net/sateliteproveeduriaitem/consultar"
                )
                .then((response) => {
                    console.log("mi user es >>>>>>", response.data);
                    setListaItems(response.data);
                    setOpenDialog(false);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });

        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    const columns: GridColDef[] = [
        {
            headerClassName: "theme-header",
            field: "priNombre",
            headerName: "Descripcion",
            editable: false,
            width: 300,
        },
        {
            headerClassName: "theme-header",
            field: "priPresentacion",
            headerName: "Presentación",
            editable: false,
            width: 125,
        },
        {
            headerClassName: "theme-header",
            field: "priPvp",
            headerName: "PVP",
            editable: false,
            width: 100,
            align: "right", 
            valueGetter: ({ row }) => {
                return formatter.format(Number(row.priPvp)).replace("$", "$  ");
            },

        },
        {
            headerClassName: "theme-header",
            field: "priObservaciones",
            headerName: "Observaciones",
            editable: false,
            width: 250,
        },


        {
            headerClassName: "theme-header",
            field: "action",
            headerName: "Detalle",
            sortable: false,
            width: 120,
            renderCell: (params) => {
                //{params.row.Id}
                return (
                    <Button
                        onClick={function (event) {
                            getItemEdit(params.row.priId);
                        }}
                    >
                        Abrir
                    </Button>
                );
            },
        },
    ];

    if (isAuthenticated) {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Grid alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 2,
                            }}
                        >
                            <Typography variant="h5" textAlign={"center"}>
                                Items de Proveeduria
                            </Typography>
                        </Paper>

                        {/* insertar validacion para mostrar */}
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 1,
                                mb: 4,
                            }}
                        >

                            <>
                                <Box
                                    sx={{
                                        "& .theme-header": {
                                            backgroundColor: "rgba(21,57,105,0.75)",
                                            color: "rgba(255,255,255,1)",
                                        },
                                    }}
                                >
                                    <DataGrid
                                        getRowId={(row) => row.priId}
                                        sx={{ height: 650, ml:"10%", mr:"10%" }}
                                        editMode="row"
                                        rows={listaItems}
                                        columns={columns}
                                        disableColumnFilter
                                        disableColumnSelector
                                        disableDensitySelector
                                        disableRowSelectionOnClick
                                        slots={{ toolbar: GridToolbarQuickFilter }}
                                        slotProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                            },
                                            pagination: {
                                                labelRowsPerPage: "Registros por página:",
                                            },
                                        }}
                                        initialState={{
                                            pagination: { paginationModel: { pageSize: 10 } },
                                        }}
                                        pageSizeOptions={[10, 25, 50]}
                                        localeText={{
                                            footerTotalRows: false,
                                            noRowsLabel: "No se ha encontrado datos.",
                                            noResultsOverlayLabel:
                                                "No se ha encontrado ningún resultado",
                                            toolbarColumns: "Columnas",
                                            toolbarColumnsLabel: "Seleccionar columnas",
                                            toolbarFilters: "Filtros",
                                            toolbarFiltersLabel: "Ver filtros",
                                            toolbarFiltersTooltipHide: "Quitar filtros",
                                            toolbarFiltersTooltipShow: "Ver filtros",
                                            toolbarQuickFilterPlaceholder: "Buscar",
                                        }}
                                    />
                                </Box>
                                <Button variant="contained" sx={{ ml: "80%", width: "12%", mt: 2 }} onClick={(e) => {
                                    setEsNuevo(true); setOpenDialog(true); setAgencia(0); setItem({
                                        ...item,
                                        priId: 0,
                                        priNombre: "",
                                        priPvp: 0,
                                        priObservaciones: "",
                                    });
                                }}>Nuevo</Button>
                            </>
                        </Paper>

                        {/* modal */}
                        <Modal
                            open={openDialog}
                            onClose={function (event) {
                                setOpenDialog(false);
                            }}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={styleModal}>
                                {/* Datos */}
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "20%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Descripción:
                                </Typography>
                                <TextField
                                    name="priNombre"
                                    size="small"
                                    margin="normal"
                                    value={item.priNombre}
                                    onChange={handleChangeItem}
                                    sx={{ mt: 1, mb: 1, width: "80%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "20%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    PVP:
                                </Typography>
                                <TextField
                                    name="priPvp"
                                    size="small"
                                    margin="normal"
                                    value={item.priPvp}
                                    onChange={handleChangeItem}
                                    sx={{ mt: 1, mb: 1, width: "80%" }}
                                />
                                 <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "20%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Presentación:
                                </Typography>
                                <TextField
                                    name="priPresentacion"
                                    size="small"
                                    margin="normal"
                                    value={item.priPresentacion}
                                    onChange={handleChangeItem}
                                    sx={{ mt: 1, mb: 1, width: "80%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "20%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Observaciones:
                                </Typography>
                                <TextField
                                    name="priObservaciones"
                                    size="small"
                                    margin="normal"
                                    value={item.priObservaciones}
                                    onChange={handleChangeItem}
                                    sx={{ mt: 1, mb: 1, width: "80%" }}
                                />

                                <Button
                                    variant="contained"
                                    sx={{
                                        mt: 3,
                                        mb: 1,
                                        marginLeft: "40%",
                                        width: "20%",
                                        backgroundColor: "rgba(21,57,105,0.75)",
                                    }}
                                    onClick={function (event) {
                                        seteoItem(Number(item.priId), item.priNombre, item.priPvp, item.priPresentacion, item.priObservaciones)
                                    }}
                                >
                                    Guardar
                                </Button>

                            </Box>
                        </Modal>
                    </Grid>
                </Grid>
            </Container>
        );
    } else {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Paper>
                    <Grid
                        //container
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Typography
                            sx={{ mt: 2, mb: 1.5 }}
                            variant="h6"
                            textAlign={"center"}
                        >
                            Acceso denegado
                        </Typography>
                    </Grid>
                </Paper>
            </Container>
        );
    }
};
