import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Paper,
    TextField,
    Typography,
    DialogContentText,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Modal,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, useMemo } from "react";
import { Box } from "@mui/system";
import { useGeolocation } from "../components/location";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});
const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export const DemoPage: React.FC<{}> = () => {
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [esNuevo, setEsNuevo] = useState<boolean>(false);
    const [mostrar, setMostrar] = useState<boolean>(false);
    const [parametroBusca, setParametroBusca] = useState<string>("");
    const [listaClientes, setListaClientes] = useState<DatosCliente[]>([]);
    const userAgent = navigator.userAgent;
    const { location, error } = useGeolocation();
    const mobileKeywords = [
        "Mobile",
        "Android",
        "iPhone",
        "iPad",
        "Windows Phone",
    ];
    const checkDeviceType = () => {
        setIsMobile(
            mobileKeywords.some((keyword) => userAgent.includes(keyword))
        );
    };

    useEffect(() => {
        checkDeviceType();
    }, []);


    type DatosCliente = {
        cliId?: number;
        cliIdentificacion: string;
        cliApellidos: string;
        cliNombres: string;
        cliSector: string;
        cliCategoria: string;
        cliRegion: string;
        cliCiudad: string;
        cliDireccion: string;
        cliLugar: string;
        cliOficina: string;
        cliTelefono: string;
        cliLatitud: string;
        cliLongitud: string;
        cliSubespecialidad: string;
        cliCorreo: string;
        cliDiasAtencion: string;
        cliHorarioAtencion: string;
        cliCumpleanos: Date;
        cliColor: string;
        cliComida: string;
        cliEquipoFutbol: string;
        cliPasatiempos: string;
        cliEstadoCivil: string;
        cliHijos: number;
        cliMaestriasCursos: string;
        cliProyectos: string;
        cliStatusMarca: string;
        cliObservaciones: string;
    }

    type DatosCPedido = {
        cpeId: number;
        cpeDia: number;
        cpeMes: number;
        cpeAno: number;
        cpeHora: string,
        usuId: number;
        cliID: number;
        cpeobservaciones: number;
    }

    type DatosDPedido = {
        dpeid: number;
        cpeId: number;
        proId: number;
        dpeCantidad: number;
        dpeObservacion: string;
    }

    type DatosProducto = {
        proId: number;
        proDescripcion: string;
        proPvp: number;
    }

    const [registroCliente, setRegistroCliente] =
        useState<DatosCliente>({
            cliId: 0,
            cliIdentificacion: "",
            cliApellidos: "",
            cliNombres: "",
            cliSector: "",
            cliCategoria: "",
            cliRegion: "",
            cliCiudad: "",
            cliDireccion: "",
            cliLugar: "",
            cliOficina: "",
            cliTelefono: "",
            cliLatitud: "",
            cliLongitud: "",
            cliSubespecialidad: "",
            cliCorreo: "",
            cliDiasAtencion: "",
            cliHorarioAtencion: "",
            cliCumpleanos: new Date(),
            cliColor: "",
            cliComida: "",
            cliEquipoFutbol: "",
            cliPasatiempos: "",
            cliEstadoCivil: "",
            cliHijos: 0,
            cliMaestriasCursos: "",
            cliProyectos: "",
            cliStatusMarca: "",
            cliObservaciones: "",
        });

    const handleChangeDebito = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRegistroCliente({
            ...registroCliente,
            [event.target.name]: event.target.value,
        });
    };

    function seteoItem(
        newregistro: DatosCliente
    ) {

        if (esNuevo) {
            const nuevoRegistro: DatosCliente = {
                cliIdentificacion: newregistro.cliIdentificacion,
                cliApellidos: newregistro.cliApellidos,
                cliNombres: newregistro.cliNombres,
                cliSector: newregistro.cliSector,
                cliCategoria: newregistro.cliCategoria,
                cliRegion: newregistro.cliRegion,
                cliCiudad: newregistro.cliCiudad,
                cliDireccion: newregistro.cliDireccion,
                cliLugar: newregistro.cliLugar,
                cliOficina: newregistro.cliOficina,
                cliTelefono: newregistro.cliTelefono,
                cliLatitud: newregistro.cliLatitud,
                cliLongitud: newregistro.cliLongitud,
                cliSubespecialidad: newregistro.cliSubespecialidad,
                cliCorreo: newregistro.cliCorreo,
                cliDiasAtencion: newregistro.cliDiasAtencion,
                cliHorarioAtencion: newregistro.cliHorarioAtencion,
                cliCumpleanos: newregistro.cliCumpleanos,
                cliColor: newregistro.cliColor,
                cliComida: newregistro.cliComida,
                cliEquipoFutbol: newregistro.cliEquipoFutbol,
                cliPasatiempos: newregistro.cliPasatiempos,
                cliEstadoCivil: newregistro.cliEstadoCivil,
                cliHijos: newregistro.cliHijos,
                cliMaestriasCursos: newregistro.cliMaestriasCursos,
                cliProyectos: newregistro.cliProyectos,
                cliStatusMarca: newregistro.cliStatusMarca,
                cliObservaciones: newregistro.cliObservaciones
            };
            console.log(
                "ruta a guardar >> ",
                JSON.stringify(nuevoRegistro, null, 4)
            );
            postGuardarRegistro(nuevoRegistro);
        } else {
            const nuevoRegistro: DatosCliente = {
                cliId: newregistro.cliId,
                cliIdentificacion: newregistro.cliIdentificacion,
                cliApellidos: newregistro.cliApellidos,
                cliNombres: newregistro.cliNombres,
                cliSector: newregistro.cliSector,
                cliCategoria: newregistro.cliCategoria,
                cliRegion: newregistro.cliRegion,
                cliCiudad: newregistro.cliCiudad,
                cliDireccion: newregistro.cliDireccion,
                cliLugar: newregistro.cliLugar,
                cliOficina: newregistro.cliOficina,
                cliTelefono: newregistro.cliTelefono,
                cliLatitud: newregistro.cliLatitud,
                cliLongitud: newregistro.cliLongitud,
                cliSubespecialidad: newregistro.cliSubespecialidad,
                cliCorreo: newregistro.cliCorreo,
                cliDiasAtencion: newregistro.cliDiasAtencion,
                cliHorarioAtencion: newregistro.cliHorarioAtencion,
                cliCumpleanos: newregistro.cliCumpleanos,
                cliColor: newregistro.cliColor,
                cliComida: newregistro.cliComida,
                cliEquipoFutbol: newregistro.cliEquipoFutbol,
                cliPasatiempos: newregistro.cliPasatiempos,
                cliEstadoCivil: newregistro.cliEstadoCivil,
                cliHijos: newregistro.cliHijos,
                cliMaestriasCursos: newregistro.cliMaestriasCursos,
                cliProyectos: newregistro.cliProyectos,
                cliStatusMarca: newregistro.cliStatusMarca,
                cliObservaciones: newregistro.cliObservaciones
            };
            console.log(
                "usuario a guardar >> ",
                JSON.stringify(nuevoRegistro, null, 4)
            );
            postGuardarRegistro(nuevoRegistro);
        }
    }

    async function postGuardarRegistro(registro: DatosCliente) {
        const headers = {
            "Content-Type": "application/json",
        };
        try {
            axios
                .post("https://apisatelite.azurewebsites.net/satelitecli", registro, {
                    headers: headers,
                })
                .then((response) => {
                    setOpenDialog(true);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getListaCliente(parametro: string) {
        try {
            await axios
                .get<DatosCliente[]>(
                    "https://apisatelite.azurewebsites.net/satelitecli/buscar/" + parametro
                )
                .then((response) => {
                    setListaClientes(response.data)
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 10 }}>
            <Grid alignItems={"center"} justifyContent={"center"}>
                <Grid item>
                    <Paper
                        sx={{
                            padding: "1.2em",
                            borderRadius: "0.5em",
                            backgroundColor: "rgb(255,255,255,0.95)",
                            mt: 2,
                        }}
                    >
                        <Typography variant="h5" textAlign={"center"}>
                            SmartBalance SAS
                        </Typography>
                    </Paper>
                    <Paper
                        sx={{
                            padding: "1.2em",
                            borderRadius: "0.5em",
                            backgroundColor: "rgb(255,255,255,0.95)",
                            mt: 1,
                        }}
                    >
                        <Button sx={{ width: "50%", mt: 1, mb: 1 }} onClick={() => {
                            setMostrar(true);
                            setEsNuevo(true); 
                            setRegistroCliente({
                                cliId: 0,
                                cliIdentificacion: "",
                                cliApellidos: "",
                                cliNombres: "",
                                cliSector: "",
                                cliCategoria: "",
                                cliRegion: "",
                                cliCiudad: "",
                                cliDireccion: "",
                                cliLugar: "",
                                cliOficina: "",
                                cliTelefono: "",
                                cliLatitud: "",
                                cliLongitud: "",
                                cliSubespecialidad: "",
                                cliCorreo: "",
                                cliDiasAtencion: "",
                                cliHorarioAtencion: "",
                                cliCumpleanos: new Date(),
                                cliColor: "",
                                cliComida: "",
                                cliEquipoFutbol: "",
                                cliPasatiempos: "",
                                cliEstadoCivil: "",
                                cliHijos: 0,
                                cliMaestriasCursos: "",
                                cliProyectos: "",
                                cliStatusMarca: "",
                                cliObservaciones: "",
                            })
                        }}>Nuevo</Button>
                        <Button sx={{ width: "50%", mt: 1, mb: 1 }} onClick={() => {setOpenModal(true); setListaClientes([]);}}>Buscar</Button>
                        <Divider sx={{ ml: "3%", mr: "3%", mt: 1, mb: 1 }} />

                        {mostrar ? <>

                            <Accordion sx={{ mt: 2, mb: 2 }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">Información general</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Identificación:
                                    </Typography>
                                    <TextField
                                        name="cliIdentificacion"
                                        value={registroCliente.cliIdentificacion}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Apellidos:
                                    </Typography>
                                    <TextField
                                        name="cliApellidos"
                                        value={registroCliente.cliApellidos}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Nombres:
                                    </Typography>
                                    <TextField
                                        name="cliNombres"
                                        value={registroCliente.cliNombres}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Sector:
                                    </Typography>
                                    <TextField
                                        name="cliSector"
                                        value={registroCliente.cliSector}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Región:
                                    </Typography>
                                    <TextField
                                        name="cliRegion"
                                        value={registroCliente.cliRegion}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Dirección:
                                    </Typography>
                                    <TextField
                                        name="cliDireccion"
                                        value={registroCliente.cliDireccion}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        # Oficina:
                                    </Typography>
                                    <TextField
                                        name="cliOficina"
                                        value={registroCliente.cliOficina}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Teléfono:
                                    </Typography>
                                    <TextField
                                        name="cliTelefono"
                                        value={registroCliente.cliTelefono}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Categoria:
                                    </Typography>
                                    <TextField
                                        name="cliCategoria"
                                        value={registroCliente.cliCategoria}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Ciudad:
                                    </Typography>
                                    <TextField
                                        name="cliCiudad"
                                        value={registroCliente.cliCiudad}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Lugar:
                                    </Typography>
                                    <TextField
                                        name="cliLugar"
                                        value={registroCliente.cliLugar}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />
                                    {error && <p style={{ color: 'red' }}>Error: {error}</p>}
                                    {location ? (
                                        <>
                                            <Typography
                                                sx={{
                                                    mt: 2,
                                                    mb: 1,
                                                    width: "28%",
                                                    ml: "2%"
                                                }}
                                                style={{ display: "inline-block" }}
                                            >
                                                GPS:
                                            </Typography>
                                            <TextField
                                                size="small"
                                                margin="normal"
                                                name=""
                                                value={location.latitude + "," + location.longitude}
                                                sx={{ mt: 1, mb: 1, width: "68%" }}
                                            />
                                        </>
                                    ) : (
                                        !error && <p>Obteniendo ubicación...</p>
                                    )}



                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Días atención:
                                    </Typography>
                                    <TextField
                                        name="cliDiasAtencion"
                                        value={registroCliente.cliDiasAtencion}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Horario:
                                    </Typography>
                                    <TextField
                                        name="cliHorarioAtencion"
                                        value={registroCliente.cliHorarioAtencion}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />

                                </AccordionDetails>
                            </Accordion>


                            <Accordion sx={{ mt: 2, mb: 2 }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">Intereses</Typography>
                                </AccordionSummary>
                                <AccordionDetails>


                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        F.Cumpleaños:
                                    </Typography>
                                    <TextField
                                        name="cliCumpleanos"
                                        value={registroCliente.cliCumpleanos}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        type="date"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Color favorito:
                                    </Typography>
                                    <TextField
                                        name="cliColor"
                                        value={registroCliente.cliColor}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Comida favorita:
                                    </Typography>
                                    <TextField
                                        name="cliComida"
                                        value={registroCliente.cliComida}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Equipo de futbol:
                                    </Typography>
                                    <TextField
                                        name="cliEquipoFutbol"
                                        value={registroCliente.cliEquipoFutbol}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Hobbies:
                                    </Typography>
                                    <TextField
                                        name="cliPasatiempos"
                                        value={registroCliente.cliPasatiempos}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Estado civil:
                                    </Typography>
                                    <TextField
                                        name="cliEstadoCivil"
                                        value={registroCliente.cliEstadoCivil}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        N° de hijos:
                                    </Typography>
                                    <TextField
                                        name="cliHijos"
                                        value={registroCliente.cliHijos}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        type="number"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />

                                </AccordionDetails>
                            </Accordion>


                            <Accordion sx={{ mt: 2, mb: 2 }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">Formación profesional</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Maestrias/cursos:
                                    </Typography>
                                    <TextField
                                        name="cliMaestriasCursos"
                                        value={registroCliente.cliMaestriasCursos}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Proyectos/planes:
                                    </Typography>
                                    <TextField
                                        name="cliProyectos"
                                        value={registroCliente.cliProyectos}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Status de marca:
                                    </Typography>
                                    <TextField
                                        name="cliStatusMarca"
                                        value={registroCliente.cliStatusMarca}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        Observaciones:
                                    </Typography>
                                    <TextField
                                        name="cliObservaciones"
                                        value={registroCliente.cliObservaciones}
                                        onChange={handleChangeDebito}
                                        style={{ backgroundColor: "rgb(255,255,255)" }}
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />
                                </AccordionDetails>
                            </Accordion>
                            <Button
                                onClick={() => seteoItem(registroCliente)}
                                sx={{ mt: 2, mb: 2, ml: "30%", width: "40%" }}
                                variant="contained">
                                Guardar
                            </Button>
                        </> : <></>}

                        <Modal
                            open={openModal}
                            onClose={() => { setOpenModal(false) }}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={styleModal}>
                                <Typography
                                    sx={{
                                        mt: 1,
                                        mb: 1,
                                        width: "20%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Buscar:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    onChange={(e) => { setParametroBusca(e.target.value) }}
                                    sx={{ mt: 1, mb: 1, width: "60%" }}
                                />
                                <Button onClick={() => getListaCliente(parametroBusca)} sx={{ mt: 1, mb: 1, width: "15%" }}>
                                🔎
                                </Button>

                                <TableContainer
                                    component={Paper}
                                    sx={{ maxHeight: 400, overflow: "auto" }} // Establece una altura máxima y habilita el scroll
                                >
                                    <Table stickyHeader>
                                        {/* Encabezado */}
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><strong>Cédula</strong></TableCell>
                                                <TableCell><strong>Nombres</strong></TableCell>
                                                <TableCell><strong>Seleccionar</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {/* Cuerpo */}
                                        <TableBody>
                                            {listaClientes.map((row) => (
                                                <TableRow key={row.cliId}>
                                                    <TableCell>{row.cliIdentificacion}</TableCell>
                                                    <TableCell>{row.cliApellidos}{" "}{row.cliNombres}</TableCell>
                                                    <TableCell align="center">
                                                        <Button
                                                            sx={{ mt: 2, mb: 2, width: "70%" }}
                                                            onClick={(e) => {
                                                                setRegistroCliente(row);
                                                                setEsNuevo(false);
                                                                setMostrar(true);
                                                                setOpenModal(false);
                                                            }}>
                                                            ✔
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </Box>
                        </Modal>

                        <Dialog
                            open={openDialog}
                            onClose={function (event) {
                                setOpenDialog(false);
                            }}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Cliente guardado correctamente!
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={ () => { setOpenDialog(false); setMostrar(false);} }
                                >
                                    Ok
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
