import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Paper,
    TextField,
    Typography,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    IconButton,
    DialogContentText,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    MenuItem,
    Modal,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, useMemo } from "react";
import { Box } from "@mui/system";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { useGeolocation } from "../components/location";
import DeleteIcon from '@mui/icons-material/Delete';
import { AnoType, MesType } from "../types";

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export const DemoPedidoPage: React.FC<{}> = () => {
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [parametroBusca, setParametroBusca] = useState<string>("");
    const [listaClientes, setListaClientes] = useState<DatosCliente[]>([]);
    const [mostrar, setMostrar] = useState<boolean>(false);
    const [iniciar, setIniciar] = useState<boolean>(false);
    const userAgent = navigator.userAgent;
    const mobileKeywords = [
        "Mobile",
        "Android",
        "iPhone",
        "iPad",
        "Windows Phone",
    ];
    const checkDeviceType = () => {
        setIsMobile(
            mobileKeywords.some((keyword) => userAgent.includes(keyword))
        );
    };

    useEffect(() => {
        checkDeviceType();
    }, []);

    const [buscarId, setBuscarId] = React.useState("");
    const dataBuscarId = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBuscarId(e.target.value);
    };

    async function getListaCliente(parametro: string) {
        try {
            await axios
                .get<DatosCliente[]>(
                    "https://apisatelite.azurewebsites.net/satelitecli/buscar/" + parametro
                )
                .then((response) => {
                    setListaClientes(response.data)
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }


    const [productoSelect, setProductoSelect] = React.useState("");
    const listaProductos: MesType[] = [
        { id: 1, mes: "NEXAM" },
        { id: 2, mes: "AZA 20" },
        { id: 3, mes: "AKUN AHA" },
        { id: 4, mes: "AKUN NIGHT" },
        { id: 5, mes: "AKUN FACE & BODY WASH" },
        { id: 6, mes: "AZA REC" },
        { id: 7, mes: "DUTAXIL" },
        { id: 8, mes: "NEXAM CR" },
        { id: 9, mes: "SPOT DAY AKUN" },
        { id: 10, mes: "KIARA" },
        { id: 11, mes: "INTIMO" },
        { id: 12, mes: "DUTAXIL ESPUMA" },
        { id: 13, mes: "DUTAXIL SHAMPOO" },
    ];



    type DatosCliente = {
        cliId?: number;
        cliIdentificacion: string;
        cliApellidos: string;
        cliNombres: string;
        cliSector: string;
        cliCategoria: string;
        cliRegion: string;
        cliCiudad: string;
        cliDireccion: string;
        cliLugar: string;
        cliOficina: string;
        cliTelefono: string;
        cliLatitud: string;
        cliLongitud: string;
        cliSubespecialidad: string;
        cliCorreo: string;
        cliDiasAtencion: string;
        cliHorarioAtencion: string;
        cliCumpleanos: Date;
        cliColor: string;
        cliComida: string;
        cliEquipoFutbol: string;
        cliPasatiempos: string;
        cliEstadoCivil: string;
        cliHijos: number;
        cliMaestriasCursos: string;
        cliProyectos: string;
        cliStatusMarca: string;
        cliObservaciones: string;
    }

    type DatosCPedido = {
        cpeId: number;
        cpeDia: number;
        cpeMes: number;
        cpeAno: number;
        cpeHora: string,
        usuId: number;
        cliID: number;
        cpeobservaciones: number;
    }

    type DatosDPedido = {
        dpeid: number;
        cpeId: number;
        proId: number;
        dpeCantidad: number;
        dpeObservacion: string;
    }

    type DatosProducto = {
        proId: number;
        proDescripcion: string;
        proPvp: number;
    }

    const [dataEjemploDetalle, setDataEjemploDetalle] =
        useState<DatosDPedido>({
            dpeid: 0,
            cpeId: 0,
            proId: 0,
            dpeCantidad: 0,
            dpeObservacion: ""
        });

    const [registroCliente, setRegistroCliente] =
        useState<DatosCliente>({
            cliId: 0,
            cliIdentificacion: "",
            cliApellidos: "",
            cliNombres: "",
            cliSector: "",
            cliCategoria: "",
            cliRegion: "",
            cliCiudad: "",
            cliDireccion: "",
            cliLugar: "",
            cliOficina: "",
            cliTelefono: "",
            cliLatitud: "",
            cliLongitud: "",
            cliSubespecialidad: "",
            cliCorreo: "",
            cliDiasAtencion: "",
            cliHorarioAtencion: "",
            cliCumpleanos: new Date(),
            cliColor: "",
            cliComida: "",
            cliEquipoFutbol: "",
            cliPasatiempos: "",
            cliEstadoCivil: "",
            cliHijos: 0,
            cliMaestriasCursos: "",
            cliProyectos: "",
            cliStatusMarca: "",
            cliObservaciones: "",
        });

    const handleChangeDebito = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRegistroCliente({
            ...registroCliente,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeDetalle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDataEjemploDetalle({
            ...dataEjemploDetalle,
            [event.target.name]: event.target.value,
        });
    };

    const { location, error } = useGeolocation();


    const [registroPedido, setRegistroPedido] = useState<Omit<DatosDPedido, 'dpeObservacion'>>({
        dpeid: 0,
        cpeId: 0,
        proId: 0,
        dpeCantidad: 0,

    });

    const [listado, setListado] = useState<DatosDPedido[]>([]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setRegistroPedido({
            ...registroPedido,
            [name]: name === 'dpeCantidad' || name === 'cpeId' || name === 'proId' ? parseInt(value) || 0 : value,
        });
    };

    const handleAdd = () => {
        const newEntry: DatosDPedido = {
            dpeObservacion: productoSelect,
            //dpeObservacion: productoSelect,
            ...registroPedido,
        };
        setListado([...listado, newEntry]);
        setRegistroPedido({ dpeid: 0, cpeId: 0, proId: 0, dpeCantidad: 0 });
    };

    const handleDelete = (id: number) => {
        setListado(listado.filter((item) => item.dpeid !== id));
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 10 }}>
            <Grid alignItems={"center"} justifyContent={"center"}>
                <Grid item>
                    <Paper
                        sx={{
                            padding: "1.2em",
                            borderRadius: "0.5em",
                            backgroundColor: "rgb(255,255,255,0.95)",
                            mt: 2,
                        }}
                    >
                        <Typography variant="h5" textAlign={"center"}>
                            SmartBalance SAS
                        </Typography>
                    </Paper>
                    <Paper
                        sx={{
                            padding: "1.2em",
                            borderRadius: "0.5em",
                            backgroundColor: "rgb(255,255,255,0.95)",
                            mt: 1,
                        }}
                    >

                        <Button sx={{ width: "100%", mt: 1, mb: 1 }} onClick={() => setOpenModal(true)}>Buscar cliente</Button>
                        <Divider sx={{ ml: "3%", mr: "3%", mt: 1, mb: 1 }} />

                        {mostrar ? <>

                            <Typography sx={{ ml: "2%", mt: 2, mb: 3 }} textAlign={"left"} variant="h6" >
                                <strong>Datos de cliente</strong>
                            </Typography>
                            <Typography
                                sx={{
                                    mt: 2,
                                    mb: 1,
                                    width: "28%",
                                    ml: "2%"
                                }}
                                style={{ display: "inline-block" }}
                            >
                                Identificación:
                            </Typography>
                            <TextField
                                name="cliIdentificacion"
                                value={registroCliente.cliIdentificacion}
                                onChange={handleChangeDebito}
                                style={{ backgroundColor: "rgb(255,255,255)" }}
                                size="small"
                                margin="normal"
                                sx={{ mt: 1, mb: 1, width: "68%" }}
                            />
                            <Typography
                                sx={{
                                    mt: 2,
                                    mb: 1,
                                    width: "28%",
                                    ml: "2%"
                                }}
                                style={{ display: "inline-block" }}
                            >
                                Apellidos:
                            </Typography>
                            <TextField
                                name="cliApellidos"
                                value={registroCliente.cliApellidos}
                                onChange={handleChangeDebito}
                                style={{ backgroundColor: "rgb(255,255,255)" }}
                                size="small"
                                margin="normal"
                                sx={{ mt: 1, mb: 1, width: "68%" }}
                            />
                            <Typography
                                sx={{
                                    mt: 2,
                                    mb: 1,
                                    width: "28%",
                                    ml: "2%"
                                }}
                                style={{ display: "inline-block" }}
                            >
                                Nombres:
                            </Typography>
                            <TextField
                                name="cliCiudad"
                                value={registroCliente.cliCiudad}
                                onChange={handleChangeDebito}
                                style={{ backgroundColor: "rgb(255,255,255)" }}
                                size="small"
                                margin="normal"
                                sx={{ mt: 1, mb: 1, width: "68%" }}
                            />
                            {location ? (
                                <>
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            mb: 1,
                                            width: "28%",
                                            ml: "2%"
                                        }}
                                        style={{ display: "inline-block" }}
                                    >
                                        GPS:
                                    </Typography>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        name=""
                                        value={location.latitude + "," + location.longitude}
                                        sx={{ mt: 1, mb: 1, width: "68%" }}
                                    />
                                </>
                            ) : (
                                !error && <p>Obteniendo ubicación...</p>
                            )}
                            <Button
                                disabled={iniciar}
                                sx={{ width: "70%", ml: "15%" }}
                                onClick={() => { setIniciar(true) }}
                                variant="contained">
                                Iniciar visita
                            </Button>


                            {iniciar ? <>
                                <Typography sx={{ ml: "2%", mt: 2, mb: 3 }} textAlign={"left"} variant="h6" >
                                    <strong>Muestras</strong>
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "28%",
                                        ml: "2%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Producto:
                                </Typography>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    size="small"
                                    margin="normal"
                                    value={productoSelect}
                                    onChange={(e) => { setProductoSelect(e.target.value) }}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    sx={{ mt: 1, mb: 1, width: "68%" }}
                                    SelectProps={{
                                        MenuProps: {
                                            disableScrollLock: true,
                                            style: {
                                                maxHeight: 300,
                                            },
                                        },
                                    }}
                                >
                                    {listaProductos.map((option) => (
                                        <MenuItem key={option.mes} value={option.mes}>
                                            {option.mes}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "28%",
                                        ml: "2%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Cantidad:
                                </Typography>
                                <TextField
                                    name="dpeCantidad"
                                    value={dataEjemploDetalle.dpeCantidad}
                                    onChange={handleChangeDetalle}
                                    type="number"
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    size="small"
                                    margin="normal"
                                    sx={{ mt: 1, mb: 1, width: "68%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "28%",
                                        ml: "2%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Observaciones:
                                </Typography>
                                <TextField
                                    name="dpeObservacion"
                                    value={dataEjemploDetalle.dpeObservacion}
                                    onChange={handleChangeDetalle}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    size="small"
                                    margin="normal"
                                    sx={{ mt: 1, mb: 1, width: "68%" }}
                                />
                                <Button sx={{ width: "40%", ml: "60%" }} color="primary" onClick={handleAdd}>
                                    Agregar al Listado
                                </Button>

                                <Typography variant="h5" gutterBottom>
                                    Listado de Productos
                                </Typography>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Producto</TableCell>
                                                <TableCell>Cantidad</TableCell>
                                                {/* <TableCell>Observación</TableCell> */}
                                                <TableCell>Acciones</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {listado.map((item) => (
                                                <TableRow key={item.dpeid}>
                                                    <TableCell>{item.dpeObservacion}</TableCell>
                                                    <TableCell>{item.dpeCantidad}</TableCell>
                                                    {/* <TableCell>{item.dpeObservacion}</TableCell> */}
                                                    <TableCell>
                                                        <IconButton onClick={() => handleDelete(item.dpeid)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>





                                <Divider

                                    sx={{ ml: "3%", mr: "3%", mt: 3, mb: 1 }} />

                                <Button
                                    sx={{ width: "70%", ml: "15%" }} variant="contained"> Guardar y terminar visita
                                </Button>
                            </> : <></>}


                        </> : <></>}



                        <Modal
                            open={openModal}
                            onClose={() => { setOpenModal(false) }}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={styleModal}>
                                <Typography
                                    sx={{
                                        mt: 1,
                                        mb: 1,
                                        width: "20%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Buscar:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    onChange={(e) => { setParametroBusca(e.target.value) }}
                                    sx={{ mt: 1, mb: 1, width: "60%" }}
                                />
                                <Button onClick={() => getListaCliente(parametroBusca)} sx={{ mt: 1, mb: 1, width: "15%" }}>
                                    🔎
                                </Button>

                                <TableContainer
                                    component={Paper}
                                    sx={{ maxHeight: 400, overflow: "auto" }} // Establece una altura máxima y habilita el scroll
                                >
                                    <Table stickyHeader>
                                        {/* Encabezado */}
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><strong>Cédula</strong></TableCell>
                                                <TableCell><strong>Nombres</strong></TableCell>
                                                <TableCell><strong>Seleccionar</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {/* Cuerpo */}
                                        <TableBody>
                                            {listaClientes.map((row) => (
                                                <TableRow key={row.cliId}>
                                                    <TableCell>{row.cliIdentificacion}</TableCell>
                                                    <TableCell>{row.cliApellidos}{" "}{row.cliNombres}</TableCell>
                                                    <TableCell align="center">
                                                        <Button
                                                            sx={{ mt: 2, mb: 2, width: "70%" }}
                                                            onClick={(e) => {
                                                                setRegistroCliente(row);
                                                                // setEsNuevo(false);
                                                                setMostrar(true);
                                                                setOpenModal(false);
                                                            }}>
                                                            ✔
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </Box>
                        </Modal>

                        <Dialog
                            open={openDialog}
                            onClose={function (event) {
                                setOpenDialog(false);
                            }}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Buscar"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <TextField
                                        name="nuevoPVP"
                                        size="small"
                                        margin="normal"
                                        type="number"
                                        fullWidth
                                        onChange={dataBuscarId}
                                        label="Cédula"
                                        defaultValue={buscarId}
                                        sx={{ mt: 1, mb: 1, width: "100%" }}
                                    />
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={function (event) {
                                        setOpenDialog(false);
                                        setMostrar(true);
                                        // setDataEjemplo({
                                        //     ...dataEjemplo,
                                        //     cliId: 0,
                                        //     cliIdentificacion: "",
                                        //     cliSector: "Control sur",
                                        //     cliCategoria: "Categoria A",
                                        //     cliRegion: "Sur",
                                        //     cliCiudad: "Cuenca",
                                        //     cliDireccion: "Juan Pio Montufar",
                                        //     cliLugar: "El Arenal",
                                        //     cliOficina: "105 3er Piso",
                                        //     cliTelefono: "0987654321",
                                        //     cliGps: "",
                                        //     cliSubespecialidad: "Medico especialista",
                                        //     cliCorreo: "email@gmail.com",
                                        //     cliDiasAtencion: 5,
                                        //     cliHorarioAtencion: "8am - 1pm y 3pm a 6pm",
                                        //     cliCumpleanos: new Date('1980-12-2'),
                                        //     cliColor: "Rojo",
                                        //     cliComida: "Pizza",
                                        //     cliEquipoFutbol: "D. Cuenca",
                                        //     cliPasatiempos: "Leer libros, cine",
                                        //     cliEstadoCivil: "Casado",
                                        //     cliHijos: 2,
                                        //     cliMaestriasCursos: "Doctorado en algo",
                                        //     cliProyectos: "Nuevo consultorio",
                                        //     cliStatusMarca: "Medio",
                                        //     cliObservaciones: "Estos son datos de prueba",
                                        // });
                                    }}
                                    autoFocus
                                >
                                    Consultar
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
