import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import CardTracking from "../../components/CardTracking";
import {
  bodegaType,
  dataTracking,
  marcaType,
  zonaType,  
  trackingNotificacionType,
  NotificacionTeamsType,
  NotificacionWPPType,
  VTrackingVehiculoType,
  AnoType,
  MesType,
  TrackingFaseResponsableType,
} from "../../types";
import { DataGrid, GridColDef, GridToolbarQuickFilter } from "@mui/x-data-grid";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { green, orange, red, yellow } from "@mui/material/colors";

export const TrackingIndexPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();
  let dataDetalleFitrado: VTrackingVehiculoType[];

  useEffect(() => {
    loadCombos();
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
    } else {
      setName("");
    }
  }, [account]);

  async function loadCombos() {
    //await getZonas();
    await getMarcas();
  }

  const [mostrar, setMostrar] = React.useState(false); 

  const listaAnos: AnoType[] = [
    { id: 2023, ano: 2023 },
    { id: 2024, ano: 2024 },
    { id: 2025, ano: 2025 },
  ];
  const listaMes: MesType[] = [
    { id: 1, mes: "Enero" },
    { id: 2, mes: "Febrero" },
    { id: 3, mes: "Marzo" },
    { id: 4, mes: "Abril" },
    { id: 5, mes: "Mayo" },
    { id: 6, mes: "Junio" },
    { id: 7, mes: "Julio" },
    { id: 8, mes: "Agosto" },
    { id: 9, mes: "Septiembre" },
    { id: 10, mes: "Octubre" },
    { id: 11, mes: "Noviembre" },
    { id: 12, mes: "Diciembre" },
  ];

  const handleChangeAno = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnoFac(Number(event.target.value));
    setMostrar(false);
  };
  const handleChangeMes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMesFac(Number(event.target.value));
    setMostrar(false);
  };

  const listadoZonas: zonaType[] = [{
    zonId: 1,
    zonNombre: "Zona 1"
  },{
    zonId: 2,
    zonNombre: "Zona 2"
  },{
    zonId: 3,
    zonNombre: "Zona 3"
  },{
    zonId: 99,
    zonNombre: "Todas"
  },];
  const listadoBodegas: bodegaType[] = [];
  const listadoMarcas: marcaType[] = [];
  const listadoNotificaciones: TrackingFaseResponsableType[] = [];

  const listaData1: dataTracking[] = [];
  const listaData2: dataTracking[] = [];
  const listaData3: dataTracking[] = [];
  const listaData4: dataTracking[] = [];
  const listaData5: dataTracking[] = [];
  const listaData6: dataTracking[] = [];
  const listaData7: dataTracking[] = [];
  const listaData8: dataTracking[] = [];

  const [dataGrafico1, setDataGrafico1] = useState<dataTracking[]>(listaData1);
  const [dataGrafico2, setDataGrafico2] = useState<dataTracking[]>(listaData2);
  const [dataGrafico3, setDataGrafico3] = useState<dataTracking[]>(listaData3);
  const [dataGrafico4, setDataGrafico4] = useState<dataTracking[]>(listaData4);
  const [dataGrafico5, setDataGrafico5] = useState<dataTracking[]>(listaData5);
  const [dataGrafico6, setDataGrafico6] = useState<dataTracking[]>(listaData6);
  const [dataGrafico7, setDataGrafico7] = useState<dataTracking[]>(listaData7);
  const [dataGrafico8, setDataGrafico8] = useState<dataTracking[]>(listaData8);

  const listaDetalle: VTrackingVehiculoType[] = [];

  const [dataDetalle, setDataDetalle] =
    useState<VTrackingVehiculoType[]>(listaDetalle);

  //const [dataZona, setDataZona] = useState<zonaType[]>(listadoZonas);
  const [dataBodega, setDataBodega] = useState<bodegaType[]>(listadoBodegas);
  const [dataMarca, setDataMarca] = useState<marcaType[]>(listadoMarcas);
  const [datafaseResponsable, setDataFaseResponsable] = useState<TrackingFaseResponsableType[]>(listadoNotificaciones);

  
  const [anoFac, setAnoFac] = React.useState(0);  
  const [mesFac, setMesFac] = React.useState(0);
  const [zona, setZona] = React.useState(0);
  const [bodega, setBodega] = React.useState(0);
  const [marca, setMarca] = React.useState(0);

  const [fase, setFase] = React.useState(0);
  const [valor, setValor] = React.useState(0);

  const handleChangeZona = (event: React.ChangeEvent<HTMLInputElement>) => {
    loadBodega(Number(event.target.value));
  };

  async function loadBodega(valor: number) {
    await setZona(Number(valor));
    await getBodegas(String(valor));
    await setDataBodega((dataBodega) => [
      ...dataBodega,
      { bodId: 0, agencia: "TODAS", descripcion: "TODAS", agency: "" },
    ]);
    await setMostrarArchivos({
      ...mostrarArchivos,
      mostrar: false,
    });
  }

  const handleChangeBodega = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBodega(Number(event.target.value));
    setMostrarArchivos({
      ...mostrarArchivos,
      mostrar: false,
    });
  };

  const handleChangeMarca = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMarca(Number(event.target.value));
    setMostrarArchivos({
      ...mostrarArchivos,
      mostrar: false,
    });
  };

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openConfirm, setOpenConfirm] = React.useState(false);
  const handleOpenConfirm = () => setOpenConfirm(true);
  const handleCloseConfirm = () => setOpenConfirm(false);

  const [openNoficado, setOpenNoficado] = React.useState(false);
  const handleOpenNoficado = () => setOpenNoficado(true);
  const handleCloseNoficado = () => setOpenNoficado(false);

  const [notificacion, setNotificacion] =
    React.useState<trackingNotificacionType>({
      idBodega: 0,
      idMarca: 0,
      tnoFecha: "",
      tnoEtapa: 0,
      tnoSolicitaNombre: "",
      tnoSolicitaCorreo: "",
      tnoVin: "",
      tnoModelo: "",
      tnoNumfactura: "",
      tnoCliente: "",
      tnoVendedor: "",
      tnoEnviado: 0,
    });

  async function getDetalleGrafico1(ano_fac:number, mes_fac:number,id_marca: number, id_zona:number, id_bodega:number, fase: number) { 
    try {
      await axios
        .get<dataTracking[]>(
          "https://apisatelite.azurewebsites.net/vtrackinggrafico/consultabodega/" +
            String(ano_fac) +
            "/" +
            String(mes_fac) +
            "/" +
            String(id_marca) +
            "/" +
            String(id_zona) +
            "/" +
            String(id_bodega) +
            "/" +
            String(fase) 
        )
        .then((response) => {
          setDataGrafico1(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getDetalleGrafico2(ano_fac:number, mes_fac:number,id_marca: number, id_zona:number, id_bodega:number, fase: number) { 
    try {
      await axios
        .get<dataTracking[]>(
          "https://apisatelite.azurewebsites.net/vtrackinggrafico/consultabodega/" +
            String(ano_fac) +
            "/" +
            String(mes_fac) +
            "/" +
            String(id_marca) +
            "/" +
            String(id_zona) +
            "/" +
            String(id_bodega) +
            "/" +
            String(fase) 
        )
        .then((response) => {
          setDataGrafico2(response.data);
          console.log("data 1 >>>>> ", response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getDetalleGrafico3(ano_fac:number, mes_fac:number,id_marca: number, id_zona:number, id_bodega:number, fase: number) { 
    try {
      await axios
        .get<dataTracking[]>(
          "https://apisatelite.azurewebsites.net/vtrackinggrafico/consultabodega/" +
            String(ano_fac) +
            "/" +
            String(mes_fac) +
            "/" +
            String(id_marca) +
            "/" +
            String(id_zona) +
            "/" +
            String(id_bodega) +
            "/" +
            String(fase) 
        )
        .then((response) => {
          setDataGrafico3(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getDetalleGrafico4(ano_fac:number, mes_fac:number,id_marca: number, id_zona:number, id_bodega:number, fase: number) { 
    try {
      await axios
        .get<dataTracking[]>(
          "https://apisatelite.azurewebsites.net/vtrackinggrafico/consultabodega/" +
            String(ano_fac) +
            "/" +
            String(mes_fac) +
            "/" +
            String(id_marca) +
            "/" +
            String(id_zona) +
            "/" +
            String(id_bodega) +
            "/" +
            String(fase) 
        )
        .then((response) => {
          setDataGrafico4(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getDetalleGrafico5(ano_fac:number, mes_fac:number,id_marca: number, id_zona:number, id_bodega:number, fase: number) { 
    try {
      await axios
        .get<dataTracking[]>(
          "https://apisatelite.azurewebsites.net/vtrackinggrafico/consultabodega/" +
            String(ano_fac) +
            "/" +
            String(mes_fac) +
            "/" +
            String(id_marca) +
            "/" +
            String(id_zona) +
            "/" +
            String(id_bodega) +
            "/" +
            String(fase) 
        )
        .then((response) => {
          setDataGrafico5(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }
  async function getDetalleGrafico6(ano_fac:number, mes_fac:number,id_marca: number, id_zona:number, id_bodega:number, fase: number) { 
    try {
      await axios
        .get<dataTracking[]>(
          "https://apisatelite.azurewebsites.net/vtrackinggrafico/consultabodega/" +
            String(ano_fac) +
            "/" +
            String(mes_fac) +
            "/" +
            String(id_marca) +
            "/" +
            String(id_zona) +
            "/" +
            String(id_bodega) +
            "/" +
            String(fase) 
        )
        .then((response) => {
          setDataGrafico6(response.data);
          console.log("data grafico 6 >> ", response.data)
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }
  async function getDetalleGrafico7(ano_fac:number, mes_fac:number,id_marca: number, id_zona:number, id_bodega:number, fase: number) { 
    try {
      await axios
        .get<dataTracking[]>(
          "https://apisatelite.azurewebsites.net/vtrackinggrafico/consultabodega/" +
            String(ano_fac) +
            "/" +
            String(mes_fac) +
            "/" +
            String(id_marca) +
            "/" +
            String(id_zona) +
            "/" +
            String(id_bodega) +
            "/" +
            String(fase) 
        )
        .then((response) => {
          setDataGrafico7(response.data);
          console.log("data grafico 7 >> ", response.data)
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }
  async function getDetalleGrafico8(ano_fac:number, mes_fac:number,id_marca: number, id_zona:number, id_bodega:number, fase: number) { 
    try {
      await axios
        .get<dataTracking[]>(
          "https://apisatelite.azurewebsites.net/vtrackinggrafico/consultabodega/" +
            String(ano_fac) +
            "/" +
            String(mes_fac) +
            "/" +
            String(id_marca) +
            "/" +
            String(id_zona) +
            "/" +
            String(id_bodega) +
            "/" +
            String(fase) 
        )
        .then((response) => {
          setDataGrafico8(response.data);
          console.log("data grafico 8 >> ", response.data)
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getDetalleTrackingDash(anoFac:number, mesFac:number, idMarca: number, idZona:number, idBdoega: number) {
    try {
      await axios
        .get<VTrackingVehiculoType[]>(
          "https://apisatelite.azurewebsites.net/vtrackingvehiculo/consulta/" +
            String(anoFac) +
            "/" +
            String(mesFac)+
            "/" +
            String(idMarca)+
            "/" +
            String(idZona)+
            "/" +
            String(idBdoega)
        )
        .then((response) => {
          setDataDetalle(response.data);
          console.log("antes filtrar 1", response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
      console.log("antes filtrar", dataDetalle);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  // async function getZonas() {
  //   try {
  //     await axios
  //       .get<zonaType[]>("https://apisatelite.azurewebsites.net/dimzona")
  //       .then((response) => {
  //         setDataZona(response.data);
  //       })
  //       .catch((error) => {
  //         console.log("algo paso >> ", error);
  //       });

  //     console.log("data es >>", JSON.stringify(dataZona, null, 4));
  //   } catch (error) {
  //     if (axios.isAxiosError(error)) {
  //       console.log("error message: ", error.message);
  //       return error.message;
  //     } else {
  //       console.log("algo paso unexpected error: ", error);
  //       return "An unexpected error occurred";
  //     }
  //   }
  // }
  async function getMarcas() {
    try {
      await axios
        .get<marcaType[]>("https://apisatelite.azurewebsites.net/dimmarca")
        .then((response) => {
          setDataMarca(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataBodega, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }
  async function getBodegas(idZona: string) {
    try {
      await axios
        .get<bodegaType[]>(
          "https://apisatelite.azurewebsites.net/dimbodega/zona/" + idZona
        )
        .then((response) => {
          setDataBodega(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataBodega, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const columns: GridColDef[] = [
    {
      field: "estado",
      headerName: "Estado",
      editable: false,
      width: 50,
      renderCell: (params) => {
        return (
          <div>
            {fase === 1 ? (
              params.row.idEstadoMovilizacion === 1 ? (
                <WatchLaterIcon sx={{ color: green[500] }} />
              ) : params.row.idEstadoMovilizacion === 2 ? (
                <WatchLaterIcon sx={{ color: orange[500] }} />
              ) :params.row.idEstadoMovilizacion === 3 ? (
                <WatchLaterIcon sx={{ color: yellow[500] }} />
              ) : (
                <WatchLaterIcon sx={{ color: red[500] }} />
              )
            ) : (
              <></>
            )}
            {fase === 2 ? (
              params.row.idEstadoImpronta === 1 ? (
                <WatchLaterIcon sx={{ color: green[500] }} />
              ) : params.row.idEstadoImpronta === 2 ? (
                <WatchLaterIcon sx={{ color: orange[500] }} />
              ) : params.row.idEstadoImpronta === 3 ? (
                <WatchLaterIcon sx={{ color: yellow[500] }} />
              ) : (
                <WatchLaterIcon sx={{ color: red[500] }} />
              )
            ) : (
              <></>
            )}
            {fase === 3 ? (
              params.row.idEstadoAccesorizacion === 1 ? (
                <WatchLaterIcon sx={{ color: green[500] }} />
              ) : params.row.idEstadoAccesorizacion === 2 ? (
                <WatchLaterIcon sx={{ color: orange[500] }} />
              ) : params.row.idEstadoAccesorizacion === 3 ? (
                <WatchLaterIcon sx={{ color: yellow[500] }} />
              ) : (
                <WatchLaterIcon sx={{ color: red[500] }} />
              )
            ) : (
              <></>
            )}
            {fase === 4 ? (
              params.row.idEstadoMatriculacion === 1 ? (
                <WatchLaterIcon sx={{ color: green[500] }} />
              ) : params.row.idEstadoMatriculacion === 2 ? (
                <WatchLaterIcon sx={{ color: orange[500] }} />
              ) : params.row.idEstadoMatriculacion === 3 ? (
                <WatchLaterIcon sx={{ color: yellow[500] }} />
              ) : (
                <WatchLaterIcon sx={{ color: red[500] }} />
              )
            ) : (
              <></>
            )}
            {fase === 5 ? (
              params.row.idEstadoMatriculacion === 1 ? (
                <WatchLaterIcon sx={{ color: green[500] }} />
              ) : params.row.idEstadoMatriculacion === 2 ? (
                <WatchLaterIcon sx={{ color: orange[500] }} />
              ) : params.row.idEstadoMatriculacion === 3 ? (
                <WatchLaterIcon sx={{ color: yellow[500] }} />
              ) : (
                <WatchLaterIcon sx={{ color: red[500] }} />
              )
            ) : (
              <></>
            )}
            {fase === 6 ? (//pdi
              params.row.idEstadoMovilizacion === 1 ? (
                <WatchLaterIcon sx={{ color: green[500] }} />
              ) : params.row.idEstadoMovilizacion === 2 ? (
                <WatchLaterIcon sx={{ color: orange[500] }} />
              ) : params.row.idEstadoMovilizacion === 3 ? (
                <WatchLaterIcon sx={{ color: yellow[500] }} />
              ) : (
                <WatchLaterIcon sx={{ color: red[500] }} />
              )
            ) : (
              <></>
            )}
            {fase === 7 ? ( //alistamiento
              params.row.idEstadoMovilizacion === 1 ? (
                <WatchLaterIcon sx={{ color: green[500] }} />
              ) : params.row.idEstadoMovilizacion === 2 ? (
                <WatchLaterIcon sx={{ color: orange[500] }} />
              ) : params.row.idEstadoMovilizacion === 3 ? (
                <WatchLaterIcon sx={{ color: yellow[500] }} />
              ) : (
                <WatchLaterIcon sx={{ color: red[500] }} />
              )
            ) : (
              <></>
            )}
            {fase === 8 ? (
              params.row.idEstadoEntregaVeh === 1 ? (
                <WatchLaterIcon sx={{ color: green[500] }} />
              ) : params.row.idEstadoEntregaVeh === 2 ? (
                <WatchLaterIcon sx={{ color: orange[500] }} />
              ) : params.row.idEstadoEntregaVeh === 3 ? (
                <WatchLaterIcon sx={{ color: yellow[500] }} />
              ) : (
                <WatchLaterIcon sx={{ color: red[500] }} />
              )
            ) : (
              <></>
            )}
          </div>
        );
      },
    },

    {
      field: "action",
      headerName: "Notificar",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        //{params.row.Id}
        return (
          <Button
            onClick={function (event) {
              setearNotificacion(
                params.row.idBodega,
                params.row.idMarca,
                new Date(Date.now()).toISOString().substring(0, 10),
                fase,
                name,
                username,
                params.row.chasis,
                params.row.modelo,
                params.row.numFactura,
                params.row.cliente,
                params.row.vendedor,
                0
              );
            }}
          >
            Notificar
          </Button>
        );
      },
    },
    {
      field: "fechaAproxEntregaCliente",
      headerName: "F.Estimada Entrega",
      editable: false,
      width: 125,
      valueGetter: ({ row }) => {
        return String(row.fechaAproxEntregaCliente).substring(0, 10);
      },
    },
    { field: "modelo", headerName: "Modelo", editable: false, width: 200 },
    { field: "chasis", headerName: "Chasis", editable: false, width: 150 },
    {
      field: "fechaFactura",
      headerName: "Fecha Factura",
      editable: false,
      width: 100,
      valueGetter: ({ row }) => {
        return String(row.fechaFactura).substring(0, 10);
      },
    },
    { field: "numeroHn", headerName: "NumeroHn", editable: false, width: 80 },
    { field: "agencia", headerName: "Agencia", editable: false, width: 200 }, 
    { field: "cliente", headerName: "Cliente", editable: false, width: 200 },
    { field: "vendedor", headerName: "Vendedor", editable: false, width: 175 },
    
  ];

  const [mostrarArchivos, setMostrarArchivos] = React.useState({
    mostrar: false,
  });

  async function principalFun() {
    await getDetalleTrackingDash(anoFac, mesFac, marca, zona,bodega);
    await getDetalleGrafico1(anoFac, mesFac, marca, zona, bodega, 1);// "proceso_movilizacion"); //ano_fac:number, mes_fac:number,id_marca: number, id_zona:number, id_bodega:number, fase: number
    await getDetalleGrafico2(anoFac, mesFac, marca, zona, bodega, 2);//"impronta");
    await getDetalleGrafico3(anoFac, mesFac, marca, zona, bodega, 3);//"accesorizacion");
    await getDetalleGrafico4(anoFac, mesFac, marca, zona, bodega, 4);//"autorizacion_matriculacion");
    await getDetalleGrafico5(anoFac, mesFac, marca, zona, bodega, 5);//"inicio_matriculacion");
    await getDetalleGrafico6(anoFac, mesFac, marca, zona, bodega, 6);//"pdi");
    await getDetalleGrafico7(anoFac, mesFac, marca, zona, bodega, 7);//"alistamiento");
    await getDetalleGrafico8(anoFac, mesFac, marca, zona, bodega, 8);//"entrega_cliente");
    await setMostrarArchivos({
      ...mostrarArchivos,
      mostrar: true,
    });
  }

  async function mostrarGraficos(fase: number, valor: number) {
    await setValor(valor);
    await setFase(fase);
    await handleOpen();
  }

  async function postGuardarParametro(
    registroGuardar: trackingNotificacionType
  ) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/trackingnotificacion",
          registroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: ", response.data.id);
          handleOpenNoficado();
        }); //setArticleId(response.data.id));
      // handleClickOpen();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }


  async function getListaNotificar(fase:number, idZona: number, tipoNotificacion: number) {
    try {
      await axios
        .get<TrackingFaseResponsableType[]>(
          "https://apisatelite.azurewebsites.net/trackingfaseresponsable/consultar/"+String(fase)+"/" +String(idZona)
        )
        .then((response) => {
          setDataFaseResponsable(response.data);
          ejecutaNotificacion(response.data, tipoNotificacion)
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function ejecutaNotificacion(lista: TrackingFaseResponsableType[], tipoNotificacion: number) {    
      lista.forEach(function (registro) {
        if (tipoNotificacion === 1) {
          EnviarNotificacionWPP(0, registro.tfr_telefono,"Hola "+registro.tfr_responsable+", el proceso de entrega del vehículo "+ notificacion.tnoModelo+" - "+notificacion.tnoVin +" se encuentra retrazado");
        }
        if (tipoNotificacion === 2){

        }
        if (tipoNotificacion === 3){
          EnviarNotificacionTeams(registro.tfr_correo,"Hola "+registro.tfr_responsable+", el proceso de entrega del vehículo "+ notificacion.tnoModelo+" - "+notificacion.tnoVin+" se encuentra retrazado","https://satelite.corporacionproauto.com.ec/");
        }
      })
    
  }
  

  async function setearNotificacion(
    idBodega: number,
    idMarca: number,
    tnoFecha: string,
    tnoEtapa: number,
    tnoSolicitaNombre: string,
    tnoSolicitaCorreo: string,
    tnoVin: string,
    tnoModelo: string,
    tnoNumfactura: string,
    tnoCliente: string,
    tnoVendedor: string,
    tnoEnviado: number
  ) {
    await setNotificacion({
      ...notificacion,
      idBodega: idBodega,
      idMarca: idMarca,
      tnoFecha: tnoFecha,
      tnoEtapa: tnoEtapa,
      tnoSolicitaNombre: tnoSolicitaNombre,
      tnoSolicitaCorreo: tnoSolicitaCorreo,
      tnoVin: tnoVin,
      tnoModelo: tnoModelo,
      tnoNumfactura: tnoNumfactura,
      tnoCliente: tnoCliente,
      tnoVendedor: tnoVendedor,
      tnoEnviado: tnoEnviado,
    });
    await handleOpenConfirm();
  }

  async function enviarNotificacion(notificacion: trackingNotificacionType) {
    await handleCloseConfirm();
    await postGuardarParametro(notificacion);
  }

  function EnviarNotificacionTeams(
    Recipient: string,
    Message: string,
    Link: string,
  ) {
    const nuevoRegistro: NotificacionTeamsType = {
      Recipient: Recipient,
      Message: Message,
      Link: Link,
    };
    console.log("datos a guardar >> ", JSON.stringify(nuevoRegistro, null, 4));
    postNotificarTeams(nuevoRegistro);
  }

  async function postNotificarTeams(
    parametroGuardar: NotificacionTeamsType
  ) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://prod-29.brazilsouth.logic.azure.com:443/workflows/6cdda030ad964f47b5b2e08bfd563fd8/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=cC8S8STppPJKF7e9d7yHpkYP2LXUxkMLD9awb9VFKJA",
          parametroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("respuesta teams ", response.data.id);
          //setOpenDialog(true);
        }); //setArticleId(response.data.id));
      // handleClickOpen();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  function EnviarNotificacionWPP(
    typing_time: number,
    to: string,
    body: string,
  ) {
    const nuevoRegistro: NotificacionWPPType = {
      typing_time: typing_time,
      to: to,
      body: body,
    };
    console.log("datos a guardar >> ", JSON.stringify(nuevoRegistro, null, 4));
    postNotificarWPP(nuevoRegistro);
  }

  async function postNotificarWPP(
    nuevoRegistro: NotificacionWPPType
  ) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + "gMlELccOPZC3Uwy9zIyvQoe8feZ0xRjD",
    };
    try {
      axios
        .post<any>(
          "https://gate.whapi.cloud/messages/text",
          nuevoRegistro,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("mi wpp: ", response);                
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error peticion crear cli dms: ", error.message);
        return error.message;
      } else {
        console.log("error peticion crear cli dms: ", error);
        return "An unexpected error occurred";
      }
    }
  }
  



  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Dashboard de Tracking de Vehículos
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "18%",
                }}
                style={{ display: "inline-block" }}
              >
                Marca:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={marca}
                onChange={handleChangeMarca}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "10%", width: "20%" }}
              >
                {dataMarca.map((option) => (
                  <MenuItem key={option.marId} value={option.marId}>
                    {option.marNombre}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                }}
                style={{ display: "inline-block" }}
              >
                Bodega:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                onChange={handleChangeBodega}
                size="small"
                margin="normal"
                value={bodega}
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
                sx={{ mt: 0.5, mb: 0.5, width: "25%", marginRight: "10%" }}
              >
                {dataBodega.map((option) => (
                  <MenuItem key={option.bodId} value={option.bodId}>
                    {option.descripcion}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "18%",
                }}
                style={{ display: "inline-block" }}
              >
                Zona:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={zona}
                onChange={handleChangeZona}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "10%", width: "20%" }}
              >
                {listadoZonas.map((option) => (
                  <MenuItem key={option.zonId} value={option.zonId}>
                    {option.zonNombre}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                }}
                style={{ display: "inline-block" }}
              >
                Año:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={anoFac}
                onChange={handleChangeAno}
                sx={{ mt: 0.5, mb: 0.5,  width: "8%" }}
              >
                {listaAnos.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.ano}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "5%",
                  marginLeft: "2%"
                }}
                style={{ display: "inline-block" }}
              >
                Mes:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={mesFac}
                onChange={handleChangeMes}
                sx={{ mt: 0.5, mb: 0.5,  width: "10%" }}
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {listaMes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.mes}
                  </MenuItem>
                ))}
              </TextField>

              <Button
              disabled = {anoFac > 0 && mesFac > 0 && zona > 0 ? false : true}
                variant="contained"
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "12%",
                  marginLeft: "28%",
                }}
                onClick={function (event) {
                  principalFun();
                }}
              >
                Consultar
              </Button>
              <Button
                variant="contained"
                href="https://app.powerbi.com/groups/f8f86495-187f-478d-bf81-fefae98a3d80/reports/7682e75c-0d76-46d2-afab-a61bf45132d8/b7a193374222e18c1847?experience=power-bi"
                target="_blank"
                component="a"               
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "12%",
                  marginLeft: "5%",
                }}
                // onClick={function (event) {
                //   principalFun();
                // }}
              >
                Power BI
              </Button>
              <Button
                variant="contained"
                href="/trackingveh/parametros_rutas"
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "12%",
                  marginLeft: "5%",
                }}
                // onClick={function (event) {
                //   principalFun();
                // }}
              >
                Parametros
              </Button>
            </Paper>
            {/* insertar validacion para mostrar */}
            {mostrarArchivos.mostrar ? (
              <Paper
                sx={{
                  padding: "1.2em",
                  borderRadius: "0.5em",
                  backgroundColor: "rgb(255,255,255,0.7)",
                  mt: 1,
                  mb: 4,
                }}
              >
                <Grid>
                  <CardTracking
                    fase={"1. Movilización"}
                    data={dataGrafico1}
                    handleClickGeneral={function (event) {
                      mostrarGraficos(1, 9);
                    }}
                    handleClickDetalle0={function (event) {
                      mostrarGraficos(1, 0);
                    }}
                    handleClickDetalle1={function (event) {
                      mostrarGraficos(1, 1);
                    }}
                    handleClickDetalle2={function (event) {
                      mostrarGraficos(1, 2);
                    }}
                    handleClickDetalle3={function (event) {
                      mostrarGraficos(1, 3);
                    }}
                  />
                  <CardTracking
                    fase={"2. Entrega de impronta"}
                    data={dataGrafico2}
                    handleClickGeneral={function (event) {
                      mostrarGraficos(2, 9);
                    }}
                    handleClickDetalle0={function (event) {
                      mostrarGraficos(2, 0);
                    }}
                    handleClickDetalle1={function (event) {
                      mostrarGraficos(2, 1);
                    }}
                    handleClickDetalle2={function (event) {
                      mostrarGraficos(2, 2);
                    }}
                    handleClickDetalle3={function (event) {
                      mostrarGraficos(2, 3);
                    }}
                  />
                  <CardTracking
                    fase={"3. Accesorización"}
                    data={dataGrafico3}
                    handleClickGeneral={function (event) {
                      mostrarGraficos(3, 9);
                    }}
                    handleClickDetalle0={function (event) {
                      mostrarGraficos(3, 0);
                    }}
                    handleClickDetalle1={function (event) {
                      mostrarGraficos(3, 1);
                    }}
                    handleClickDetalle2={function (event) {
                      mostrarGraficos(3, 2);
                    }}
                    handleClickDetalle3={function (event) {
                      mostrarGraficos(3, 3);
                    }}
                  />
                  <CardTracking
                    fase={"4. Autorización de matriculación"}
                    data={dataGrafico4}
                    handleClickGeneral={function (event) {
                      mostrarGraficos(4, 9);
                    }}
                    handleClickDetalle0={function (event) {
                      mostrarGraficos(4, 0);
                    }}
                    handleClickDetalle1={function (event) {
                      mostrarGraficos(4, 1);
                    }}
                    handleClickDetalle2={function (event) {
                      mostrarGraficos(4, 2);
                    }}
                    handleClickDetalle3={function (event) {
                      mostrarGraficos(4, 3);
                    }}
                  />
                </Grid>
                <Grid>
                  <CardTracking
                    fase={"5. Matriculación"}
                    data={dataGrafico5}
                    handleClickGeneral={function (event) {
                      mostrarGraficos(5, 9);
                    }}
                    handleClickDetalle0={function (event) {
                      mostrarGraficos(5, 0);
                    }}
                    handleClickDetalle1={function (event) {
                      mostrarGraficos(5, 1);
                    }}
                    handleClickDetalle2={function (event) {
                      mostrarGraficos(5, 2);
                    }}
                    handleClickDetalle3={function (event) {
                      mostrarGraficos(5, 3);
                    }}
                  />
                  <CardTracking
                    fase={"6. PDI"}
                    data={dataGrafico6}
                    handleClickGeneral={function (event) {
                      mostrarGraficos(6, 9);
                    }}
                    handleClickDetalle0={function (event) {
                      mostrarGraficos(6, 0);
                    }}
                    handleClickDetalle1={function (event) {
                      mostrarGraficos(6, 1);
                    }}
                    handleClickDetalle2={function (event) {
                      mostrarGraficos(6, 2);
                    }}
                    handleClickDetalle3={function (event) {
                      mostrarGraficos(6, 3);
                    }}
                  />
                  <CardTracking
                    fase={"7. Alistamiento"}
                    data={dataGrafico7}
                    handleClickGeneral={function (event) {
                      mostrarGraficos(7, 9);
                    }}
                    handleClickDetalle0={function (event) {
                      mostrarGraficos(7, 0);
                    }}
                    handleClickDetalle1={function (event) {
                      mostrarGraficos(7, 1);
                    }}
                    handleClickDetalle2={function (event) {
                      mostrarGraficos(7, 2);
                    }}
                    handleClickDetalle3={function (event) {
                      mostrarGraficos(7, 3);
                    }}
                  />
                  <CardTracking
                    fase={"8. Entrega"}
                    data={dataGrafico8}
                    handleClickGeneral={function (event) {
                      mostrarGraficos(8, 9);
                    }}
                    handleClickDetalle0={function (event) {
                      mostrarGraficos(8, 0);
                    }}
                    handleClickDetalle1={function (event) {
                      mostrarGraficos(8, 1);
                    }}
                    handleClickDetalle2={function (event) {
                      mostrarGraficos(8, 2);
                    }}
                    handleClickDetalle3={function (event) {
                      mostrarGraficos(8, 3);
                    }}
                  />
                </Grid>              

                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={styleModal}>
                    <Typography
                      id="modal-modal-description"
                      sx={{ mt: 2, mb: 2, textAlign: "center" }}
                    >
                    <strong>{fase === 1
                        ? `1. En proceso de movilización`
                        : fase === 2
                        ? `2. Entrega de impronta`
                        : fase === 3
                        ? `3. Agendamiento de alistamiento`
                        : fase === 4
                        ? `4. Autorización de matriculación`
                        : fase === 5
                        ? `5. Inicio de matriculación`
                        : fase === 6
                        ? `6. Fecha fin PDI`
                        : fase === 7
                        ? `7. Fin de matricula`
                        : fase === 8
                        ? `8. Movilización para entrega`
                        : fase === 9
                        ? `9. Entrega al cliente`
                        : ``}</strong>
                    </Typography>

                    <DataGrid
                      getRowId={(row) => row.id}
                      sx={{ height: 410 }}
                      editMode="row"
                      rows={
                        fase === 1 && valor != 9
                          ? dataDetalle.filter((obj) => {
                              return obj.idEstadoMovilizacion === (valor+1);
                            })
                          : fase === 2 && valor != 9
                          ? dataDetalle.filter((obj) => {
                              return obj.idEstadoImpronta === (valor+1);
                            })
                          : fase === 3 && valor != 9
                          ? dataDetalle.filter((obj) => {
                              return obj.idEstadoAccesorizacion === (valor+1);
                            })
                          : fase === 4 && valor != 9
                          ? dataDetalle.filter((obj) => {
                              return obj.idEstadoMatriculacion === (valor+1);
                            })
                          : fase === 5 && valor != 9
                          ? dataDetalle.filter((obj) => {
                              return obj.idEstadoMatriculacion === (valor+1);
                            })
                          : fase === 6 && valor != 9 //pdi
                          ? dataDetalle.filter((obj) => {
                              return obj.idEstadoMovilizacion === (valor+1);
                            })
                          : fase === 7 && valor != 9 //alistamiento
                          ? dataDetalle.filter((obj) => {
                              return obj.idEstadoMovilizacion === (valor+1);
                            })
                          : fase === 8 && valor != 9
                          ? dataDetalle.filter((obj) => {
                              return obj.idEstadoEntregaVeh === (valor+1);
                            })
                          : valor === 9
                          ? dataDetalle
                          : dataDetalle
                      }
                      columns={columns}
                      slots={{ toolbar: GridToolbarQuickFilter }}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 5 } },
                      }}
                      pageSizeOptions={[5, 10, 25]}
                      slotProps={{
                        pagination: {
                          labelRowsPerPage: "Registros por página:",
                        },
                      }}
                      localeText={{
                        footerTotalRows: false,
                        noRowsLabel: "No se ha encontrado datos.",
                        noResultsOverlayLabel:
                          "No se ha encontrado ningún resultado",
                        toolbarColumns: "Columnas",
                        toolbarColumnsLabel: "Seleccionar columnas",
                        toolbarFilters: "Filtros",
                        toolbarFiltersLabel: "Ver filtros",
                        toolbarFiltersTooltipHide: "Quitar filtros",
                        toolbarFiltersTooltipShow: "Ver filtros",
                        toolbarQuickFilterPlaceholder: "Buscar",
                      }}
                    />
                  </Box>
                </Modal>
                <Dialog
                  open={openConfirm}
                  //onClose={handleCloseDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Notificación"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      ¿Desea notificar via correo sobre este vehículo?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                  <Button
                  sx={{color: "darkgreen"}}
                      onClick={function (event) {
                        getListaNotificar(fase, zona, 1);
                        //EnviarNotificacionWPP(0, "593962911313","El proceso de entrega del vehículo "+ notificacion.tnoModelo+" - "+notificacion.tnoVin +" se encuentra retrazado");
                      }}
                    >
                      WhatsApp
                    </Button>
                    <Button
                  //sx={{color: "darkgree"}}
                      onClick={function (event) {
                        getListaNotificar(fase, zona, 2);
                        //enviarNotificacion(notificacion);
                      }}
                    >
                      Correo
                    </Button>
                    <Button
                  sx={{color: "violet"}}
                      onClick={function (event) {
                        getListaNotificar(fase, zona, 3);
                        //EnviarNotificacionTeams("ximena.bautista@mirasol.com.ec","El proceso de entrega del vehículo "+ notificacion.tnoModelo+" - "+notificacion.tnoVin+" se encuentra retrazado","https://satelite.corporacionproauto.com.ec/");
                      }}
                    >
                      Teams
                    </Button>
                    <Button
                  sx={{color: "red"}}
                      onClick={function (event) {
                        handleCloseConfirm();
                      }}
                    >
                      Cancelar
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={openNoficado}
                  //onClose={handleCloseDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Correcto!"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Notificación enviada correctamente
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={function (event) {
                        handleCloseNoficado();
                      }}
                    >
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>
              </Paper>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
