import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Paper,
    TextField,
    Typography,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    IconButton,
    Input,
    Modal,
    DialogContentText,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, useMemo } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Box } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { MesType, SateliteTestDrive, SateliteTestDriveImagen, SateliteTestDriveImagenMostrar, SateliteTestDriveRuta, VSateliteTestDriveRuta, vventassatelitecrmType } from "../../types";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export const EntradaTestDrivePage: React.FC<{}> = () => {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const isAuthenticated = useIsAuthenticated();
    const params = useParams();
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [rutas, setRutas] = useState<SateliteTestDriveRuta[]>([]);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [openDialogGuardado, setOpenDialogGuardado] = useState<boolean>(false);
    const [openDialogGuardadoError, setOpenDialogGuardadoError] = useState<boolean>(false);
    const userAgent = navigator.userAgent;
    const mobileKeywords = [
        "Mobile",
        "Android",
        "iPhone",
        "iPad",
        "Windows Phone",
    ];
    const checkDeviceType = () => {
        setIsMobile(
            mobileKeywords.some((keyword) => userAgent.includes(keyword))
        );
    };

    useEffect(() => {
        if (account && account.name) {
            console.log("este son los atributos de msal >>>>>>", account);
            setName(account.name);
            setUsername(account.username);
            console.log(account);
            checkDeviceType();
            getDatosTestDrive();
            getTestDriveImages(Number(params.id));
        } else {
            setName("");
        }
    }, [account]);



    const [dataTestDrive, setDataTestDrive] = useState<SateliteTestDrive>({
        stdId: 0,
        stdFecha: new Date(),
        stdFechaIni: new Date(),
        stdFechaFin: new Date(),
        stdFinalizado: 0,
        idBodega: 0,
        idCrm: "",
        sruId: 0,
        stdEsInventario: 0,
        stdVinPlaca: "",
        stdUsuCorreoCrea: "",
        stdUsuNombreCrea: "",
        stdUsuCorreoResponsable: "",
        stdUsuNombreResponsable: "",
        stdUsuCorreoFin: "",
        stdUsuNombreFin: "",
        stdObservacion1: "",
        stdObservacion2: "",
        stdKmInicio: 0,
        stdKmFin: 0,
    });

    const [dataNegocio, setDataNegocio] = useState<vventassatelitecrmType>({
        id: 0,
        idcrm: "",
        createdate: "",
        cedula: "",
        cliente: "",
        asesor: "",
        dealname: "",
        agencia: "",
        modelo: "",
        correo: "",
        ageDireccion: "",
    });

    const [dataVRuta, setDataVRuta] = useState<VSateliteTestDriveRuta>({
        sruId: 0,
        idBodega: 0,
        idZona: 0,
        agencia: "",
        zona: "",
        sruDescripcion: "",
        sruMetrosDistancia: 0,
        sruObservacion: "",
        ruMetrosDistanciaTolerancia: 0,
        sruMinutos: 0,
        sruMinutosTolerancia: 0,
    });


    const [images, setImages] = useState<SateliteTestDriveImagenMostrar[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);

    const handleImageClick = (index: number) => {
        setCurrentIndex(index);
        setOpenDialog(true);
    };

    async function getDatosTestDrive() {
        try {
            await axios
                .get<SateliteTestDrive>("https://apisatelite.azurewebsites.net/testdrive/buscar/" + params.id)
                .then((response) => {
                    setDataTestDrive(response.data);
                    getDatosNegocio(String(response.data.idCrm));
                    getDatosRuta(Number(response.data.sruId));
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getDatosNegocio(idcrm: string) {
        try {
            await axios
                .get<vventassatelitecrmType[]>("https://apisatelite.azurewebsites.net/vventassatelitecrm/idcrm/" + idcrm)
                .then((response) => {
                    setDataNegocio(response.data[0]);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getDatosRuta(id: number) {
        try {
            await axios
                .get<VSateliteTestDriveRuta>("https://apisatelite.azurewebsites.net/vsatelitetestdriveruta/buscar/" + String(id))
                .then((response) => {
                    setDataVRuta(response.data);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getTestDriveImages(id: number) { //: Promise<SateliteTestDriveImagen[]>
        try {
            const response = await axios.get<{ data: SateliteTestDriveImagen[] }>(
                `https://apisatelite.azurewebsites.net/testdriveimagen/testdrive/${id}`
            );
            if (response.status === 200) {
                // Procesamos cada objeto para transformar stiImagen (Buffer) a base64 URL
                const images = response.data.data.map((item) => ({
                    ...item,
                    stiImagen: `data:image/jpeg;base64,${item.stiImagen}`, // O PNG según corresponda
                }));
                setImages(images);
            } else {
                console.error("Error al obtener las imágenes:", response.statusText);
                return [];
            }
        } catch (error) {
            console.error("Error de Axios:", error);
            return [];
        }
    }



    const handlePrevious = () => {
        if (currentIndex !== null && currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleNext = () => {
        if (currentIndex !== null && currentIndex < images.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    function stringToBlob(string: string) {
        try {
            // Tu string en formato Base64 que representa la imagen
            const base64Image = "data:image/png;" + string; // Recorta tu base64 aquí

            // Convertir el Base64 a un Blob
            const base64Data = base64Image.split(',')[1]; // Separar el encabezado
            const byteCharacters = atob(base64Data); // Decodificar Base64
            const byteNumbers = new Uint8Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const blob = new Blob([byteNumbers], { type: 'image/png' });

            // Crear un objeto File a partir del Blob
            const file = new File([blob], "example.png", { type: 'image/png' });

            console.log('Blob:', blob);
            console.log('File:', file);
            return file
            // Ahora puedes usar este archivo para cargarlo en un servidor o realizar otras operaciones.
        } catch (error) {
            return ""
        }
    }


    async function postGuardarRegistro(registro: SateliteTestDrive) {
        const headers = {
            "Content-Type": "application/json",
        };
        try {
            axios
                .post("https://apisatelite.azurewebsites.net/testdrive", registro, {
                    headers: headers,
                })
                .then((response) => {
                    console.log("id guardado es: >>>>>>>> ", response);
                    handleSaveAll(String(registro.stdId));
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    // const GuardarImagenes = async (images: string[]) => {
    //     try {
    //         for (const image of images) {
    //             const formData = new FormData();
    //             formData.append("file", stringToBlob(image)); // Base64 como string
    //             formData.append("stdId", "0");
    //             formData.append("stiNombre", "Pruebas APP");
    //             formData.append("stiFecha", new Date().toString());
    //             //console.log("mi json de imagenes es >>", formData.values);
    //             //console.log("mi imagen >>", image.stiImagen);
    //             await axios.post(
    //                 "https://apisatelite.azurewebsites.net/testdriveimagen/upload", // Ajusta la URL según tu configuración
    //                 formData,
    //                 { headers: { "Content-Type": "multipart/form-data" } }
    //             );
    //         }
    //         alert("Todas las imágenes fueron guardadas con éxito.");
    //         //setTempImages([]);
    //     } catch (error) {
    //         console.error("Error al guardar imágenes:", error);
    //         alert("Error al guardar las imágenes.");
    //     }
    // };


    const [tempImages, setTempImages] = useState<SateliteTestDriveImagen[]>([]);
    const [currentImageIndex, setCurrentImageIndex] = useState<number | null>(null);


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];

            setTempImages((prev) => [
                ...prev,
                {
                    stiId: 0, // Provisional
                    stdId: 123, // ID de ejemplo
                    stiNombre: file.name,
                    stiImagen: file, // Guarda el archivo original
                    stiFecha: new Date().toISOString(),
                },
            ]);
        }
    };



    const handleSaveAll = async (_idTest: string) => {

        try {
            for (const image of tempImages) {
                const formData = new FormData();
                formData.append("file", image.stiImagen); // Archivo binario
                formData.append("stdId", _idTest);
                formData.append("stiNombre", "Registro de entrada");
                formData.append("stiFecha", image.stiFecha);

                await axios.post(
                    "https://apisatelite.azurewebsites.net/testdriveimagen/upload",
                    formData,
                    { headers: { "Content-Type": "multipart/form-data" } }
                );
            }
            setOpenDialogGuardado(true);
            setTempImages([]);
        } catch (error) {
            console.error("Error al guardar imágenes:", error);
            setOpenDialogGuardadoError(true);
        }
    };

    const handleDeleteImage = (stiNombre: string) => {
        setTempImages((prev) => prev.filter((img) => img.stiNombre !== stiNombre));
        if (currentImageIndex !== null && tempImages[currentImageIndex]?.stiNombre === stiNombre) {
            setCurrentImageIndex(null);
        }
    };

    const handleOpenModal = (index: number) => {
        setCurrentImageIndex(index);
    };

    const handleCloseModal = () => {
        setCurrentImageIndex(null);
    };

    const handleNextImage = () => {
        if (currentImageIndex !== null) {
            setCurrentImageIndex((prevIndex) => (prevIndex! + 1) % tempImages.length);
        }
    };

    const handlePrevImage = () => {
        if (currentImageIndex !== null) {
            setCurrentImageIndex(
                (prevIndex) => (prevIndex! - 1 + tempImages.length) % tempImages.length
            );
        }
    };


    if (isAuthenticated) {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Grid alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 2,
                            }}
                        >
                            <Typography variant="h5" textAlign={"center"}>
                                Test Drive: Registro de Entrada
                            </Typography>
                        </Paper>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 1,
                            }}
                        >
                            {isMobile ? <>
                            </> : <>

                                <Typography sx={{ ml: "10%", mt: 2, mb: 3 }} textAlign={"left"} variant="h6" >
                                    <strong>Información general</strong>
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Identificacion:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataNegocio.cedula}
                                    // onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "15%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Cliente:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataNegocio.cliente}
                                    // onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "35%" }}
                                />


                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Teléfono:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataNegocio.contactphone}
                                    sx={{ mt: 1, mb: 1, width: "15%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Correo:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataNegocio.contactemail}
                                    sx={{ mt: 1, mb: 1, width: "35%" }}
                                />
                                <Divider sx={{ ml: "3%", mr: "3%", mt: 2, mb: 2 }} />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Ruta:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataVRuta.sruDescripcion}
                                    sx={{ mt: 1, mb: 1, width: "35%" }}
                                />

                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "15%",
                                        ml: "8%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Distancia de la ruta:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={`${dataVRuta.sruMetrosDistancia} metros`}
                                    sx={{ mt: 1, mb: 1, width: "12%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Vehículo:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataTestDrive.stdVinPlaca}
                                    sx={{ mt: 1, mb: 1, width: "35%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "15%",
                                        ml: "8%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    KM de inicio:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataTestDrive.stdKmInicio}
                                    sx={{ mt: 1, mb: 1, width: "12%" }}
                                />

                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Responsable:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataNegocio.asesor}
                                    sx={{ mt: 1, mb: 1, width: "35%" }}
                                />

                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "8%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Hora de inicio:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataTestDrive.stdFechaIni?.toLocaleString().substring(0,10)+", "+ dataTestDrive.stdFechaIni?.toLocaleString().substring(11,16)}
                                    sx={{ mt: 1, mb: 1, width: "17%" }}
                                />

                                <Divider sx={{ ml: "3%", mr: "3%", mt: 3, mb: 1 }} />
                                <Typography sx={{ ml: "10%", mt: 2, mb: 3 }} textAlign={"left"} variant="h6" >
                                    <strong>Fotos del registro de salida</strong>
                                </Typography>

                                <Box sx={{ ml: "10%", mr: "10%" }}>
                                    <Grid container spacing={2}>
                                        {images.map((image, index) => (
                                            <Grid item xs={6} sm={3} md={3} key={image.stiId}>
                                                <Card>
                                                    <CardActionArea onClick={() => handleImageClick(index)}>
                                                        <CardMedia
                                                            component="img"
                                                            height="200"
                                                            image={String(image.stiImagen)}
                                                            alt={image.stiNombre}
                                                        />
                                                        <CardContent>
                                                            <Typography variant="h6">{image.stiNombre}</Typography>
                                                            <Typography variant="body2" color="text.secondary">
                                                                Fecha: {new Date(image.stiFecha).toLocaleDateString()}
                                                            </Typography>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>

                                    {/* Modal para mostrar imagen ampliada */}
                                    {currentIndex !== null && (
                                        <Dialog open={openDialog} maxWidth="md">
                                            <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <IconButton onClick={handlePrevious} disabled={currentIndex === 0}>
                                                    <ArrowBack />
                                                </IconButton>
                                                {images[currentIndex].stiNombre}
                                                <IconButton onClick={handleNext} disabled={currentIndex === null || currentIndex === images.length - 1}>
                                                    <ArrowForward />
                                                </IconButton>
                                            </DialogTitle>
                                            <DialogContent>
                                                {currentIndex !== null && (
                                                    <Box
                                                        component="img"
                                                        src={String(images[currentIndex].stiImagen)}
                                                        alt="Imagen ampliada"
                                                        sx={{
                                                            width: "100%",
                                                            maxHeight: "80vh",
                                                            objectFit: "contain",
                                                            borderRadius: 2,
                                                        }}
                                                    />
                                                )}
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={(e) => setOpenDialog(false)}>Cerrar</Button>
                                            </DialogActions>
                                        </Dialog>
                                    )}
                                </Box>
                                <Divider sx={{ ml: "3%", mr: "3%", mt: 3, mb: 1 }} />
                                <Typography sx={{ ml: "10%", mt: 2, mb: 3 }} textAlign={"left"} variant="h6" >
                                    <strong>Fotos del registro de entrada</strong>
                                </Typography>



                                <Input sx={{ ml: "10%", mb: 2 }}
                                    type="file"
                                    inputProps={{ accept: "image/jpeg, image/png" }}
                                    onChange={handleFileChange}
                                />

                                <Box sx={{ ml: "10%", mr: "10%", mb: 2 }}>

                                    <Grid container spacing={2}>
                                        {tempImages.map((image, index) => (
                                            <Grid item xs={6} sm={3} md={3} key={image.stiId}>
                                                <Card
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => handleOpenModal(index)}
                                                >
                                                    <CardMedia
                                                        component="img"
                                                        height="140"
                                                        image={typeof image.stiImagen === "string"
                                                            ? image.stiImagen
                                                            : URL.createObjectURL(image.stiImagen)} // Convierte File en URL
                                                        alt={image.stiNombre}
                                                    />
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>

                                <Modal open={currentImageIndex !== null} onClose={handleCloseModal}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "100%",
                                            bgcolor: "background.paper",
                                            p: 4,
                                            position: "relative",
                                        }}
                                    >
                                        {currentImageIndex !== null && (
                                            <>
                                                <IconButton
                                                    sx={{ position: "absolute", left: 20, top: "50%" }}
                                                    onClick={handlePrevImage}
                                                >
                                                    <ArrowBack />
                                                </IconButton>
                                                <img
                                                    src={URL.createObjectURL(tempImages[currentImageIndex].stiImagen)} // Si es un File, crea una URL temporal
                                                    alt={tempImages[currentImageIndex].stiNombre}
                                                    style={{ maxHeight: "80%", maxWidth: "80%" }}
                                                />
                                                <IconButton
                                                    sx={{ position: "absolute", right: 20, top: "50%" }}
                                                    onClick={handleNextImage}
                                                >
                                                    <ArrowForward />
                                                </IconButton>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() =>
                                                        handleDeleteImage(tempImages[currentImageIndex].stiNombre)
                                                    }
                                                    sx={{ mt: 2 }}
                                                >
                                                    Eliminar Imagen
                                                </Button>
                                            </>
                                        )}
                                    </Box>
                                </Modal>

                                <Divider sx={{ mt: 2, mb: 2, ml: "3%", mr: "3%" }} />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "7%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    KM final:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name="stdKmFin"
                                    type="number"
                                    onChange={(e) => {
                                        setDataTestDrive({
                                            ...dataTestDrive,
                                            stdKmFin: Number(e.target.value),
                                            stdFinalizado: 1,
                                            stdFechaFin: new Date(),
                                            stdUsuCorreoFin: username,
                                            stdUsuNombreFin: name,
                                        })
                                    }}
                                    value={dataTestDrive.stdKmFin}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    sx={{ mt: 1, mb: 1, width: "11%" }}
                                />

                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "3%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Hora final:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={new Date().toLocaleString()}
                                    sx={{ mt: 1, mb: 1, width: "17%" }}
                                />
                                <Divider sx={{ mt: 2, mb: 2, ml: "3%", mr: "3%" }} />

                                <Button
                                    variant="contained"
                                    onClick={(e) => { postGuardarRegistro(dataTestDrive) }}
                                    sx={{ ml: "42%", width: "16%" }}
                                    disabled={tempImages.length === 0 ? true : dataTestDrive.stdKmFin === 0 ? true : false}
                                >
                                    Guardar
                                </Button>
                                <Dialog
                                    open={openDialogGuardado}
                                    //onClose={handleCloseDialog}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Guardado correctamente!
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            href={`/testdrive/pendientes`}
                                            onClick={function (event) {
                                                setOpenDialogGuardado(false);
                                            }}
                                            autoFocus
                                        >
                                            OK
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                    open={openDialogGuardadoError}
                                    //onClose={handleCloseDialog}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Error al guardar los datos!
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={function (event) {
                                                setOpenDialogGuardadoError(false);
                                            }}
                                            autoFocus
                                        >
                                            OK
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </>}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        );
    } else {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Paper>
                    <Grid
                        //container
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Typography
                            sx={{ mt: 2, mb: 1.5 }}
                            variant="h6"
                            textAlign={"center"}
                        >
                            Acceso denegado
                        </Typography>
                    </Grid>
                </Paper>
            </Container>
        );
    }
};
