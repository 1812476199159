import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import {
  AnoType,
  MesType,
  PlantillaMUCResumenType,
  PlantillaMUCType,
  TipoFinanciamientoType,
  VentasCRMFIType,
  bodegaType,
  canalNegocioType,
} from "../../types";
import { GridCellParams } from "@mui/x-data-grid";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { blue, green, grey, orange, red } from "@mui/material/colors";
import { wait } from "@testing-library/user-event/dist/utils";

export const FIConsultaPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    getMarcas();
    getBodegas();
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);

      setUsername(account.username);
      console.log(account);
    } else {
      setName("");
    }
  }, [account]);

  type marcaType = {
    marId: number;
    marNombre: string;
  };
  type lineaType = {
    linId: number;
    linNombre: string;
  };

  const listadoMarcas: marcaType[] = [];
  const listadoAgencias: bodegaType[] = [];
  const listadoLineas: lineaType[] = [
    // { linId: 1, linNombre: "Carc" },
    { linId: 2, linNombre: "Livianos" },
    { linId: 3, linNombre: "Pesados" },
    // { linId: 4, linNombre: "Flotas" },
  ];
  const listaAnos: AnoType[] = [
    { id: 2023, ano: 2023 },
    { id: 2024, ano: 2024 },
    { id: 2025, ano: 2025 },
  ];

  const listaTipo: TipoFinanciamientoType[] = [
    { id: 1, nombre: "Contado" },
    { id: 2, nombre: "Credito" },
    { id: 99, nombre: "Todo" },
  ];

  const listaEstado: TipoFinanciamientoType[] = [
    { id: 0, nombre: "Pendiente" },
    { id: 1, nombre: "Por pagar" },
    { id: 2, nombre: "Pagado" },
    { id: 3, nombre: "Anulado" },
    { id: 99, nombre: "Todo" },
  ];

  const listaMes: MesType[] = [
    { id: 1, mes: "Enero" },
    { id: 2, mes: "Febrero" },
    { id: 3, mes: "Marzo" },
    { id: 4, mes: "Abril" },
    { id: 5, mes: "Mayo" },
    { id: 6, mes: "Junio" },
    { id: 7, mes: "Julio" },
    { id: 8, mes: "Agosto" },
    { id: 9, mes: "Septiembre" },
    { id: 10, mes: "Octubre" },
    { id: 11, mes: "Noviembre" },
    { id: 12, mes: "Diciembre" },
  ];

  const [listaDatos, setListaDatos] = useState<VentasCRMFIType[]>([]);
  const [dataMarca, setDataMarca] = useState<marcaType[]>(listadoMarcas);
  const [dataAgencia, setDataAgencia] = useState<bodegaType[]>(listadoAgencias);
  const [dataAno, setDataAno] = useState<AnoType[]>(listaAnos);
  const [dataMes, setDataMes] = useState<MesType[]>(listaMes);
  const [dataEstado, setDataEstado] =
    useState<TipoFinanciamientoType[]>(listaEstado);
  const [dataTipo, setDataTipo] = useState<TipoFinanciamientoType[]>(listaTipo);
  const [marca, setMarca] = React.useState(1);
  const [ano, setAno] = React.useState(2023);
  const [mes, setMes] = React.useState(12);
  const [estado, setEstado] = React.useState(0);
  const [agencia, setAgencia] = React.useState(0);
  const [tipo, setTipo] = React.useState(0);
  const [mostrar, setMostrar] = React.useState<boolean>(false);

  const handleChangeMarca = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMarca(Number(event.target.value));
    setMostrar(false);
  };
  const handleChangeAno = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAno(Number(event.target.value));
    setMostrar(false);
  };
  const handleChangeMes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMes(Number(event.target.value));
    setMostrar(false);
  };
  const handleChangeAgencia = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgencia(Number(event.target.value));
    setMostrar(false);
  };
  const handleChangeTipo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTipo(Number(event.target.value));
    setMostrar(false);
  };
  const handleChangeEstado = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEstado(Number(event.target.value));
    setMostrar(false);
  };

  async function getBodegas() {
    try {
      await axios
        .get<bodegaType[]>(
          "https://apisatelite.azurewebsites.net/dimbodega" ///zona/" + idZona
        )
        .then((response) => {
          setDataAgencia(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataAgencia, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getMarcas() {
    try {
      await axios
        .get<marcaType[]>("https://apisatelite.azurewebsites.net/dimmarca")
        .then((response) => {
          setDataMarca(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const columns: GridColDef[] = [
    // {field: "id", headerName: "id",   editable: false,   width: 100},

    {
      field: "estado",
      headerName: "Estado",
      editable: false,
      width: 50,
      renderCell: (params) => {
        return (
          <div>
            {params.row.nfiEstado === 0 ? (
              <RadioButtonCheckedIcon sx={{ color: blue[500] }} />
            ) : params.row.nfiEstado === 1 ? (
              <RadioButtonCheckedIcon sx={{ color: orange[500] }} />
            ) : params.row.nfiEstado === 2 ? (
              <RadioButtonCheckedIcon sx={{ color: green[500] }} />
            ) : params.row.nfiEstado === 3 ? (
              <RadioButtonCheckedIcon sx={{ color: red[500] }} />
            ) : (
              <RadioButtonCheckedIcon sx={{ color: grey[500] }} />
            )}          
          </div>
        );
      },
    },
    { field: "idcrm", headerName: "ID CRM", editable: false, width: 95 },
    {
      field: "fechaFactura",
      headerName: "Facturación",
      editable: false,
      width: 90,
      valueGetter: ({ row }) => {
        return String(row.fechaFactura).substring(0, 10);
      },
    },

    { field: "asesor", headerName: "Asesor", editable: false, width: 130 },
    { field: "cedula", headerName: "Cédula", editable: false, width: 90 },
    { field: "cliente", headerName: "Cliente", editable: false, width: 200 },
    //{ field: "dealname", headerName: "Negocio", editable: false, width: 200 },
    { field: "agencia", headerName: "Agencia", editable: false, width: 125 },
    {
      field: "formaPago",
      headerName: "Forma pago",
      editable: false,
      width: 100,
    },
    {
      field: "action",
      headerName: "Detalle",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        //{params.row.Id}
        return (
          <Button
            href={`/fi/negocio/${params.row.idcrm}`}
            onClick={function (event) {
              //setRespuestaDetalleRow(params.row);
              //handleOpen();
            }}
          >
            Abrir{" "}
          </Button>
        );
      },
    },
  ];

  async function getListado(
    anio: number,
    mes: number,
    marca: string,
    idBodega: number,
    tipofinanciamiento: string,
    mfi_estado: number
  ) {
    try {
      await axios
        .get<VentasCRMFIType[]>(
          "https://apisatelite.azurewebsites.net/vventassatelitecrmfi/consulta/" +
            String(anio) +
            "/" +
            String(mes) +
            "/" +
            marca +
            "/" +
            String(idBodega) +
            "/" +
            tipofinanciamiento +
            "/" +
            String(mfi_estado)
        )
        .then((response) => {
          setListaDatos(response.data);
          setMostrar(true);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getListadoTodoTodo(
    anio: number,
    mes: number,
    marca: string,
    idBodega: number
  ) {
    try {
      await axios
        .get<VentasCRMFIType[]>(
          "https://apisatelite.azurewebsites.net/vventassatelitecrmfi/todo/" +
            String(anio) +
            "/" +
            String(mes) +
            "/" +
            marca +
            "/" +
            String(idBodega)
        )
        .then((response) => {
          setListaDatos(response.data);
          setMostrar(true);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getListadoEstadoTodo(
    anio: number,
    mes: number,
    marca: string,
    idBodega: number,
    tipofinanciamiento: string
  ) {
    try {
      await axios
        .get<VentasCRMFIType[]>(
          "https://apisatelite.azurewebsites.net/vventassatelitecrmfi/estadotodo/" +
            String(anio) +
            "/" +
            String(mes) +
            "/" +
            marca +
            "/" +
            String(idBodega) +
            "/" +
            tipofinanciamiento
        )
        .then((response) => {
          setListaDatos(response.data);
          setMostrar(true);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getListadoFormaPagoTodo(
    anio: number,
    mes: number,
    marca: string,
    idBodega: number,
    mfi_estado: number
  ) {
    try {
      await axios
        .get<VentasCRMFIType[]>(
          "https://apisatelite.azurewebsites.net/vventassatelitecrmfi/formapagotodo/" +
            String(anio) +
            "/" +
            String(mes) +
            "/" +
            marca +
            "/" +
            String(idBodega) +
            "/" +
            String(mfi_estado)
        )
        .then((response) => {
          setListaDatos(response.data);
          setMostrar(true);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Seguros - F&I
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "8%",
                  marginLeft: "18%",
                }}
                style={{ display: "inline-block" }}
              >
                Año:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={ano}
                onChange={handleChangeAno}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "5%", width: "19%" }}
              >
                {dataAno.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.ano}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "5%",
                }}
                style={{ display: "inline-block" }}
              >
                Mes:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={mes}
                onChange={handleChangeMes}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "10%", width: "19%" }}
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {dataMes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.mes}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "8%",
                  marginLeft: "18%",
                }}
                style={{ display: "inline-block" }}
              >
                Marca:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={marca}
                onChange={handleChangeMarca}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "5%", width: "19%" }}
              >
                {dataMarca.map((option) => (
                  <MenuItem key={option.marId} value={option.marId}>
                    {option.marNombre}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "5%",
                }}
                style={{ display: "inline-block" }}
              >
                Agencia:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={agencia}
                onChange={handleChangeAgencia}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "10%", width: "19%" }}
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {dataAgencia.map((option) => (
                  <MenuItem key={option.bodId} value={option.bodId}>
                    {option.agencia}
                  </MenuItem>
                ))}
              </TextField>

              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "8%",
                  marginLeft: "18%",
                }}
                style={{ display: "inline-block" }}
              >
                Forma pago:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={tipo}
                onChange={handleChangeTipo}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "5%", width: "19%" }}
              >
                {dataTipo.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.nombre}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "5%",
                }}
                style={{ display: "inline-block" }}
              >
                Estado:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={estado}
                onChange={handleChangeEstado}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "10%", width: "19%" }}
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {dataEstado.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.id === 0 ? (
                      <RadioButtonCheckedIcon sx={{ color: blue[500] }} />
                    ) : option.id === 1 ? (
                      <RadioButtonCheckedIcon sx={{ color: orange[500] }} />
                    ) : option.id === 2 ? (
                      <RadioButtonCheckedIcon sx={{ color: green[500] }} />
                    ) : option.id === 3 ? (
                      <RadioButtonCheckedIcon sx={{ color: red[500] }} />
                    ) : option.id === 4 ? (
                      <RadioButtonCheckedIcon sx={{ color: red[500] }} />
                    ) : (
                      <RadioButtonCheckedIcon sx={{ color: grey[500] }} />
                    )}
                    {<>&nbsp;&nbsp;&nbsp;&nbsp;</>}
                    {option.nombre}
                  </MenuItem>
                ))}
              </TextField>

              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 1,
                  width: "12%",
                  marginLeft: "44%",
                }}
                onClick={function (event) {
                  console.log(
                    "mis parametros a buscar son >>> Forma pago: ",
                    String(tipo),
                    " - Estado : ",
                    String(estado)
                  );
                  if (tipo === 99 && estado === 99) {
                    getListadoTodoTodo(
                      ano,
                      mes,
                      marca === 1
                        ? "CHEVROLET"
                        : marca === 2
                        ? "GAC"
                        : "VOLKSWAGEN",
                      agencia
                    );
                  }
                  if (tipo === 99 && estado != 99) {
                    getListadoFormaPagoTodo(
                      ano,
                      mes,
                      marca === 1
                        ? "CHEVROLET"
                        : marca === 2
                        ? "GAC"
                        : "VOLKSWAGEN",
                      agencia,
                      estado
                    );
                  }
                  if (tipo != 99 && estado === 99) {
                    getListadoEstadoTodo(
                      ano,
                      mes,
                      marca === 1
                        ? "CHEVROLET"
                        : marca === 2
                        ? "GAC"
                        : "VOLKSWAGEN",
                      agencia,
                      tipo === 1 ? "CONTADO" : "CREDITO"
                    );
                  }
                  if (tipo != 99 && estado != 99) {
                    getListado(
                      ano,
                      mes,
                      marca === 1
                        ? "CHEVROLET"
                        : marca === 2
                        ? "GAC"
                        : "VOLKSWAGEN",
                      agencia,
                      tipo === 1 ? "CONTADO" : "CREDITO",
                      estado
                    );
                  }
                }}
              >
                Consultar
              </Button>
            </Paper>

            {mostrar ? (
              <Paper
                sx={{
                  padding: "1.2em",
                  borderRadius: "0.5em",
                  backgroundColor: "rgb(255,255,255,0.95)",
                  mt: 1,
                  mb: 4,
                }}
              >
                <Box
                  sx={{
                    //height: 300,
                    width: "100%",
                    "& .verdeoscuro": {
                      backgroundColor: "#BCF39B",
                      color: "#1a3e72",
                    },
                    "& .verde": {
                      backgroundColor: "#CFFFB2",
                      color: "#1a3e72",
                    },
                    "& .naranja": {
                      backgroundColor: "#FEE777",
                      color: "#1a3e72",
                    },
                    "& .amarillo": {
                      backgroundColor: "#FEFFCF",
                      color: "#1a3e72",
                    },
                    "& .gris": {
                      backgroundColor: "#DEDEDE",
                      color: "#1a3e72",
                    },
                    "& .TOTAL": {
                      fontPalette: "dark",
                      fontSize: 16,
                      fontWeight: "bold",
                    },
                  }}
                >
                  <DataGrid
                    //getRowId={(row) => row.id}
                    sx={{ height: 670 }}
                    editMode="row"
                    rows={listaDatos}
                    columns={columns}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    slots={{ toolbar: GridToolbarQuickFilter }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                      pagination: {
                        labelRowsPerPage: "Registros por página:",
                      },
                    }}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                    localeText={{
                      footerTotalRows: false,
                      noRowsLabel: "No se ha encontrado datos.",
                      noResultsOverlayLabel:
                        "No se ha encontrado ningún resultado",
                      toolbarColumns: "Columnas",
                      toolbarColumnsLabel: "Seleccionar columnas",
                      toolbarFilters: "Filtros",
                      toolbarFiltersLabel: "Ver filtros",
                      toolbarFiltersTooltipHide: "Quitar filtros",
                      toolbarFiltersTooltipShow: "Ver filtros",
                    }}
                  />
                </Box>
              </Paper>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
