import { useState, useEffect } from 'react';

interface Location {
  latitude: number;
  longitude: number;
}

export const useGeolocation = () => {
  const [location, setLocation] = useState<Location | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!navigator.geolocation) {
      setError('La geolocalización no es soportada por este navegador.');
      return;
    }

    const handleSuccess = (position: GeolocationPosition) => {
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    };

    const handleError = (error: GeolocationPositionError) => {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          setError('El usuario denegó el permiso para obtener la ubicación.');
          break;
        case error.POSITION_UNAVAILABLE:
          setError('La información de ubicación no está disponible.');
          break;
        case error.TIMEOUT:
          setError('El tiempo de espera para obtener la ubicación se agotó.');
          break;
        default:
          setError('Ocurrió un error desconocido.');
          break;
      }
    };

    const watcher = navigator.geolocation.watchPosition(handleSuccess, handleError);

    return () => {
      navigator.geolocation.clearWatch(watcher);
    };
  }, []);

  return { location, error };
};
