import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import chevrolet from "../../assets/chevrolet.png";
import vw from "../../assets/vw.png";
import gac from "../../assets/gac.png";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ModeloFamiliaType, TablaRentaVhType } from "../../types";
import { redirect } from 'react-router-dom';

export const ModeloFamiliaPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
      getListadoModeloFamilia();
    } else {
      setName("");
    }
  }, [account]);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const params = useParams();
  const isAuthenticated = useIsAuthenticated();
  const [listaModelos, setListaModelos] = useState<ModeloFamiliaType[]>([]);

  const navigate = useNavigate();

  const realizarAccion = (idCotItem: number) => {
    navigate(`/cotizacion/${params.idcrm}/${idCotItem}`);
  };


  function guardar(idCrm: string, idCotItem: number){
    const nuevoRegistro:TablaRentaVhType = {
      idCrm: idCrm,
      idCotItem: idCotItem 
    }
    eliminarRegistro(nuevoRegistro);
  }

  async function eliminarRegistro(parametroGuardar: TablaRentaVhType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .delete(
          "https://apisatelite.azurewebsites.net/tablarentavh/crm/"+ String(params.idcrm)
        )
        .then((response) => {
          console.log("id guardado es: >>>>>>>> ", response);
          postGuardarRegistro(parametroGuardar);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function postGuardarRegistro(parametroGuardar: TablaRentaVhType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/tablarentavh",
          parametroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: >>>>>>>> ", response);
          realizarAccion(parametroGuardar.idCotItem)
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }


  async function getListadoModeloFamilia() {
    try {
      await axios
        .get<ModeloFamiliaType[]>(
          "https://apisatelite.azurewebsites.net/tablarentamodelo/familia/" +
            params.familia
        )
        .then((response) => {
          setListaModelos(response.data);
          console.log("mi respuesta de modelo familia: ", response.data);
        })
        .catch((error) => {
          console.log("entre al catch >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
      <Grid alignItems={"center"} justifyContent={"center"}>
        <Grid item>
          <Paper
            sx={{
              padding: "1.2em",
              borderRadius: "0.5em",
              backgroundColor: "rgb(255,255,255,0.95)",
              mt: 2,
            }}
          >
              <Typography variant="h5" textAlign={"center"} sx={{ mt: 1 }}>
                MODELOS DE LA FAMILIA {params.familia}
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.7)",
                mt: 1,
              }}
            >
              <Grid sx={{ marginLeft: 20 }}>
                {listaModelos.map((item) => (
                  <Card
                    sx={{
                      width: 240,
                      height: 370,
                      display: "inline-block",
                      marginRight: 4,
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    <CardMedia
                      sx={{ height: 140 }}
                      image={
                        item.marca === "CHEVROLET"
                          ? chevrolet
                          : item.marca === "GAC"
                          ? gac
                          : vw
                      }
                      title="Corporacion Proauto"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        {item.modelo}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <strong>Año:</strong> {item.anio} 
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                      <strong>Días Inv:</strong> {item.dias} 
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        PVP:{" "}
                        <strong>
                          {formatter.format(item.pvp).replace("$", "$  ")}{" "}
                        </strong>
                        ;
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                      <strong>Rebate:</strong> {item.rebate} 
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                      variant="contained"
                      sx={{width: "60%", marginLeft: "20%", marginRight: "20%"}}
                        onClick={function (event) {guardar(String(params.idcrm),Number(item.idCotItem))}}
                       // href={`/cotizacion/${params.idcrm}/${item.idCotItem}`}
                        size="small"
                      >
                        Catalogo de Accesrios
                      </Button>
                    </CardActions>
                  </Card>
                ))}
              </Grid>
              <Button
                onClick={function (event) {
                  window.history.go(-1);
                }}
              >
                ATRAS
              </Button>

              
              <Typography
                variant="h6"
                textAlign={"center"}
                sx={{ mt: 3, mb: 1 }}
              >
                © Corporación Proauto 2025
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return <></>;
  }
};
