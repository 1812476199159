import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import {
  AnoType,
  CrearDealHubSpotPropiedadesType,
  CrearDealHubSpotType,
  CrmAsesorType,
  MesType,
  SateliteCrearNegocioType,
  SateliteModeloFamiliaType,
  SateliteSolicitudNegocioType,
  VSateliteUsuarioType,
  vventassatelitecrmType,
  VVentasSateliteValidacionType,
} from "../../types";
import { useParams } from "react-router-dom";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import { PlaceOutlined } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import { orange, red } from "@mui/material/colors";

export const ReferidosIndexPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
      getAsesores();
      getUsuario(account.username);
    } else {
      setName("");
    }
  }, [account]);

  const [sateliteUsuario, setSateliteUsuario] = useState<VSateliteUsuarioType>({
    id: 0,
    nombre: "",
    correo: "",
    idMarca: 0,
    idBodega: 0,
    usuSupervisor: 0,
    usuJefeAgencia: 0,
    agenciaCrm: "",
    marca: "",
    bodega: "",
  });

  async function getUsuario(correo: string) {
    try {
      await axios
        .get<VSateliteUsuarioType>(
          "https://apisatelite.azurewebsites.net/vsateliteusuario/correo/" +
          correo
        )
        .then((response) => {
          console.log("mi user es >>>>>>", response.data);
          setSateliteUsuario(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(sateliteUsuario, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const fechaActual = new Date();
  const anioActual = fechaActual.getFullYear();
  const mesActual = fechaActual.getMonth() + 1;

  const listaAnos: AnoType[] = [
    { id: 2023, ano: 2023 },
    { id: 2024, ano: 2024 },
    { id: 2025, ano: 2025 },
  ];
  const listaMes: MesType[] = [
    { id: 1, mes: "Enero" },
    { id: 2, mes: "Febrero" },
    { id: 3, mes: "Marzo" },
    { id: 4, mes: "Abril" },
    { id: 5, mes: "Mayo" },
    { id: 6, mes: "Junio" },
    { id: 7, mes: "Julio" },
    { id: 8, mes: "Agosto" },
    { id: 9, mes: "Septiembre" },
    { id: 10, mes: "Octubre" },
    { id: 11, mes: "Noviembre" },
    { id: 12, mes: "Diciembre" },
  ];

  const [anoConsulta, setAnoConsulta] = React.useState(anioActual);
  const [mesConsulta, setMesConsulta] = React.useState(mesActual);
  const [mostrar, setMostrar] = React.useState<boolean>(false);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [openDialogError, setOpenDialogError] = React.useState(false);
  const [dataAno, setDataAno] = useState<AnoType[]>(listaAnos);
  const [dataMes, setDataMes] = useState<MesType[]>(listaMes);
  const listaListado: SateliteSolicitudNegocioType[] = [];
  const listaAsesor: CrmAsesorType[] = [];
  const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
  const [openDialog0, setOpenDialog0] = React.useState(false);
  const [openDialog1, setOpenDialog1] = React.useState(false);
  const [respuestaCrearNego, setRespuestaCrearNego] = React.useState("");

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleChangeAno = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnoConsulta(Number(event.target.value));
    setMostrar(false);
  };
  const handleChangeMes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMesConsulta(Number(event.target.value));
    setMostrar(false);
  };

  const [dataLista, setDataLista] =
    useState<SateliteSolicitudNegocioType[]>(listaListado);

  const [dataAsesores, setDataAsesores] =
    useState<CrmAsesorType[]>(listaAsesor);

  const [dataNegocio, setDataNegocio] = useState<SateliteSolicitudNegocioType>({
    ssnFecha: "",
    ssnFechaEstado: "",
    ssnUsuario: "",
    ssnEstado: 0,
    ssnUsuarioAprueba: "",
    ssnEstadoRazon: "",
    idBodega: 0,
    idMarca: 0,
    ssnAgency: "",
    ssnAgencia: "",
    ssnDealname: "",
    ssnDealstage: "",
    ssnOrigen: "",
    ssnPipeline: "",
    ssnSource: "",
    ssnTipoCliente: "",
    ssnChannel: "",
    ssnIdentificacion: "",
    ssnTipoPersona: 0,
    ssnApellido1: "",
    ssnApellido2: "",
    ssnNombre1: "",
    ssnNombre2: "",
    ssnCorreo: "",
    ssnTelefono: "",
    ssnReferidor: "",
    ssnIdFamiliaVh: 0,
    ssnFamiliaVh: "",
    ssnIdVh: 0,
    ssnVh: "",
    ssnKmat: "",
    ssnAsesorId: 0,
    ssnAsesorNombre: "",
    ssnAsesorCorreo: "",
    ssnOwnerId: "",
    ssnFinanciamientoId: 0,
    ssnFinanciamiento: "",
    ssnPlacaUsado: "",
  });


  const [validacionNegocio, setValidacionNegocio] =
    useState<VVentasSateliteValidacionType>({
      id: 0,
      idCrm: "",
      idCotBodega: 0,
      contactdocument: "",
      interestedin: "",
      agency: "",
      createdate: "",
      dias: 0,
      canal: "",
    });



  async function guardarRegistroNotificacion(
    scnFecha: string,
    idBodega: number,
    idMarca: number,
    scnUsuario: string,
    scnIdentificacion: string,
    scnCanal: string,
    scnCorreo: string,
    scnTelefono: string,
    scnNuevo: number,
    scnDias: number,
    mfaId: number,
    idAsesor: number,
    existe: number,
    placa: string
  ) {
    const nuevoRegistro: SateliteCrearNegocioType = {
      scnFecha: scnFecha,
      idBodega: idBodega,
      idMarca: idMarca,
      scnUsuario: scnUsuario,
      scnIdentificacion: scnIdentificacion,
      scnCanal: scnCanal,
      scnCorreo: scnCorreo,
      scnTelefono: scnTelefono,
      scnNuevo: scnNuevo,
      scnDias: scnDias,
      mfaId: mfaId,
      idAsesor: idAsesor,
      placa: placa,
    };

    postGuardarNegocio(nuevoRegistro, existe);
  }

  async function postGuardarNegocio(
    registroGuardar: SateliteCrearNegocioType,
    existe: number
  ) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/satelitecrearnegocio",
          registroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: ", response.data.id);
          console.log("mi existe es >>>>>", existe);
          existe > 0 ? setOpenDialog1(true) : setOpenDialog0(true);
        }); //setArticleId(response.data.id));
      // handleClickOpen();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }


  async function setRegistroGuardar(
    scnFecha: string,
    idBodega: number,
    idMarca: number,
    scnUsuario: string,
    scnIdentificacion: string,
    scnCanal: string,
    scnCorreo: string,
    scnTelefono: string,
    scnNuevo: number,
    scnDias: number,
    mfaId: number,
    idAsesor: number,
    existe: number,
    idNegocioExiste: string,
    placa: string
  ) {
    console.log("tabla a guardar >> ");
    const nuevoRegistro: SateliteCrearNegocioType = {
      scnFecha: scnFecha,
      idBodega: idBodega,
      idMarca: idMarca,
      scnUsuario: scnUsuario,
      scnIdentificacion: scnIdentificacion,
      scnCanal: scnCanal,
      scnCorreo: scnCorreo,
      scnTelefono: scnTelefono,
      scnNuevo: scnNuevo,
      scnDias: scnDias,
      mfaId: mfaId,
      idAsesor: idAsesor,
      placa: placa,
    };
    console.log("datos a guardar >> ", JSON.stringify(nuevoRegistro, null, 4));
    console.log(
      "validacion historico >> ",
      JSON.stringify(validacionNegocio, null, 4)
    );
    //postGuardarNegocio(nuevoRegistro, validacionNegocio.id);
    existe > 0
      ? guardarRegistroNotificacion(
        new Date(Date.now()).toISOString().substring(0, 10),
        dataNegocio.idBodega,
        dataNegocio.idMarca,
        username,
        idNegocioExiste,
        "",
        dataNegocio.ssnApellido1 +
        " " +
        dataNegocio.ssnNombre1,
        "",
        existe,
        0,
        dataNegocio.ssnIdVh,
        dataNegocio.ssnAsesorId,
        existe,
        "" //placa
      )
      :
      guardarNegocio(existe);
  }

  async function guardarNegocio(existe: number) {
    const propiedades: CrearDealHubSpotPropiedadesType = {
      contactdocument: dataNegocio.ssnIdentificacion,
      contactname:
        dataNegocio.ssnTipoPersona === 3
          ? ""
          : dataNegocio.ssnNombre1 +
          " " +
          dataNegocio.ssnNombre2,
      contactlastname:
        dataNegocio.ssnTipoPersona === 3
          ? dataNegocio.ssnApellido1
          : dataNegocio.ssnApellido1 +
          " " +
          dataNegocio.ssnApellido2,
      contactemail: dataNegocio.ssnCorreo,
      contactphone: dataNegocio.ssnTelefono,
      dealname:
        dataNegocio.ssnTipoPersona === 3
          ? dataNegocio.ssnApellido1 + " - " + dataNegocio.ssnFamiliaVh
          : dataNegocio.ssnApellido1 +
          " " +
          dataNegocio.ssnNombre1 +
          " - " +
          dataNegocio.ssnFamiliaVh,
      dealstage:
        dataNegocio.idMarca === 1
          ? "235777854" // anterior >> 19582632
          : dataNegocio.idMarca === 2
            ? "10889473"
            : "10889408",
      hubspot_owner_id: Number(dataNegocio.ssnOwnerId),
      interestedin: dataNegocio.ssnFamiliaVh,
      origen: "Referidos",
      pipeline:
        dataNegocio.idMarca === 1
          ? "136919880"// antiguo >> "6580563" >> 27/08/2024
          : dataNegocio.idMarca === 2
            ? "10889471"
            : "10889406",
      source: "Referidos internos",
      tipo_persona_cliente:
        Number(dataNegocio.ssnTipoPersona) === 1
          ? "Persona Natural"
          : Number(dataNegocio.ssnTipoPersona) === 2
            ? "Persona Natural"
            : Number(dataNegocio.ssnTipoPersona) === 3
              ? "Persona Jurídica"
              : "Extranjero",
      pointofsale: sateliteUsuario.agenciaCrm,
      channel: "Referidos",
      formname: "Referidos internos"
    };
    dataNegocio.idMarca === 3 ? propiedades.agencia = sateliteUsuario.agenciaCrm : propiedades.agency = sateliteUsuario.agenciaCrm;
    const nuevoRegistro: CrearDealHubSpotType = {
      accessToken:
        dataNegocio.idMarca === 1
          ? "pat-na1-4775b2d8-02a8-49b5-b6d0-a6b9967924e5"
          : dataNegocio.idMarca === 2
            ? "pat-na1-31beb53b-a3dd-46bf-9833-e4c990d44ff1"
            : "pat-na1-1a846a5e-e897-492a-9d37-84cfd60aef1a",
      email: dataNegocio.ssnCorreo,
      firstName:
        dataNegocio.ssnTipoPersona === 3
          ? ""
          : dataNegocio.ssnNombre1 +
          " " +
          dataNegocio.ssnNombre2,
      lastName:
        dataNegocio.ssnTipoPersona === 3
          ? dataNegocio.ssnApellido1
          : dataNegocio.ssnApellido1 +
          " " +
          dataNegocio.ssnApellido2,
      phone:
        dataNegocio.ssnTelefono.length === 10 &&
          dataNegocio.ssnTelefono.substring(0, 2) === "09"
          ? "+593" + dataNegocio.ssnTelefono.substring(1, 100)
          : dataNegocio.ssnTelefono,
      ownerId: Number(dataNegocio.ssnOwnerId),
      dealData: propiedades,
    };
    console.log("datos a guardar >> ", JSON.stringify(nuevoRegistro, null, 4));
    postGuardarRegistro(nuevoRegistro, existe);
  }

  async function postGuardarRegistro(parametroGuardar: CrearDealHubSpotType, existe: number) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/hubspotdeal/crearnegocio",
          parametroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: >>>>>>>> ", response);

          if (response.statusText === "Created") {
            console.log(
              "mi nuevo id es >>>>>>>>>>>> ", response.data
            );
            setRespuestaCrearNego(String(response.data));
            guardarRegistroNotificacion(
              new Date(Date.now()).toISOString().substring(0, 10),
              dataNegocio.idBodega,
              dataNegocio.idMarca,
              username,
              response.data,
              "",
              parametroGuardar.lastName + " " + parametroGuardar.lastName,
              "",
              existe,
              0,
              dataNegocio.ssnIdVh,
              dataNegocio.ssnAsesorId,
              existe,
              "" //placa
            );
          } else {
            setOpenDialogError(true);
          }
          console.log("respuesta del registro peticion >>> :", response.data);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getHistorico(
    _cedula: string,
    _idBodega: number,
    _modelo: string,
    _marca: number
  ) {
    try {
      setBotonDeshabilitado(true);
      await axios
        .get<VVentasSateliteValidacionType>(
          "https://apisatelite.azurewebsites.net/vventassatelitevalidacion/buscarduplicado/" +
          _cedula +
          "/" +
          String(_idBodega) +
          "/" +
          _modelo +
          "/" +
          _marca
        )
        .then((response) => {
          setValidacionNegocio(response.data);
          console.log(
            "mi historico es >>>>>",
            JSON.stringify(validacionNegocio, null, 4)
          );
          setRegistroGuardar(
            new Date(Date.now()).toISOString().substring(0, 10),
            dataNegocio.idBodega,
            dataNegocio.idMarca,
            username,
            dataNegocio.ssnIdentificacion,
            "GESTION EXTERNA",
            dataNegocio.ssnCorreo,
            dataNegocio.ssnTelefono,
            1,
            0,
            dataNegocio.ssnIdVh,
            dataNegocio.ssnAsesorId,
            response.data.id,
            response.data.idCrm,
            "" //placa
          );
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(validacionNegocio, null, 4));
    } catch (error) {
      setBotonDeshabilitado(false);
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getAsesores() {
    try {
      await axios
        .get<CrmAsesorType[]>(
          "https://apisatelite.azurewebsites.net/vcrmasesor/todo"
        )
        .then((response) => {
          setDataAsesores(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataAsesores, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getListadoNegocios() {
    try {
      await axios
        .get<SateliteSolicitudNegocioType[]>(
          "https://apisatelite.azurewebsites.net/satelitesolicitudnegocio/consultar/" +
          String(anoConsulta) +
          "/" +
          String(mesConsulta) + "/Referidos"
        )
        .then((response) => {
          setDataLista(response.data);
          setMostrar(true);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataLista, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const columns: GridColDef[] = [
    {
      headerClassName: "theme-header",
      field: "ssnFecha",
      headerName: "Fecha",
      editable: false,
      width: 90,
      valueGetter: ({ row }) => {
        return String(row.ssnFecha).substring(0, 10);
      },
    },
    {
      headerClassName: "theme-header",
      field: "ssnReferidor",
      headerName: "Referidor",
      editable: false,
      width: 90,
    },
    {
      headerClassName: "theme-header",
      field: "ssnIdentificacion",
      headerName: "Cédula",
      editable: false,
      width: 90,
    },
    {
      headerClassName: "theme-header",
      field: "ssnDealname",
      headerName: "Negocio",
      editable: false,
      width: 200,
    },
    {
      headerClassName: "theme-header",
      field: "ssnAsesorNombre",
      headerName: "Asesor",
      editable: false,
      width: 150,
    },
    {
      headerClassName: "theme-header",
      field: "ssnAgency",
      headerName: "Agencia",
      editable: false,
      width: 150,
    },
    {
      headerClassName: "theme-header",
      field: "ssnCorreo",
      headerName: "Correo",
      editable: false,
      width: 150,
    },
    {
      headerClassName: "theme-header",
      field: "ssnTelefono",
      headerName: "Telefono",
      editable: false,
      width: 90,
    },
    {
      headerClassName: "theme-header",
      field: "action",
      headerName: "Detalle",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <Button
            onClick={function (event) {
              setDataNegocio(params.row);
              setShowDialog(true);
            }}
          >
            Abrir
          </Button>
        );
      },
    },
  ];

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Referidos
              </Typography>
            </Paper>

            {/* insertar validacion para mostrar */}
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
                mb: 4,
              }}
            >
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "5%",
                  marginLeft: "27%",
                }}
                style={{ display: "inline-block" }}
              >
                Año:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={anoConsulta}
                onChange={handleChangeAno}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "5%", width: "15%" }}
              >
                {dataAno.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.ano}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                }}
                style={{ display: "inline-block" }}
              >
                Mes:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={mesConsulta}
                onChange={handleChangeMes}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "10%", width: "15%" }}
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {dataMes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.mes}
                  </MenuItem>
                ))}
              </TextField>
              <Button
                variant="contained"
                sx={{
                  marginLeft: "42%",
                  width: "16%",
                  mt: 2,
                  mb: 2,
                  backgroundColor: "rgba(21,57,105,0.75)",
                }}
                onClick={function (event) {
                  getListadoNegocios()
                }}
              >
                Consultar
              </Button>
              {mostrar ? (
                <>
                  <Box
                    sx={{
                      "& .theme-header": {
                        backgroundColor: "rgba(21,57,105,0.75)",
                        color: "rgba(255,255,255,1)",
                      },
                    }}
                  >
                    <DataGrid
                      getRowId={(row) => row.ssnId}
                      sx={{ height: 650 }}
                      editMode="row"
                      rows={dataLista}
                      columns={columns}
                      disableRowSelectionOnClick
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      slots={{ toolbar: GridToolbarQuickFilter, }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                        pagination: {
                          labelRowsPerPage: "Registros por página:",
                        },
                      }}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                      }}
                      pageSizeOptions={[10, 25, 50]}
                      localeText={{
                        footerTotalRows: false,
                        noRowsLabel: "No se ha encontrado datos.",
                        noResultsOverlayLabel:
                          "No se ha encontrado ningún resultado",
                        toolbarColumns: "Columnas",
                        toolbarColumnsLabel: "Seleccionar columnas",
                        toolbarFilters: "Filtros",
                        toolbarFiltersLabel: "Ver filtros",
                        toolbarFiltersTooltipHide: "Quitar filtros",
                        toolbarFiltersTooltipShow: "Ver filtros",
                        toolbarQuickFilterPlaceholder: "Buscar",
                      }}
                    />
                  </Box>
                </>
              ) : (
                <></>
              )}
            </Paper>

            <Modal
              open={showDialog}
              onClose={function (event) {
                setShowDialog(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styleModal}>
                <Typography
                  sx={{ mt: 3, mb: 2 }}
                  variant="h6"
                  textAlign={"center"}
                >
                  <strong>{dataNegocio.ssnDealname}</strong>
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "15%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  <strong>Fecha:</strong>
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "80%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  {dataNegocio.ssnFecha.substring(0, 10)}
                </Typography>

                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "15%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  <strong>Identificación:</strong>
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "30%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  {dataNegocio.ssnIdentificacion.substring(0, 10)}
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "15%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  <strong>Cliente:</strong>
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "30%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  {dataNegocio.ssnApellido1 + " " + dataNegocio.ssnApellido2 + " " + dataNegocio.ssnNombre1 + " " + dataNegocio.ssnNombre2}
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "15%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  <strong>Correo:</strong>
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "30%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  {dataNegocio.ssnCorreo}
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "15%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  <strong>Teléfono:</strong>
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "30%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  {dataNegocio.ssnTelefono}
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "15%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  <strong>Referidor:</strong>
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "30%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  {dataNegocio.ssnReferidor}
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "15%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  <strong>Correo:</strong>
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "30%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  {dataNegocio.ssnUsuario}
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "15%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  <strong>Agencia:</strong>
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "80%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  {dataNegocio.ssnAgencia}
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "15%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  <strong>Asesor:</strong>
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "30%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  {dataNegocio.ssnAsesorNombre}
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "15%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  <strong>Correo:</strong>
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "30%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  {dataNegocio.ssnAsesorCorreo}
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "15%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  <strong>Familia:</strong>
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "70%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  {dataNegocio.ssnFamiliaVh}
                </Typography>
                <Button variant="contained" sx={{ width: '12%', mr: "2%", mt: 5, ml: "70%" }}>
                  No aprobado
                </Button>
                <Button variant="contained" sx={{ width: '12%', mr: "2%", mt: 5 }} onClick={(e) => {
                  getHistorico(
                    dataNegocio.ssnIdentificacion,
                    dataNegocio.idBodega,
                    dataNegocio.ssnFamiliaVh,
                    dataNegocio.idMarca
                  );
                }}>
                  Crear en Hubspot
                </Button>
              </Box>
            </Modal>

            <Dialog
              open={openDialog0}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  backgroundColor: "rgb(213,255,213)",
                  boxShadow: "none",
                },
              }}
            >
              <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Negocio creado correctamente
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  sx={{ width: "50%" }}
                  target="_blank"
                  component="a"
                  href={
                    dataNegocio.idMarca === 1
                      ? `https://app.hubspot.com/contacts/8008779/record/0-3/${respuestaCrearNego}`
                      : dataNegocio.idMarca === 2
                        ? `https://app.hubspot.com/contacts/9244718/record/0-3/${respuestaCrearNego}`
                        : `https://app.hubspot.com/contacts/9269500/record/0-3/${respuestaCrearNego}`
                  }
                  size="small"
                >
                  Ir al negocio
                </Button>

                <Button
                  sx={{ width: "50%" }}
                  href="/referidos/index"
                  onClick={function (event) {
                    setOpenDialog0(false);
                    setBotonDeshabilitado(false);
                  }}
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openDialog1}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  backgroundColor: "rgb(253,247,159)",
                  boxShadow: "none",
                },
              }}
            >
              <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
              <DialogContent
                sx={{ width: 300 }}>
                <DialogContentText id="alert-dialog-description">
                  Negocio ya existe
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  href="/referidos/index"
                  onClick={function (event) {
                    setOpenDialog1(false);
                    setBotonDeshabilitado(false);
                  }}
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            {/* dialog error */}
            <Dialog
              open={openDialogError}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  backgroundColor: "rgb(253,247,159)",
                  boxShadow: "none",
                },
              }}
            >
              <DialogTitle id="alert-dialog-title">
                {" "}
                <CancelIcon
                  sx={{ marginRight: "10%", color: red[500] }}
                ></CancelIcon>{" "}
                {"      Error"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Error al grabar negocio
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  //href="/ventas/negocio/crear"
                  onClick={function (event) {
                    setOpenDialogError(false);
                    setBotonDeshabilitado(false);
                  }}
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
