import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    Paper,
    TextField,
    Typography, FormControlLabel, Checkbox, CircularProgress, MenuItem, Alert, List, ListItem, ListItemText
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, useMemo } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Box } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { SateliteParametroCotizadorType, ValorCuotaPostType, ValorCuotasType, vventassatelitecrmType } from "../../types";

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

interface Descuento {
    nombre: string;
    valor: number;
}

type Extra = {
    nombre: string;
    valor: number;
    checked: boolean;
};
export const CotizadorPage: React.FC<{}> = () => {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const isAuthenticated = useIsAuthenticated();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogEnviadoOk, setOpenDialogEnviadoOk] = React.useState(false);
    const [openDialogEnviadoError, setOpenDialogEnviadoError] = React.useState(false);
    const params = useParams();

    const [validarCambios, setValidarCambios] = React.useState(false);
    const [precio, setPrecio] = useState(0);
    const [descuento, setDescuento] = useState('');
    const [porcentaje, setPorcentaje] = useState('');
    const [seguro, setSeguro] = useState<string>('');
    const [cuota, setCuota] = useState<string>('');
    const [entrada, setEntrada] = useState<string>('');
    const [montoAFinanciar, setMontoAFinanciar] = useState<string>('');
    const [matricula, setMatricula] = useState<string>('');
    const [modelos, setModelos] = useState<SateliteParametroCotizadorType[]>([]); // Estado tipado
    const [selectedModelo, setSelectedModelo] = useState('');
    const [descuentoTotal, setDescuentoTotal] = useState(0);
    const [specifications, setSpecifications] = useState<SateliteParametroCotizadorType | null>(null);
    const [precioEspecial, setPrecioEspecial] = useState<number>(0);
    const [minPrecio, setMinPrecio] = useState<number>(0);
    const [maxPrecio, setMaxPrecio] = useState<number>(0);

    const [checked12, setChecked12] = useState(true);
    const [checked24, setChecked24] = useState(true);
    const [checked36, setChecked36] = useState(true);
    const [checked48, setChecked48] = useState(true);
    const [checked60, setChecked60] = useState(true);
    const [checked72, setChecked72] = useState(true);


    const [extrasAdicionales, setExtrasAdicionales] = useState([
        { nombre: "", valor: 0, checked: false },
        //{ nombre: "Extra 2", valor: 150, checked: true }
    ]);
    const [parametroCotizador, setParametroCotizador] = React.useState<SateliteParametroCotizadorType>({
        spcId: 0,
        spcImagen1: "",
        spcImagen2: "",
        spcNombre: "",
        spcText2: "",
        spcMarcaModeloVersion: "",
        spcCaracteristica1: "",
        spcDetalle1: "",
        spcCaracteristica2: "",
        spcDetalle2: "",
        spcCaracteristica3: "",
        spcDetalle3: "",
        spcCaracteristica4: "",
        spcDetalle4: "",
        spcCaracteristica5: "",
        spcDetalle5: "",
        spcCaracteristica6: "",
        spcDetalle6: "",
        spcCaracteristica7: "",
        spcDetalle7: "",
        spcCaracteristica8: "",
        spcDetalle8: "",
        spcDispositivo: "",
        spcDispositivoA1: 0,
        spcDispositivoA2: 0,
        spcDispositivoA3: 0,
        spcDispositivoA4: 0,
        spcDispositivoA5: 0,
        spcPlan1Tipo: "",
        spcPlan1Modelo: "",
        spcPlan1Descripcion: "",
        spcPlan1PvpNormal: 0,
        spcPlan1PvpMpc: 0,
        spcPlan1Cobertura: "",
        spcPlan2Tipo: "",
        spcPlan2Modelo: "",
        spcPlan2Descripcion: "",
        spcPlan2PvpNormal: 0,
        spcPlan2PvpMpc: 0,
        spcPlan2Cobertura: "",
        spcLink1: "",
        spcFamilia: "",
        spcDescuento1: "",
        spcDescuento1Valor: 0,
        spcDescuento2: "",
        spcDescuento2Valor: 0,
        spcDescuento3: "",
        spcDescuento3Valor: 0,
        spcDescuento4: "",
        spcDescuento4Valor: 0,
        spcDescuento5: "",
        spcDescuento5Valor: 0,
        spcPvp: 0,
        idCotItem: 0,
        spcIdDispositivo: 0,
        spcModeloEspecifico: ""
    });


    const [modelo, setModelo] = useState<string>('');
    const [descuentos, setDescuentos] = useState<Descuento[]>([]);
    const [error, setError] = useState<string | null>(null);
    const colores = [
        { id: 1, color: "Rojo", r: 0.56, g: 0.12, b: 0.1 },
        { id: 2, color: "Blanco", r: 0.50, g: 0.50, b: 0.50 },
        { id: 3, color: "Plateado", r: 0.45, g: 0.45, b: 0.45 },
        { id: 4, color: "Café", r: 0.38, g: 0, b: 0 },
        { id: 5, color: "Gris", r: 0.40, g: 0.40, b: 0.40 },
        { id: 6, color: "Negro", r: 0.1, g: 0.1, b: 0.1 },
        { id: 7, color: "Azul", r: 0, g: 0.2, b: 0.38 }
    ];
    const [selectedColor, setSelectedColor] = useState({
        color: 0,
        colorN: "",
        rgbColor: { r: 0, g: 0, b: 0 }
    });

    const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const itemValue = parseInt(event.target.value, 10);
        const selected = colores.find((item) => item.id === itemValue);
        if (selected) {
            setSelectedColor({
                color: selected.id,
                colorN: selected.color,
                rgbColor: { r: selected.r, g: selected.g, b: selected.b }
            });
        }
    };
    const validarExtras = () => {
        for (const extra of extrasAdicionales) {
            if (!String(extra.nombre).trim() || !String(extra.valor).trim()) {
                alert("Por favor, completa todos los campos de nombre y valor de los extras.");
                return false;
            }
        }
        return true;
    };
    const validarCampos = () => {
        for (const extra of extrasAdicionales) {
            if (!String(extra.nombre).trim() || !String(extra.valor).trim()) {
                alert("Por favor, completa todos los campos nombre y valor de extras.");
                return false;
            }
        }
        return true;
    };
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });



    const [isMobile, setIsMobile] = useState<boolean>(false);
    const userAgent = navigator.userAgent;
    const mobileKeywords = [
        "Mobile",
        "Android",
        "iPhone",
        "iPad",
        "Windows Phone",
    ];
    const checkDeviceType = () => {
        setIsMobile(
            mobileKeywords.some((keyword) => userAgent.includes(keyword))
        );
    };

    useEffect(() => {
        //traer datos negocios

        checkDeviceType();
        getDatosNegocio()
        const fetchModelos = async () => {
            try {
                const response = await axios.get("https://apisatelite.azurewebsites.net/sateliteparametrocotizador/app");
                console.log(response.data);
                setModelos(response.data); // Guardar los modelos en el estado
            } catch (error) {
                console.error("Error al cargar los modelos:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchModelos();
    }, []);

    const [dataNegocio, setDataNegocio] = useState<vventassatelitecrmType>({
        id: 0,
        idcrm: "",
        createdate: "",
        cedula: "",
        cliente: "",
        asesor: "",
        dealname: "",
        agencia: "",
        modelo: "",
        correo: "",
        ageDireccion: "",
    });


    const [dataCuotas, setDataCuotas] = useState<ValorCuotasType>({
        success: false,
        subtotal: 0,
        valorCuota12: 0,
        valorCuota24: 0,
        valorCuota36: 0,
        valorCuota48: 0,
        valorCuota60: 0,
        valorCuota72: 0,
    });



    function setearDatosCuotas(
        valorVh: number,
        entrada: number,
    ) {
        const nuevoRegistro: ValorCuotaPostType = {
            valorVh: valorVh,
            entrada: entrada,
        };
        console.log(
            "datos a consultar >> ",
            JSON.stringify(nuevoRegistro, null, 4)
        );
        postDatosCuota(nuevoRegistro);
        setValidarCambios(true);
    }




    async function postDatosCuota(registro: ValorCuotaPostType) {
        const headers = {
            "Content-Type": "application/json",
        };
        try {
            axios
                .post(
                    "https://apisatelite.azurewebsites.net/cotizador/valorcuota",
                    registro,
                    {
                        headers: headers,
                    }
                )
                .then((response) => {
                    console.log("id guardado es: >>>>>>>> ", response);
                    setDataCuotas(response.data)
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getDatosNegocio() {
        try {
            await axios
                .get<vventassatelitecrmType[]>("https://apisatelite.azurewebsites.net/vventassatelitecrm/idcrm/" + params.idcrm)
                .then((response) => {
                    setDataNegocio(response.data[0]);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    const handleModeloChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setValidarCambios(false);
        const itemValue = event.target.value as string;
        setSelectedModelo(itemValue);

        const selectedSpecification = modelos.find(
            (item) => item.spcMarcaModeloVersion === itemValue
        );

        if (selectedSpecification) {
            setParametroCotizador(selectedSpecification)
            console.log("mis especificaciones son >>", selectedSpecification)
            setSpecifications(selectedSpecification);
            setPrecio(selectedSpecification.spcPvp || 0);

            const availableDiscounts = [
                { nombre: selectedSpecification.spcDescuento1, valor: selectedSpecification.spcDescuento1Valor },
                { nombre: selectedSpecification.spcDescuento2, valor: selectedSpecification.spcDescuento2Valor },
                // Agrega más descuentos si es necesario
            ].filter((discount) => discount.nombre);

            setDescuentos(availableDiscounts);
        }
    };


    const [alerta, setAlerta] = useState<string>("");



    const [pvValues, setPvValues] = useState(
        descuentos.map(() => ({ valor: '', checkPv: false, checkEntrada: false }))
    );
    const [loading, setLoading] = useState(false);
    const convertirTelefono = (telefono: string) => {
        if (telefono.startsWith('0')) {
            return `593${telefono.slice(1)}`; // Reemplazar el primer 0 por 593
        }
        return telefono; // Si no empieza con 0, se retorna igual
    };
    useEffect(() => {
        setLoading(true);
        // Simula una carga de datos
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);
    const [alertMessage, setAlertMessage] = useState<string | null>(null);
    const handleInputChange = (index: number, value: string) => {
        setValidarCambios(false);
        // Filtrar caracteres no numéricos
        const numericValue = value.replace(/[^0-9.]/g, '');

        // Convertir el valor a número
        const parsedValue = Number(numericValue);

        // Obtener el valor máximo permitido desde la API y convertirlo a número
        const maxValue = Number(descuentos[index].valor);

        // Validar que el valor no exceda el máximo permitido
        if (parsedValue > maxValue) {
            alert(`El valor ingresado no puede ser mayor a ${maxValue}.`);
            return;
        }

        // Actualizar el estado si pasa la validación
        const updatedValues = [...pvValues];
        updatedValues[index] = { ...updatedValues[index], valor: numericValue };
        setPvValues(updatedValues);
    };

    const handleChangeCuotas = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value)
        setDataCuotas({
            ...dataCuotas,
            [e.target.name]: e.target.value,
        });

    };

    // Función para calcular los meses restantes hasta diciembre
    const calcularMesesRestantes = () => {
        const fechaActual = new Date();
        const mesActual = fechaActual.getMonth(); // 0 - 11 (0 = enero, 11 = diciembre)
        const diaActual = fechaActual.getDate();

        if (mesActual === 11) {
            return 1; // Si es diciembre, falta un mes para el nuevo año
        }

        const mesesRestantes = 11 - mesActual; // Meses restantes hasta diciembre
        return mesesRestantes + 1; // Contamos el mes actual como completo
    };
    const mesesRestantes = calcularMesesRestantes(); // Calcula los meses restantes una vez

    // Calcula el valor de la matrícula usando `useMemo`
    const valorMatricula = useMemo(() => {
        const valor = (precioEspecial * 0.04) / 12 * mesesRestantes;
        return valor.toLocaleString("en-US", { style: "currency", currency: "USD" }); // Formato de moneda en USD
    }, [precioEspecial, mesesRestantes]);


    const handleCheckPvChange = (index: number, value: boolean) => {
        setValidarCambios(false);
        const updatedValues = [...pvValues];

        // Verificar si el objeto en la posición index existe, si no lo creamos con valores por defecto
        if (!updatedValues[index]) {
            updatedValues[index] = { valor: '', checkPv: false, checkEntrada: false };
        }

        // Si se selecciona PV, desmarcamos Entrada
        updatedValues[index].checkPv = value;
        if (value) {
            updatedValues[index].checkEntrada = false; // Desmarcamos Entrada

            // Llenar automáticamente el valor con item.valor si se selecciona PV
            updatedValues[index].valor = descuentos[index].valor.toString();
        } else if (!updatedValues[index].checkEntrada) {
            // Si se deselecciona PV y Entrada también está desmarcado, limpiar el campo de texto
            updatedValues[index].valor = '';
        }

        setPvValues(updatedValues);
    };

    const handleCheckEntradaChange = (index: number, value: boolean) => {
        setValidarCambios(false);
        const updatedValues = [...pvValues];

        // Verificar si el objeto en la posición index existe, si no lo creamos con valores por defecto
        if (!updatedValues[index]) {
            updatedValues[index] = { valor: '', checkPv: false, checkEntrada: false };
        }

        // Si se selecciona Entrada, desmarcamos PV
        updatedValues[index].checkEntrada = value;
        if (value) {
            updatedValues[index].checkPv = false; // Desmarcamos PV

            // Llenar automáticamente el valor con item.valor si se selecciona Entrada
            updatedValues[index].valor = descuentos[index].valor.toString();
        } else if (!updatedValues[index].checkPv) {
            // Si se deselecciona Entrada y PV también está desmarcado, limpiar el campo de texto
            updatedValues[index].valor = '';
        }

        setPvValues(updatedValues);
    };

    // Función para limpiar el formato de la moneda (eliminar caracteres no numéricos)
    const limpiarFormatoMoneda = (value: string) => {
        return value.replace(/[^0-9.-]+/g, "");
    };

    // Función para calcular el seguro vehicular
    const calcularValores = () => {
        const precioVenta = parseFloat(limpiarFormatoMoneda(String(precio)));
        if (isNaN(precioVenta)) return;

        // Calcular el valor del seguro base
        const valorSeguro = precioVenta * 0.03;

        // Calcular los valores adicionales en base al valor del seguro
        const valorSuperintendencia = valorSeguro * 0.035; // 3.5% Superintendencia de Banco
        const valorSeguroCampesino = valorSeguro * 0.005; // 0.5% Seguro Campesino
        const derechosEmision = 0.45; // Derechos de Emisión en valor fijo
        const iva = (valorSeguro + valorSuperintendencia + valorSeguroCampesino + derechosEmision) * 0.15; // 15% IVA

        // Sumar todos los valores para obtener el valor total del seguro
        const valorSeguroTotal = valorSeguro + valorSuperintendencia + valorSeguroCampesino + derechosEmision + iva;

        // Calcular el valor de la cuota mensual
        const valorCuota = valorSeguroTotal / 12;

        // Actualizar los valores en la UI
        setSeguro(valorSeguroTotal.toFixed(2)); // Mostrar como número
        setCuota(valorCuota.toFixed(2)); // Mostrar como número
    };
    const calcularPrecioEspecial = (): number => {
        // Asegurarse de que `descuentoTotal` es un número
        const descuentoTotalNumber = typeof descuentoTotal === 'number' ? descuentoTotal : parseFloat(descuentoTotal) || 0;

        // Calcular el total de los extras
        const totalExtras = extrasAdicionales
            .filter(extra => !extra.checked) // Solo cuenta los extras que no son cortesía
            .reduce((total, extra) => total + (typeof extra.valor === 'number' ? extra.valor : parseFloat(extra.valor) || 0), 0); // Convierte `extra.valor` a número si es necesario

        // Asegurarse de que `precio` es un número
        const precioNumber = typeof precio === 'number' ? precio : parseFloat(precio) || 0; // Convierte `precio` a número si es necesario

        // Realiza el cálculo final (precio + extras - descuento)
        const precioFinal = precioNumber + totalExtras - descuentoTotalNumber;

        return precioFinal;
    };

    // Actualiza el precio especial cada vez que cambian los valores de `precio`, `extrasAdicionales` o `descuentoTotal`
    useEffect(() => {
        setPrecioEspecial(calcularPrecioEspecial());
        // Asigna el número calculado
    }, [precio, extrasAdicionales, descuentoTotal]);

    const calcularDescuento = (index: number, value: string) => {
        // Actualiza el valor directamente como string
        const updatedPvValues = [...pvValues];
        updatedPvValues[index].valor = value;

        setPvValues(updatedPvValues);
    };

    // Calcula el descuento total cada vez que los valores cambian
    useEffect(() => {
        const totalDescuento = pvValues.reduce((total, item) => {
            // Convierte el valor a número para los cálculos
            const itemValor = parseFloat(item.valor) || 0;  // Si no es un número, usa 0
            return total + itemValor;
        }, 0);

        // Asigna el valor calculado directamente como número
        setDescuentoTotal(totalDescuento);
    }, [pvValues]);

    const interestRate = 5; // Tasa de interés anual
    const desgravamenRates: { [key: number]: number } = {
        12: 3.44,
        24: 6.77,
        36: 6.77,
        48: 6.77,
        60: 10.1,
    };



    // Función para agregar un extra
    const agregarExtra = () => {
        if (extrasAdicionales.length < 4) {
            setExtrasAdicionales([
                ...extrasAdicionales,
                { nombre: '', valor: 0, checked: false } // Inicializa `valor` como número
            ]);
        } else {
            alert("No se puede agregar más de 4 items."); // Muestra un mensaje si se intenta agregar más de 4
        }
    };
    const formatCurrencyValue = (value: string) => {
        return `$${parseFloat(value).toFixed(2)}`;
    };

    // Función para convertir string en número
    const parseCurrency = (value: string) => {
        return parseFloat(value.replace(/[^0-9.-]+/g, "")) || 0;
    };
    // Función para eliminar un extra
    const eliminarExtra = (index: number) => {
        const updatedExtras = extrasAdicionales.filter((_, i) => i !== index);
        setExtrasAdicionales(updatedExtras);
    };
    const formatCurrency = (value: string): string => {
        // Convierte la cadena a un número eliminando símbolos de dólar y comas
        const number = parseFloat(value.replace(/[$,]/g, ''));

        // Devuelve $0.00 si el número es inválido
        if (isNaN(number)) return '$0.00';

        // Convierte el número a una cadena con dos decimales
        const parts = number.toFixed(2).split('.');

        // Separa los miles y los decimales
        const integerPart = parts[0];
        const decimalPart = parts[1];

        // Agrega la separación de miles
        const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        // Devuelve el resultado en formato deseado
        return `$${formattedIntegerPart}.${decimalPart}`;
    };
    // Función para manejar el cambio de texto en los campos
    const handleChange = (index: number, field: 'nombre' | 'valor', value: string) => {
        setValidarCambios(false);
        const updatedExtras = [...extrasAdicionales];
        updatedExtras[index] = { ...updatedExtras[index], [field]: field === 'valor' ? Number(value) : value };
        setExtrasAdicionales(updatedExtras);
    };

    // Función para manejar el check de cortesía
    const manejarCheck = (index: number) => {
        setValidarCambios(false);
        const updatedExtras = [...extrasAdicionales];
        updatedExtras[index].checked = !updatedExtras[index].checked;
        setExtrasAdicionales(updatedExtras);
    };


    const [extras, setExtras] = useState([
        { valor: '0' }, { valor: '0' }, { valor: '0' }, { valor: '0' }
    ]);
    // const handlePorcentajeBlur = () => {
    //     if (porcentaje) {
    //       const entradaCalculada = (parseFloat(porcentaje) / 100) * precioEspecial;
    //       setEntrada(entradaCalculada.toFixed(2));
    //       const monto = precioEspecial - entradaCalculada;
    //       setMontoAFinanciar(monto.toFixed(2));
    //     }
    //   };



    useEffect(() => {

        // Calcula el precio especial cada vez que cambian precio, descuento o extras
        const precioVenta = parseCurrency(String(precio));
        const totalDescuentoNumerico = parseCurrency(descuento);

        // Sumar los valores de los extras adicionales
        const valorExtrasAdicionales = extras.reduce((total, extra) => {
            const extraValor = extra.valor ? parseCurrency(extra.valor) : 0;
            return total + extraValor;
        }, 0);

        // Calculamos el precio especial sumando precio de venta + extras - descuento
        const precioEspecialCalculado = precioVenta + valorExtrasAdicionales - totalDescuentoNumerico;
        setPrecioEspecial(precioEspecialCalculado);

    }, [precio, descuento, extras]); // Ejecuta el cálculo cuando cambian estas dependencias

    // Función para manejar el cambio de porcentaje y calcular la entrada y monto a financiar


    const handlePorcentajeBlur = () => {
        if (porcentaje) {
            const entradaCalculada = (parseFloat(porcentaje) / 100) * precioEspecial;
            setEntrada(entradaCalculada.toFixed(2));
            setMontoAFinanciar((precioEspecial - entradaCalculada).toFixed(2));
        }
    };

    // Función para calcular el porcentaje y monto a financiar cuando se ingresa la entrada
    const handleEntradaBlur = () => {
        if (entrada) {
            const porcentajeCalculado = (parseFloat(entrada) / precioEspecial) * 100;
            setPorcentaje(parseFloat(porcentajeCalculado.toFixed(2)).toString()); // Formatea sin ceros innecesarios
            setMontoAFinanciar((precioEspecial - parseFloat(entrada)).toFixed(2));
        }
    };
    const filename = `Cotización ${dataNegocio.nombreCliente}.pdf`;

    const concesionarios: { [key: number]: string } = {
        1: "PROAUTO",
        2: "EMAULME",
        3: "MIRASOL"
    };

    const idZona = dataNegocio.idZona ?? 0;  // Si es undefined o null, usa 0 como valor por defecto
    const concesionario = (idZona in concesionarios)
        ? concesionarios[idZona]
        : "PROAUTO";
    // Asegúrate de que el correo esté en el cliente

    // Generar el asunto
    const asunto = `Cotización ${dataNegocio.nombreCliente}`; // Usa el primer nombre y primer apellido

    const cuerpo =
        `Estimad@ ${dataNegocio.nombreCliente},<br><br>
    Nos es grato en ${concesionario} dirigirnos a usted para saludarlo y brindarle información completa del vehículo de su interés.<br><br>
    Adjunto a este correo encontrará la cotización del vehículo Chevrolet ${specifications?.spcMarcaModeloVersion}, en la cual detallamos, entre otros aspectos, la motorización, equipamiento exterior e interior, alternativas de color, precio y opciones de financiamiento.<br><br>
    En ${concesionario} estaremos gustosos de resolver cualquier inquietud que pudiese tener, coordinar una prueba de manejo y asesorarlo en el proceso.<br><br>
    Sabemos que la compra de un vehículo es una decisión importante y por ello nos ponemos a su disposición. De igual forma, le ofrecemos el mejor servicio posventa con la más amplia cobertura de talleres y repuestos genuinos Chevrolet a nivel nacional.<br><br>
    Agradezco nuevamente por su interés y espero poder ayudarle a tomar la mejor decisión en su compra.<br><br>
    Cordialmente,<br><br>
    ${dataNegocio.asesor}<br>
    Asesor Comercial<br>
    ${concesionario}<br>
    Teléfono: ${dataNegocio.telefonoAsesor}<br>
    Correo: ${dataNegocio.correo}`;

    // Generar el filename para la imagen // Asegúrate de que el correo esté en el cliente
    const obtenerFechaFormateada = () => {
        const meses = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'];
        const fechaActual = new Date();
        const dia = fechaActual.getDate().toString().padStart(2, '0');
        const mes = meses[fechaActual.getMonth()];
        const año = fechaActual.getFullYear();

        return `${dia}.${mes}.${año}`;
    };
    // Función para manejar el cambio de entrada y calcular el porcentaje y monto a financiar
    // const handleEntradaBlur = () => {
    //     if (entrada) {
    //         const valorEntrada = parseFloat(entrada.replace(/[^0-9.]/g, ''));
    //         const nuevoPorcentaje = (valorEntrada / precioEspecial) * 100;
    //         setPorcentaje(nuevoPorcentaje.toFixed(2));

    //         const montoFinanciar = precioEspecial - valorEntrada;
    //         setMontoAFinanciar(formatCurrencyValue(montoFinanciar.toFixed(2)));
    //     }
    // };
    useEffect(() => {
        if (account && account.name) {
            console.log("este son los atributos de msal >>>>>>", account);
            setName(account.name);
            setUsername(account.username);
            console.log(account);
        } else {
            setName("");
        }
    }, [account]);

    const enviarDatos = async () => {
        setLoading(true);
        console.log("paso 1")
        const telefonoConPrefijo = convertirTelefono(String(dataNegocio.contactphone));
        const generarTextosExtras = (extras: Extra[]): any[] => {
            return extras.map((extra, index) => {
                const yPosition = 670 - (index * 20);

                return [
                    {
                        text: extra.nombre,
                        x: 30,
                        y: yPosition,
                        fontSize: 10,
                        isBold: true,
                        pageNumber: 2,
                        color: {
                            "r": selectedColor.rgbColor.r * 255,
                            "g": selectedColor.rgbColor.g * 255,
                            "b": selectedColor.rgbColor.b * 255
                        },
                    },
                    {
                        text: extra.checked ? "Cortesía" : `$${extra.valor}`, // Cambia el valor a "Cortesía" si está marcado
                        x: 185,
                        y: yPosition,
                        fontSize: 11,
                        isBold: false,
                        pageNumber: 2,
                        color: {
                            r: extra.checked ? selectedColor.rgbColor.r * 255 : 0.35 * 255, // Usa el color seleccionado o 0.35
                            g: extra.checked ? selectedColor.rgbColor.g * 255 : 0.35 * 255,
                            b: extra.checked ? selectedColor.rgbColor.b * 255 : 0.35 * 255,
                        },
                    },
                ];
            }).flat();
        };

        const dynamicJson = {
            phoneNumber: telefonoConPrefijo,
            dealId: dataNegocio.idcrm,
            color: selectedColor.color,
            filename: filename,
            cliente: dataNegocio.nombreCliente, // Usa el primer nombre y primer apellido del cliente
            concesionario: concesionario,
            destinatario: dataNegocio.contactemail,
            modelo: parametroCotizador.spcMarcaModeloVersion,
            nombreAsesor: dataNegocio.asesor,
            telefonoAsesor: dataNegocio.telefonoAsesor,
            correoAsesor: dataNegocio.correo,
            asunto: asunto,
            cuerpo: cuerpo,

            pdfData: {
                texts: [
                    {
                        text: specifications?.spcNombre || "",
                        "x": 12,
                        "y": 740,
                        "fontSize": 20,
                        "font": "Helvetica",
                        "alignment": "left",
                        "isBold": true,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: specifications?.spcText2 || "",
                        "x": 12,
                        "y": 720,
                        "fontSize": 13,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": `${obtenerFechaFormateada()}  |  Cotización N° ${dataNegocio.idcrm}`,
                        "x": 390,
                        "y": 720,
                        "fontSize": 8,
                        "isBold": false,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": "Señor(a):",
                        "x": 30,
                        "y": 430,
                        "fontSize": 11,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `${dataNegocio.cliente}`,
                        "x": 30,
                        "y": 415,
                        "fontSize": 11,
                        "alignment": "left",
                        "isBold": true,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: "Presente",
                        x: 30,
                        y: 400,
                        fontSize: 11,
                        alignment: "left",
                        isBold: false,
                        pageNumber: 1,
                        color: {
                            r: 0.35,
                            g: 0.35,
                            b: 0.35
                        }
                    },
                    {
                        "text": "Nos es grato brindarle a continuación información detallada del vehículo de su interés",
                        "x": 30,
                        "y": 380,
                        "fontSize": 11,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `${specifications?.spcMarcaModeloVersion || modelo}`,
                        "x": 226,
                        "y": 335.9,
                        "fontSize": 14,
                        "alignment": "left",
                        "isBold": true,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `${selectedColor.colorN}`,
                        "x": 80,
                        "y": 314,
                        "fontSize": 11,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: specifications?.spcCaracteristica1 || "",
                        "x": 45,
                        "y": 245,
                        "fontSize": 10,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: specifications?.spcDetalle1 || "",
                        "x": 225,
                        "y": 245,
                        "fontSize": 10,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: specifications?.spcCaracteristica2 || "",
                        "x": 45,
                        "y": 225,
                        "fontSize": 10,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: specifications?.spcDetalle2 || "",
                        "x": 225,
                        "y": 225,
                        "fontSize": 10,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: specifications?.spcCaracteristica3 || "",
                        "x": 45,
                        "y": 205,
                        "fontSize": 10,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: specifications?.spcDetalle3 || "",
                        "x": 225,
                        "y": 205,
                        "fontSize": 10,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: specifications?.spcCaracteristica4 || "",
                        "x": 45,
                        "y": 185,
                        "fontSize": 10,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: specifications?.spcDetalle4 || "",
                        "x": 225,
                        "y": 185,
                        "fontSize": 10,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: specifications?.spcCaracteristica5 || "",
                        "x": 45,
                        "y": 165,
                        "fontSize": 10,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: specifications?.spcDetalle5 || "",
                        "x": 225,
                        "y": 165,
                        "fontSize": 10,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: specifications?.spcCaracteristica6 || "",
                        "x": 45,
                        "y": 145,
                        "fontSize": 10,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: specifications?.spcDetalle6 || "",
                        "x": 225,
                        "y": 145,
                        "fontSize": 10,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: specifications?.spcCaracteristica7 || "",
                        "x": 45,
                        "y": 125,
                        "fontSize": 10,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: specifications?.spcDetalle7 || "",
                        "x": 225,
                        "y": 125,
                        "fontSize": 10,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: specifications?.spcCaracteristica8 || "",
                        "x": 45,
                        "y": 105,
                        "fontSize": 10,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: specifications?.spcDetalle8 || "",
                        "x": 225,
                        "y": 105,
                        "fontSize": 10,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: specifications?.spcNombre || "",
                        "x": 12,
                        "y": 740,
                        "fontSize": 20,
                        "alignment": "left",
                        "isBold": true,
                        "pageNumber": 2,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: specifications?.spcText2 || "",
                        "x": 12,
                        "y": 720,
                        "fontSize": 13,
                        "isBold": false,
                        "pageNumber": 2,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": `${obtenerFechaFormateada()}  |  Cotización N° ${dataNegocio.idcrm}`,
                        "x": 400,
                        "y": 720,
                        "fontSize": 9,
                        "isBold": false,
                        "pageNumber": 2,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `${formatCurrency(String(precio)).replace("$", "$ ")}`,
                        "x": 185,
                        "y": 695,
                        "fontSize": 11,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 2,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    ...generarTextosExtras(extrasAdicionales),
                    {
                        "text": descuentoTotal > 0 ? "DESCUENTO" : "",
                        "x": 30,
                        "y": 585,
                        "fontSize": 11,
                        "alignment": "left",
                        "isBold": true,
                        "pageNumber": 2,
                        "color": {
                            "r": selectedColor.rgbColor.r * 255,
                            "g": selectedColor.rgbColor.g * 255,
                            "b": selectedColor.rgbColor.b * 255
                        }
                    },
                    {
                        "text": descuentoTotal > 0 ? `${formatCurrency(descuentoTotal.toFixed(2)).replace("$", "$ ")}` : "",
                        //"text": "500",
                        "x": 185,
                        "y": 585,
                        "fontSize": 11,

                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 2,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },

                    {
                        "text": `${formatCurrency(precioEspecial.toFixed(2)).replace("$", "$ ")} Inc. IVA`,
                        "x": 182,
                        "y": 561,
                        "fontSize": 11.5,
                        "alignment": "left",
                        "isBold": true,
                        "pageNumber": 2,
                        "color": {
                            "r": 247,
                            "g": 247,
                            "b": 247
                        }
                    },
                    {
                        "text": `Matrícula (Valor referencial SRI) ${formatCurrency(valorMatricula)}`,
                        "x": 30,
                        "y": 465,
                        "fontSize": 11,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 2,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },                    
                    {
                        "text": "*Una vez facturado se confirmará el valor final inlcuido impuestos AMT+ANT",
                        "x": 30,
                        "y": 450,
                        "fontSize": 11,

                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 2,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `ENTRADA`,
                        "x": 30,
                        "y": 325,
                        "fontSize": 10,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 2,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `${porcentaje}%`,
                        "x": 150,
                        "y": 325,
                        "fontSize": 10,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 2,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        //text: `${entrada}`,
                        text: `${formatCurrency(entrada)}`,
                        "x": 215,
                        "y": 326,
                        "fontSize": 10,

                        "alignment": "right",
                        "isBold": true,
                        "pageNumber": 2,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        },

                    },

                    {
                        text: `MONTO A FINANCIAR`,
                        "x": 30,
                        "y": 311,
                        "fontSize": 10,
                        "alignment": "left",
                        "isBold": false,
                        "pageNumber": 2,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        },

                    },
                    {
                        text: `${formatCurrency(montoAFinanciar)}`,
                        "x": 215,
                        "y": 311,
                        "fontSize": 10,

                        "alignment": "right",
                        "isBold": true,
                        "pageNumber": 2,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        },

                    },

                    //nuevas posiciones de valores a cuotas 12 24 36 48 60 72
                    {
                        "text": checked12 ? formatter.format(dataCuotas.valorCuota12).replace("$", "$  ") : "",
                        "x": 108,
                        "y": 237,
                        "fontSize": 10,
                        "isBold": false,
                        "pageNumber": 2,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": checked24 ? formatter.format(dataCuotas.valorCuota24).replace("$", "$  ") : "",
                        "x": 173.5,
                        "y": 237,
                        "fontSize": 10,
                        "isBold": false,
                        "pageNumber": 2,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": checked36 ? formatter.format(dataCuotas.valorCuota36).replace("$", "$  ") : "",
                        "x": 240,
                        "y": 237,
                        "fontSize": 10,
                        "isBold": false,
                        "pageNumber": 2,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": checked48 ? formatter.format(dataCuotas.valorCuota48).replace("$", "$  ") : "",
                        "x": 305,
                        "y": 237,
                        "fontSize": 10,
                        "isBold": false,
                        "pageNumber": 2,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": checked60 ? formatter.format(dataCuotas.valorCuota60).replace("$", "$  ") : "",
                        "x": 370,
                        "y": 237,
                        "fontSize": 10,
                        "isBold": false,
                        "pageNumber": 2,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": checked72 ? formatter.format(dataCuotas.valorCuota72).replace("$", "$  ") : "",
                        "x": 437,
                        "y": 237,
                        "fontSize": 10,
                        "isBold": false,
                        "pageNumber": 2,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": "Hasta fin de este mes.",
                        "x": 140,
                        "y": 166,
                        "fontSize": 10,
                        "pageNumber": 2,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": `${obtenerFechaFormateada()}  |  Cotización N° ${dataNegocio.idcrm}`,
                        "x": 400,
                        "y": 720,
                        "fontSize": 9,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: specifications?.spcNombre || "",
                        "x": 12,
                        "y": 740,
                        "fontSize": 20,
                        "isBold": true,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: specifications?.spcText2 || "",
                        "x": 12,
                        "y": 720,
                        "fontSize": 13,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: parametroCotizador.spcIdDispositivo === 1 ? "SERVICIO CHEVYSTAR" : "   SERVICIO ON-STAR",
                        "x": 250,
                        "y": 626,
                        "fontSize": 9,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 247,
                            "g": 247,
                            "b": 247
                        }
                    },
                    {
                        "text": "1 año",
                        "x": 266,
                        "y": 603,
                        "fontSize": 9.5,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `$${specifications?.spcDispositivoA1}`,
                        "x": 296,
                        "y": 603,
                        "fontSize": 9.5,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": "2 años",
                        "x": 265,
                        "y": 587,
                        "fontSize": 9.5,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `$${specifications?.spcDispositivoA2}`,
                        "x": 298,
                        "y": 587,
                        "fontSize": 9.5,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": "3 años",
                        "x": 262,
                        "y": 571,
                        "fontSize": 9.5,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `$${specifications?.spcDispositivoA3}`,
                        "x": 296,
                        "y": 571,
                        "fontSize": 9.5,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": "4 años",
                        "x": 262,
                        "y": 555,
                        "fontSize": 9.5,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `$${specifications?.spcDispositivoA4}`,
                        "x": 296,
                        "y": 555,
                        "fontSize": 9.5,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": "5 años",
                        "x": 262,
                        "y": 539,
                        "fontSize": 9.5,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `$${specifications?.spcDispositivoA5}`,
                        "x": 296,
                        "y": 539,
                        "fontSize": 9.5,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },

                    {
                        "text": "Vehículo",
                        "x": 216,
                        "y": 420,
                        "fontSize": 9.5,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {

                        text: `${formatCurrency(seguro)}`,

                        "x": 333,
                        "y": 420,
                        "fontSize": 9.5,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }


                    },
                    {
                        "text": "CUOTAS",
                        "x": 216,
                        "y": 400,
                        "fontSize": 9.5,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {

                        text: `12 cuotas de ${formatCurrency(cuota)}`,

                        "x": 308,
                        "y": 400,
                        "fontSize": 9.5,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": "Valor incluye amparo patrimonial",
                        "x": 228,
                        "y": 359,
                        "fontSize": 9,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `${specifications?.spcPlan1Tipo}`,
                        "x": 66,
                        "y": 262,
                        "fontSize": 9.5,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `${specifications?.spcFamilia},`,
                        "x": 145,
                        "y": 262,
                        "fontSize": 9.5,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `${specifications?.spcPlan2Modelo}`,
                        "x": 134.5,
                        "y": 248,
                        "fontSize": 7.8,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": "Mantenimiento",
                        "x": 218,
                        "y": 262,
                        "fontSize": 9,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `${specifications?.spcPlan1Descripcion}`,
                        "x": 217.3,
                        "y": 248,
                        "fontSize": 7,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `$${specifications?.spcPlan1PvpNormal}`,
                        "x": 320,
                        "y": 258,
                        "fontSize": 9.5,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `$${specifications?.spcPlan1PvpMpc}`,
                        "x": 402,
                        "y": 258,
                        "fontSize": 9.5,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `${specifications?.spcPlan1Cobertura}`,
                        "x": 457,
                        "y": 258,
                        "fontSize": 8.3,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `${specifications?.spcPlan2Tipo}`,
                        "x": 66,
                        "y": 233,
                        "fontSize": 9.5,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `${specifications?.spcFamilia},`,
                        "x": 145,
                        "y": 233,
                        "fontSize": 9.5,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `${specifications?.spcPlan2Modelo}`,
                        "x": 134.5,
                        "y": 222,
                        "fontSize": 7.8,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": "Mantenimiento",
                        "x": 218,
                        "y": 233,
                        "fontSize": 9,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `${specifications?.spcPlan2Descripcion}`,
                        "x": 217.3,
                        "y": 222,
                        "fontSize": 7,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `$${specifications?.spcPlan2PvpNormal}`,
                        "x": 320,
                        "y": 230,
                        "fontSize": 9.5,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `$${specifications?.spcPlan2PvpMpc}`,
                        "x": 402,
                        "y": 230,
                        "fontSize": 9.5,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `${specifications?.spcPlan2Cobertura}`,
                        "x": 457,
                        "y": 230,
                        "fontSize": 8.3,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": "Cordialmente,",
                        "x": 46,
                        "y": 150,
                        "fontSize": 11,
                        "isBold": true,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `${dataNegocio.asesor}`,
                        "x": 46,
                        "y": 135,
                        "fontSize": 11,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": "Su Asesor Comercial Chevrolet ",
                        "x": 46,
                        "y": 120,
                        "fontSize": 11,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": "Mail:",
                        "x": 46,
                        "y": 105,
                        "fontSize": 11,
                        "isBold": true,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `${dataNegocio.correo}`,
                        "x": 76,
                        "y": 105,
                        "fontSize": 11,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": "Cel.:",
                        "x": 46,
                        "y": 90,
                        "fontSize": 11,
                        "isBold": true,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: `${dataNegocio.telefonoAsesor}`,
                        "x": 76,
                        "y": 90,
                        "fontSize": 11,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": "Dir.:",
                        "x": 46,
                        "y": 75,
                        "fontSize": 11,
                        "isBold": true,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": dataNegocio.ageDireccion,
                        "x": 76,
                        "y": 75,
                        "fontSize": 11,
                        "isBold": false,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": `${obtenerFechaFormateada()}  |  Cotización N° ${dataNegocio.idcrm}`,
                        "x": 400,
                        "y": 720,
                        "fontSize": 9,
                        "isBold": false,
                        "pageNumber": 4,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: specifications?.spcNombre || "",
                        "x": 12,
                        "y": 742,
                        "fontSize": 20,
                        "isBold": true,
                        "pageNumber": 4,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        text: specifications?.spcText2 || "",
                        "x": 12,
                        "y": 723,
                        "fontSize": 13,
                        "isBold": false,
                        "pageNumber": 4,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },

                    {
                        "text": "Quito - Guayaquil - Cuenca - Loja - Machala - Azogues - Cayambe",
                        "x": 275,
                        "y": 18,
                        "fontSize": 9,
                        "isBold": true,
                        "pageNumber": 1,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": "Quito - Guayaquil - Cuenca - Loja - Machala - Azogues - Cayambe",
                        "x": 275,
                        "y": 18,
                        "fontSize": 9,
                        "isBold": true,
                        "pageNumber": 2,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": "Quito - Guayaquil - Cuenca - Loja - Machala - Azogues - Cayambe",
                        "x": 275,
                        "y": 18,
                        "fontSize": 9,
                        "isBold": true,
                        "pageNumber": 3,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },
                    {
                        "text": "Quito - Guayaquil - Cuenca - Loja - Machala - Azogues - Cayambe",
                        "x": 275,
                        "y": 18,
                        "fontSize": 9,
                        "isBold": true,
                        "pageNumber": 4,
                        "color": {
                            "r": 0.35,
                            "g": 0.35,
                            "b": 0.35
                        }
                    },


                ],
                images: [
                    {
                        fileName: parametroCotizador.spcImagen1,
                        x: 0,
                        y: 468,
                        pageNumber: 1,
                        width: 595,
                        height: 240
                    },
                    {
                        fileName: parametroCotizador.spcImagen2,
                        "x": 0,
                        "y": 438,
                        "pageNumber": 4,
                        "width": 595,
                        "height": 240
                    },
                    {
                        fileName: String("z" + String(Number(dataNegocio.idZona)) + ".png"),
                        x: 50,
                        y: 798,
                        pageNumber: 1,
                        width: 125,
                        height: 21,

                    },
                    {
                        fileName: String("z" + String(Number(dataNegocio.idZona)) + ".png"),
                        x: 50,
                        y: 798,
                        pageNumber: 2,
                        width: 125,
                        height: 21
                    },
                    {
                        fileName: String("z" + String(Number(dataNegocio.idZona)) + ".png"),
                        x: 50,
                        y: 798,
                        pageNumber: 3,
                        width: 125,
                        height: 21
                    },
                    {
                        fileName: String("z" + String(Number(dataNegocio.idZona)) + ".png"),
                        x: 50,
                        y: 798,
                        pageNumber: 4,
                        width: 125,
                        height: 21
                    },
                    {
                        fileName: parametroCotizador.spcIdDispositivo === 1 ? "chevystar.png" : "onstar.png",
                        x: 195,
                        y: 638,
                        pageNumber: 3,
                        width: 80,
                        height: 35
                    },
                    {
                        fileName: "vinetas.png",
                        x: 30,
                        y: 100,
                        pageNumber: 1,
                        width: 12,
                        height: 155
                    },
                    {
                        fileName: "colores.png",
                        x: 500,
                        y: 105,
                        pageNumber: 1,
                        width: 40,
                        height: 171
                    }
                ],
                links: [
                    {
                        url: parametroCotizador.spcIdDispositivo === 1 ? "https://www.proautochevrolet.com.ec/chevystar" : "https://www.proautochevrolet.com.ec/chevrolet-onstar",
                        x: 225,
                        y: 625,
                        pageNumber: 3,
                        width: 150,
                        height: 50
                    },
                    {
                        url: specifications?.spcLink1,
                        x: 85,
                        y: 660,
                        pageNumber: 4,
                        width: 150,
                        height: 50
                    }
                ],
            }
        };
        console.log('mi json para peticion', JSON.stringify(dynamicJson))
        try {
            await axios.post("https://apisatelite.azurewebsites.net/cotizador/sendpdf", dynamicJson)
                .then((response) => {
                    console.log("mi respuesta es >> ", response);
                    setOpenDialogEnviadoOk(true);
                });
        } catch (error) {
            console.log('tengo error >> ', error)
            setOpenDialogEnviadoError(true)
            if (axios.isAxiosError(error)) {
                console.error("Error al enviar los datos:", error.response?.data || error.message);
            } else {
                console.error("Error desconocido:", error);
            }
        }
    };


    async function getParametros(_id: number) {
        try {
            await axios
                .get<string>("https://apisatelite.azurewebsites.net/sateliteparametrocotizador/" + String(_id))
                .then((response) => { })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    if (isAuthenticated) {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Grid alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 2,
                            }}
                        >
                            <Typography variant="h5" textAlign={"center"}>
                                Cotizador
                            </Typography>
                        </Paper>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 1,
                            }}
                        >

                            {isMobile ? <>
                                <Typography textAlign={"left"} variant="h6" >
                                    <strong>Datos cliente</strong>
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    label="Cliente"
                                    value={dataNegocio.cliente}
                                    // onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "100%" }}
                                />
                                <TextField
                                    size="small"
                                    margin="normal"
                                    label="Identificación"
                                    value={dataNegocio.cedula}
                                    // onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "100%" }}
                                />
                                <TextField
                                    size="small"
                                    margin="normal"
                                    label="Correo"
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    value={dataNegocio.contactemail}
                                    onChange={(e) => setDataNegocio({
                                        ...dataNegocio,
                                        contactemail: e.target.value,
                                    })}
                                    sx={{ mt: 1, mb: 1, width: "100%" }}
                                />
                                <TextField
                                    size="small"
                                    margin="normal"
                                    label="Teléfono"
                                    value={dataNegocio.contactphone}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    onChange={(e) => setDataNegocio({
                                        ...dataNegocio,
                                        contactphone: e.target.value,
                                    })}
                                    sx={{ mt: 1, mb: 1, width: "100%" }}
                                />
                                <Divider sx={{ ml: "3%", mr: "3%", mt: 2, mb: 1 }} /> <Typography textAlign={"left"} variant="h6" >
                                    <strong>Datos vehículo</strong>
                                </Typography>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    size="small"
                                    margin="normal"
                                    label="Modelo"
                                    value={selectedModelo}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    onChange={handleModeloChange}
                                    sx={{ mt: 0.5, mb: 0.5, width: "100%" }}
                                    SelectProps={{
                                        MenuProps: { disableScrollLock: true, style: { maxHeight: 300 } },
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>Seleccione un modelo</em>
                                    </MenuItem>
                                    {modelos.map((item) => (
                                        <MenuItem key={item.spcId} value={item.spcMarcaModeloVersion}>
                                            {item.spcModeloEspecifico}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "50%",
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Color:
                                </Typography>
                                <TextField
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    id="outlined-select-color"
                                    select
                                    size="small"
                                    margin="normal"
                                    value={selectedColor.color}
                                    onChange={handleColorChange}
                                    sx={{ mt: 0.5, mb: 0.5, width: "50%" }}
                                    SelectProps={{
                                        MenuProps: {
                                            disableScrollLock: true,
                                            style: {
                                                maxHeight: 300
                                            }
                                        }
                                    }}
                                >
                                    <MenuItem value={0} disabled>
                                        Seleccione un color
                                    </MenuItem>
                                    {colores.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.color}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "50%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Precio de venta:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    label="$"
                                    value={precio}
                                    onChange={(e) => { setPrecio(Number(e.target.value)) }}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 1, mb: 1, width: "50%" }}
                                />
                                {/* Título "Extras" */}
                                <Divider sx={{ ml: '3%', mr: '3%', mt: 2, mb: 1 }} />
                                <Typography textAlign="left" variant="h6" style={{ display: 'inline-block' }}>
                                    <strong>Extras</strong>
                                </Typography>

                                {/* Mapeo de los extras */}
                                {extrasAdicionales.map((extra, index) => (
                                    <Box key={index}>
                                        <TextField
                                            style={{ backgroundColor: "rgb(255,255,255)" }}
                                            size="small"
                                            margin="normal"
                                            label={"Extra " + String(index + 1)}
                                            value={extra.nombre}
                                            onChange={(e) => handleChange(index, 'nombre', e.target.value)}
                                            sx={{ mt: 1, mb: 1, width: '60%', mr: '2%', }}
                                            placeholder={`Extra ${index + 1}`}
                                        />
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            label="$"
                                            value={extra.valor}
                                            //value={formatter.format(Number(extra.valor)).replace("$", "$  ")}
                                            onChange={(e) => handleChange(index, 'valor', e.target.value)}
                                            sx={{ mt: 1, mb: 1, width: '38%' }}
                                            disabled={extra.checked}
                                            style={{
                                                backgroundColor:
                                                    extra.checked
                                                        ? "rgb(242,242,242)"
                                                        : "rgb(255,255,255)",
                                            }}
                                            type="number"
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        // Deshabilitar el campo de valor si el extra es de cortesía
                                        />
                                        {/* CheckBox para Cortesía */}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={extra.checked}
                                                    onChange={() => manejarCheck(index)}
                                                    color="primary"
                                                />
                                            }
                                            label="Cortesía"
                                            sx={{ width: "35%", ml: "15%" }}
                                        />

                                        {/* Botón de eliminar */}
                                        <Button
                                            size="large"
                                            color="error"
                                            onClick={() => eliminarExtra(index)}
                                            sx={{ width: "45%" }}
                                        >
                                            Eliminar
                                        </Button>
                                        <Divider sx={{ ml: "15%", mr: "15%", mt: 2, mb: 2 }} />
                                    </Box>
                                ))}

                                <Button variant="contained" sx={{ ml: "50%", width: "50%" }} onClick={agregarExtra}>
                                    Agregar extra
                                </Button>
                                <Divider sx={{ ml: "3%", mr: "3%", mt: 2, mb: 1 }} />
                                <Typography textAlign={"left"} variant="h6"  >
                                    <strong>Descuentos disponibles</strong>
                                </Typography>
                                {descuentos.map((item, index) => (
                                    <div key={item.nombre} >
                                        <Typography sx={{ width: "100%", mt: 2, mb: 1 }} style={{ display: "inline-block" }}>
                                            {item.nombre} (${item.valor})
                                        </Typography>
                                        <label style={{ marginTop: 2, marginBottom: 1 }}>
                                            PV
                                            <input
                                                style={{ marginTop: 2, marginBottom: 1 }}
                                                type="checkbox"
                                                checked={pvValues[index]?.checkPv || false}
                                                onChange={(e) => handleCheckPvChange(index, e.target.checked)}
                                            />
                                        </label>
                                        {/* Checkbox Entrada */}
                                        <label style={{ marginTop: 2, marginBottom: 1 }}>
                                            EN
                                            <input
                                                style={{ marginTop: 2, marginBottom: 1 }}
                                                type="checkbox"
                                                checked={pvValues[index]?.checkEntrada || false}
                                                onChange={(e) => handleCheckEntradaChange(index, e.target.checked)}
                                            />
                                        </label>
                                        <TextField
                                            type="number"
                                            size="small"
                                            margin="normal"
                                            label="$"
                                            value={pvValues[index]?.valor || ''}
                                            onChange={(e) => handleInputChange(index, e.target.value)}
                                            sx={{ mt: 1, mb: 1, ml: "3%", width: "40%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        {/* Checkbox PV */}
                                        <Divider sx={{ ml: "15%", mr: "15%" }} />
                                    </div>
                                ))}

                                {/* Campos para Descuento, Precio Especial y Matrícula en una línea */}


                                {/* Descuento */}
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '50%',
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Descuento:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(descuentoTotal).replace("$", "$  ")}
                                    sx={{ mt: 1, mb: 1, width: '45%' }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />

                                {/* Precio Especial */}
                                <Divider sx={{ ml: "10%", mr: "10%", mt: 3, mb: 1 }} />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '50%',
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Precio Especial:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(precioEspecial).replace("$", "$  ")}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 1, mb: 1, width: '45%' }}
                                />

                                {/* Matrícula */}
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '50%'
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Matrícula:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={valorMatricula.replace("$", "$  ")} // Mostramos el valor de la matrícula calculada
                                    onChange={(e) => setMatricula(e.target.value)}
                                    sx={{ mt: 1, mb: 1, width: '45%' }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />
                                <Divider sx={{ ml: "3%", mr: "3%", mt: 3, mb: 1 }} />
                                <Typography variant="h6" >
                                    <strong>Formas de pago</strong>
                                </Typography>

                                {/* Campo para el Precio Especial en una línea */}

                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '50%'
                                    }}
                                    style={{ display: 'inline-block' }}
                                    variant="h6"
                                >
                                    Contado:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(Number(precioEspecial)).replace("$", "$  ")}
                                    onChange={(e) => setPrecioEspecial(parseFloat(e.target.value))}
                                    sx={{ mt: 1, mb: 1, width: '45%' }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />
                                <Divider sx={{ ml: "10%", mr: "10%", mt: 3, mb: 1 }} />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '50%'
                                    }}
                                    variant="h6"
                                >
                                    Crédito:
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '50%'
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Porcentaje de entrada:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    value={porcentaje}
                                    onChange={(e) => { setPorcentaje(e.target.value); setValidarCambios(false); }}
                                    onBlur={handlePorcentajeBlur}
                                    label="%"
                                    sx={{ mt: 1, mb: 1, width: '45%' }}
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '50%'
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Monto de entrada:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    value={entrada}
                                    label="$"
                                    onChange={(e) => {
                                        setValidarCambios(false);
                                        setEntrada(e.target.value);
                                    }}
                                    onBlur={handleEntradaBlur}
                                    sx={{ mt: 1, mb: 1, width: '45%' }}
                                    InputProps={{

                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />

                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '50%'
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Monto a Financiar:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(Number(montoAFinanciar)).replace("$", "$  ")}
                                    onChange={(e) => setMontoAFinanciar(e.target.value)}
                                    sx={{ mt: 1, mb: 1, width: '45%' }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />


                                <Divider sx={{ ml: "3%", mr: "3%", mt: 3, mb: 1 }} />
                                <Typography variant="h6" >
                                    <strong>Cuotas mensuales</strong>
                                </Typography>


                                <TextField
                                    size="small"
                                    margin="normal"
                                    label={`12 Meses`}
                                    value={`$ ${dataCuotas.valorCuota12}`}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 1, mb: 1, width: '40%', ml: "10%" }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                />
                                <TextField
                                    size="small"
                                    margin="normal"
                                    label={`24 Meses`}
                                    value={`$ ${dataCuotas.valorCuota24}`}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 1, mb: 1, width: '40%', ml: "10%" }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                />
                                <TextField
                                    size="small"
                                    margin="normal"
                                    label={`36 Meses`}
                                    value={`$ ${dataCuotas.valorCuota36}`}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 1, mb: 1, width: '40%', ml: "10%" }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                />
                                <TextField
                                    size="small"
                                    margin="normal"
                                    label={`48 Meses`}
                                    value={`$ ${dataCuotas.valorCuota48}`}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 1, mb: 1, width: '40%', ml: "10%" }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                />
                                <TextField
                                    size="small"
                                    margin="normal"
                                    label={`60 Meses`}
                                    value={`$ ${dataCuotas.valorCuota60}`}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 1, mb: 1, width: '40%', ml: "10%" }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                />
                                <TextField
                                    size="small"
                                    margin="normal"
                                    label={`72 Meses`}
                                    value={`$ ${dataCuotas.valorCuota72}`}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 1, mb: 1, width: '40%', ml: "10%" }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                />

                                {/* Título */}
                                <Divider sx={{ ml: "3%", mr: "3%", mt: 2, mb: 1 }} />
                                <Typography textAlign={"left"} variant="h6" >
                                    <strong>Seguro vehicular</strong>
                                </Typography>

                                <Typography style={{ display: 'inline-block' }} sx={{ width: "50%", mt: 2, mb: 1 }}>Vehículo</Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(Number(seguro)).replace("$", "$  ")}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ width: '45%', mt: 1, mb: 1 }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                />


                                <Typography style={{ display: 'inline-block' }} sx={{ width: "50%", mt: 2, mb: 1 }}>12 Cuotas</Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(Number(cuota)).replace("$", "$  ")}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ width: '45%', mt: 1, mb: 1 }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                />

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => { calcularValores(); setearDatosCuotas(precioEspecial, Number(entrada)) }}
                                    sx={{ ml: "50%", width: "45%" }}
                                    disabled={precioEspecial > 0 ? false : true}
                                >
                                    Calcular
                                </Button>
                                <div>
                                    <Button
                                        onClick={async () => {
                                            setLoading(true);
                                            await enviarDatos();                                            
                                            setLoading(false);
                                        }}
                                        variant="contained"
                                        sx={{
                                            mt: 3,
                                            mb: 1,
                                            width: "80%",
                                            marginLeft: "10%",
                                            position: 'relative',
                                        }}
                                        disabled={loading || validarCambios ? false : true}
                                    >
                                        {loading ? (
                                            <>
                                                <CircularProgress size={24} sx={{ position: 'absolute' }} />
                                                {"Espere por favor"}
                                            </>
                                        ) : (
                                            "Enviar Cotización"
                                        )}
                                    </Button>

                                    {error && <div style={{ color: 'red' }}>{error}</div>} {/* Mostrar error si ocurre */}
                                </div>
                            </> : <>
                                <Typography sx={{ ml: "10%" }} textAlign={"left"} variant="h6" >
                                    <strong>Datos cliente</strong>
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Cliente:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataNegocio.cliente}
                                    // onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "35%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Identificacion:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataNegocio.cedula}
                                    // onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "15%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Correo:
                                </Typography>
                                <TextField
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataNegocio.contactemail}
                                    onChange={(e) => setDataNegocio({
                                        ...dataNegocio,
                                        contactemail: e.target.value,
                                    })}
                                    sx={{ mt: 1, mb: 1, width: "35%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Teléfono:
                                </Typography>
                                <TextField
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    size="small"
                                    margin="normal"
                                    name=""
                                    value={dataNegocio.contactphone}
                                    onChange={(e) => setDataNegocio({
                                        ...dataNegocio,
                                        contactphone: e.target.value,
                                    })}
                                    sx={{ mt: 1, mb: 1, width: "15%" }}
                                />
                                <Divider sx={{ ml: "3%", mr: "3%", mt: 2, mb: 1 }} /> <Typography sx={{ ml: "10%" }} textAlign={"left"} variant="h6" >
                                    <strong>Datos vehículo</strong>
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Modelo:
                                </Typography>
                                <TextField
                                    id="outlined-select-currency"
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    select
                                    size="small"
                                    margin="normal"
                                    value={selectedModelo}
                                    onChange={handleModeloChange}
                                    sx={{ mt: 0.5, mb: 0.5, width: "70%" }}
                                    SelectProps={{
                                        MenuProps: { disableScrollLock: true, style: { maxHeight: 300 } },
                                    }}
                                >

                                    <MenuItem value="">
                                        <em>Seleccione un modelo</em>
                                    </MenuItem>
                                    {modelos.map((item) => (
                                        <MenuItem key={item.spcId} value={item.spcMarcaModeloVersion}>
                                            {item.spcModeloEspecifico}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Color:
                                </Typography>
                                <TextField
                                    id="outlined-select-color"
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    select
                                    size="small"
                                    margin="normal"
                                    value={selectedColor.color}
                                    onChange={handleColorChange}
                                    sx={{ mt: 0.5, mb: 0.5, width: "15%", mr: "20%" }}
                                    SelectProps={{
                                        MenuProps: {
                                            disableScrollLock: true,
                                            style: {
                                                maxHeight: 300
                                            }
                                        }
                                    }}
                                >
                                    <MenuItem value={0} disabled>
                                        Seleccione un color
                                    </MenuItem>
                                    {colores.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.color}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "10%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Precio de venta:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    label="$"
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    value={precio}
                                    onChange={(e) => { setPrecio(Number(e.target.value)) }} // Muestra el valor de `spcPvp`
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 1, mb: 1, width: "15%" }}
                                />
                                {/* Título "Extras" */}
                                <Divider sx={{ ml: '3%', mr: '3%', mt: 2, mb: 1 }} />
                                <Typography textAlign="left" variant="h6" sx={{ ml: "10%" }} style={{ display: 'inline-block' }}>
                                    <strong>Extras</strong>
                                </Typography>

                                {/* Mapeo de los extras */}
                                {extrasAdicionales.map((extra, index) => (
                                    <Box key={index}>
                                        <TextField
                                            size="small"
                                            style={{ backgroundColor: "rgb(255,255,255)" }}
                                            margin="normal"
                                            label={"Extra " + String(index + 1)}
                                            value={extra.nombre}
                                            onChange={(e) => handleChange(index, 'nombre', e.target.value)}
                                            sx={{ mt: 1, mb: 1, width: '40%', mr: '2%', ml: "10%" }}
                                            placeholder={`Extra ${index + 1}`}
                                        />
                                        <TextField
                                            size="small"
                                            margin="normal"
                                            label="$"
                                            value={extra.valor}
                                            onChange={(e) => handleChange(index, 'valor', e.target.value)}
                                            sx={{ mt: 1, mb: 1, width: '15%' }}
                                            disabled={extra.checked}
                                            style={{
                                                backgroundColor:
                                                    extra.checked
                                                        ? "rgb(242,242,242)"
                                                        : "rgb(255,255,255)",
                                            }}
                                            type="number"
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        // Deshabilitar el campo de valor si el extra es de cortesía
                                        />
                                        {/* CheckBox para Cortesía */}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={extra.checked}
                                                    onChange={() => manejarCheck(index)}
                                                    color="primary"
                                                />
                                            }
                                            label="Cortesía"
                                            sx={{ ml: 2 }}
                                        />

                                        {/* Botón de eliminar */}
                                        <Button
                                            size="large"
                                            color="error"
                                            onClick={() => eliminarExtra(index)}
                                            sx={{ ml: 2, width: "10%" }}
                                        >
                                            Eliminar
                                        </Button>
                                    </Box>
                                ))}

                                <Button variant="contained" sx={{ ml: "80%", width: "10%" }} onClick={agregarExtra}>
                                    Agregar extra
                                </Button>
                                <Divider sx={{ ml: "3%", mr: "3%", mt: 2, mb: 1 }} />
                                <Typography textAlign={"left"} variant="h6" sx={{ ml: "10%" }} >
                                    <strong>Descuentos disponibles</strong>
                                </Typography>
                                {descuentos.map((item, index) => (
                                    <div key={item.nombre} >
                                        <Typography sx={{ ml: "15%", width: "33%", mt: 2, mb: 1 }} style={{ display: "inline-block" }}>
                                            {item.nombre} (${item.valor})
                                        </Typography>
                                        <label>
                                            Precio de venta
                                            <input
                                                type="checkbox"
                                                checked={pvValues[index]?.checkPv || false}
                                                onChange={(e) => handleCheckPvChange(index, e.target.checked)}
                                            />
                                        </label>
                                        {/* Checkbox Entrada */}
                                        <label style={{ marginLeft: '10px' }}>
                                            Entrada
                                            <input
                                                type="checkbox"
                                                checked={pvValues[index]?.checkEntrada || false}
                                                onChange={(e) => handleCheckEntradaChange(index, e.target.checked)}
                                            />
                                        </label>
                                        <TextField
                                            type="number"
                                            size="small"
                                            margin="normal"
                                            label="$"
                                            value={pvValues[index]?.valor || ''}
                                            onChange={(e) => handleInputChange(index, e.target.value)}
                                            sx={{ mt: 1, mb: 1, ml: "3%" }}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                },
                                            }}
                                        />
                                        {/* Checkbox PV */}

                                    </div>
                                ))}

                                {/* Campos para Descuento, Precio Especial y Matrícula en una línea */}


                                {/* Descuento */}
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '55%',
                                        ml: '15%'  // Alineación de la izquierda
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Descuento:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(descuentoTotal).replace("$", "$  ")}
                                    sx={{ mt: 1, mb: 1, width: '16%', mr: '1%', ml: '0%' }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />

                                {/* Precio Especial */}
                                <Divider sx={{ ml: "10%", mr: "10%", mt: 3, mb: 1 }} />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '25%',
                                        ml: '30%'  // Alineación de la izquierda
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Precio Especial:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(precioEspecial).replace("$", "$  ")}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 1, mb: 1, width: '16%' }}
                                />

                                {/* Matrícula */}
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '25%',
                                        ml: '30%'
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Matrícula:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={valorMatricula.replace("$", "$  ")} // Mostramos el valor de la matrícula calculada
                                    onChange={(e) => setMatricula(e.target.value)}
                                    sx={{ mt: 1, mb: 1, width: '16%' }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />
                                <Divider sx={{ ml: "3%", mr: "3%", mt: 3, mb: 1 }} />
                                <Typography sx={{ ml: "10%" }} variant="h6" >
                                    <strong>Formas de pago</strong>
                                </Typography>

                                {/* Campo para el Precio Especial en una línea */}

                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '25%',
                                        ml: '30%'
                                    }}
                                    style={{ display: 'inline-block' }}
                                    variant="h6"
                                >
                                    Contado:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(Number(precioEspecial)).replace("$", "$  ")}
                                    onChange={(e) => setPrecioEspecial(parseFloat(e.target.value))}
                                    sx={{ mt: 1, mb: 1, width: '16%' }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />
                                <Divider sx={{ ml: "10%", mr: "10%", mt: 3, mb: 1 }} />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '25%',
                                        ml: '30%'
                                    }}
                                    variant="h6"
                                >
                                    Crédito:
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '25%',
                                        ml: '30%'
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Porcentaje de entrada:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    value={porcentaje}
                                    onChange={(e) => { setPorcentaje(e.target.value); setValidarCambios(false); }}
                                    onBlur={handlePorcentajeBlur}
                                    label="%"
                                    sx={{ mt: 1, mb: 1, width: '16%' }}
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '25%',
                                        ml: '30%'
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Monto de entrada:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={entrada}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                    label="$"
                                    onChange={(e) => {
                                        setValidarCambios(false);
                                        setEntrada(e.target.value);
                                    }}
                                    onBlur={handleEntradaBlur}
                                    sx={{ mt: 1, mb: 1, width: '16%' }}
                                    InputProps={{

                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />

                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: '25%',
                                        ml: '30%'
                                    }}
                                    style={{ display: 'inline-block' }}
                                >
                                    Monto a Financiar:
                                </Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(Number(montoAFinanciar)).replace("$", "$  ")}
                                    onChange={(e) => setMontoAFinanciar(e.target.value)}
                                    sx={{ mt: 1, mb: 1, width: '16%' }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                />


                                <Divider sx={{ ml: "3%", mr: "3%", mt: 3, mb: 1 }} />
                                <Typography sx={{ ml: "10%" }} variant="h6" >
                                    <strong>Cuotas mensuales</strong>
                                </Typography>

                                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ ml: "5%", mr: "5%" }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked12}
                                                onChange={() => setChecked12(!checked12)}
                                            />
                                        }
                                        label="12 meses"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked24}
                                                onChange={() => setChecked24(!checked24)}
                                            />
                                        }
                                        label="24 meses"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked36}
                                                onChange={() => setChecked36(!checked36)}
                                            />
                                        }
                                        label="36 meses"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked48}
                                                onChange={() => setChecked48(!checked48)}
                                            />
                                        }
                                        label="48 meses"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked60}
                                                onChange={() => setChecked60(!checked60)}
                                            />
                                        }
                                        label="60 meses"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked72}
                                                onChange={() => setChecked72(!checked72)}
                                            />
                                        }
                                        label="72 meses"
                                    />
                                </Box>

                                <TextField
                                    size="small"
                                    name="valorCuota12"
                                    margin="normal"
                                    type="number"
                                    disabled={checked12 ? false : true}
                                    label={"$"}
                                    onChange={handleChangeCuotas}
                                    value={dataCuotas.valorCuota12}
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 0, mb: 1, width: '11%', ml: "4%" }}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                />
                                <TextField
                                    size="small"
                                    name="valorCuota24"
                                    margin="normal"
                                    type="number"
                                    disabled={checked24 ? false : true}
                                    label={"$"}
                                    onChange={handleChangeCuotas}
                                    value={dataCuotas.valorCuota24}
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 0, mb: 1, width: '10%', ml: "6%" }}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                />
                                <TextField
                                    size="small"
                                    name="valorCuota36"
                                    margin="normal"
                                    type="number"
                                    disabled={checked36 ? false : true}
                                    label={"$"}
                                    onChange={handleChangeCuotas}
                                    value={dataCuotas.valorCuota36}
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 0, mb: 1, width: '10%', ml: "6%" }}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                />
                                <TextField
                                    size="small"
                                    name="valorCuota48"
                                    margin="normal"
                                    type="number"
                                    disabled={checked48 ? false : true}
                                    label={"$"}
                                    onChange={handleChangeCuotas}
                                    value={dataCuotas.valorCuota48}
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 0, mb: 1, width: '10%', ml: "6%" }}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                />
                                <TextField
                                    size="small"
                                    name="valorCuota60"
                                    margin="normal"
                                    type="number"
                                    disabled={checked60 ? false : true}
                                    label={"$"}
                                    onChange={handleChangeCuotas}
                                    value={dataCuotas.valorCuota60}
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 0, mb: 1, width: '10%', ml: "6%" }}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                />

                                <TextField
                                    size="small"
                                    name="valorCuota72"
                                    margin="normal"
                                    type="number"
                                    disabled={checked72 ? false : true}
                                    label={"$"}
                                    onChange={handleChangeCuotas}
                                    value={dataCuotas.valorCuota72}
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ mt: 0, mb: 1, width: '10%', ml: "6%" }}
                                    style={{ backgroundColor: "rgb(255,255,255)" }}
                                />


                                {/* Título */}
                                {/* <Divider sx={{ ml: "3%", mr: "3%", mt: 2, mb: 1 }} /> */}
                                <Typography textAlign={"left"} variant="h6" sx={{ ml: "10%" }}>
                                    <strong>Seguro vehicular</strong>
                                </Typography>

                                <Typography style={{ display: 'inline-block' }} sx={{ width: "10%", ml: "18%", mt: 2, mb: 1 }}>Vehículo</Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(Number(seguro)).replace("$", "$  ")}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ width: '12%', mt: 1, mb: 1 }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                />


                                <Typography style={{ display: 'inline-block' }} sx={{ width: "10%", ml: "10%", mt: 2, mb: 1 }}>12 Cuotas</Typography>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    value={formatter.format(Number(cuota)).replace("$", "$  ")}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                    }}
                                    sx={{ width: '12%', mt: 1, mb: 1 }}
                                    style={{ backgroundColor: "rgb(242,242,242)" }}
                                />

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => { calcularValores(); setearDatosCuotas(precioEspecial, Number(entrada)) }}
                                    sx={{ ml: "4%", width: "13%" }}
                                    disabled={precioEspecial > 0 ? false : true}
                                >
                                    Calcular
                                </Button>
                                <div>
                                    <Button

                                        onClick={async () => {
                                            if (!validarCampos()) {
                                                return; // Detiene la ejecución si hay campos vacíos
                                            }
                                            try {
                                                await enviarDatos(); // Solo se ejecuta si la validación es exitosa
                                            } catch (error) {
                                                console.error("Error al enviar los datos:", error);
                                            } finally {
                                                setLoading(false);
                                            }
                                        }}
                                        variant="contained"
                                        sx={{
                                            mt: 3,
                                            mb: 1,
                                            width: "12%",
                                            marginLeft: "44%",
                                            position: 'relative',
                                        }}
                                        disabled={loading === true ? true : validarCambios ? false : true} //|| validarCambios ? false : true //setLoading(true);
                                    >
                                        {loading ? (
                                            <>
                                                <CircularProgress size={24} sx={{ position: 'absolute' }} />
                                                {"Espere por favor"}
                                            </>
                                        ) : (
                                            "Enviar Cotización"
                                        )}
                                    </Button>

                                    {error && <div style={{ color: 'red' }}>{error}</div>} {/* Mostrar error si ocurre */}
                                </div>
                            </>}

                            <Dialog
                                open={openDialog}
                                //onClose={handleCloseDialog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Datos guardados correctamente
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        //href="/politicacomercial/lista"
                                        onClick={function (event) { }}
                                    //autoFocus
                                    >
                                        OK
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Paper>s

                    </Grid>
                </Grid>
                <Dialog
                    open={openDialogEnviadoOk}
                    //onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Cotización enviada correctamente
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={function (event) {
                                setOpenDialogEnviadoOk(false);
                            }}
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openDialogEnviadoError}
                    //onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Ocurrio un error
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={function (event) {
                                setOpenDialogEnviadoError(false);
                            }}
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        );
    } else {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Paper>
                    <Grid
                        //container
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Typography
                            sx={{ mt: 2, mb: 1.5 }}
                            variant="h6"
                            textAlign={"center"}
                        >
                            Acceso denegado
                        </Typography>
                    </Grid>
                </Paper>
            </Container>
        );
    }
};
