import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "31aba5b4-fd5c-4ed2-8acf-3cd09094fcc7",
        authority: "https://login.microsoftonline.com/afce2360-e586-4402-b97b-3fa3a8df7ab4",
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: "localStorage", // Cambiar a "sessionStorage" si prefieres que no persista después de cerrar el navegador
        storeAuthStateInCookie: false // Cambiar a true si tienes problemas con navegadores antiguos
    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["User.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};